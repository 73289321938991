import { useUnityBuildStore } from "../states/store";
import { ResetStates } from "../states/resetStates";
import { useStore } from "zustand";
import { useCloseoutStore } from "../states/closeoutStore";
import { useCreateAssemblyStore } from "../states/createAssemblyStore";

export const useHandleSetCreateStates = () => {
  const store1 = useStore(useUnityBuildStore);
  const store2 = useStore(useCloseoutStore);
  const store3 = useStore(useCreateAssemblyStore);

  return (setStateFunction: Function, bool: boolean) => {
    ResetStates.resetTakeoffStates(store1, store3);
    ResetStates.resetCloseoutStates(store2);
    setStateFunction(bool);
  };
};
