export const keepBoxInView = (
  cursorX: number | null,
  cursorY: number | null,
  boxWidth: number,
  boxHeight: number,
  windowWidth: number,
  windowHeight: number
) => {
  if (cursorX !== null && cursorY !== null) {
    // Start with the box positioned at the cursor
    let boxX = cursorX; // Top-left X coordinate
    let boxY = cursorY; // Top-left Y coordinate

    // Calculate the maximum coordinates
    const boxXMax = boxX + boxWidth;
    const boxYMax = boxY + boxHeight;

    // Adjust position if the box goes out of the viewport
    if (boxX < 0) {
      boxX = 0; // Box is too far left
    } else if (boxXMax > windowWidth) {
      boxX = windowWidth - (boxWidth + boxWidth / 2); // Box is too far right
    }

    if (boxY < 0) {
      boxY = 0; // Box is too far up
    } else if (boxYMax > windowHeight) {
      // If it would go off the bottom, adjust to display from bottom left
      boxY = cursorY - (boxHeight + boxHeight / 2); // Position from the bottom
      if (boxY < 0) {
        boxY = 0; // Ensure it doesn't go off the top
      }
    }

    return { x: boxX, y: boxY };
  }

  // Default position if cursor is null
  return { x: 0, y: 0 };
};
