import "../App.css";
import AppBar from "@mui/material/AppBar";
import { angleOptionMultipliers, useUnityBuildStore } from "../states/store";
import { ResetStates } from "../states/resetStates";
import { useSubmitTakeoffAssembly } from "../hooks/useSubmitTakeoffAssembly";
import { useFetchCountedAssemblies } from "../hooks/useFetchCountedAssemblies";
import { useEffect, useRef, useState } from "react";
import { CustomScale } from "./CustomScale";
import { useStore } from "zustand";
import { useInferenceStore } from "../states/inferenceStore";
import { useCreateAssemblyStore } from "../states/createAssemblyStore";
import { SCALE_OPTIONS } from "../utils/constants";
import { ToolBarHelper } from "../hooks/toolbarHooks";
import { UploadObject } from "./objectComponents/UploadObject";
import { useFetchObjects } from "../hooks/objectHooks";

export const ToolBar = () => {
  const ubs = useStore(useUnityBuildStore);
  const store1 = useStore(useCreateAssemblyStore);
  const { setInferenceItemFilter } = useStore(useInferenceStore);

  const { data: fetchedAssemblies } = useFetchCountedAssemblies();
  const { data: availableObjects, refetchObjects } = useFetchObjects();

  const [isCreatingScale, setIsCreatingScale] = useState<boolean>(false);
  const [inputPage, setInputPage] = useState<number>(ubs.currentPage);
  const [isViewingFileManager, setIsViewingFileManager] = useState(false); // Controls UploadObject visibility

  const submitAssembly = useSubmitTakeoffAssembly();
  const currentObjectRef = useRef(0);
  const currentPageIndex = ubs.currentPage - 1;

  // useEffect to handle the submission after userScale is set
  useEffect(() => {
    const submitAssembliesWithUpdatedScale = async () => {
      if (fetchedAssemblies && fetchedAssemblies.length > 0 && ubs.userScale) {
        for (const assembly of fetchedAssemblies) {
          if (
            (assembly.assemblymeasurementtype === "length" ||
              assembly.assemblymeasurementtype === "area") &&
            assembly.pagenumber === ubs.currentPage
          ) {
            await submitAssembly({
              assembly,
              isUpdating: true,
            });
          }
        }
      }
      refetchObjects();
    };

    submitAssembliesWithUpdatedScale();
    //eslint-disable-next-line
  }, [ubs.userScale]);

  const handleIsViewingTakeoffPallette = () => {
    ubs.setIsViewingTakeoffPallette(!ubs.isViewingTakeoffPallette);
  };

  // Handle File Manager display toggle
  const handleFileManager = () => {
    setIsViewingFileManager(!isViewingFileManager);
  };

  const handleGetObject = (e: any) => {
    if (availableObjects) {
      ToolBarHelper.handleGetObject(
        e,
        availableObjects,
        setInputPage,
        ubs.setSelectedObjectId,
        ubs.setSelectedObjectName,
        ubs.setUserScaleList,
        ubs.setUserScale,
        ubs.numPages,
        currentPageIndex,
        currentObjectRef
      );
    }
  };

  const handleSetPage = (e: any) => {
    if (availableObjects) {
      ToolBarHelper.handleSetPage(
        e,
        ubs.numPages,
        setInputPage,
        ubs.setCurrentPage,
        ubs.setUserScale,
        availableObjects,
        ubs.selectedObjectId
      );
    }
  };

  const handleNextPage = () => {
    if (availableObjects) {
      ToolBarHelper.handleNextPage(
        ubs.currentPage,
        ubs.numPages,
        ubs.setCurrentPage,
        setInputPage,
        ubs.setUserScale,
        availableObjects,
        ubs.selectedObjectId,
        setInferenceItemFilter,
        ubs,
        store1
      );
    }
  };

  const handlePrevPage = () => {
    if (availableObjects) {
      ToolBarHelper.handlePrevPage(
        ubs.currentPage,
        ubs.setCurrentPage,
        setInputPage,
        ubs.setUserScale,
        availableObjects,
        ubs.selectedObjectId,
        setInferenceItemFilter,
        ubs,
        store1
      );
    }
  };

  let feet = 0;
  let inches = 0;

  if (ubs.selectedTakeoffAssembly) {
    const result = ToolBarHelper.extractSegmentLength(
      ubs.selectedTakeoffAssembly
    );
    feet = result.feet;
    inches = result.inches;
  }

  const handleSetScale = (e: React.ChangeEvent<HTMLSelectElement>) => {
    ToolBarHelper.handleSetScale(
      e,
      ubs.currentPage,
      ubs.setUserScale,
      setIsCreatingScale,
      ubs.setUserScaleList,
      ubs.selectedObjectId,
      ubs.userScaleList,
      refetchObjects
    );
  };

  const handleEnableCrosshair = () => {
    ubs.setEnableCrosshair(!ubs.enableCrosshair);
  };

  const handleMeasure = () => {
    ResetStates.resetTakeoffStates(ubs, store1);
    ubs.setIsAnnotating(!ubs.isAnnotating);
    ubs.setIsMeasuring(!ubs.isMeasuring);
  };

  const handleSetSnaps = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let angleOption = parseInt(e.target.value, 10);

    const angleOptions = Array.from(
      { length: 360 / angleOption },
      (_, i) => i * angleOption
    );
    ubs.setAngleOptions(angleOptions);
  };

  return (
    <>
      <AppBar position="relative">
        <div
          className="toolbar"
          style={{ backgroundColor: ubs.userBackgroundColor }}
        >
          <label
            key="drawings"
            style={{ marginRight: "5px", marginLeft: "5px" }}
          >
            <button onClick={handleFileManager}>Document:</button>
          </label>
          <select
            onChange={handleGetObject}
            style={{ margin: "1px", cursor: "pointer" }}
            defaultValue=""
          >
            <option value="" disabled>
              Select a document
            </option>
            {availableObjects &&
              availableObjects.map((option, index) => (
                <option
                  key={index}
                  value={`${option.id},${option.gcsObjectName}`}
                >
                  {option.objectName}
                </option>
              ))}
          </select>
          <label style={{ marginRight: "5px", marginLeft: "5px" }}>
            Pages: {ubs.currentPage}/{ubs.numPages}
          </label>
          <button onClick={handlePrevPage} style={{ marginLeft: "5px" }}>
            Prev
          </button>
          <button onClick={handleNextPage} style={{ marginRight: "5px" }}>
            Next
          </button>
          <input
            type="number"
            value={inputPage}
            onChange={handleSetPage}
            min={0}
            max={ubs.numPages}
            onBlur={(e) => {
              const page = parseInt(e.target.value, 10);
              if (!isNaN(page) && page >= 1 && page <= ubs.numPages) {
                ubs.setCurrentPage(page);
              } else {
                setInputPage(ubs.currentPage);
              }
            }}
          />
          <label key="Scale" style={{ marginRight: "5px", marginLeft: "5px" }}>
            Scale:
            <select
              onChange={handleSetScale}
              value={ubs.userScale}
              style={{ margin: "1px", cursor: "pointer" }}
            >
              {SCALE_OPTIONS.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </label>
          <button
            onClick={handleEnableCrosshair}
            className="button"
            style={{ color: ubs.enableCrosshair ? "blue" : "black" }}
          >
            Crosshair
          </button>
          <label style={{ marginRight: "5px", marginLeft: "5px" }}>
            Angle Snap:
            <select onChange={handleSetSnaps} style={{ marginLeft: "10px" }}>
              {angleOptionMultipliers.map((angle) => (
                <option key={angle} value={angle}>
                  {angle}
                </option>
              ))}
            </select>
          </label>
          <button
            onClick={handleMeasure}
            className="button"
            style={{ color: ubs.isMeasuring ? "blue" : "black" }}
          >
            Measure
          </button>
          <label style={{ marginRight: "5px", marginLeft: "5px" }}>
            {ubs.selectedTakeoffAssembly
              ? ubs.selectedTakeoffAssembly.assemblyname
              : `No Item Selected`}
          </label>
          <label style={{ marginRight: "5px", marginLeft: "5px" }}>
            {ubs.selectedTakeoffAssembly
              ? `Count: ${ubs.selectedTakeoffAssembly?.pointsList?.length ?? 0}`
              : ``}
          </label>
          <label style={{ marginRight: "5px", marginLeft: "5px" }}>
            {ubs.selectedTakeoffAssembly
              ? `Total Length: ${feet}' ${inches}"`
              : ``}
          </label>
          <button
            onClick={handleIsViewingTakeoffPallette}
            style={{ position: "absolute", right: "20px" }}
          >
            {ubs.isViewingTakeoffPallette ? "Hide" : "Show"} Takeoff Pallette
          </button>
        </div>
        {isCreatingScale && <CustomScale />}
      </AppBar>

      {/* Conditionally render UploadObject when file manager is active */}

      {isViewingFileManager && (
        <UploadObject onClose={() => setIsViewingFileManager(false)} />
      )}
    </>
  );
};
