import { useFetchAssembliesByFileId } from "../../hooks/useFetchAssembliesByFileId";
import { Inference } from "../../hooks/inferenceHooks";
import { useSetSelectedAssemblyFromAssemblyPicker } from "../../hooks/useSetSelectedAssembly";
import { CircularProgress } from "@mui/material";
import { AssemblyTableProps } from "../../types/FolderTypes";
import { GlobalAssembly } from "../../api/protosCompiled/globalAssembly/globalAssembly_pb";

export function AssemblyTable({
  folderOrFile,
  handleRightClick,
  refetchFolders,
}: AssemblyTableProps) {
  const { data: globalAssemblies, isLoading } = useFetchAssembliesByFileId(
    folderOrFile.folderid
  );
  const { data: classifications } = Inference.useFetchClass();

  const setSelectedAssembly = useSetSelectedAssemblyFromAssemblyPicker();

  if (!globalAssemblies || isLoading) {
    return (
      <tr>
        <td colSpan={3}>
          <CircularProgress />
        </td>
      </tr>
    );
  }

  return (
    <>
      {globalAssemblies.map((assembly: GlobalAssembly.AsObject) => (
        <tr
          key={assembly.globalassemblyid}
          onClick={() => setSelectedAssembly(assembly)}
          onContextMenu={(e) => handleRightClick(e, assembly)}
          style={{ cursor: "pointer" }}
        >
          <td>{assembly.assemblyname}</td>
          <td>{assembly.assemblymeasurementtype}</td>
          <td>
            {
              classifications?.find(
                (c) => c.id === assembly.imageclassificationid.toString()
              )?.classname
            }
          </td>
        </tr>
      ))}
    </>
  );
}
