import { Button, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Folder as FolderProto } from "../../api/protosCompiled/folder/folder_pb";
import { useFetchFolders } from "../../hooks/useFetchFolders";
import { Entitytype } from "../../types/FolderTypes";
import { manageFolder } from "./helpers";

export interface FolderToRename extends FolderProto.AsObject {
  assemblyname?: string;
}

export const FolderCreationForm = ({
  parentFolderId,
  onFolderCreated,
  folderToRename,
  entitytype,
}: {
  parentFolderId?: string | null;
  onFolderCreated: () => void;
  folderToRename?: FolderToRename | null;
  entitytype: Entitytype;
}) => {
  const { refetchFolders } = useFetchFolders();
  const [folderName, setFolderName] = useState("");

  useEffect(() => {
    const folderName =
      folderToRename?.name || folderToRename?.assemblyname || "";

    if (folderToRename) {
      setFolderName(folderName);
    }
  }, [folderToRename]);

  const handleCreateOrRenameFolder = async () => {
    await manageFolder({
      folder: folderToRename || undefined,
      folderName: folderName,
      parentFolderId: parentFolderId || "",
      isFile: false,
      entitytype: entitytype,
      isActive: true,
      refetchFolders,
      onSuccess: () => {
        onFolderCreated();
        setFolderName("");
      },
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {/* Use formRef to detect clicks outside */}
      <TextField
        label={folderToRename ? "Rename Folder" : "New Folder Name"}
        value={folderName}
        onChange={(e) => setFolderName(e.target.value)}
      />
      <Button onClick={handleCreateOrRenameFolder} disabled={!folderName}>
        {folderToRename ? "Rename" : "Create"} Folder
      </Button>
    </div>
  );
};
