import React, { useEffect, useState } from "react";
import { Calculations as c } from "../../utils/calculations";
import { QuoteRowProps } from "./closeoutTypes";

export const QuoteRow: React.FC<
  QuoteRowProps & {
    handleFieldUpdate: (field: string, value: number) => void;
    handleItemUpdate: (itemIndex: number, field: string, value: number) => void;
  }
> = ({ quote, handleFieldUpdate, handleItemUpdate }) => {
  const [quotedCost, setQuotedCost] = useState<string>(
    quote.quotedcost.toFixed(2)
  );
  const [adjustedPercent, setAdjustedPercent] = useState<string>(
    (quote.adjustedpercent * 100).toFixed(2)
  );

  // For sub-items, store their costs as strings locally too
  const [itemCosts, setItemCosts] = useState<string[]>(
    quote.quoteitemsList.map((item) => item.itemcost.toFixed(2))
  );

  useEffect(() => {
    setQuotedCost(quote.quotedcost.toFixed(2));
    setAdjustedPercent((quote.adjustedpercent * 100).toFixed(2));
    setItemCosts(quote.quoteitemsList.map((item) => item.itemcost.toFixed(2)));
  }, [quote]);

  const floatRegex = /^[0-9]{0,10}(\.[0-9]{0,2})?$/;

  const handleFieldFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  // ---------------- PARENT ROW FIELDS ----------------
  const handleFieldChange = (field: string, value: string) => {
    // Allow empty or valid float formats
    if (value === "" || floatRegex.test(value)) {
      if (field === "quotedCost") {
        setQuotedCost(value);
      } else if (field === "adjustedPercent") {
        setAdjustedPercent(value);
      }
    }
  };

  const handleFieldBlur = (field: string, value: string) => {
    const floatValue = parseFloat(value) || 0;
    if (field === "quotedCost") {
      setQuotedCost(floatValue.toFixed(2));
      handleFieldUpdate(field, floatValue);
    } else if (field === "adjustedPercent") {
      const adjusted = floatValue / 100;
      setAdjustedPercent((adjusted * 100).toFixed(2));
      handleFieldUpdate(field, adjusted);
    }
  };

  // ---------------- SUBTABLE ITEM FIELDS ----------------
  const handleSubItemFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handleSubItemChange = (itemIndex: number, value: string) => {
    // Only update local state here
    if (value === "" || floatRegex.test(value)) {
      const updatedItemCosts = [...itemCosts];
      updatedItemCosts[itemIndex] = value;
      setItemCosts(updatedItemCosts);
    }
  };

  const handleSubItemBlur = (itemIndex: number, value: string) => {
    const floatValue = parseFloat(value) || 0;
    // On blur, finalize the value to two decimals and update store
    const updatedItemCosts = [...itemCosts];
    updatedItemCosts[itemIndex] = floatValue.toFixed(2);
    setItemCosts(updatedItemCosts);

    handleItemUpdate(itemIndex, "itemcost", floatValue);
  };

  const fullCost = c.calculateQuoteTotal(quote);

  return (
    <>
      <tr>
        <td>
          <strong>{quote.quotegroup}</strong>
        </td>
        <td>
          $
          <input
            type="text"
            value={quotedCost}
            onFocus={handleFieldFocus}
            onChange={(e) => handleFieldChange("quotedCost", e.target.value)}
            onBlur={(e) => handleFieldBlur("quotedCost", e.target.value)}
          />
        </td>
        <td>
          <input
            type="text"
            value={adjustedPercent}
            onFocus={handleFieldFocus}
            onChange={(e) =>
              handleFieldChange("adjustedPercent", e.target.value)
            }
            onBlur={(e) => handleFieldBlur("adjustedPercent", e.target.value)}
          />
        </td>
        <td>${fullCost.toFixed(2)}</td>
      </tr>
      {quote.quoteitemsList.length > 0 && (
        <tr>
          <td colSpan={4}>
            <table style={{ marginLeft: "20px", width: "95%" }}>
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Quantity</th>
                  <th>Cost</th>
                  <th>Total Item Cost</th>
                </tr>
              </thead>
              <tbody>
                {quote.quoteitemsList.map((item, itemIndex) => {
                  const displayCost = itemCosts[itemIndex] || "0.00";
                  const totalItemCost = (
                    item.itemqty * parseFloat(displayCost || "0")
                  ).toFixed(2);
                  return (
                    <tr key={itemIndex}>
                      <td>{item.itemname}</td>
                      <td>{item.itemqty}</td>
                      <td>
                        $
                        <input
                          type="text"
                          value={displayCost}
                          onFocus={handleSubItemFocus}
                          onChange={(e) =>
                            handleSubItemChange(itemIndex, e.target.value)
                          }
                          onBlur={(e) =>
                            handleSubItemBlur(itemIndex, e.target.value)
                          }
                        />
                      </td>
                      <td>${totalItemCost}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
};
