import { ChangeOrderItem } from "../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import {
  TakeoffStats,
  EstimateItem,
} from "../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import {
  EquipmentType,
  ExtensionType,
  GeneralExpenseType,
  QuoteType,
  SubcontractType,
} from "../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { Point } from "../types/Point";

/**
 * Calculations is a namespace that contains various utility functions for calculations
 */
export namespace Calculations {
  /**
   * UOM is a type that represents the unit of measure of an item
   */

  /**
   * calculateDistance calculates the distance between two points and returns number of pixels between them
   * @param startPoint - The starting point of the distance
   * @param endPoint - The ending point of the distance
   * @returns The distance in pixels
   */
  export const calculateDistance = (
    startPoint: { x: number; y: number },
    endPoint: { x: number; y: number }
  ): number => {
    const dx = endPoint.x - startPoint.x;
    const dy = endPoint.y - startPoint.y;
    return Math.sqrt(dx * dx + dy * dy);
  };

  /**
   * convertPixelsToInch converts the dimensions of a viewport in pixels to inches
   * @param viewportHeight - The height of the viewport in pixels
   * @param viewportWidth - The width of the viewport in pixels
   * @param ppi - The resolution of the viewport in pixels per inch
   * @returns An object containing the height and width of the viewport in inches
   */
  export const convertPixelsToInch = (
    viewportHeight: number,
    viewportWidth: number,
    ppi: number
  ) => ({
    heightInInches: viewportHeight / ppi,
    widthInInches: viewportWidth / ppi,
  });

  /**
   * calculateTotalDistance calculates the total distance between all points in the pointsList
   * and returns the value in inches determined by the user scale
   * @param pointsList - An array of points
   * @param userScale - The user scale of the assembly
   * @param measurementType - The measurement type of the assembly
   * @returns The total distance in inches
   */
  type TotalDistanceMeasurementType = "length" | "area";
  export const calculateTotalDistance = (
    pointsList: Array<Point>,
    userScale: number,
    measurementType: TotalDistanceMeasurementType
  ): number => {
    if (!pointsList || pointsList.length < 2) {
      console.log("Not enough points to calculate total length");
      return 0;
    } else if (!userScale) {
      console.log("No user scale provided");
      return 0;
    }

    let totalVerticalLength = 0;
    for (let i = 0; i < pointsList.length; i++) {
      const point = pointsList[i];
      if (point.verticallength !== undefined) {
        totalVerticalLength += point.verticallength;
      }
    }

    let totalFeet = 0;
    const numPoints = pointsList.length;

    // Calculate distances between consecutive points
    for (let i = 0; i < numPoints - 1; i++) {
      const point = pointsList[i];
      const nextPoint = pointsList[i + 1];
      const pixelDistance = Math.sqrt(
        (nextPoint.x - point.x) ** 2 + (nextPoint.y - point.y) ** 2
      );
      totalFeet += (pixelDistance / 72) * userScale; // Convert pixels to inches, then to feet using userScale
    }

    // If measurementType is "area", include the closing segment
    if (measurementType === "area") {
      const firstPoint = pointsList[0];
      const lastPoint = pointsList[numPoints - 1];
      const closingPixelDistance = Math.sqrt(
        (firstPoint.x - lastPoint.x) ** 2 + (firstPoint.y - lastPoint.y) ** 2
      );
      totalFeet += (closingPixelDistance / 72) * userScale;
    }

    return totalFeet + totalVerticalLength;
  };

  /**
   * materialCostPerUnit calculates the total material cost per unit
   * @param materialRate - The total material rate of the assembly
   * @param materialCount - The number of materials used in the assembly
   * @param uom - The unit of measure of the materials
   * @returns The total material cost per unit
   */
  export const materialCostPerUnit = (
    estimateitem?: EstimateItem.AsObject,
    estimateitemWithOverrides?: ExtensionType.AsObject
  ) => {
    let unitPrice = 0;
    if (estimateitem) {
      unitPrice = safeDivide(
        estimateitem.costsList[0]?.itemunitcost,
        estimateitem.costsList[0]?.uomvalue
      );
    } else if (estimateitemWithOverrides) {
      unitPrice = safeDivide(
        estimateitemWithOverrides.itemunitcost,
        estimateitemWithOverrides.itemuomvalue
      );
    }

    return unitPrice ? unitPrice : 0;
  };

  export const calulateTotalMaterialCost = (
    qty: number,
    unitCost: number
  ): number => {
    return qty * unitCost;
  };

  //calculate the total number of labor hours using the items UOM
  export const calculateTotalLaborHours = (
    calculatedQuantity: number,
    estimateitem?: EstimateItem.AsObject,
    estimateitemWithOverrides?: ExtensionType.AsObject
  ) => {
    let laborHours = 0;
    if (estimateitem) {
      laborHours =
        safeDivide(calculatedQuantity, estimateitem.costsList[0]?.uomvalue) *
        estimateitem.costsList[0]?.hourlyproductionrate;
    } else if (estimateitemWithOverrides) {
      laborHours =
        safeDivide(calculatedQuantity, estimateitemWithOverrides.itemuomvalue) *
        estimateitemWithOverrides.hourlyproductionrate;
    }
    return laborHours ? laborHours : 0;
  };

  /**
   * laborCostPerUnit calculates the total labor cost per unit
   * @param laborHours
   * @param laborrate
   * @returns The total labor cost per unit
   */
  export const laborCostPerUnit = (
    laborHours: number,
    laborrate: number
  ): number => {
    return laborHours * laborrate;
  };

  export const roundToTwoDecimals = (num: number) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  /**
   * Calculates the subtotal labor cost
   * @param labor - The labor object containing labor details
   * @returns The subtotal labor cost
   */
  export const calculateLaborSubtotal = (labor: any): number => {
    return labor.laborrate * labor.allocatedhours;
  };

  /**
   * Calculates the total burden cost
   * @param labor - The labor object containing labor details
   * @returns The total burden cost
   */
  export const calculateBurdenTotal = (labor: any): number => {
    return labor.laborrate * labor.allocatedhours * labor.burdenpercent;
  };

  /**
   * Calculates the total fringe cost
   * @param labor - The labor object containing labor details
   * @returns The total fringe cost
   */
  export const calculateFringeTotal = (labor: any): number => {
    return labor.allocatedhours * labor.fringe;
  };

  /**
   * Calculates the full hourly cost including labor rate, burden, and fringe
   * @param labor - The labor object containing labor details
   * @returns The full hourly cost
   */
  export const calculateFullHoursCost = (labor: any): number => {
    return labor.laborrate * (1 + labor.burdenpercent) + labor.fringe;
  };

  /**
   * Calculates the full cost based on the full hourly cost and allocated hours
   * @param labor - The labor object containing labor details
   * @returns The full cost
   */
  export const calculateFullLaborCost = (labor: any): number => {
    const fullHoursCost = calculateFullHoursCost(labor);
    return fullHoursCost * labor.allocatedhours;
  };

  /**
   * Calculates the allocated hours based on total labor hours and distribution percent
   * @param totalLaborHours - The total labor hours available
   * @param distributionpercent - The distribution percent in decimal form
   * @returns The allocated hours
   */
  export const calculateAllocatedHours = (
    totalLaborHours: number,
    distributionpercent: number
  ): number => {
    return totalLaborHours * distributionpercent;
  };

  /**
   * Calculates the quantity of the item based on the takeoff variable type and the quantity of the takeoff variable.
   *
   * @param item - The ItemQtyFormula object containing the item details.
   * @param takeoffVariable - The TakeoffVariableQty object containing the takeoff variable values.
   * @returns The calculated quantity of the item.
   *
   * @example
   * const itemQty = Calculations.getItemQtyByTakeoffVariable(item, takeoffVariable);
   * console.log(itemQty);
   */
  export const getItemQtyByTakeoffVariable = (
    assembly: TakeoffStats.AsObject,
    item: EstimateItem.AsObject | ChangeOrderItem.AsObject
  ): number => {
    let totalQty = 0;

    for (const formula of item.itemqtyformulasList) {
      switch (formula.takeoffvariabletype) {
        case "point":
          totalQty += Math.round(assembly.totalpointcount * formula.itemqty);
          break;

        case "segment":
          totalQty += Math.round(assembly.totalsegmentcount * formula.itemqty);
          break;

        case "length":
          totalQty += Math.round(
            (assembly.totalsegmentlength * formula.itemqty) / formula.forevery
          );
          break;
        case "area":
          totalQty += Math.round(assembly.totalsqft * formula.itemqty);
          break;

        default:
          break;
      }
    }
    if (totalQty < 0) {
      totalQty = 0;
    }

    return totalQty;
  };

  export const calculateQuantitiesForAssembly = (
    assembly: TakeoffStats.AsObject
  ) => {
    const uniqueItemsMap: { [key: string]: EstimateItem.AsObject } = {};

    assembly.globalitemsList.forEach((item: EstimateItem.AsObject) => {
      const attributesKey =
        item.itemqtyformulasList?.[0]?.attributesList
          .map((attr) => attr.attributevalue)
          .join(", ") || "";

      const uniqueKey = `${attributesKey}-${item.itemqtyformulasList?.[0]?.itemname}`;

      // Add to the map if the item doesn't already exist
      if (!uniqueItemsMap[uniqueKey]) {
        uniqueItemsMap[uniqueKey] = item;
      }
    });

    // Now calculate quantities for the unique items
    return Object.values(uniqueItemsMap).map((item) => {
      const itemQuantity = Calculations.getItemQtyByTakeoffVariable(
        assembly,
        item
      );
      return { ...item, calculatedQuantity: itemQuantity };
    });
  };

  export const aggregateItemQuantities = (
    assemblyStats: TakeoffStats.AsObject[]
  ) => {
    const itemMap: {
      [key: string]: EstimateItem.AsObject & { calculatedQuantity: number };
    } = {};

    assemblyStats.forEach((assembly) => {
      const itemsWithQuantities = calculateQuantitiesForAssembly(assembly);

      itemsWithQuantities.forEach((item) => {
        const formulaAttributes =
          item.itemqtyformulasList?.[0]?.attributesList ?? [];

        const attributeValues =
          formulaAttributes.map((attr) => attr.attributevalue).join(", ") || "";

        if (!attributeValues) return;

        // Determine which key to use based on whether the item is quoted
        const isQuoted = item.itemqtyformulasList?.[0].isquoted === true;
        const assemblyName = item.itemqtyformulasList?.[0]?.assemblyname || "";

        // Strip any "(n)" suffixes from the item name if we're using it
        const baseItemName = item.itemname.replace(/\s\(\d+\)$/, "");

        // Use assemblyName if quoted, otherwise use baseItemName
        const keyName = isQuoted ? assemblyName : baseItemName;

        // Build the unique key based on the chosen name
        const uniqueKey = `${attributeValues} - ${keyName}`;

        if (!itemMap[uniqueKey]) {
          // Initialize the item in the map, making sure we store the chosen name
          itemMap[uniqueKey] = { ...item, itemname: keyName };
        } else {
          // Aggregate the calculatedQuantity
          itemMap[uniqueKey].calculatedQuantity += item.calculatedQuantity;
        }
      });
    });

    return Object.values(itemMap);
  };

  /**
   * Calculates the subtotal general expense cost
   * @param generalExpense - The general expense object containing general expense details
   * @returns The subtotal general expense cost
   */
  export const calculateGenExpenseSubtotal = (
    generalExpense: GeneralExpenseType.AsObject
  ): number => {
    return generalExpense.unitcost * generalExpense.quantity;
  };

  /**
   * Calculates the total general expense cost
   * @param generalExpense - The general expense object containing general expense details
   * @returns The total general expense cost
   */
  export const calculateGenExpenseTotal = (
    generalExpense: GeneralExpenseType.AsObject
  ): number => {
    return (
      generalExpense.quantity *
      generalExpense.unitcost *
      generalExpense.duration
    );
  };

  /**
   * calculateEquipmentTotal is used for calculating the total cost of equipment
   * @param equipment - The equipment to calculate the total cost for
   * @returns The total cost of the equipment
   */
  export const calculateEquipmentTotal = (
    equipment: EquipmentType.AsObject
  ): number => {
    return equipment.quantity * equipment.unitcost * equipment.duration;
  };

  /**
   * calculateSubcontractTotal is used for calculating the total cost of subcontract
   * @param subcontract - The subcontract to calculate the total cost for
   * @param subcontract.adjustedPercent - The percentage in decimal form
   * @returns The total cost of the subcontract
   */
  export const calculateSubcontractTotal = (
    subcontract: SubcontractType.AsObject
  ): number => {
    return (
      subcontract.quotedcost * subcontract.adjustedpercent +
      subcontract.quotedcost
    );
  };

  /**
   * calculateQuoteTotal is used for calculating the total cost of a quote
   * @param quote - The quote to calculate the total cost for
   * @param quote.adjustedPercent - The percentage in decimal form
   * @returns The total cost of the quote
   */
  // Assuming quote: QuoteType.AsObject
  export function calculateQuoteTotal(quote: QuoteType.AsObject): number {
    const sumOfItems = quote.quoteitemsList.reduce(
      (sum, item) => sum + item.itemqty * item.itemcost,
      0
    );

    // quotedcost is added directly
    const combinedCost = quote.quotedcost + sumOfItems;

    // adjustedpercent is a multiplier, if quote.adjustedpercent = 0.10 means 10%
    const adjustedMultiplier = 1 + quote.adjustedpercent;

    return combinedCost * adjustedMultiplier;
  }

  export const safeDivide = (numerator: number, denominator: number) => {
    if (numerator === undefined || denominator === undefined) return 0;
    return denominator === 0 ? 0 : numerator / denominator;
  };
}
