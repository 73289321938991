import { clients } from "../clients/grpcClients";
import {
  CheckoutItem,
  CheckoutSessionRequest,
  CheckoutSessionResponse,
  SessionStatusRequest,
  SessionStatusResponse,
  StripeMetadata, // Import the metadata message
} from "./protosCompiled/checkout/checkout_pb";

export class CheckoutService {
  public static createCheckoutSession = (
    items: CheckoutItem.AsObject[]
  ): Promise<CheckoutSessionResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const request = new CheckoutSessionRequest();

      // Add each item to the request
      items.forEach((item) => {
        const checkoutItem = new CheckoutItem();
        checkoutItem.setPriceId(item.priceId);
        checkoutItem.setQuantity(item.quantity);

        // Set metadata if provided
        if (item.metadata) {
          const metadata = new StripeMetadata();
          metadata.setUbAccountId(item.metadata.ubAccountId);
          metadata.setNumSeats(item.metadata.numSeats);
          checkoutItem.setMetadata(metadata);
        }

        request.addItems(checkoutItem);
      });

      clients.checkoutServiceClient.createCheckoutSession(
        request,
        {}, // Pass an empty object for additional metadata if needed
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          if (response) {
            resolve(response.toObject());
          } else {
            reject(new Error("No response received"));
          }
        }
      );
    });
  };

  public static retrieveCheckoutSession = (
    sessionId: string
  ): Promise<SessionStatusResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const request = new SessionStatusRequest();
      request.setSessionId(sessionId);

      clients.checkoutServiceClient.retrieveCheckoutSession(
        request,
        {}, // Pass an empty object or null if additional metadata is required but not needed
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          if (response) {
            resolve(response.toObject());
          } else {
            reject(new Error("No response received"));
          }
        }
      );
    });
  };
}
