import {
  CreateProjectEstimateRequest,
  CreateProjectEstimateResponse,
  GetProjectEstimatesRequest,
  GetProjectEstimatesResponse,
  Estimate,
  UpdateProjectEstimateRequest,
  UpdateProjectEstimateResponse,
} from "./protosCompiled/projectEstimate/projectEstimate_pb";
import { clients } from "../clients/grpcClients";
import { useUnityBuildStore } from "../states/store";

export class ProjectEstimateService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  public static createProjectEstimate = (
    data: Estimate.AsObject
  ): Promise<CreateProjectEstimateResponse> => {
    const pe = new Estimate();
    pe.setDescription(data.description || ""); // Add default to avoid undefined
    pe.setDuedate(data.duedate || ""); // Add default to avoid undefined
    pe.setProjectid(data.projectid || "");
    pe.setName(data.name || "");
    pe.setCustomid(data.customid || "");

    // Build the CreateProjectEstimateRequest and attach the project estimate
    const req = new CreateProjectEstimateRequest();
    req.setSessiontoken(this.getMetadata().sessionToken || ""); // Ensure token is passed
    req.setEstimate(pe); // Attach the ProjectEstimate object

    // Return the promise with proper error handling
    return new Promise((resolve, reject) => {
      clients.projectEstimateClient.createProjectEstimate(
        req,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err); // Reject with error if there's an issue
            return;
          }
          resolve(response.toObject()); // Resolve with response if successful
        }
      );
    });
  };

  public static fetchEstimates = (
    projectId: string
  ): Promise<GetProjectEstimatesResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const request = new GetProjectEstimatesRequest();
      request.setProjectid(projectId);
      request.setSessiontoken(this.getMetadata().sessionToken);
      clients.projectEstimateClient.getProjectEstimates(
        request,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static updateProjectEstimate = (
    data: Estimate.AsObject
  ): Promise<UpdateProjectEstimateResponse.AsObject> => {
    // Build the ProjectEstimate object
    const estimate = new Estimate();
    estimate.setEstimateid(data.estimateid || ""); // Add default to avoid undefined
    estimate.setDescription(data.description || ""); // Add default to avoid undefined
    estimate.setDuedate(data.duedate || ""); // Add default to avoid undefined
    estimate.setProjectid(data.projectid || "");
    estimate.setName(data.name || "");
    estimate.setCustomid(data.customid || "");

    // Build the UpdateProjectEstimateRequest and attach the project estimate object
    const req = new UpdateProjectEstimateRequest();
    req.setSessiontoken(this.getMetadata().sessionToken || ""); // Ensure token is passed
    req.setEstimate(estimate); // Attach the ProjectEstimate object

    return new Promise((resolve, reject) => {
      clients.projectEstimateClient.updateProjectEstimate(
        req,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject()); // Resolve with response if successful
        }
      );
    });
  };
}
