import { useState } from "react";
import {
  AttributeGroup,
  AttributeValue,
} from "../../../api/protosCompiled/globalItem/globalItem_pb";
import { ItemService } from "../../../api/ItemService";
import { useFetchAttributeGroups } from "../../../hooks/useFetchAttributeGroups";
import { useMutation, useQueryClient } from "react-query";

export const CreateAttribute = () => {
  const queryClient = useQueryClient();
  // State variables
  const [newAttributeGroupName, setNewAttributeGroupName] = useState("");
  const [editingAttributeGroupId, setEditingAttributeGroupId] = useState<
    number | null
  >(null);
  const [newAttributeValue, setNewAttributeValue] = useState("");
  const [editingAttributeValueId, setEditingAttributeValueId] = useState<
    number | null
  >(null);

  // Fetch attribute groups
  const { data: attributeGroups } = useFetchAttributeGroups();

  // Find the selected attribute group
  const selectedAttributeGroup = attributeGroups?.find(
    (group) => group.attributegroupid === editingAttributeGroupId
  );

  // Extract attribute values
  const attributeValues = selectedAttributeGroup?.attributevaluesList || [];

  // Mutations
  const { mutateAsync: createOrUpdateAttributeGroupMutate } = useMutation(
    ItemService.createOrUpdateAttributeGroup,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("attributeGroups");
        if (response && response.attributegroupid) {
          setEditingAttributeGroupId(response.attributegroupid);
        }
      },
    }
  );

  const { mutateAsync: createOrUpdateAttributeValueMutate } = useMutation(
    ItemService.createOrUpdateAttributeValue,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("attributeGroups");
      },
    }
  );

  const { mutateAsync: deleteAttributeGroupMutate } = useMutation(
    ItemService.deleteAttributeGroup,
    {
      onSuccess: (_, attributeGroupId) => {
        queryClient.invalidateQueries("attributeGroups");
        if (editingAttributeGroupId === attributeGroupId) {
          setEditingAttributeGroupId(null);
          setNewAttributeGroupName("");
          setEditingAttributeValueId(null);
          setNewAttributeValue("");
        }
      },
    }
  );

  const { mutateAsync: deleteAttributeValueMutate } = useMutation(
    ItemService.deleteAttributeValue,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("attributeGroups");
      },
    }
  );

  // Handlers remain mostly the same
  const handleCreateOrUpdateAttributeGroup = async () => {
    if (!newAttributeGroupName) return;

    const attributeGroupData: AttributeGroup.AsObject = {
      attributegroupid: editingAttributeGroupId || 0,
      attributegroupname: newAttributeGroupName,
      attributevaluesList: [],
    };

    try {
      await createOrUpdateAttributeGroupMutate(attributeGroupData);
      setNewAttributeGroupName("");
      setEditingAttributeValueId(null);
      setNewAttributeValue("");
    } catch (error) {
      console.error("Error creating or updating Attribute Group:", error);
    }
  };

  const handleEditAttributeGroup = (
    attributeGroup: AttributeGroup.AsObject
  ) => {
    setNewAttributeGroupName(attributeGroup.attributegroupname);
    setEditingAttributeGroupId(attributeGroup.attributegroupid);
    setEditingAttributeValueId(null);
    setNewAttributeValue("");
  };

  const handleDeleteAttributeGroup = async (attributeGroupId: number) => {
    try {
      await deleteAttributeGroupMutate(attributeGroupId);
    } catch (error) {
      console.error("Error deleting Attribute Group:", error);
    }
  };

  const handleCreateOrUpdateAttributeValue = async () => {
    if (!newAttributeValue || editingAttributeGroupId === null) return;

    const attributeValueData: AttributeValue.AsObject = {
      attributevalueid: editingAttributeValueId || 0,
      attributegroupid: editingAttributeGroupId,
      attributevalue: newAttributeValue,
      lastupdated: "",
    };

    try {
      await createOrUpdateAttributeValueMutate(attributeValueData);
      setNewAttributeValue("");
      setEditingAttributeValueId(null);
    } catch (error) {
      console.error("Error creating or updating Attribute Value:", error);
    }
  };

  const handleDeleteAttributeValue = async (attributeValueId: number) => {
    try {
      await deleteAttributeValueMutate(attributeValueId);
    } catch (error) {
      console.error("Error deleting Attribute Value:", error);
    }
  };

  const handleEditAttributeValue = (
    attributeValue: AttributeValue.AsObject
  ) => {
    setNewAttributeValue(attributeValue.attributevalue);
    setEditingAttributeValueId(attributeValue.attributevalueid);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <h4>Manage Attribute Groups</h4>
      <div>
        <label>
          Attribute Group Name:
          <input
            type="text"
            placeholder="Attribute Group Name"
            value={newAttributeGroupName}
            onChange={(e) => setNewAttributeGroupName(e.target.value)}
            style={{ width: "400px" }}
          />
        </label>
        <button onClick={handleCreateOrUpdateAttributeGroup}>
          {editingAttributeGroupId
            ? "Update Attribute Group"
            : "Add Attribute Group"}
        </button>
      </div>

      {/* Section to add values to the selected attribute group */}
      <div>
        <h5>Manage Values for Attribute Group</h5>
        <div>
          <div>
            <label>
              Attribute Value:
              <input
                type="text"
                placeholder="Attribute Value"
                value={newAttributeValue}
                onChange={(e) => setNewAttributeValue(e.target.value)}
              />
            </label>
            <button onClick={handleCreateOrUpdateAttributeValue}>
              {editingAttributeValueId
                ? "Update Attribute Value"
                : "Add Attribute Value"}
            </button>
            {editingAttributeValueId && (
              <button
                onClick={() => {
                  setNewAttributeValue("");
                  setEditingAttributeValueId(null);
                }}
              >
                Cancel Edit
              </button>
            )}
          </div>

          {/* Tables */}
          <div style={{ display: "flex", flexDirection: "row" }}>
            {/* Attributes table */}
            <div
              className="scrollableCDiv"
              style={{
                height: "500px",
                border: "1px solid black",
                margin: "5px",
              }}
            >
              <table>
                <thead>
                  <tr>
                    <th>Attribute Groups</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {attributeGroups?.map((attributeGroup) => (
                    <tr key={attributeGroup.attributegroupid}>
                      <td>{attributeGroup.attributegroupname}</td>
                      <td>
                        <button
                          onClick={() =>
                            handleEditAttributeGroup(attributeGroup)
                          }
                        >
                          Edit
                        </button>
                        <button
                          onClick={() =>
                            handleDeleteAttributeGroup(
                              attributeGroup.attributegroupid
                            )
                          }
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* Attribute values table */}
            <div
              className="scrollableCDiv"
              style={{
                height: "500px",
                border: "1px solid black",
                margin: "5px",
              }}
            >
              <table style={{ minWidth: "400px" }}>
                <thead>
                  <tr>
                    <th>Value</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {attributeValues.length > 0 ? (
                    attributeValues.map((attributeValue) => (
                      <tr key={attributeValue.attributevalueid}>
                        <td>{attributeValue.attributevalue}</td>
                        <td>
                          <button
                            onClick={() =>
                              handleEditAttributeValue(attributeValue)
                            }
                          >
                            Edit
                          </button>
                          <button
                            onClick={() =>
                              handleDeleteAttributeValue(
                                attributeValue.attributevalueid
                              )
                            }
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={2} style={{ textAlign: "center" }}>
                        <p>Add Attribute Value to Group</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
