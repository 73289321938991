import {
  DirectLaborType,
  EquipmentType,
  ExtensionType,
  GeneralExpenseType,
  IncidentalLaborType,
  IndirectLaborType,
  LaborFactoringType,
  SubcontractType,
  QuoteType,
  TotalFinalRowData,
  TotalRowPricingData,
} from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";

export const defaultQuotedItem: QuoteType.AsObject = {
  quotegroup: "",
  quotedcost: 0,
  adjustedpercent: 0,
  totalcost: 0,
  quoteitemsList: [],
};

export interface ExtentionRowProps {
  item: ExtensionType.AsObject;
  index: number;
  handleFieldUpdate: (index: number, field: string, value: number) => void;
}

export interface DirectLaborRowProps {
  labor: DirectLaborType.AsObject;
  totalLaborHours: number;
  handleDistributionChange: (labor: DirectLaborType.AsObject) => void;
  handleFieldUpdate: (field: string, value: any) => void;
}

export interface LaborFactoringRowProps {
  labor: LaborFactoringType.AsObject;
  totalLaborHours: number;
  handleFieldUpdate: (field: string, value: number, index: number) => void;
}

export interface IncidentalLaborRowProps {
  labor: IncidentalLaborType.AsObject;
  handleFieldUpdate: (field: string, value: any) => void;
}

export interface IndirectLaborRowProps {
  labor: IndirectLaborType.AsObject;
  totalLaborHours: number;
  handleDistributionChange: (labor: IndirectLaborType.AsObject) => void;
  handleFieldUpdate: (field: string, value: any) => void;
}

export interface EquipmentRowProps {
  equipment: EquipmentType.AsObject;
  handleDelete: (name: string) => void;
  handleFieldUpdate: (field: string, value: any) => void;
}

export interface GeneralExpenseRowProps {
  generalExpense: GeneralExpenseType.AsObject;
  handleDelete: (name: string) => void;
  handleFieldUpdate: (field: string, value: any) => void;
}

export interface SubcontractRowProps {
  subcontract: SubcontractType.AsObject;
  handleDelete: (name: string) => void;
  handleFieldUpdate: (field: string, value: any) => void;
}

export interface QuoteRowProps {
  quote: QuoteType.AsObject;
  extension?: ExtensionType.AsObject;
  handleFieldUpdate: (field: string, value: any) => void;
}

export interface TotalPricingRowProps {
  name: string;
  rowData: TotalRowPricingData.AsObject;
  handleFieldUpdate: (field: string, name: string, value: number) => void;
}

//Temporary data for the closeout, will replace with the actual data from the database

export const totalPricingDefaults: TotalFinalRowData.AsObject = {
  totalfinaldata: {
    totalcost: 0,
    totaloverhead: 0,
    totalmarkup: 0,
    totalfinalprice: 0,
  },
  rowtypesList: [
    {
      name: "Materials Cost",
      rowdata: {
        extendedcost: 0,
        adjustedpercent: 0.07,
        adjustedcost: 0,
        overheadpercent: 0,
        totaloverhead: 0,
        markuppercent: 0,
        totalmarkup: 0,
        totalcost: 0,
      },
    },
    {
      name: "Quotes Cost",
      rowdata: {
        extendedcost: 0,
        adjustedpercent: 0.07,
        adjustedcost: 0,
        overheadpercent: 0,
        totaloverhead: 0,
        markuppercent: 0,
        totalmarkup: 0,
        totalcost: 0,
      },
    },
    {
      name: "Direct Labor Cost",
      rowdata: {
        extendedcost: 0,
        adjustedpercent: 0,
        adjustedcost: 0,
        overheadpercent: 0,
        totaloverhead: 0,
        markuppercent: 0,
        totalmarkup: 0,
        totalcost: 0,
      },
    },
    {
      name: "Labor Factoring Cost",
      rowdata: {
        extendedcost: 0,
        adjustedpercent: 0,
        adjustedcost: 0,
        overheadpercent: 0,
        totaloverhead: 0,
        markuppercent: 0,
        totalmarkup: 0,
        totalcost: 0,
      },
    },
    {
      name: "Incidental Labor Cost",
      rowdata: {
        extendedcost: 0,
        adjustedpercent: 0,
        adjustedcost: 0,
        overheadpercent: 0,
        totaloverhead: 0,
        markuppercent: 0,
        totalmarkup: 0,
        totalcost: 0,
      },
    },
    {
      name: "Indirect Labor Cost",
      rowdata: {
        extendedcost: 0,
        adjustedpercent: 0,
        adjustedcost: 0,
        overheadpercent: 0,
        totaloverhead: 0,
        markuppercent: 0,
        totalmarkup: 0,
        totalcost: 0,
      },
    },
    {
      name: "Equipment Cost",
      rowdata: {
        extendedcost: 0,
        adjustedpercent: 0.07,
        adjustedcost: 0,
        overheadpercent: 0,
        totaloverhead: 0,
        markuppercent: 0,
        totalmarkup: 0,
        totalcost: 0,
      },
    },
    {
      name: "General Expense Cost",
      rowdata: {
        extendedcost: 0,
        adjustedpercent: 0,
        adjustedcost: 0,
        overheadpercent: 0,
        totaloverhead: 0,
        markuppercent: 0,
        totalmarkup: 0,
        totalcost: 0,
      },
    },
    {
      name: "Subcontract Cost",
      rowdata: {
        extendedcost: 0,
        adjustedpercent: 0,
        adjustedcost: 0,
        overheadpercent: 0,
        totaloverhead: 0,
        markuppercent: 0,
        totalmarkup: 0,
        totalcost: 0,
      },
    },
  ],
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const directLaborDefaults: DirectLaborType.AsObject[] = [
  {
    name: "Other",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Average Crew",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Electrician",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Refrigeration Technician",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Plumber",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "HVAC Technician",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Welder",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Carpenter",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Mason",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Painter",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Drywall Installer",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Roofer",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Sheet Metal Worker",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Ironworker",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Concrete Finisher",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Glazier",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Insulation Worker",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Tile Setter",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Pipelayer",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Heavy Equipment Operator",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Laborer",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Crane Operator",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Pipefitter",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Boilermaker",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Millwright",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Scaffolder",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Rigging Specialist",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Demolition Worker",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Flooring Installer",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Plasterer",
    laborrate: 40,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
];

export const incidentalLaborDefaults: IncidentalLaborType.AsObject[] = [
  {
    name: "Other",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
  },
  {
    name: "Travel Pay",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
  },
  {
    name: "Commissioning",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
  },
  {
    name: "Mobilization",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
  },
  {
    name: "Demobilization",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
  },
  {
    name: "Site Cleaning",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
  },
  {
    name: "Material Handling",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
  },
  {
    name: "Equipment Setup and Removal",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
  },
  {
    name: "Temporary Utilities Setup",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
  },
  {
    name: "Waste Disposal",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
  },
  {
    name: "Safety Meetings and Training",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
  },
  {
    name: "Punch List Work",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
  },
  {
    name: "Inspections and Testing",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
  },
  {
    name: "Daily Reporting",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
  },
  {
    name: "Equipment Maintenance",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
  },
  {
    name: "Temporary Structure Setup",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
  },
  {
    name: "Rework Due to Site Conditions",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
  },
];

export const indirectLaborDefaults: IndirectLaborType.AsObject[] = [
  {
    name: "Other",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Project Manager",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Superintendent",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Security Personnel",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Estimator",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Purchasing Agent",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Site Foreman",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Quality Control Inspector",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Safety Officer",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Administrative Assistant",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Logistics Coordinator",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Surveyor",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Commissioning Agent",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Field Engineer",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Office Manager",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Cost Control Manager",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Change Order Specialist",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Document Control Clerk",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Material Expediter",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "Human Resources",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
  {
    name: "IT Support",
    laborrate: 60,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
  },
];

export const laborFactoringDefaults: LaborFactoringType.AsObject[] = [
  {
    name: "Other",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
  {
    name: "General Effeciancy Factor",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
  {
    name: "Night Work",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
  {
    name: "Confined Space",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
  {
    name: "Extreme Heat",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
  {
    name: "Extreme Cold",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
  {
    name: "Rainy Conditions",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
  {
    name: "High Winds",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
  {
    name: "Height Work (Over 50ft)",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
  {
    name: "Underground Work",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
  {
    name: "Hazardous Materials",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
  {
    name: "Remote Location",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
  {
    name: "Occupied Facility",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
  {
    name: "Tight Schedule (Accelerated Work)",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
  {
    name: "Overtime",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
  {
    name: "Multiple Shifts",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
  {
    name: "Restricted Access",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
  {
    name: "Demolition Work",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
  {
    name: "Critical Equipment Work",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
  {
    name: "Limited Work Hours (e.g., Noise Restrictions)",
    laborrate: 40.0,
    fringe: 0,
    burdenpercent: 0.4,
    allocatedhours: 0,
    totalcost: 0,
    distributionpercent: 0,
    impactpercent: 0,
    laborpercent: 0,
  },
];

export const equipmentDefaults: EquipmentType.AsObject[] = [
  {
    name: "Other",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Scissor Lift (Electric 19'-32')",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Boom Lift (Articulating 40'-60')",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Boom Lift (Telescopic 60'-120')",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Forklift (10,000lb-15,000lb)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Telehandler (Reach Forklift)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Rough Terrain Forklift",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Excavator (5-10 ton)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Excavator (10-20 ton)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Excavator (20-40 ton)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Mini Excavator (Compact)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Backhoe Loader (14'-15')",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Skid Steer Loader",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Compact Track Loader",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Bulldozer (Small to Medium)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Bulldozer (Large)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  { name: "Track Loader", quantity: 1, duration: 1, unitcost: 0, totalcost: 0 },
  {
    name: "Wheel Loader (Small)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Wheel Loader (Medium)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Wheel Loader (Large)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Dump Truck (Standard)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Dump Truck (Articulated)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Roller (Ride-On, Vibratory)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Compactor (Plate)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Pneumatic Tire Roller",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Trencher (Walk-Behind)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Trencher (Ride-On)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  { name: "Water Truck", quantity: 1, duration: 1, unitcost: 0, totalcost: 0 },
  {
    name: "Boom Truck (Telescopic)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Rough Terrain Crane (25-50 ton)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Lattice Boom Crawler Crane",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  { name: "Tower Crane", quantity: 1, duration: 1, unitcost: 0, totalcost: 0 },
  {
    name: "Hydraulic Hammer (Excavator Attachment)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Concrete Mixer (Towable)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Concrete Pump Truck",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Boom Concrete Pump",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Scraper (Earthmoving)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  { name: "Grader", quantity: 1, duration: 1, unitcost: 0, totalcost: 0 },
  {
    name: "Ride-On Sweeper",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Telehandler (Rotating)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Utility Vehicle (UTV)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Towable Air Compressor",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  { name: "Light Tower", quantity: 1, duration: 1, unitcost: 0, totalcost: 0 },
  {
    name: "Generator (50kW-100kW)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Generator (100kW-200kW)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Tower Light/Generator Combo",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Articulating Dump Truck",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Vacuum Excavator",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Post Driver (Hydraulic)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Post Hole Digger",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Auger Attachment (Skid Steer/Excavator)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Road Saw (Ride-On)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Cold Planer (Asphalt Milling)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Vibratory Soil Compactor",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Tractor Loader",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  { name: "Scaffolding", quantity: 1, duration: 1, unitcost: 0, totalcost: 0 },
  {
    name: "Aerial Work Platform",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Scissor Lift (Rough Terrain)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  { name: "Pallet Jack", quantity: 1, duration: 1, unitcost: 0, totalcost: 0 },
  {
    name: "Hydraulic Gantry",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Material Hoist",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  { name: "Bridge Crane", quantity: 1, duration: 1, unitcost: 0, totalcost: 0 },
  { name: "Trench Box", quantity: 1, duration: 1, unitcost: 0, totalcost: 0 },
  {
    name: "Hydraulic Breaker (Skid Steer Attachment)",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Soil Stabilizer",
    quantity: 1,
    duration: 1,
    unitcost: 0,
    totalcost: 0,
  },
  { name: "Man Basket", quantity: 1, duration: 1, unitcost: 0, totalcost: 0 },
];

export const generalExpenseDefaults: GeneralExpenseType.AsObject[] = [
  {
    name: "Other",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Permit Fees",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Small Materials & Tools",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Paid Mileage",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Per Diem (Meals)",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Per Diem (Lodging)",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Temporary Utilities",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Temporary Fencing",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Site Security",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Sanitary Facilities (Porta Potties)",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Temporary Heat",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Temporary Lighting",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Dumpster and Waste Removal",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Project Signage",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Safety Equipment",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Mobilization and Demobilization",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Project Management Fees",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Quality Control/Inspection",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Construction Cleaning",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Environmental Protection (Erosion Control)",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Insurance (General Liability)",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Performance and Payment Bonds",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Submittals and Shop Drawings",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "As-Built Drawings",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Traffic Control",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Weather Protection (Tarps, Covers)",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Temporary Office/Trailer Rental",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Safety Meetings and Training",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Site Surveys and Testing",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Employee Background Checks",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
  {
    name: "Commissioning",
    quantity: 1,
    duration: 1,
    durationmultiplier: 0,
    durationmultipliername: "",
    unitcost: 0,
    totalcost: 0,
  },
];

export const subcontractExpenseDefaults: SubcontractType.AsObject[] = [
  { name: "Fire Alarm", quotedcost: 0, adjustedpercent: 0, totalcost: 0 },
  { name: "Other", quotedcost: 0, adjustedpercent: 0, totalcost: 0 },
  { name: "Painting", quotedcost: 0, adjustedpercent: 0, totalcost: 0 },
  {
    name: "Welding and Metal Fabrication",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Engineering and Design",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Lightning Protection",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Electrical Contracting",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Plumbing Services",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "HVAC Installation",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Roofing Installation",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  { name: "Masonry", quotedcost: 0, adjustedpercent: 0, totalcost: 0 },
  { name: "Carpentry", quotedcost: 0, adjustedpercent: 0, totalcost: 0 },
  {
    name: "Drywall Installation",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  { name: "Framing", quotedcost: 0, adjustedpercent: 0, totalcost: 0 },
  { name: "Concrete Work", quotedcost: 0, adjustedpercent: 0, totalcost: 0 },
  { name: "Landscaping", quotedcost: 0, adjustedpercent: 0, totalcost: 0 },
  {
    name: "Excavation and Site Preparation",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  { name: "Demolition", quotedcost: 0, adjustedpercent: 0, totalcost: 0 },
  {
    name: "Asphalt and Paving",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Fencing and Gates",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Insulation Installation",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Fire Protection Systems",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Security and Access Control",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "CCTV Installation",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Glazing and Windows",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Elevator Installation",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  { name: "Scaffolding", quotedcost: 0, adjustedpercent: 0, totalcost: 0 },
  { name: "Waterproofing", quotedcost: 0, adjustedpercent: 0, totalcost: 0 },
  {
    name: "Flooring Installation",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Tile and Stone Installation",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Specialty Coatings",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Acoustic Treatments",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Millwork and Casework",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Interior Finishing",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Concrete Cutting and Coring",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Low Voltage Wiring",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Signage and Wayfinding",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Site Utilities (Water, Sewer, Gas)",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Trenching and Boring",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Heavy Equipment Rental",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  { name: "Earthwork", quotedcost: 0, adjustedpercent: 0, totalcost: 0 },
  {
    name: "Window Treatment Installation",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Solar Panel Installation",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Geotechnical Services",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Environmental Remediation",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Underground Utilities",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Waste Management and Disposal",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  { name: "Hardscaping", quotedcost: 0, adjustedpercent: 0, totalcost: 0 },
  { name: "Mold Remediation", quotedcost: 0, adjustedpercent: 0, totalcost: 0 },
  {
    name: "Hazardous Materials Abatement",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Temporary Structures",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  { name: "Traffic Control", quotedcost: 0, adjustedpercent: 0, totalcost: 0 },
  { name: "Utility Locating", quotedcost: 0, adjustedpercent: 0, totalcost: 0 },
  {
    name: "Swimming Pool Installation",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
  {
    name: "Window and Door Installation",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
  },
];

export const quotesExpenseDefaults: QuoteType.AsObject[] = [
  {
    quotegroup: "Other",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Lighting Fixtures",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Switchgear and Panels",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Transformers",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Generators and Backup Power Systems",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Automatic Transfer Switches",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Variable Frequency Drives",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Fire Alarm and Suppression Systems",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Security and Access Control Systems",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Elevators and Escalators",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Renewable Energy Systems (Solar, Wind)",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Electric Vehicle Charging Stations",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "UPS Systems",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Data Center Equipment",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "HVAC Systems (Chillers, Air Handlers, etc.)",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Packaged Rooftop Units",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Boilers and Water Heaters",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Heat Exchangers and Pumps",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Radiant Heating Panels",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Pre-Fabricated Steel Structures",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Structural Steel (Beams, Joists)",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Pre-Cast Concrete and Cladding Systems",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Curtain Wall and Window Systems",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Roofing Systems",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Exterior Insulation Finishing Systems (EIFS)",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Doors and Fire Rated Assemblies",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Acoustic and Insulated Panels",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Laboratory and Chemical Storage Systems",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Industrial Shelving and Mezzanine Systems",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Commercial Kitchen Equipment",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Cold Storage and Refrigeration Systems",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Bollards and Security Fencing",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Parking Garage Equipment",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Bus Ducts and Motor Control Centers",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Explosion-Proof Electrical Equipment",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Fuel Oil and Compressed Air Systems",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Pneumatic Tube and Building Automation Systems",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Dock Equipment (Levelers, Seals)",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Raised Flooring and Access Floors",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Walk-in Coolers, Freezers, and Shower Units",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Skylights",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Backup Power Systems",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Low and Medium Voltage Switchgear",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Power Monitoring and Metering Systems",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Laboratory Gas and Detection Systems",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Dehumidification and Air Quality Units",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Hydraulic, Passenger, and Freight Elevators",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Modular Bathroom Pods",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "High-Pressure Steam Boilers",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
  {
    quotegroup: "Explosion-Proof Fans and HVAC Terminal Units",
    quotedcost: 0,
    adjustedpercent: 0,
    totalcost: 0,
    quoteitemsList: [],
  },
];
