import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Calculations as c } from "../../utils/calculations";
import { IncidentalLaborRowProps } from "./closeoutTypes";
import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";

export const IncidentalLaborRow: React.FC<
  IncidentalLaborRowProps & {
    handleDelete: (name: string) => void;
    handleFieldUpdate: (field: string, value: number) => void;
  }
> = ({ labor, handleDelete, handleFieldUpdate }) => {
  const { includedIncidentalLaborTypes } = useStore(useCloseoutStore);
  const [allocatedhours, setAllocatedHours] = useState<string>(
    labor.allocatedhours.toFixed(2)
  );
  const [laborrate, setLaborRate] = useState<string>(
    labor.laborrate.toFixed(2)
  );
  const [burdenpercent, setBurdenPercent] = useState<string>(
    (labor.burdenpercent * 100).toFixed(2)
  );
  const [fringe, setFringe] = useState<string>(labor.fringe.toFixed(2));

  useEffect(() => {
    setAllocatedHours(allocatedhours);
    setLaborRate(labor.laborrate.toFixed(2));
    setBurdenPercent((labor.burdenpercent * 100).toFixed(2));
    setFringe(labor.fringe.toFixed(2));
  }, [labor, includedIncidentalLaborTypes, allocatedhours]);

  const handleFieldChange = (field: string, value: string) => {
    // Allow only numbers and the decimal point
    const regex = /^[0-9]*\.?[0-9]{0,2}$/;
    if (!regex.test(value)) return;

    switch (field) {
      case "allocatedhours":
        setAllocatedHours(value);
        break;
      case "laborrate":
        if (parseFloat(value) > 999.99) return;
        setLaborRate(value);
        break;
      case "burdenpercent":
        if (parseFloat(value) > 100) return;
        setBurdenPercent(value);
        break;
      case "fringe":
        if (parseFloat(value) > 999.99) return;
        setFringe(value);
        break;
      default:
        break;
    }
  };

  const handleBlur = (field: string, value: string) => {
    const floatValue = parseFloat(value) || 0;

    switch (field) {
      case "allocatedhours":
        setAllocatedHours(value);
        handleFieldUpdate(field, floatValue);
        break;
      case "laborrate":
        setLaborRate(value);
        handleFieldUpdate(field, floatValue);
        break;
      case "burdenpercent":
        setBurdenPercent(value);
        handleFieldUpdate(field, floatValue / 100);
        break;
      case "fringe":
        setFringe(value);
        handleFieldUpdate(field, floatValue);
        break;
      default:
        break;
    }
  };

  const validLaborRate = isNaN(parseFloat(laborrate))
    ? 0
    : parseFloat(laborrate);
  const validBurdenPercent = isNaN(parseFloat(burdenpercent))
    ? 0
    : parseFloat(burdenpercent) / 100;
  const validFringe = isNaN(parseFloat(fringe)) ? 0 : parseFloat(fringe);

  const subtotal = c.calculateLaborSubtotal({
    ...labor,
    allocatedhours: Number(allocatedhours),
    laborrate: validLaborRate,
  });
  const burdenTotal = c.calculateBurdenTotal({
    ...labor,
    allocatedhours: Number(allocatedhours),
    laborrate: validLaborRate,
    burdenpercent: validBurdenPercent,
  });
  const fringeTotal = c.calculateFringeTotal({
    ...labor,
    allocatedhours: Number(allocatedhours),
    fringe: validFringe,
  });
  const fullHoursCost = c.calculateFullHoursCost({
    ...labor,
    allocatedhours: Number(allocatedhours),
    laborrate: validLaborRate,
    burdenpercent: validBurdenPercent,
    fringe: validFringe,
  });
  const fullCost = c.calculateFullLaborCost({
    ...labor,
    allocatedhours: Number(allocatedhours),
    laborrate: validLaborRate,
    burdenpercent: validBurdenPercent,
    fringe: validFringe,
  });

  return (
    <tr>
      {/* Description */}
      <td>
        <button
          onClick={() => handleDelete(labor.name)}
          style={{ width: "40px" }}
        >
          <DeleteIcon />
        </button>
        {labor.name}
      </td>
      {/* Allocated Hours */}
      <td>
        <input
          type="text"
          value={allocatedhours}
          onChange={(e) => handleFieldChange("allocatedhours", e.target.value)}
          onBlur={(e) => handleBlur("allocatedhours", e.target.value)}
        />
      </td>
      {/* Labor Rate */}
      <td>
        <input
          type="text"
          value={laborrate}
          onChange={(e) => handleFieldChange("laborrate", e.target.value)}
          onBlur={(e) => handleBlur("laborrate", e.target.value)}
        />
      </td>
      {/* Subtotal */}
      <td>${subtotal.toFixed(2)}</td>
      {/* Burden Percent */}
      <td>
        <input
          type="text"
          value={burdenpercent}
          onChange={(e) => handleFieldChange("burdenpercent", e.target.value)}
          onBlur={(e) => handleBlur("burdenpercent", e.target.value)}
        />
      </td>
      {/* Fringe */}
      <td>
        <input
          type="text"
          value={fringe}
          onChange={(e) => handleFieldChange("fringe", e.target.value)}
          onBlur={(e) => handleBlur("fringe", e.target.value)}
        />
      </td>
      {/* Burden Total */}
      <td>${burdenTotal.toFixed(2)}</td>
      {/* Fringe Total */}
      <td>${fringeTotal.toFixed(2)}</td>
      {/* Full Hourly Cost */}
      <td>${fullHoursCost.toFixed(2)}</td>
      {/* Full Cost */}
      <td>${fullCost.toFixed(2)}</td>
    </tr>
  );
};
