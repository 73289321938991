import { totalPricingDefaults } from "../components/closeout/closeoutTypes";
import { CloseoutStore } from "./closeoutStore";
import { CreateGlobalAssemblyStore } from "./createAssemblyStore";
import { InferenceStore } from "./inferenceStore";
import {
  defaultChangeOrder,
  defaultContract,
  defaultEstimate,
  defaultGlobalAssembly,
  EstimateStore,
} from "./store";

export namespace ResetStates {
  export function resetTakeoffStates(
    store: EstimateStore,
    store1: CreateGlobalAssemblyStore
  ) {
    store.setIsCounting(false);
    store.setIsMeasuring(false);
    store.setMeasuredPoints([]);
    store.setPointIsSelected(false);
    store.setIsGettingLength(false);
    store.setIsGettingArea(false);
    store.setIsAnnotating(false);
    store.setIsCreatingTakeoffAssembly(false);
    store.setIsUpdatingTakeoffAssembly(false);
    store.setSelectedTotalLength(0);
    store.setTemporaryPoints([]);
    store.setIsDragging(false);
    store.setSelectedTakeoffAssembly(null);
    store.setIsEditingPoint({
      isEditingPoint: false,
      isEditingStyle: false,
      assemblyIndex: null,
      pointIndex: 0,
      x: null,
      y: null,
    });
    store.setIsEditingPointStyle({
      isEditingPointFillColor: false,
      isEditingPointBorderColor: false,
      isEditingLineColor: false,
    });
    store.setIsViewingExtension(false);
    store.setIncludeAllPoints(false);
    store.setIsInsertingPoint(false);
    store1.setGlobalAssembly(defaultGlobalAssembly);
  }

  export function resetMasterProjectViewStates(
    store: EstimateStore,
    store1: CreateGlobalAssemblyStore,
    store2: InferenceStore
  ) {
    store.setSelectedObjectName("");
    store.setIsOpeningViewport(false);
    store.setIsCreatingChangeOrder(false);
    store.setIsCreatingEstimate(false);
    store.setSelectedChangeOrder(defaultChangeOrder);
    store.setSelectedEstimate(defaultEstimate);
    store.setSelectedContract(defaultContract);
    store.setSelectedChangeOrder(defaultChangeOrder);
    store.setFilteredCountedAssemblies([]);
    store.setSelectedObjectId("");
    store1.setGlobalAssembly(defaultGlobalAssembly);
    store2.setInferenceCountObjects([]);
  }

  export function resetCreateAssemblyStates(
    store1: CreateGlobalAssemblyStore,
    store: EstimateStore
  ) {
    store1.setFilteredItems([]);
    store1.setAssemblyItems([]);
    store1.setGlobalAssembly({
      folderid: "",
      globalassemblyid: "",
      assemblyname: "",
      isactive: true,
      lastupdated: "",
      parentid: "",
      level: 0,
      entitytype: "",
      isfile: false,
      assemblymeasurementtype: "",
      itemqtyformulasList: [],
      imageclassificationid: 0,
    });
  }

  export function resetCreateAssemblyForm(store1: CreateGlobalAssemblyStore) {
    store1.setFilteredItems([]);
    store1.setAssemblyItems([]);
    store1.setGlobalAssembly(defaultGlobalAssembly);
  }

  export function resetAssemblyPickerStates(store1: CreateGlobalAssemblyStore) {
    store1.setGlobalAssembly(defaultGlobalAssembly);
  }

  export function resetMainMenuStates(
    store: EstimateStore,
    store1: CreateGlobalAssemblyStore
  ) {
    store.setIsCreatingNewProject(false);
    store.setIsCreatingContract(false);
    store.setIsViewingEstimateList(false);
    store.setIsViewingContractList(false);
    store.setIsViewingChangeOrderList(false);
    store.setSelectedEstimate(defaultEstimate);
    store.setSelectedContract(defaultContract);
    store1.setGlobalAssembly(defaultGlobalAssembly);
    store.setCurrentPage(1);
    store.setNumPages(1);
  }

  export function resetCloseoutStates(store: CloseoutStore) {
    store.setIsUpdatingDirectLaborAllocation(false);
    store.setIncludedExtentionTypes([]);
    store.setIncludedDirectLaborTypes([]);
    store.setIncludedLaborFactoringTypes([]);
    store.setIncludedIncidentalLaborTypes([]);
    store.setIncludedIndirectLaborTypes([]);
    store.setIncludedEquipmentTypes([]);
    store.setIncludedGenExpenseTypes([]);
    store.setIncludedSubcontractTypes([]);
    store.setIncludedQuoteTypes([]);
    store.setIncludedTotalPricingTypes(totalPricingDefaults);
  }
}
