/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: estimateCloseout.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as estimateCloseout_pb from './estimateCloseout_pb'; // proto import: "estimateCloseout.proto"


export class EstimateCloseoutServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateEstimateCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/CreateEstimateCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.CreateEstimateCloseoutRequest,
    estimateCloseout_pb.CreateEstimateCloseoutResponse,
    (request: estimateCloseout_pb.CreateEstimateCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.CreateEstimateCloseoutResponse.deserializeBinary
  );

  createEstimateCloseout(
    request: estimateCloseout_pb.CreateEstimateCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.CreateEstimateCloseoutResponse>;

  createEstimateCloseout(
    request: estimateCloseout_pb.CreateEstimateCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateEstimateCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.CreateEstimateCloseoutResponse>;

  createEstimateCloseout(
    request: estimateCloseout_pb.CreateEstimateCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateEstimateCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/CreateEstimateCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateEstimateCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/CreateEstimateCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateEstimateCloseout);
  }

  methodDescriptorCreateOrUpdateEstimateCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/CreateOrUpdateEstimateCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.CreateOrUpdateEstimateCloseoutRequest,
    estimateCloseout_pb.CreateOrUpdateEstimateCloseoutResponse,
    (request: estimateCloseout_pb.CreateOrUpdateEstimateCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.CreateOrUpdateEstimateCloseoutResponse.deserializeBinary
  );

  createOrUpdateEstimateCloseout(
    request: estimateCloseout_pb.CreateOrUpdateEstimateCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.CreateOrUpdateEstimateCloseoutResponse>;

  createOrUpdateEstimateCloseout(
    request: estimateCloseout_pb.CreateOrUpdateEstimateCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateOrUpdateEstimateCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.CreateOrUpdateEstimateCloseoutResponse>;

  createOrUpdateEstimateCloseout(
    request: estimateCloseout_pb.CreateOrUpdateEstimateCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.CreateOrUpdateEstimateCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/CreateOrUpdateEstimateCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateOrUpdateEstimateCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/CreateOrUpdateEstimateCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateOrUpdateEstimateCloseout);
  }

  methodDescriptorGetEstimateCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/GetEstimateCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.GetEstimateCloseoutRequest,
    estimateCloseout_pb.GetEstimateCloseoutResponse,
    (request: estimateCloseout_pb.GetEstimateCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.GetEstimateCloseoutResponse.deserializeBinary
  );

  getEstimateCloseout(
    request: estimateCloseout_pb.GetEstimateCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.GetEstimateCloseoutResponse>;

  getEstimateCloseout(
    request: estimateCloseout_pb.GetEstimateCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetEstimateCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.GetEstimateCloseoutResponse>;

  getEstimateCloseout(
    request: estimateCloseout_pb.GetEstimateCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetEstimateCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/GetEstimateCloseout',
        request,
        metadata || {},
        this.methodDescriptorGetEstimateCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/GetEstimateCloseout',
    request,
    metadata || {},
    this.methodDescriptorGetEstimateCloseout);
  }

  methodDescriptorGetEstimateCloseouts = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/GetEstimateCloseouts',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.GetEstimateCloseoutsRequest,
    estimateCloseout_pb.GetEstimateCloseoutsResponse,
    (request: estimateCloseout_pb.GetEstimateCloseoutsRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.GetEstimateCloseoutsResponse.deserializeBinary
  );

  getEstimateCloseouts(
    request: estimateCloseout_pb.GetEstimateCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.GetEstimateCloseoutsResponse>;

  getEstimateCloseouts(
    request: estimateCloseout_pb.GetEstimateCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetEstimateCloseoutsResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.GetEstimateCloseoutsResponse>;

  getEstimateCloseouts(
    request: estimateCloseout_pb.GetEstimateCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.GetEstimateCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/GetEstimateCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetEstimateCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/GetEstimateCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetEstimateCloseouts);
  }

  methodDescriptorUpdateEstimateCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/UpdateEstimateCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.UpdateEstimateCloseoutRequest,
    estimateCloseout_pb.UpdateEstimateCloseoutResponse,
    (request: estimateCloseout_pb.UpdateEstimateCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.UpdateEstimateCloseoutResponse.deserializeBinary
  );

  updateEstimateCloseout(
    request: estimateCloseout_pb.UpdateEstimateCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.UpdateEstimateCloseoutResponse>;

  updateEstimateCloseout(
    request: estimateCloseout_pb.UpdateEstimateCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateEstimateCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.UpdateEstimateCloseoutResponse>;

  updateEstimateCloseout(
    request: estimateCloseout_pb.UpdateEstimateCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.UpdateEstimateCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/UpdateEstimateCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateEstimateCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/UpdateEstimateCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateEstimateCloseout);
  }

  methodDescriptorDeleteEstimateCloseout = new grpcWeb.MethodDescriptor(
    '/EstimateCloseoutService/DeleteEstimateCloseout',
    grpcWeb.MethodType.UNARY,
    estimateCloseout_pb.DeleteEstimateCloseoutRequest,
    estimateCloseout_pb.DeleteEstimateCloseoutResponse,
    (request: estimateCloseout_pb.DeleteEstimateCloseoutRequest) => {
      return request.serializeBinary();
    },
    estimateCloseout_pb.DeleteEstimateCloseoutResponse.deserializeBinary
  );

  deleteEstimateCloseout(
    request: estimateCloseout_pb.DeleteEstimateCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<estimateCloseout_pb.DeleteEstimateCloseoutResponse>;

  deleteEstimateCloseout(
    request: estimateCloseout_pb.DeleteEstimateCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.DeleteEstimateCloseoutResponse) => void): grpcWeb.ClientReadableStream<estimateCloseout_pb.DeleteEstimateCloseoutResponse>;

  deleteEstimateCloseout(
    request: estimateCloseout_pb.DeleteEstimateCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: estimateCloseout_pb.DeleteEstimateCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/EstimateCloseoutService/DeleteEstimateCloseout',
        request,
        metadata || {},
        this.methodDescriptorDeleteEstimateCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/EstimateCloseoutService/DeleteEstimateCloseout',
    request,
    metadata || {},
    this.methodDescriptorDeleteEstimateCloseout);
  }

}

