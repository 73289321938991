import { TotalPricingRow } from "./TotalPricingRow";
import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";
import { TotalRowPricingData } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import {
  updatedTotalPricingTypes,
  updatedTotalFinalData,
} from "./closeoutHelpers";

export const TotalPricingHeader = () => {
  const { includedTotalPricingTypes, setIncludedTotalPricingTypes } =
    useStore(useCloseoutStore);

  const handleFieldUpdate = (field: string, name: string, value: number) => {
    const updatedTotalPricingTypesData = updatedTotalPricingTypes(
      includedTotalPricingTypes,
      field,
      name,
      value
    );
    const totalfinaldata = updatedTotalFinalData(updatedTotalPricingTypesData);

    setIncludedTotalPricingTypes({
      rowtypesList: updatedTotalPricingTypesData,
      totalfinaldata,
    });
  };

  return (
    <>
      <div className="scrollableCDiv">
        <table>
          <thead>
            <tr>
              <th>Cost Description</th>
              <th>Extended Cost</th>
              <th>% Adjustment</th>
              <th>Adjusted Cost</th>
              <th>% Overhead</th>
              <th>Total Overhead</th>
              <th>% Markup</th>
              <th>Total Markup</th>
              <th>Total Price</th>
            </tr>
          </thead>
          <tbody>
            {includedTotalPricingTypes.rowtypesList.map((pricing, index) => (
              <TotalPricingRow
                key={index}
                name={pricing.name}
                rowData={pricing.rowdata as TotalRowPricingData.AsObject}
                handleFieldUpdate={handleFieldUpdate}
              />
            ))}
          </tbody>
        </table>
        <table>
          <tr>
            <td>
              <strong>
                Total Cost: $
                {includedTotalPricingTypes.totalfinaldata?.totalcost &&
                  includedTotalPricingTypes.totalfinaldata.totalcost.toFixed(2)}
              </strong>
            </td>
          </tr>
          <tr>
            <td>
              <strong>
                Total Overhead: $
                {includedTotalPricingTypes.totalfinaldata?.totaloverhead &&
                  includedTotalPricingTypes.totalfinaldata?.totaloverhead.toFixed(
                    2
                  )}
              </strong>
            </td>
          </tr>
          <tr>
            <td>
              <strong>
                Total Profit: $
                {includedTotalPricingTypes.totalfinaldata?.totalmarkup &&
                  (includedTotalPricingTypes.totalfinaldata?.totalmarkup).toFixed(
                    2
                  )}
              </strong>
            </td>
          </tr>
          <tr>
            <td style={{ backgroundColor: "lightgreen" }}>
              <strong>
                Total Estimated Selling Price: $
                {includedTotalPricingTypes.totalfinaldata?.totalfinalprice &&
                  includedTotalPricingTypes.totalfinaldata?.totalfinalprice.toFixed(
                    2
                  )}
              </strong>
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};
