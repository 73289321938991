import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Calculations as c } from "../../utils/calculations";
import { LaborFactoringRowProps } from "./closeoutTypes";
import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";
import { LaborFactoringType } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";

export const LaborFactoringRow: React.FC<
  LaborFactoringRowProps & {
    handleDelete: (name: string) => void;
    handleFieldUpdate: (field: string, value: number, index: number) => void;
    handleLaborDistribution: (
      updatedLabor: LaborFactoringType.AsObject,
      index: number
    ) => void;
    index: number;
  }
> = ({
  labor,
  totalLaborHours,
  handleDelete,
  handleFieldUpdate,
  handleLaborDistribution,
  index,
}) => {
  const { includedLaborFactoringTypes } = useStore(useCloseoutStore);
  const [laborpercent, setLaborPercent] = useState<string>(
    ((labor.laborpercent || 0) * 100).toFixed(2)
  );
  const [impactpercent, setImpactPercent] = useState<string>(
    ((labor.laborpercent || 0) * 100).toFixed(2)
  );
  const [allocatedhours, setAllocatedHours] = useState(labor.allocatedhours);

  const [laborrate, setLaborRate] = useState<string>(
    labor.laborrate.toFixed(2)
  );
  const [burdenpercent, setBurdenPercent] = useState<string>(
    (labor.burdenpercent * 100).toFixed(2)
  );
  const [fringe, setFringe] = useState<string>(labor.fringe.toFixed(2));

  useEffect(() => {
    setLaborPercent(((labor.laborpercent || 0) * 100).toFixed(2));
    setImpactPercent(((labor.impactpercent || 0) * 100).toFixed(2));
    setAllocatedHours(labor.allocatedhours);
    setLaborRate(labor.laborrate.toFixed(2));
    setBurdenPercent((labor.burdenpercent * 100).toFixed(2));
    setFringe(labor.fringe.toFixed(2));
  }, [labor, totalLaborHours, includedLaborFactoringTypes]);

  const handleFieldChange = (field: string, value: string) => {
    // Allow only numbers and the decimal point
    const regex = /^[0-9]*\.?[0-9]{0,2}$/;
    if (!regex.test(value)) return;

    switch (field) {
      case "laborpercent":
        setLaborPercent(value);
        break;
      case "impactpercent":
        setImpactPercent(value);
        break;
      case "laborrate":
        if (parseFloat(value) > 999.99) return;
        setLaborRate(value);
        break;
      case "burdenpercent":
        if (parseFloat(value) > 100) return;
        setBurdenPercent(value);
        break;
      case "fringe":
        if (parseFloat(value) > 999.99) return;
        setFringe(value);
        break;
      default:
        break;
    }
  };

  const handleBlur = (field: string, value: string) => {
    const floatValue = parseFloat(value) || 0;

    switch (field) {
      case "allocatedhours":
        setAllocatedHours(Number(value));
        handleFieldUpdate(field, floatValue, index);
        break;
      case "laborpercent":
      case "impactpercent":
        const updatedLabor = {
          ...labor,
          [field]: c.safeDivide(floatValue, 100),
        };
        handleLaborDistribution(updatedLabor, index);
        handleFieldUpdate(field, c.safeDivide(floatValue, 100), index);
        break;
      case "laborrate":
        setLaborRate(value);
        handleFieldUpdate(field, floatValue, index);
        break;
      case "burdenpercent":
        setBurdenPercent(value);
        handleFieldUpdate(field, c.safeDivide(floatValue, 100), index);
        break;
      case "fringe":
        setFringe(value);
        handleFieldUpdate(field, floatValue, index);
        break;
      default:
        break;
    }
  };

  const validLaborRate = isNaN(parseFloat(laborrate))
    ? 0
    : parseFloat(laborrate);
  const validBurdenPercent = isNaN(parseFloat(burdenpercent))
    ? 0
    : c.safeDivide(parseFloat(burdenpercent), 100);
  const validFringe = isNaN(parseFloat(fringe)) ? 0 : parseFloat(fringe);

  const subtotal = c.calculateLaborSubtotal({
    ...labor,
    allocatedhours,
    laborrate: validLaborRate,
  });
  const burdenTotal = c.calculateBurdenTotal({
    ...labor,
    allocatedhours,
    laborrate: validLaborRate,
    burdenpercent: validBurdenPercent,
  });
  const fringeTotal = c.calculateFringeTotal({
    ...labor,
    allocatedhours,
    fringe: validFringe,
  });
  const fullHoursCost = c.calculateFullHoursCost({
    ...labor,
    allocatedhours,
    laborrate: validLaborRate,
    burdenpercent: validBurdenPercent,
    fringe: validFringe,
  });
  const fullCost = c.calculateFullLaborCost({
    ...labor,
    allocatedhours,
    laborrate: validLaborRate,
    burdenpercent: validBurdenPercent,
    fringe: validFringe,
  });

  return (
    <tr>
      {/* Description */}
      <td>
        <button
          onClick={() => handleDelete(labor.name)}
          style={{ width: "40px" }}
        >
          <DeleteIcon />
        </button>
        {labor.name}
      </td>
      {/* Labor % */}
      <td>
        <input
          type="text"
          value={laborpercent}
          onChange={(e) => handleFieldChange("laborpercent", e.target.value)}
          onBlur={(e) => handleBlur("laborpercent", e.target.value)}
        />
      </td>
      {/* Impact % */}
      <td>
        <input
          type="text"
          value={impactpercent}
          onChange={(e) => handleFieldChange("impactpercent", e.target.value)}
          onBlur={(e) => handleBlur("impactpercent", e.target.value)}
        />
      </td>
      {/* Allocated Hours */}
      <td>{allocatedhours.toFixed(2)}</td>
      {/* Labor Rate */}
      <td>
        <input
          type="text"
          value={laborrate}
          onChange={(e) => handleFieldChange("laborrate", e.target.value)}
          onBlur={(e) => handleBlur("laborrate", e.target.value)}
        />
      </td>
      {/* Subtotal */}
      <td>${subtotal.toFixed(2)}</td>
      {/* Burden Percent */}
      <td>
        <input
          type="text"
          value={burdenpercent}
          onChange={(e) => handleFieldChange("burdenpercent", e.target.value)}
          onBlur={(e) => handleBlur("burdenpercent", e.target.value)}
        />
      </td>
      {/* Fringe */}
      <td>
        <input
          type="text"
          value={fringe}
          onChange={(e) => handleFieldChange("fringe", e.target.value)}
          onBlur={(e) => handleBlur("fringe", e.target.value)}
        />
      </td>
      {/* Burden Total */}
      <td>${burdenTotal.toFixed(2)}</td>
      {/* Fringe Total */}
      <td>${fringeTotal.toFixed(2)}</td>
      {/* Full Hourly Cost */}
      <td>${fullHoursCost.toFixed(2)}</td>
      {/* Full Cost */}
      <td>${fullCost.toFixed(2)}</td>
    </tr>
  );
};
