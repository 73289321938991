import { useEffect, useCallback, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { TakeoffSVG } from "./takeoffSVGelements/TakeoffSVG";
import { useUnityBuildStore } from "../states/store";
import { ReactZoomPanPinchRef } from "react-zoom-pan-pinch";

import "../App.css";
import { useFetchPDFBlobURL } from "../hooks/useFetchBlobUrl";
import { HIGH_RES_SCALE } from "../utils/constants";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const DocumentViewport = () => {
  const {
    isDragging,
    selectedObjectName,
    currentPage,
    setNumPages,
    setPdfDimensions,
    setCurrentPage,
  } = useUnityBuildStore();

  const {
    data: blobURL,
    isLoading,
    isError,
  } = useFetchPDFBlobURL(selectedObjectName);

  const transformWrapperRef = useRef<ReactZoomPanPinchRef | null>(null);

  const [containerDimensions, setContainerDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // We'll store the original PDF dimensions after load.
  // Before these are known, we display the PDF normally.
  const [originalDimensions, setOriginalDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setContainerDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onDocumentLoadSuccess = useCallback(
    (pdf: any) => {
      setNumPages(pdf.numPages);
      setCurrentPage(1);

      // Get the original dimensions at scale=1
      pdf.getPage(1).then((page: any) => {
        const viewport = page.getViewport({ scale: 1 });
        const dimensions = { width: viewport.width, height: viewport.height };
        setPdfDimensions(dimensions);
        setOriginalDimensions(dimensions);

        if (transformWrapperRef.current) {
          const { width: containerWidth, height: containerHeight } =
            containerDimensions;
          const { width: contentWidth, height: contentHeight } = dimensions;

          const scaleX = containerWidth / contentWidth;
          const scaleY = containerHeight / contentHeight;
          const scale = Math.min(scaleX, scaleY) * 0.9; // Slightly zoomed out

          const x = (containerWidth - contentWidth * scale) / 2;
          const y = (containerHeight - contentHeight * scale) / 2;

          transformWrapperRef.current.setTransform(x, y, scale);
        }
      });
    },
    [setNumPages, setCurrentPage, setPdfDimensions, containerDimensions]
  );

  const styles = {
    wrapper: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
      position: "relative",
      backgroundColor: "rgb(124, 121, 121)",
    } as React.CSSProperties,
    content: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    } as React.CSSProperties,
  };

  let content: JSX.Element;

  if (isLoading) {
    content = <div>Loading...</div>;
  } else if (isError) {
    content = <div>Error loading PDF</div>;
  } else if (!originalDimensions) {
    // Before we know original dimensions, just render normally at scale=1
    // This ensures the user sees the built-in loading and page rendering.
    content = (
      <Document file={blobURL} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          pageNumber={currentPage}
          renderAnnotationLayer={false}
          scale={1}
        />
      </Document>
    );
  } else {
    // Once we know original dimensions, we apply high-res rendering
    // and scale it back down with CSS to maintain the original size.
    content = (
      <div
        style={{
          width: originalDimensions.width + "px",
          height: originalDimensions.height + "px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            transform: `scale(${1 / HIGH_RES_SCALE})`,
            transformOrigin: "top left",
          }}
        >
          <Document file={blobURL} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              pageNumber={currentPage}
              renderAnnotationLayer={false}
              scale={HIGH_RES_SCALE}
            />
          </Document>
        </div>
      </div>
    );
  }

  return (
    <TransformWrapper
      ref={transformWrapperRef}
      initialScale={1}
      minScale={0.25}
      maxScale={5}
      limitToBounds={false}
      doubleClick={{ disabled: true }}
      panning={{ velocityDisabled: true, disabled: isDragging }}
      wheel={{ smoothStep: 0.01 }}
    >
      {() => (
        <TransformComponent
          wrapperStyle={styles.wrapper}
          contentStyle={styles.content}
        >
          {content}
          <TakeoffSVG />
        </TransformComponent>
      )}
    </TransformWrapper>
  );
};
