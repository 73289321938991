/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: ub_account.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as ub_account_pb from './ub_account_pb'; // proto import: "ub_account.proto"


export class UB_AccountServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateAccount = new grpcWeb.MethodDescriptor(
    '/UB_AccountService/CreateAccount',
    grpcWeb.MethodType.UNARY,
    ub_account_pb.CreateAccountRequest,
    ub_account_pb.CreateAccountResponse,
    (request: ub_account_pb.CreateAccountRequest) => {
      return request.serializeBinary();
    },
    ub_account_pb.CreateAccountResponse.deserializeBinary
  );

  createAccount(
    request: ub_account_pb.CreateAccountRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ub_account_pb.CreateAccountResponse>;

  createAccount(
    request: ub_account_pb.CreateAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ub_account_pb.CreateAccountResponse) => void): grpcWeb.ClientReadableStream<ub_account_pb.CreateAccountResponse>;

  createAccount(
    request: ub_account_pb.CreateAccountRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ub_account_pb.CreateAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UB_AccountService/CreateAccount',
        request,
        metadata || {},
        this.methodDescriptorCreateAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UB_AccountService/CreateAccount',
    request,
    metadata || {},
    this.methodDescriptorCreateAccount);
  }

  methodDescriptorUpdateAccount = new grpcWeb.MethodDescriptor(
    '/UB_AccountService/UpdateAccount',
    grpcWeb.MethodType.UNARY,
    ub_account_pb.UpdateAccountRequest,
    ub_account_pb.UpdateAccountResponse,
    (request: ub_account_pb.UpdateAccountRequest) => {
      return request.serializeBinary();
    },
    ub_account_pb.UpdateAccountResponse.deserializeBinary
  );

  updateAccount(
    request: ub_account_pb.UpdateAccountRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ub_account_pb.UpdateAccountResponse>;

  updateAccount(
    request: ub_account_pb.UpdateAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ub_account_pb.UpdateAccountResponse) => void): grpcWeb.ClientReadableStream<ub_account_pb.UpdateAccountResponse>;

  updateAccount(
    request: ub_account_pb.UpdateAccountRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ub_account_pb.UpdateAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UB_AccountService/UpdateAccount',
        request,
        metadata || {},
        this.methodDescriptorUpdateAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UB_AccountService/UpdateAccount',
    request,
    metadata || {},
    this.methodDescriptorUpdateAccount);
  }

  methodDescriptorGetAccountUser = new grpcWeb.MethodDescriptor(
    '/UB_AccountService/GetAccountUser',
    grpcWeb.MethodType.UNARY,
    ub_account_pb.GetAccountUserRequest,
    ub_account_pb.GetAccountUserResponse,
    (request: ub_account_pb.GetAccountUserRequest) => {
      return request.serializeBinary();
    },
    ub_account_pb.GetAccountUserResponse.deserializeBinary
  );

  getAccountUser(
    request: ub_account_pb.GetAccountUserRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ub_account_pb.GetAccountUserResponse>;

  getAccountUser(
    request: ub_account_pb.GetAccountUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ub_account_pb.GetAccountUserResponse) => void): grpcWeb.ClientReadableStream<ub_account_pb.GetAccountUserResponse>;

  getAccountUser(
    request: ub_account_pb.GetAccountUserRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ub_account_pb.GetAccountUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UB_AccountService/GetAccountUser',
        request,
        metadata || {},
        this.methodDescriptorGetAccountUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UB_AccountService/GetAccountUser',
    request,
    metadata || {},
    this.methodDescriptorGetAccountUser);
  }

  methodDescriptorGetAccountUsers = new grpcWeb.MethodDescriptor(
    '/UB_AccountService/GetAccountUsers',
    grpcWeb.MethodType.UNARY,
    ub_account_pb.GetAccountUsersRequest,
    ub_account_pb.GetAccountUsersResponse,
    (request: ub_account_pb.GetAccountUsersRequest) => {
      return request.serializeBinary();
    },
    ub_account_pb.GetAccountUsersResponse.deserializeBinary
  );

  getAccountUsers(
    request: ub_account_pb.GetAccountUsersRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ub_account_pb.GetAccountUsersResponse>;

  getAccountUsers(
    request: ub_account_pb.GetAccountUsersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ub_account_pb.GetAccountUsersResponse) => void): grpcWeb.ClientReadableStream<ub_account_pb.GetAccountUsersResponse>;

  getAccountUsers(
    request: ub_account_pb.GetAccountUsersRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ub_account_pb.GetAccountUsersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UB_AccountService/GetAccountUsers',
        request,
        metadata || {},
        this.methodDescriptorGetAccountUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UB_AccountService/GetAccountUsers',
    request,
    metadata || {},
    this.methodDescriptorGetAccountUsers);
  }

  methodDescriptorLogAccountUserInorOut = new grpcWeb.MethodDescriptor(
    '/UB_AccountService/LogAccountUserInorOut',
    grpcWeb.MethodType.UNARY,
    ub_account_pb.LogAccountUserInorOutRequest,
    ub_account_pb.LogAccountUserInorOutResponse,
    (request: ub_account_pb.LogAccountUserInorOutRequest) => {
      return request.serializeBinary();
    },
    ub_account_pb.LogAccountUserInorOutResponse.deserializeBinary
  );

  logAccountUserInorOut(
    request: ub_account_pb.LogAccountUserInorOutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ub_account_pb.LogAccountUserInorOutResponse>;

  logAccountUserInorOut(
    request: ub_account_pb.LogAccountUserInorOutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ub_account_pb.LogAccountUserInorOutResponse) => void): grpcWeb.ClientReadableStream<ub_account_pb.LogAccountUserInorOutResponse>;

  logAccountUserInorOut(
    request: ub_account_pb.LogAccountUserInorOutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ub_account_pb.LogAccountUserInorOutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UB_AccountService/LogAccountUserInorOut',
        request,
        metadata || {},
        this.methodDescriptorLogAccountUserInorOut,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UB_AccountService/LogAccountUserInorOut',
    request,
    metadata || {},
    this.methodDescriptorLogAccountUserInorOut);
  }

}

