export const mutateSelectedAssembly = ({
  assembly,
  color,
  size,
  pointType,
  lineDashArray,
  isEditingPointBorderColor = false,
  isEditingPointFillColor = false,
  isEditingLineColor = false,
  isEditingPointSize = false,
  isEditingLineSize = false,
  isEditingPointType = false,
  isEditinglineDashArray = false,
}: {
  assembly: any;
  color?: string;
  size?: number;
  pointType?: string;
  lineDashArray?: string;
  lineStyle?: string;
  pointStyle?: string;
  isEditingPointBorderColor?: boolean;
  isEditingPointFillColor?: boolean;
  isEditingLineColor?: boolean;
  isEditingPointSize?: boolean;
  isEditingLineSize?: boolean;
  isEditingPointType?: boolean;
  isEditinglineDashArray?: boolean;
}) => {
  try {
    if (isEditingPointBorderColor) {
      assembly.pointbordercolor = color;
    } else if (isEditingPointFillColor) {
      assembly.pointfillcolor = color;
    } else if (isEditingLineColor) {
      assembly.linecolor = color;
    } else if (isEditingPointSize) {
      assembly.pointsize = size;
    } else if (isEditingLineSize) {
      assembly.linesize = size;
    } else if (isEditingPointType) {
      assembly.pointtype = pointType;
    } else if (isEditinglineDashArray) {
      assembly.linedasharray = lineDashArray;
    }
  } catch (error) {
    console.error(error);
  }
  return assembly;
};
