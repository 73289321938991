import { pdfjs } from "react-pdf";
import { clients } from "../clients/grpcClients";
import {
  GenerateGETSignedUrlRequest,
  GeneratePUTSignedUrlRequest,
  GetAllObjectsByProjectIdRequest,
  GetAllObjectsByProjectIdResponse,
  UpdateObjectScaleRequest,
  UserScaleList,
} from "./protosCompiled/object/object_pb";
import { ChangeOrderAssembly } from "./protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import { EstimateAssembly } from "./protosCompiled/estimateAssembly/estimateAssembly_pb";
import { AssistantService } from "./AssistantService";
import { useUnityBuildStore } from "../states/store";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const client = clients.objectServiceClient;

export class ObjectService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  private static uploadObjectToGCS = async (
    signedUrl: string,
    method: string,
    file: File
  ) => {
    const contentType = "application/pdf"; // TODO:  Create a function to parse the content type from the file extension
    const res = await fetch(signedUrl, {
      method: method,
      body: file,
      headers: {
        "Content-Type": contentType,
      },
    });

    if (!res.ok) {
      throw new Error(
        `HTTP status ${res.status}: Failed to handle file: ${file.name}`
      );
    }
  };

  // Assuming this method is in your ObjectService class
  private static async getObjectFromGCS(signedUrl: string): Promise<string> {
    const res = await fetch(signedUrl, {
      method: "GET",
      cache: "no-store",
    });

    if (!res.ok) {
      throw new Error(`HTTP status ${res.status}: Failed to fetch file`);
    }

    const blob = await res.blob();
    const blobUrl = URL.createObjectURL(blob);

    return blobUrl;
  }

  public static getObjectListByProjectId = async (
    projectId: string
  ): Promise<GetAllObjectsByProjectIdResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new GetAllObjectsByProjectIdRequest();
      req.setProjectid(projectId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      client.getAllObjectsByProjectId(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  };

  // handlePUTGCSRequest generates a signed URL for a PUT request to GCS
  // and uploads the file to GCS
  public static async handlePUTGCSRequest(
    request: GeneratePUTSignedUrlRequest,
    file: File,
    projectId: string
  ) {
    try {
      const response = await client.generatePUTSignedUrl(request, null);
      const objectDetails = response.getObjectdetailsList();
      await Promise.all(
        objectDetails.map(async (detail) => {
          const signedUrl = detail.getSignedurl();
          await this.uploadObjectToGCS(signedUrl, "PUT", file);
          await AssistantService.addGCSObjectsToVectorStore({
            projectid: projectId,
            objectid: detail.getObjectid(),
          });
        })
      );
    } catch (error) {
      if (error instanceof Error) {
        console.error(`Error with file ${file.name}: ${error.message}`);
      } else {
        console.error("An unexpected error occurred", error);
      }
    }
  }

  // handleGETGCSRequest generates a signed URL for a GET request to GCS
  // and fetches the file from GCS
  public static handleGETGCSRequest = async (
    request: GenerateGETSignedUrlRequest,
    objectName: string
  ) => {
    let signedUrl = "";
    try {
      const response = await client.generateGETSignedUrl(request, null);
      signedUrl = response.getSignedurl();
    } catch (error) {
      if (error instanceof Error) {
        console.error(`Error with object ${objectName}: ${error.message}`);
      } else {
        console.error("An unexpected error occurred", error);
      }
    }

    try {
      const blobURL = await this.getObjectFromGCS(signedUrl);
      return blobURL;
    } catch (error) {
      if (error instanceof Error) {
        console.error(`Error with object ${objectName}: ${error.message}`);
      } else {
        console.error("An unexpected error occurred", error);
      }
    }
  };

  // Update the scale of an object
  public static updateObjectScale = async (
    objectId: string,
    userScaleList: UserScaleList.AsObject[]
  ) => {
    try {
      const request = new UpdateObjectScaleRequest();
      request.setObjectid(objectId);
      // Convert AsObject[] to UserScaleList[]
      const userScaleInstances = userScaleList.map((scaleObj) => {
        const userScale = new UserScaleList();
        userScale.setPagenumber(scaleObj.pagenumber);
        userScale.setScale(scaleObj.scale);
        return userScale;
      });

      request.setUserscalesList(userScaleInstances);

      const response = await client.updateObjectScale(request, null);
      return response;
    } catch (error) {
      if (error instanceof Error) {
        console.error(`Error with object ${objectId}: ${error.message}`);
      } else {
        console.error("An unexpected error occurred", error);
      }
    }
  };

  // Evaluate if the assemblies objectId is the same as the selected objectId
  public static filterAssemblyByObjectIdandPageNumber(
    assemblies: Array<ChangeOrderAssembly.AsObject | EstimateAssembly.AsObject>,
    selectedObjectId: string,
    currentPage: number
  ) {
    return assemblies.filter((object: any) => {
      return (
        object.objectid === selectedObjectId &&
        object.pagenumber === currentPage
      );
    });
  }
}
