import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { defaultEstimate, useUnityBuildStore } from "../../states/store";
import {
  useCreateEstimateMutation,
  useUpdateEstimateMutation,
} from "../../hooks/useCreateProjectEstimateMutation";
import { useStore } from "zustand";
import { Estimate } from "../../api/protosCompiled/projectEstimate/projectEstimate_pb";
import { useFetchEstimates } from "../../hooks/useFetchProjectEstimates";

// Helper function to format date to "yyyy-MM-dd"
const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0]; // Extract only the date part
};

export const CreateEstimate: React.FC = () => {
  const {
    isCreatingEstimate,
    selectedEstimate,
    selectedProject,
    setSelectedEstimate,
    setIsCreatingEstimate,
    setIsViewingEstimateForm,
  } = useStore(useUnityBuildStore);

  const { data: estimates } = useFetchEstimates();

  const { mutate: createEstimate } = useCreateEstimateMutation();
  const { mutate: updateEstimate } = useUpdateEstimateMutation();

  const [description, setDescription] = useState("");
  const [duedate, setDueDate] = useState<string>(
    formatDate(new Date().toISOString())
  );
  const [name, setName] = useState("");
  const [customid, setCustomId] = useState("");

  useEffect(() => {
    if (!isCreatingEstimate) {
      const selectedEstimateObj = estimates?.find(
        (estimate) =>
          estimate.estimate?.estimateid.trim() ===
          selectedEstimate?.estimateid.trim()
      );
      if (!selectedEstimateObj || !selectedEstimateObj.estimate) {
        setSelectedEstimate(defaultEstimate);
      } else {
        setSelectedEstimate(selectedEstimateObj.estimate);
        setDescription(selectedEstimateObj.estimate?.description ?? "");
        setDueDate(formatDate(selectedEstimateObj.estimate?.duedate ?? "")); // Ensure date is properly formatted
        setName(selectedEstimateObj.estimate?.name ?? "");
        setCustomId(selectedEstimateObj.estimate?.customid ?? "");
      }
    }
    //eslint-disable-next-line
  }, [selectedEstimate, estimates]);

  type Field = "name" | "description" | "duedate" | "custumid";

  const handleSetValue = (field: Field, value: string) => {
    switch (field) {
      case "description":
        setDescription(value);
        break;
      case "duedate":
        setDueDate(value);
        break;
      case "name":
        setName(value);
        break;
      case "custumid":
        setCustomId(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formData: Estimate.AsObject = {
      estimateid: selectedEstimate.estimateid ?? "",
      description: description,
      duedate: duedate,
      projectid: selectedProject.id,
      datecreated: selectedEstimate.datecreated ?? "",
      name: name,
      customid: customid,
      createdbyuserid: selectedEstimate.createdbyuserid ?? "",
      createdbyaccountid: selectedEstimate.createdbyaccountid ?? "",
      lastupdated: "",
    };

    if (isCreatingEstimate) {
      createEstimate(formData);
    } else {
      updateEstimate(formData);
    }
    setIsViewingEstimateForm(false);
    setIsCreatingEstimate(false);
    resetForm();
  };

  const handleEstimateFormCancel = () => {
    setIsCreatingEstimate(false);
    setIsViewingEstimateForm(false);
  };

  const resetForm = () => {
    setDescription("");
    setDueDate(formatDate(new Date().toISOString())); // Reset date in correct format
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="name"
        label="Estimate Name"
        rows={4}
        value={name}
        onChange={(e) => handleSetValue("name", e.target.value)}
      />
      <TextField
        margin="normal"
        fullWidth
        id="description"
        label="Estimate Description"
        multiline
        rows={4}
        value={description}
        onChange={(e) => handleSetValue("description", e.target.value)}
      />
      <TextField
        margin="normal"
        fullWidth
        id="customId"
        label="Estimate Id"
        rows={4}
        value={customid}
        onChange={(e) => handleSetValue("custumid", e.target.value)}
      />
      <TextField
        margin="normal"
        fullWidth
        id="dueDate"
        label="Due Date"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        value={duedate} // Ensure duedate is in "yyyy-MM-dd" format
        onChange={(e) => handleSetValue("duedate", e.target.value)}
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }}>
        {isCreatingEstimate ? "Create Estimate" : "Update Estimate"}
      </Button>
      <Button onClick={handleEstimateFormCancel}>Cancel</Button>
    </Box>
  );
};
