import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

// Privacy Policy Dialog Component
export const PrivacyPolicyDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => (
  <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
    <DialogTitle>Privacy Policy</DialogTitle>
    <DialogContent dividers>
      {/* Last Updated Date */}
      <Typography variant="subtitle2" gutterBottom>
        Last Updated: November 30, 2024
      </Typography>

      {/* Introduction */}
      <Typography variant="body1" paragraph>
        At Lumpy Studio LLC ("Company," "we," "us," or "our"), we are committed
        to protecting your privacy. This Privacy Policy explains how we collect,
        use, disclose, and safeguard your information when you use our
        construction estimation software, Unity-Build-Estimation ("Service"). By
        accessing or using the Service, you agree to the collection and use of
        information in accordance with this Privacy Policy.
      </Typography>

      {/* Section 1 */}
      <Typography variant="h6" gutterBottom>
        1. Information We Collect
      </Typography>

      {/* 1.1 Personal Information */}
      <Typography variant="subtitle1" gutterBottom>
        1.1 Personal Information
      </Typography>
      <Typography variant="body1" paragraph>
        While using our Service, we may ask you to provide certain personally
        identifiable information that can be used to contact or identify you
        ("Personal Information"). This may include:
      </Typography>
      <Typography variant="body1" component="div">
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Company name</li>
          <li>Billing information (for paid services)</li>
        </ul>
      </Typography>

      {/* 1.2 Usage Data */}
      <Typography variant="subtitle1" gutterBottom>
        1.2 Usage Data
      </Typography>
      <Typography variant="body1" paragraph>
        We may collect information on how the Service is accessed and used
        ("Usage Data"). This may include:
      </Typography>
      <Typography variant="body1" component="div">
        <ul>
          <li>IP address</li>
          <li>Browser type and version</li>
          <li>Pages visited</li>
          <li>Time and date of access</li>
          <li>Time spent on pages</li>
          <li>Other diagnostic data</li>
        </ul>
      </Typography>

      {/* 1.3 Cookies and Tracking Technologies */}
      <Typography variant="subtitle1" gutterBottom>
        1.3 Cookies and Tracking Technologies
      </Typography>
      <Typography variant="body1" paragraph>
        We use cookies and similar tracking technologies to track the activity
        on our Service and hold certain information.
      </Typography>

      {/* Section 2 */}
      <Typography variant="h6" gutterBottom>
        2. How We Use Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We use the collected data for various purposes:
      </Typography>
      <Typography variant="body1" component="div">
        <ul>
          <li>To provide and maintain the Service</li>
          <li>To notify you about changes to our Service</li>
          <li>To provide customer support</li>
          <li>
            To gather analysis or valuable information so we can improve our
            Service
          </li>
          <li>To monitor the usage of the Service</li>
          <li>To detect, prevent, and address technical issues</li>
          <li>To enhance and improve our products and services</li>
        </ul>
      </Typography>

      {/* Section 3 */}
      <Typography variant="h6" gutterBottom>
        3. Data Sharing and Disclosure
      </Typography>

      {/* 3.1 No Sale of Personal Data */}
      <Typography variant="subtitle1" gutterBottom>
        3.1 No Sale of Personal Data
      </Typography>
      <Typography variant="body1" paragraph>
        We will <strong>never sell</strong> your personal data or disclose it to
        third parties without your consent.
      </Typography>

      {/* 3.2 Service Providers */}
      <Typography variant="subtitle1" gutterBottom>
        3.2 Service Providers
      </Typography>
      <Typography variant="body1" paragraph>
        We may employ third-party companies and individuals to facilitate our
        Service ("Service Providers"), provide the Service on our behalf, or
        perform Service-related services. These third parties have access to
        your Personal Information only to perform these tasks on our behalf and
        are obligated not to disclose or use it for any other purpose.
      </Typography>

      {/* 3.3 Legal Requirements */}
      <Typography variant="subtitle1" gutterBottom>
        3.3 Legal Requirements
      </Typography>
      <Typography variant="body1" paragraph>
        We may disclose your Personal Information if required to do so by law or
        in response to valid requests by public authorities.
      </Typography>

      {/* Section 4 */}
      <Typography variant="h6" gutterBottom>
        4. Data Security
      </Typography>
      <Typography variant="body1" paragraph>
        We value your trust in providing us your Personal Information, thus we
        strive to use commercially acceptable means of protecting it. However,
        no method of transmission over the internet or method of electronic
        storage is 100% secure.
      </Typography>

      {/* Section 5 */}
      <Typography variant="h6" gutterBottom>
        5. Data Retention
      </Typography>
      <Typography variant="body1" paragraph>
        We will retain your Personal Information only for as long as is
        necessary for the purposes set out in this Privacy Policy.
      </Typography>

      {/* Section 6 */}
      <Typography variant="h6" gutterBottom>
        6. Your Rights
      </Typography>
      <Typography variant="body1" paragraph>
        Depending on your location, you may have the following data protection
        rights:
      </Typography>
      <Typography variant="body1" component="div">
        <ul>
          <li>
            <strong>Access:</strong> You may request access to the Personal
            Information we hold about you.
          </li>
          <li>
            <strong>Correction:</strong> You may request that we correct any
            inaccuracies in your Personal Information.
          </li>
          <li>
            <strong>Deletion:</strong> You may request that we delete your
            Personal Information.
          </li>
          <li>
            <strong>Opt-Out:</strong> You may opt-out of receiving promotional
            communications from us.
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" paragraph>
        To exercise these rights, please contact us at
        support@unity-build-estimation.com.
      </Typography>

      {/* Section 7 */}
      <Typography variant="h6" gutterBottom>
        7. Children's Privacy
      </Typography>
      <Typography variant="body1" paragraph>
        Our Service is not intended for individuals under the age of 18. We do
        not knowingly collect Personal Information from anyone under the age of
        18.
      </Typography>

      {/* Section 8 */}
      <Typography variant="h6" gutterBottom>
        8. Changes to This Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </Typography>

      {/* Section 9 */}
      <Typography variant="h6" gutterBottom>
        9. Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions about this Privacy Policy, please contact us:
      </Typography>
      <Typography variant="body1" component="div">
        <ul>
          <li>
            <strong>Email:</strong> support@unity-build-estimation.com
          </li>
          <li>
            <strong>Address:</strong> Lumpy Studio LLC, [Your Address]
          </li>
        </ul>
      </Typography>

      <Typography variant="body1" paragraph>
        **By using the Service, you acknowledge that you have read, understood,
        and agree to be bound by this Privacy Policy.**
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);
