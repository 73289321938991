import { useState, useEffect } from "react";
import { ItemService } from "../../../api/ItemService";
import { FolderService } from "../../../api/FolderService";
import {
  GlobalItem,
  ItemAttribute,
  ItemCost,
} from "../../../api/protosCompiled/globalItem/globalItem_pb";
import { Folder as FolderProto } from "../../../api/protosCompiled/folder/folder_pb";
import { useFetchFolders } from "../../../hooks/useFetchFolders";
import { CreateItemTabProps } from "./CreateItemTab";
import { useFetchUoMs } from "../../../hooks/useFetchUoMs";
import { useFetchAttributeGroups } from "../../../hooks/useFetchAttributeGroups";
import { MenuOptions } from "../../../types/FolderTypes";
import { Dialog, DialogContent } from "@mui/material";
import { CreateAttribute } from "./CreateAttribute";
import { CreateUoM } from "./CreateUoM";

export type CreateItemProps = CreateItemTabProps & {
  items: GlobalItem.AsObject[];
  refetchItems: () => void;
};

export const CreateItem = ({
  parentFolderId,
  onItemCreated,
  item,
  items,
  menuOption,
  setMenuOption,
  refetchItems,
}: {
  parentFolderId: string | null;
  onItemCreated: () => void;
  item: GlobalItem.AsObject;
  items: GlobalItem.AsObject[];
  menuOption?: MenuOptions | null;
  setMenuOption: (option: MenuOptions | null) => void;
  refetchItems: () => void;
} & CreateItemProps) => {
  const { refetchFolders } = useFetchFolders();
  const [itemName, setItemName] = useState("");
  const [selectedUom, setSelectedUom] = useState<string | null>(null);
  const [selectedAttributeGroup, setSelectedAttributeGroup] = useState<
    string | null
  >(null);
  const [selectedAttributes, setSelectedAttributes] = useState<any>({});
  const [parentFolderLevel, setParentFolderLevel] = useState<number>(1);

  const { data: uoms } = useFetchUoMs();
  const { data: attributeGroups } = useFetchAttributeGroups();

  useEffect(() => {
    if (menuOption === "Edit Item") {
      const firstItem = items[0];
      setItemName(firstItem?.name || "");

      // Set selected UoM based on costs from the first item
      if (firstItem?.costsList && firstItem?.costsList.length > 0) {
        setSelectedUom(firstItem?.costsList[0]?.uomid?.toString() || "");
      } else {
        setSelectedUom("");
      }

      // Set selected attributes based on all items
      const initialSelectedAttributes: { [key: string]: string[] } = {};
      items.forEach((item) => {
        if (item.attributesList && item.attributesList.length > 0) {
          item.attributesList.forEach((attr) => {
            const groupId = attr.attributegroupid.toString();
            const valueId = attr.attributevalueid.toString();

            if (!initialSelectedAttributes[groupId]) {
              initialSelectedAttributes[groupId] = [];
            }
            // Prevent duplicates
            if (!initialSelectedAttributes[groupId].includes(valueId)) {
              initialSelectedAttributes[groupId].push(valueId);
            }
          });
        }
      });

      if (items[0]?.attributesList && items[0]?.attributesList.length > 0) {
        setSelectedAttributeGroup(
          items[0]?.attributesList[0]?.attributegroupid?.toString()
        );
      }
      setSelectedAttributes(initialSelectedAttributes);
    }
  }, [items, menuOption, item]);

  useEffect(() => {
    const fetchParentFolderDetails = async () => {
      if (parentFolderId) {
        try {
          const foldersResponse = await FolderService.getFolders();
          const parentFolder = foldersResponse.foldersList.find(
            (folder: FolderProto.AsObject) => folder.folderid === parentFolderId
          );
          if (parentFolder) {
            setParentFolderLevel(parentFolder.level);
          }
        } catch (error) {
          console.error("Error fetching parent folder details:", error);
        }
      }
    };
    fetchParentFolderDetails();
  }, [parentFolderId]);

  const handleCreateItem = async () => {
    if (!parentFolderId || !selectedUom) return;

    try {
      const newItem = new GlobalItem();

      // Set basic item details
      switch (menuOption) {
        case "Create Item":
          if (item?.parentid && item.isfile) {
            parentFolderId = item.parentid;
          }
          newItem.setFolderid("");
          newItem.setName(itemName);
          newItem.setParentid(parentFolderId);
          newItem.setIsactive(true);
          newItem.setLevel(parentFolderLevel + 1);
          newItem.setEntitytype("item");
          newItem.setLastupdated(new Date().toISOString());
          break;
        case "Edit Item":
          if (!item) return;
          newItem.setFolderid(item.folderid);
          newItem.setName(itemName);
          newItem.setParentid(item.parentid);
          newItem.setIsactive(true);
          newItem.setLevel(item.level);
          newItem.setEntitytype("item");
          newItem.setLastupdated(new Date().toISOString());
          break;
        default:
          break;
      }

      // Create a map of existing costs for easy lookup
      const existingCostsMap = new Map();
      if (menuOption === "Edit Item") {
        item.costsList.forEach((cost) => {
          const key = `${cost.itemattributevalueid}-${cost.uomid}`;
          existingCostsMap.set(key, cost);
        });
      }

      // Add attributes and costs to newItem
      Object.keys(selectedAttributes).forEach((groupId) => {
        selectedAttributes[groupId].forEach((attrValueId: string) => {
          const attributeValueId = Number(attrValueId);
          const uomId = Number(selectedUom);

          // Add attribute to newItem
          const itemAttr = new ItemAttribute();
          itemAttr.setAttributegroupid(Number(groupId));
          itemAttr.setAttributevalueid(attributeValueId);
          newItem.addAttributes(itemAttr);

          // Prepare itemCost
          const itemCost = new ItemCost();
          itemCost.setUomid(uomId);
          itemCost.setItemattributevalueid(attributeValueId);

          // Check if cost exists and set values accordingly
          const key = `${attributeValueId}-${uomId}`;
          const existingCost = existingCostsMap.get(key);

          if (existingCost) {
            // Use existing cost values
            itemCost.setItemid(existingCost.itemid);
            itemCost.setItemcostid(existingCost.itemcostid);
            itemCost.setItemunitcost(existingCost.itemunitcost);
            itemCost.setHourlyproductionrate(existingCost.hourlyproductionrate);
          } else {
            // Set default values for new costs
            itemCost.setItemunitcost(0);
            itemCost.setItemid(item.folderid);
            itemCost.setHourlyproductionrate(0);
          }

          newItem.addCosts(itemCost);
        });
      });

      await ItemService.createOrUpdateGlobalItem(newItem);
    } catch (error) {
      console.error(`Error with ${menuOption}:`, error);
    }
    refetchFolders();
    refetchItems();
    if (menuOption === "Create Item") {
      onItemCreated();
    }
  };

  const handleCheckboxChange = (groupId: string, valueId: string) => {
    setSelectedAttributes((prevSelected: any) => {
      const groupValues = prevSelected[groupId] || [];
      const updatedValues = groupValues.includes(valueId)
        ? groupValues.filter((id: string) => id !== valueId)
        : [...groupValues, valueId];

      return {
        ...prevSelected,
        [groupId]: updatedValues,
      };
    });
  };

  const handleCloseWindow = () => {
    onItemCreated();
    setMenuOption(null);
  };

  const handleOpenUOM = () => {
    if (menuOption !== "UOM") {
      setMenuOption("UOM");
    }
  };

  const handleOpenAttribute = () => {
    if (menuOption !== "Attribute Group") {
      setMenuOption("Attribute Group");
    }
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <div>
          <label>Generic Item Name (e.g. Tubing, not 1/2" Tubing)</label>
          <input
            type="text"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            style={{ width: "80%" }}
          />
        </div>
      </div>

      {/* UOM Selector */}
      <div>
        <label>
          Select UOM (Unit of Measure the items are sold in. This cannot be
          changed after it is set.)
        </label>
        <select
          value={selectedUom || ""}
          onChange={(e) => setSelectedUom(e.target.value)}
          style={{ width: "80%" }}
          disabled={
            menuOption === "Edit Item" &&
            item.costsList &&
            item.costsList.length > 0 &&
            item.costsList[0].uomid !== 0
          }
        >
          <option value="" disabled>
            Select UOM
          </option>
          {uoms?.map((uom) => (
            <option key={uom.id} value={uom.id}>
              {uom.name}
            </option>
          ))}
        </select>
      </div>

      {/* Attribute Group Selector */}
      <div style={{ width: "80%" }}>
        <label>
          Select Attribute Group (Cannot be changed after it is set.)
        </label>
        <select
          value={selectedAttributeGroup || ""}
          onChange={(e) => setSelectedAttributeGroup(e.target.value)}
          style={{ width: "80%" }}
          disabled={
            menuOption === "Edit Item" &&
            item.costsList &&
            item.costsList.length > 0 &&
            item.costsList[0].itemattributevalueid !== 0
          }
        >
          <option value="" disabled>
            Select Attribute Group
          </option>
          {attributeGroups?.map((group) => (
            <option key={group.attributegroupid} value={group.attributegroupid}>
              {group.attributegroupname}
            </option>
          ))}
        </select>
      </div>

      {/* Attribute Values Checkbox List */}
      <div className="attribute-values-container">
        {attributeGroups &&
          selectedAttributeGroup &&
          attributeGroups
            .find(
              (group) =>
                String(group.attributegroupid) === selectedAttributeGroup
            )
            ?.attributevaluesList.map((value) => (
              <div key={value.attributevalueid}>
                <input
                  type="checkbox"
                  checked={
                    selectedAttributes[selectedAttributeGroup]?.includes(
                      value.attributevalueid.toString()
                    ) || false
                  }
                  onChange={() =>
                    handleCheckboxChange(
                      selectedAttributeGroup,
                      value.attributevalueid.toString()
                    )
                  }
                />
                <label>{value.attributevalue}</label>
              </div>
            ))}
      </div>

      <div style={{ position: "absolute", bottom: "20px", right: "20px" }}>
        <button onClick={handleOpenUOM}>UOMs</button>
        <button onClick={handleOpenAttribute}>Attribute Groups</button>
        <button onClick={handleCreateItem} disabled={!itemName || !selectedUom}>
          {"Save Item"}
        </button>
        <button style={{ color: "red" }} onClick={handleCloseWindow}>
          X
        </button>
      </div>

      <Dialog
        open={menuOption === "Attribute Group" || menuOption === "UOM"}
        onClose={() => setMenuOption("Create Item")}
      >
        <DialogContent
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 4,
            height: "700px",
            overflowY: "hidden",
            backgroundColor: "lightgray",
            border: "1px solid black",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          {menuOption === "Attribute Group" && <CreateAttribute />}
          {menuOption === "UOM" && <CreateUoM />}
        </DialogContent>
      </Dialog>
    </>
  );
};
