import {
  Box,
  Button,
  Popover,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import React from "react";
import { useStore } from "zustand";
import LogIn from "../hooks/LogIn/LogIn";
import { useHandleGoHome } from "../hooks/useHandleGoHome";
import { useHandleSetCreateStates } from "../hooks/useHandleSetCreateStates";
import { useHandleSetViewingState } from "../hooks/useUseHandleSetViewingState";
import { useUnityBuildStore } from "../states/store";
import { UserPicture } from "./UserPicture";
import { Settings } from "./Settings";

export function MainMenu() {
  const {
    userBackgroundColor,
    isFetchingToken,
    isLoggedIn,
    selectedProject,
    isOpeningViewport,
    isViewingExtension,
    selectedEstimate,
    selectedContract,
    selectedChangeOrder,
    setIsViewingEstimateList,
    setIsViewingExtension,
  } = useStore(useUnityBuildStore);

  const handleGoHome = useHandleGoHome();
  const handleSetViewingState = useHandleSetViewingState();
  const handleSetCreateStates = useHandleSetCreateStates();

  const [loginDialogOpen, setLoginDialogOpen] = React.useState(false);

  const handleOpenLoginDialog = () => {
    setLoginDialogOpen(true);
  };

  const handleCloseLoginDialog = () => {
    setLoginDialogOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleOpenSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSettings = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "settings-popover" : undefined;

  return (
    <Box
      sx={{
        backgroundColor: userBackgroundColor,
      }}
    >
      {isLoggedIn ? (
        <>
          <Button
            onClick={handleOpenSettings}
            aria-describedby={id}
            color="inherit"
          >
            <UserPicture />
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleCloseSettings}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Settings
              onCloseMenu={handleCloseSettings}
              onLogout={() => setLoginDialogOpen(false)} // Ensure the dialog closes on logout
            />
          </Popover>

          {selectedProject.id && (
            <Button
              color="inherit"
              sx={{ border: "1px solid black", margin: "2px" }}
              onClick={() => handleGoHome(true)}
            >
              Project List
            </Button>
          )}
          {selectedProject.id && (
            <>
              {(selectedEstimate.estimateid ||
                selectedChangeOrder.changeorderid ||
                selectedContract.contractid) && (
                <Button
                  color="inherit"
                  sx={{ border: "1px solid black", margin: "2px" }}
                  onClick={() =>
                    handleSetViewingState(setIsViewingEstimateList, true)
                  }
                >
                  Takeoff Lists
                </Button>
              )}
              {isOpeningViewport && (
                <Button
                  color="inherit"
                  sx={{ border: "1px solid black", margin: "2px" }}
                  onClick={() =>
                    handleSetCreateStates(
                      setIsViewingExtension,
                      !isViewingExtension
                    )
                  }
                >
                  Closeout
                </Button>
              )}
              <Typography
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "20px",
                }}
              >
                Project Name: {selectedProject.name}
              </Typography>
            </>
          )}
        </>
      ) : (
        <>
          {isFetchingToken ? (
            <Typography>Loading...</Typography>
          ) : (
            <Button
              type="submit"
              variant="contained"
              onClick={handleOpenLoginDialog}
            >
              Login
            </Button>
          )}
          <Dialog
            open={loginDialogOpen}
            onClose={handleCloseLoginDialog}
            aria-labelledby="login-dialog-title"
          >
            <DialogContent>
              <LogIn />
            </DialogContent>
          </Dialog>
        </>
      )}
    </Box>
  );
}
