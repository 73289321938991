import { clients } from "../clients/grpcClients";
import {
  ChangeOrder,
  CreateChangeOrderRequest,
  CreateChangeOrderResponse,
  GetChangeOrdersRequest,
  GetChangeOrdersResponse,
  UpdateChangeOrderRequest,
  UpdateChangeOrderResponse,
} from "./protosCompiled/changeOrder/changeOrder_pb";
import { useUnityBuildStore } from "../states/store";

const client = clients.changeOrderServiceClient;

export class ChangeOrderService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  public static createChangeOrder = (
    data: ChangeOrder.AsObject
  ): Promise<CreateChangeOrderResponse> => {
    const changeOrder = new ChangeOrder();
    changeOrder.setDescription(data.description);
    changeOrder.setContractid(data.contractid);
    changeOrder.setName(data.name);
    changeOrder.setCustomid(data.customid);
    changeOrder.setDuedate(data.duedate);

    const req = new CreateChangeOrderRequest();
    req.setSessiontoken(this.getMetadata().sessionToken);
    req.setChangeorder(changeOrder);

    return new Promise((resolve, reject) => {
      client.createChangeOrder(req, {}, (err, response: any) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  };

  public static getChangeOrders = (
    contractId: string
  ): Promise<GetChangeOrdersResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new GetChangeOrdersRequest();
      req.setSessiontoken(this.getMetadata().sessionToken);
      req.setContractid(contractId);

      client.getChangeOrders(
        req,
        {},
        (err, response: GetChangeOrdersResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static updateChangeOrder = (
    data: ChangeOrder.AsObject
  ): Promise<UpdateChangeOrderResponse.AsObject> => {
    const changeOrder = new ChangeOrder();
    changeOrder.setChangeorderid(data.changeorderid ?? "");
    changeOrder.setDescription(data.description ?? "");
    changeOrder.setDuedate(data.duedate ?? "");
    changeOrder.setContractid(data.contractid ?? "");
    changeOrder.setName(data.name ?? "");
    changeOrder.setCustomid(data.customid ?? "");

    const request = new UpdateChangeOrderRequest();
    request.setChangeorder(changeOrder);
    request.setSessiontoken(this.getMetadata().sessionToken);

    return new Promise((resolve, reject) => {
      client.updateChangeOrder(request, {}, (err, response: any) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  };
}
