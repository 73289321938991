import { useState } from "react";
import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";

import { Calculations as c } from "../../utils/calculations";
import { SubcontractRow } from "./SubcontractRow";
import { SubcontractType } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";

export const rowStyle = { width: "85px" };

export const SubcontractHeader = ({
  subcontractExpenses,
  subcontractExpensesDefaults,
}: {
  subcontractExpenses: SubcontractType.AsObject[];
  subcontractExpensesDefaults: SubcontractType.AsObject[];
}) => {
  const { setIncludedSubcontractTypes, updateSubcontractType } =
    useStore(useCloseoutStore);
  const [selectedSubcontractType, setselectedSubcontractType] =
    useState<string>("");

  const handleChange = (e: any) => {
    const newValue = e.target.value as string;
    const defaultSubcontractType = subcontractExpensesDefaults.find(
      (subcontractType) => subcontractType.name === newValue
    );

    if (defaultSubcontractType) {
      const newGenExpenseTypeType: SubcontractType.AsObject = {
        name: defaultSubcontractType.name,
        quotedcost: defaultSubcontractType.quotedcost || 0,
        adjustedpercent: defaultSubcontractType.adjustedpercent || 0,
        totalcost: c.calculateSubcontractTotal(defaultSubcontractType) || 0,
      };

      if (
        !subcontractExpenses.some(
          (generalExpenses) => generalExpenses.name === newValue
        )
      ) {
        const newIncludedSubcontractTypes = [
          ...subcontractExpenses,
          newGenExpenseTypeType,
        ];
        setIncludedSubcontractTypes(newIncludedSubcontractTypes);
        setselectedSubcontractType("");
      }
    }
  };

  const handleDelete = (laborName: string) => {
    const newIncludedGenExpenseTypes = subcontractExpenses.filter(
      (l) => l.name !== laborName
    );
    setIncludedSubcontractTypes(newIncludedGenExpenseTypes);
  };

  const handleFieldUpdate = (field: string, value: any, index: number) => {
    const updatedSubcontractExpense = {
      ...subcontractExpenses[index],
      [field]: value,
    };

    if (field === "quotedCost") {
      updatedSubcontractExpense.quotedcost = value;
    } else if (field === "adjustedPercent") {
      updatedSubcontractExpense.adjustedpercent = value;
    }
    updateSubcontractType(index, updatedSubcontractExpense);
  };

  return (
    <>
      <select value={selectedSubcontractType} onChange={handleChange}>
        <option value="" disabled selected hidden>
          Select subcontract type
        </option>
        {subcontractExpensesDefaults
          .sort((a, b) => a.name.localeCompare(b.name)) // Sorting alphabetically
          .map((subcontractExpensesType, index) => (
            <option value={subcontractExpensesType.name} key={index}>
              {subcontractExpensesType.name}
            </option>
          ))}
      </select>
      <div className="scrollableCDiv">
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Quoted Cost</th>
              <th>% Adjustment</th>
              <th>Total Cost</th>
            </tr>
          </thead>
          <tbody>
            {subcontractExpenses.map((subcontract, index) => (
              <SubcontractRow
                key={index}
                subcontract={subcontract}
                handleDelete={handleDelete}
                handleFieldUpdate={(field, value) =>
                  handleFieldUpdate(field, value, index)
                }
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
