import { MenuItem } from "@mui/material";
import { capitalizeFirstLetter } from "./helpers";
import { Entitytype, MenuOptions } from "../../types/FolderTypes";
import { Folder } from "../../api/protosCompiled/folder/folder_pb";

interface CollapsableRowMenuProps {
  clickedFolderOrItem: Folder.AsObject;
  entitytype: Entitytype;
  handleMenuSelect: (option: MenuOptions) => void;
}

export const CollapsableRowMenu: React.FC<CollapsableRowMenuProps> = ({
  clickedFolderOrItem,
  entitytype,
  handleMenuSelect,
}) => {
  const getMenuOptionForEntityType = (entitytype: Entitytype): MenuOptions => {
    switch (entitytype) {
      case "project":
        return "Create Project";
      case "assembly":
        return "Create Assembly";
      case "item":
        return "Create Item";
      default:
        throw new Error(`Invalid entity type: ${entitytype}`);
    }
  };
  return (
    <>
      <MenuItem
        onClick={() =>
          handleMenuSelect(
            clickedFolderOrItem.entitytype === "item" &&
              clickedFolderOrItem.isfile
              ? "Edit Item"
              : clickedFolderOrItem.entitytype === "assembly" &&
                clickedFolderOrItem.isfile
              ? "Edit Assembly"
              : "Create Folder"
          )
        }
      >
        {clickedFolderOrItem.entitytype === "item" && clickedFolderOrItem.isfile
          ? "Edit Item"
          : clickedFolderOrItem.entitytype === "assembly" &&
            clickedFolderOrItem.isfile
          ? "Edit Assembly"
          : "Create Folder"}
      </MenuItem>
      <MenuItem
        onClick={() => handleMenuSelect(getMenuOptionForEntityType(entitytype))}
      >
        Create {capitalizeFirstLetter(entitytype)}
      </MenuItem>
      <MenuItem onClick={() => handleMenuSelect("Rename")}>Rename</MenuItem>
      {/* Delete Item, Assembly */}
      <MenuItem
        disabled={clickedFolderOrItem.parentid === ""}
        onClick={() =>
          handleMenuSelect(
            clickedFolderOrItem.entitytype === "item" &&
              clickedFolderOrItem.isfile
              ? "Delete Item"
              : clickedFolderOrItem.entitytype === "assembly" &&
                clickedFolderOrItem.isfile
              ? "Delete Assembly"
              : "Delete Folder"
          )
        }
      >
        {clickedFolderOrItem.entitytype === "item" && clickedFolderOrItem.isfile
          ? "Delete Item"
          : clickedFolderOrItem.entitytype === "assembly" &&
            clickedFolderOrItem.isfile
          ? "Delete Assembly"
          : "Delete Folder"}
      </MenuItem>
      <MenuItem
        disabled={clickedFolderOrItem.parentid === ""}
        onClick={() => handleMenuSelect("Move")}
      >
        Move
      </MenuItem>
    </>
  );
};
