// AccountCreateFlow.tsx

import { Box, Stepper, Step, StepLabel } from "@mui/material";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, Routes, Route } from "react-router-dom";
import { CreateAccount } from "./CreateAccount";
import { CreateUser } from "./CreateUser";
import { CheckoutForm } from "./Checkout";

const steps = ["Create Account", "Create User", "Setup Payments"];

export const AccountCreateFlow = () => {
  const [setupStep, setSetupStep] = useState(1);
  const [accountId, setAccountId] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/signup") {
      setSetupStep(1);
    } else if (location.pathname === "/signup/create-user") {
      setSetupStep(2);
    } else if (location.pathname === "/signup/payment") {
      setSetupStep(3);
    }
  }, [location.pathname, navigate]);

  const handleNextStep = () => {
    setSetupStep((prevStep) => prevStep + 1);
    switch (setupStep + 1) {
      case 2:
        navigate("/signup/create-user");
        break;
      case 3:
        navigate("/signup/payment");
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={{ width: "100%", mt: 4 }}>
      <Stepper activeStep={setupStep - 1} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ my: 4 }}>
        <Routes>
          <Route
            path="/"
            element={
              <CreateAccount
                accountId={accountId}
                setAccountId={setAccountId}
                onNextStep={handleNextStep}
              />
            }
          />
          <Route
            path="create-user"
            element={
              <CreateUser accountId={accountId} onNextStep={handleNextStep} />
            }
          />
          <Route path="payment" element={<CheckoutForm />} />
        </Routes>
      </Box>
    </Box>
  );
};
