import { useQuery, useQueryClient } from "react-query";
import { GlobalAssemblyService as gas } from "../api/GlobalAssemblyService";
import {
  GetAllGlobalAssembliesRequest,
  GlobalAssembly,
} from "../api/protosCompiled/globalAssembly/globalAssembly_pb";
import { useUnityBuildStore } from "../states/store";

export function useGetGlobalAssemblies() {
  const { data, error, isLoading, refetch } = useQuery(
    "assemblies",
    async () => {
      const options: GetAllGlobalAssembliesRequest.AsObject = {
        pagenumber: 1,
        pagesize: 100,
        searchkeyword: "",
        sortby: "name",
        sortdescending: false,
        sessiontoken: useUnityBuildStore.getState().sessionToken,
      };
      const response = await gas.getAllGlobalAssemblies(options);
      return response.assembliesList;
    },
    {}
  );

  return { data, error, isLoading, refetch };
}

export function useFetchAssembliesByFileId(fileId: string) {
  const queryClient = useQueryClient();

  const fetchAssemblies = async () => {
    const res = await gas.getAssembliesByFileId(fileId);
    return res.assembliesList || [];
  };

  const { data, error, isLoading } = useQuery<GlobalAssembly.AsObject[], Error>(
    ["assembliesByFileId", fileId],
    fetchAssemblies,
    {
      enabled: !!fileId,
    }
  );

  const refetchAssemblies = () => {
    queryClient.invalidateQueries(["assembliesByFileId", fileId]);
  };

  // Return the query data, refetch function, and the fetching function to call elsewhere
  return { data, error, isLoading, refetchAssemblies, fetchAssemblies };
}

//TODO - add an update react-query function for creating and updating updating the global assembly
//we can then use this state instead of creating a zustand state for the global assemblies
