import {
  Classification,
  CreateClassificationRequest,
  CreateClassificationResponse,
  GetAllClassificationsRequest,
  GetAllClassificationsResponse,
  UpdateClassificationRequest,
  UpdateClassificationResponse,
  DeleteClassificationRequest,
  DeleteClassificationResponse,
  GetClassificationMapRequest,
  GetClassificationMapResponse,
} from "./protosCompiled/classification/classification_pb";

import { clients } from "../clients/grpcClients";

export class ClassificationService {
  public static createClassification = (
    classification: Classification.AsObject
  ): Promise<CreateClassificationResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const request = new CreateClassificationRequest();
      const classificationMessage = new Classification();
      classificationMessage.setId(classification.id);
      classificationMessage.setClassname(classification.classname);
      request.setClassification(classificationMessage);

      clients.classificationServiceClient.createClassification(
        request,
        {},
        (err: any, response: CreateClassificationResponse) => {
          if (err) {
            console.error("gRPC Error:", err);
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static getAllImageClassifications =
    (): Promise<GetAllClassificationsResponse.AsObject> => {
      return new Promise((resolve, reject) => {
        const request = new GetAllClassificationsRequest();

        clients.classificationServiceClient.getAllClassifications(
          request,
          {},
          (err: any, response: GetAllClassificationsResponse) => {
            if (err) {
              console.error("gRPC Error:", err);
              reject(err);
              return;
            }
            resolve(response.toObject());
          }
        );
      });
    };

  public static updateClassification = (
    classification: Classification.AsObject
  ): Promise<UpdateClassificationResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const request = new UpdateClassificationRequest();
      const classificationMessage = new Classification();
      classificationMessage.setId(classification.id);
      classificationMessage.setClassname(classification.classname);
      request.setClassification(classificationMessage);

      clients.classificationServiceClient.updateClassification(
        request,
        {},
        (err: any, response: UpdateClassificationResponse) => {
          if (err) {
            console.error("gRPC Error:", err);
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static deleteClassification = (
    id: string
  ): Promise<DeleteClassificationResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const request = new DeleteClassificationRequest();
      request.setId(id);

      clients.classificationServiceClient.deleteClassification(
        request,
        {},
        (err: any, response: DeleteClassificationResponse) => {
          if (err) {
            console.error("gRPC Error:", err);
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  /**
   *
   * @returns - The classification map is the decoder to map class IDs to class names
   */
  public static getClassificationMap =
    (): Promise<GetClassificationMapResponse.AsObject> => {
      return new Promise((resolve, reject) => {
        const request = new GetClassificationMapRequest();

        clients.classificationServiceClient.getClassificationMap(
          request,
          {},
          (err: any, response: GetClassificationMapResponse) => {
            if (err) {
              console.error("gRPC Error:", err);
              reject(err);
              return;
            }
            resolve(response.toObject());
          }
        );
      });
    };
}
