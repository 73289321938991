import { useQuery } from "react-query";
import { AssistantService } from "../api/AssistantService";

export function useListMessagesOnThread(projectId: string) {
  const { data, error, isLoading, isFetching } = useQuery(
    ["messages", projectId],
    async () => {
      if (projectId) {
        const res = await AssistantService.listMessagesOnThread({
          projectid: projectId,
        });
        return res.messagesList;
      }
      throw new Error("No valid ID available for fetching change orders");
    },
    {
      enabled: !!projectId,
    }
  );
  return { data, error, isLoading, isFetching };
}
