import React from "react";
import { motion, useMotionValue } from "framer-motion";
import { useUnityBuildStore } from "../../states/store";
import { useSubmitTakeoffAssembly } from "../../hooks/useSubmitTakeoffAssembly";
import { ResetStates } from "../../states/resetStates";
import { useStore } from "zustand";

import { useCreateAssemblyStore } from "../../states/createAssemblyStore";

interface ShapePoint {
  x: number;
  y: number;
}

export interface AssemblyStyle {
  pointType?: string;
  pointSize?: number;
  pointBorderColor?: string;
  pointFillColor?: string;
  lineDashArray?: string;
  lineSize?: number;
  lineColor?: string;
  verticallength?: number;
  isSelected: boolean;
}

export interface PointIndex {
  assemblyIndex: number;
  pointIndex: number;
}

interface SVGShapeProps {
  point?: ShapePoint;
  takeoffId?: string;
  assemblyStyle?: AssemblyStyle;
  cursorPositionRef?: any;
  pointMotionX?: any;
  pointMotionY?: any;
  eventHandlers?: any;
  verticalLength: number;
}

// Define a helper function or component for each shape
export const CircleShape: React.FC<SVGShapeProps> = ({
  pointMotionX,
  pointMotionY,
  assemblyStyle,
  eventHandlers,
  verticalLength,
}) => (
  <>
    {/* Outer dashed stroke, visible only if selected */}
    {assemblyStyle?.isSelected && (
      <motion.circle
        cx={pointMotionX}
        cy={pointMotionY}
        r={(assemblyStyle?.pointSize || 5) + 3} // Slightly larger radius
        fill="none" // Transparent fill
        stroke={"red"}
        strokeWidth={1} // Thin dashed line
        strokeDasharray="4,2" // Dash style: 4px dash, 2px gap
      />
    )}
    {/* Main Shape */}
    <motion.circle
      cx={pointMotionX}
      cy={pointMotionY}
      r={assemblyStyle?.pointSize || 5}
      {...eventHandlers}
      fill={assemblyStyle?.pointFillColor || "red"}
      stroke={assemblyStyle?.pointBorderColor || "black"}
    />
    {verticalLength > 0 && (
      <text
        x={pointMotionX.get() + 2}
        y={pointMotionY.get() + 16}
        fill="black"
        fontSize="12"
      >
        {`${parseFloat(verticalLength.toFixed(2))}'`}
      </text>
    )}
  </>
);

const RectShape: React.FC<SVGShapeProps> = ({
  pointMotionX,
  pointMotionY,
  assemblyStyle,
  eventHandlers,
  verticalLength,
}) => {
  const halfSize = assemblyStyle?.pointSize ? assemblyStyle.pointSize / 2 : 5;
  return (
    <>
      {/* Outer dashed stroke */}
      {assemblyStyle?.isSelected && (
        <motion.rect
          x={pointMotionX.get() - halfSize - 3} // Adjust position for larger size
          y={pointMotionY.get() - halfSize - 3}
          width={(assemblyStyle?.pointSize || 10) + 6} // Slightly larger
          height={(assemblyStyle?.pointSize || 10) + 6}
          fill="none"
          stroke={"red"}
          strokeWidth={1}
          strokeDasharray="4,2"
        />
      )}
      {/* Main Shape */}
      <motion.rect
        x={pointMotionX.get() - halfSize}
        y={pointMotionY.get() - halfSize}
        width={assemblyStyle?.pointSize || 10}
        height={assemblyStyle?.pointSize || 10}
        {...eventHandlers}
        fill={assemblyStyle?.pointFillColor || "blue"}
        stroke={assemblyStyle?.pointBorderColor || "black"}
      />
      {verticalLength > 0 && (
        <text
          x={pointMotionX.get() + 2}
          y={pointMotionY.get() + 16}
          fill="black"
          fontSize="12"
        >
          {`${parseFloat(verticalLength.toFixed(2))}'`}
        </text>
      )}
    </>
  );
};

const SquareShape: React.FC<SVGShapeProps> = ({
  pointMotionX,
  pointMotionY,
  assemblyStyle,
  eventHandlers,
  verticalLength,
}) => {
  const halfSize = assemblyStyle?.pointSize ? assemblyStyle.pointSize / 2 : 5;
  return (
    <>
      {assemblyStyle?.isSelected && (
        <motion.rect
          x={pointMotionX.get() - halfSize - 3} // Adjust position for larger size
          y={pointMotionY.get() - halfSize - 3}
          width={(assemblyStyle?.pointSize || 10) + 6} // Slightly larger
          height={(assemblyStyle?.pointSize || 10) + 6}
          fill="none"
          stroke={"red"}
          strokeWidth={1}
          strokeDasharray="4,2"
        />
      )}
      {/* Main Shape */}
      <motion.rect
        x={pointMotionX.get() - halfSize}
        y={pointMotionY.get() - halfSize}
        width={assemblyStyle?.pointSize || 10}
        height={assemblyStyle?.pointSize || 10}
        {...eventHandlers}
        fill={assemblyStyle?.pointFillColor || "green"}
        stroke={assemblyStyle?.pointBorderColor || "black"}
      />
      {verticalLength > 0 && (
        <text
          x={pointMotionX.get() + 2}
          y={pointMotionY.get() + 16}
          fill="black"
          fontSize="12"
        >
          {`${parseFloat(verticalLength.toFixed(2))}'`}
        </text>
      )}
    </>
  );
};

export const SVGShape: React.FC<SVGShapeProps & PointIndex> = ({
  point,
  pointIndex,
  assemblyIndex,
  takeoffId,
  assemblyStyle,
  cursorPositionRef,
  verticalLength,
}) => {
  const store = useStore(useUnityBuildStore);
  const store1 = useStore(useCreateAssemblyStore);
  const {
    updatePoint,
    selectedTakeoffAssembly,
    setSelectedTakeoffAssembly,
    setIsDragging,
    filteredCountedAssemblies,
    setIsEditingPoint,
  } = useStore(useUnityBuildStore);

  const submitAssembly = useSubmitTakeoffAssembly();

  const pointMotionX = useMotionValue(point?.x);
  const pointMotionY = useMotionValue(point?.y);

  const newPosition: any = {
    x: pointMotionX.get(),
    y: pointMotionY.get(),
  };

  const handleMouseDown = (e: any) => {
    if (selectedTakeoffAssembly) return;
    const assembly =
      filteredCountedAssemblies && filteredCountedAssemblies[assemblyIndex];
    setSelectedTakeoffAssembly(assembly);
    setIsDragging(true);
    setIsEditingPoint({
      isEditingPoint: false,
      assemblyIndex: assemblyIndex,
      pointIndex: pointIndex,
    });
  };

  const handleMouseUp = (e: any) => {
    if (e.button === 0) {
      ResetStates.resetTakeoffStates(store, store1);
    }
    setIsDragging(false);
  };

  const onDrag = () => {
    if (cursorPositionRef.current) {
      const transformedX = cursorPositionRef.current.x;
      const transformedY = cursorPositionRef.current.y;

      pointMotionX.set(transformedX);
      pointMotionY.set(transformedY);

      updatePoint({
        assemblyIndex: assemblyIndex,
        pointIndex: pointIndex,
        newPosition: newPosition,
        isDeletingPoint: false,
      });
    }
  };

  const onDragEnd = async () => {
    if (
      selectedTakeoffAssembly === null ||
      selectedTakeoffAssembly.takeoffid !== takeoffId
    )
      return;

    const newPosition = { x: pointMotionX.get(), y: pointMotionY.get() };

    updatePoint({
      assemblyIndex: assemblyIndex,
      pointIndex: pointIndex,
      newPosition: newPosition,
      isDeletingPoint: false,
    });

    const updatedPoints = [...selectedTakeoffAssembly.pointsList];
    updatedPoints[pointIndex] = {
      ...updatedPoints[pointIndex],
      x: newPosition.x,
      y: newPosition.y,
    };

    const updatedAssembly = {
      ...selectedTakeoffAssembly,
      pointsList: updatedPoints,
    };
    await submitAssembly({
      assembly: updatedAssembly,
      isUpdating: true,
    });
    ResetStates.resetTakeoffStates(store, store1);
  };

  const eventHandlers = {
    drag: "x",
    onMouseDown: handleMouseDown,
    onMouseUp: handleMouseUp,
    onDrag: onDrag,
    onDragEnd: onDragEnd,
    dragMomentum: false,
    dragElastic: 0,
    whileDrag: { cursor: "grabbing" },
    dragConstraints: { top: 0, right: 0, bottom: 0, left: 0 },
  };

  const renderShapeSwitch = () => {
    switch (assemblyStyle?.pointType) {
      case "circle":
        return (
          <CircleShape
            pointMotionX={pointMotionX}
            pointMotionY={pointMotionY}
            assemblyStyle={assemblyStyle}
            eventHandlers={eventHandlers}
            verticalLength={verticalLength}
          />
        );
      case "rectangle":
        return (
          <RectShape
            pointMotionX={pointMotionX}
            pointMotionY={pointMotionY}
            assemblyStyle={assemblyStyle}
            eventHandlers={eventHandlers}
            verticalLength={verticalLength}
          />
        );
      case "square":
        return (
          <SquareShape
            pointMotionX={pointMotionX}
            pointMotionY={pointMotionY}
            assemblyStyle={assemblyStyle}
            eventHandlers={eventHandlers}
            verticalLength={verticalLength}
          />
        );
      default:
        return null;
    }
  };

  return <>{renderShapeSwitch()}</>;
};
