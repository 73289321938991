import { useQuery } from "react-query";
import { UbAdminService } from "../api/UbAdminService";

export function useListOpenAiFiles() {
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    ["openaiFiles"],
    async () => {
      const response = await UbAdminService.getOpenAiFileList();
      return response;
    },
    {}
  );
  return { data, error, isLoading, isFetching, refetch };
}
