import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useStore } from "zustand";
import {
  AssociatedChangeOrder,
  AssociatedEstimate,
  Contract,
} from "../../api/protosCompiled/contract/contract_pb";
import {
  useCreateContractMutation,
  useUpdateContractMutation,
} from "../../hooks/useCreateContractMutation";
import { defaultContract, useUnityBuildStore } from "../../states/store";
import { useFetchContracts } from "../../hooks/useFetchContracts";
import { useFetchEstimates } from "../../hooks/useFetchProjectEstimates";

export const CreateContract: React.FC = () => {
  const {
    isCreatingContract,
    selectedContract,
    setSelectedContract,
    selectedProject,
    setIsCreatingContract,
    setIsViewingContractForm,
  } = useStore(useUnityBuildStore);

  const { data: estimates } = useFetchEstimates();
  const { data: contracts } = useFetchContracts();

  const { mutate: createContract } = useCreateContractMutation();
  const { mutate: updateContract } = useUpdateContractMutation();

  const [customId, setCustomId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [contractDescription, setContractDescription] = useState<string>("");
  const [associatedEstimates, setAssociatedEstimates] = useState<
    AssociatedEstimate.AsObject[]
  >([]);
  const [associatedChangeOrders, setAssociatedChangeOrders] = useState<
    AssociatedChangeOrder.AsObject[]
  >([]);

  useEffect(() => {
    if (!isCreatingContract) {
      if (!contracts || contracts.length === 0 || !selectedContract) {
        console.log("Contracts or selectedContract is not defined.");
        return;
      }

      // Normalize IDs to avoid any subtle comparison issues
      const selectedContractObj = contracts.find(
        (contract) =>
          contract.contractid.trim() === selectedContract.contractid.trim()
      );

      if (!selectedContractObj) {
        setSelectedContract(defaultContract);
      } else {
        setCustomId(selectedContractObj.customid ?? "");
        setName(selectedContractObj.contractname ?? "");
        setContractDescription(selectedContractObj.contractdescription ?? "");
        setAssociatedEstimates(
          selectedContractObj?.associatedestimatesList ?? []
        );
        setAssociatedChangeOrders(
          selectedContractObj?.associatedchangeordersList ?? []
        );
      }
    }
    //eslint-disable-next-line
  }, [selectedContract, contracts]);

  type Field =
    | "customId"
    | "contractName"
    | "contractDescription"
    | "associatedEstimates";

  const handleSetValue = (field: Field, value: string) => {
    switch (field) {
      case "customId":
        setCustomId(value);
        break;
      case "contractName":
        setName(value);
        break;
      case "contractDescription":
        setContractDescription(value);
        break;
      default:
        break;
    }
  };

  const handleEstimatesChange = (event: any) => {
    const {
      target: { value },
    } = event;

    // Find the selected estimates by their ids and store the full object
    const selectedEstimates = estimates
      ?.filter(
        (estimateObj) =>
          estimateObj.estimate &&
          value.includes(estimateObj.estimate.estimateid)
      )
      .map((estimateObj: any) => ({
        estimateid: estimateObj.estimate.estimateid,
        contractid: estimateObj.estimate.contractid,
        projectid: estimateObj.estimate.projectid ?? "",
        description: estimateObj.estimate.description ?? "",
        duedate: estimateObj.estimate.duedate ?? "",
        datecreated: estimateObj.estimate.datecreated ?? "",
        name: estimateObj.estimate.estimatename ?? "",
        customid: estimateObj.estimate.customid ?? "",
        createdbyuserid: estimateObj.estimate.createdbyuserid ?? "",
        createdbyaccountid: estimateObj.estimate.createdbyaccountid ?? "",
        lastupdated: estimateObj.estimate.lastupdated ?? "",
      }));

    setAssociatedEstimates(selectedEstimates ?? []);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const formData: Contract.AsObject = {
      contractid: selectedContract.contractid ?? "",
      customid: customId,
      projectid: selectedProject.id,
      datecreated: selectedContract.datecreated ?? "",
      lastupdated: "",
      contractname: name,
      contractdescription: contractDescription,
      associatedestimatesList: associatedEstimates,
      associatedchangeordersList: associatedChangeOrders,
    };

    if (isCreatingContract) {
      createContract(formData);
    } else {
      updateContract(formData);
    }
    resetForm();
    setIsViewingContractForm(false);
    setIsCreatingContract(false);
  };

  const handleContractFormCancel = () => {
    setIsCreatingContract(false);
    setIsViewingContractForm(false);
  };

  const resetForm = () => {
    setCustomId("");
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="contractName"
        label="Provide a Contract Name"
        rows={4}
        value={name}
        onChange={(e) => handleSetValue("contractName", e.target.value)}
      />
      <TextField
        margin="normal"
        fullWidth
        id="contractDescription"
        label="Provide a Contract Description"
        rows={4}
        value={contractDescription}
        onChange={(e) => handleSetValue("contractDescription", e.target.value)}
      />
      <TextField
        margin="normal"
        fullWidth
        id="customId"
        label="Provide a Custom ID"
        rows={4}
        value={customId}
        onChange={(e) => handleSetValue("customId", e.target.value)}
      />
      {/* Multi-Select box for estimates */}
      <FormControl fullWidth margin="normal">
        <InputLabel>Associate Estimates</InputLabel>
        <Select
          multiple
          value={associatedEstimates.map((estimate) => estimate.estimateid)}
          onChange={handleEstimatesChange}
          renderValue={(selected) =>
            (selected as string[])
              .map(
                (estimateId) =>
                  associatedEstimates.find(
                    (estimate) => estimate.estimateid === estimateId
                  )?.name
              )
              .join(", ")
          }
        >
          {estimates?.map((estimateObj) => {
            const estimate = estimateObj.estimate;
            return (
              estimate && (
                <MenuItem key={estimate.estimateid} value={estimate.estimateid}>
                  <Checkbox
                    checked={associatedEstimates.some(
                      (associatedEstimate) =>
                        associatedEstimate.estimateid === estimate.estimateid
                    )}
                  />
                  <ListItemText primary={estimate.name} />
                </MenuItem>
              )
            );
          })}
        </Select>
      </FormControl>
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }}>
        {isCreatingContract ? "Create Contract" : "Update Contract"}
      </Button>
      <Button onClick={handleContractFormCancel}>Cancel</Button>
    </Box>
  );
};
