import { useEffect } from "react";
import { useListOpenAiFiles } from "../../hooks/useListOpenAiFiles";
import { UbAdminService } from "../../api/UbAdminService";

export const MainAdminPanel = () => {
  const { data: files, isLoading, refetch } = useListOpenAiFiles();

  useEffect(() => {}, [files]);

  async function handleDelete(fileId: string) {
    try {
      await UbAdminService.deleteOpenAiFile(fileId);
      refetch();
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  }

  async function deleteFilesWithDelay(files: any[]) {
    const delay = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    for (const file of files) {
      try {
        await UbAdminService.deleteOpenAiFile(file.id);
        await delay(500);
      } catch (error) {
        console.error(`Error deleting file ${file.id}:`, error);
      }
    }

    refetch();
  }

  return (
    <div>
      <h1>Admin Panel</h1>
      {isLoading && <p>Loading...</p>}
      {!isLoading && files && files.length > 0 && (
        <div>
          <button onClick={() => deleteFilesWithDelay(files)}>
            Delete All Files
          </button>
          {files.map((file) => (
            <div key={file.id}>
              <p>{file.filename}</p>
              <button onClick={() => handleDelete(file.id)}>Delete</button>
            </div>
          ))}
        </div>
      )}
      {!isLoading && files && files.length === 0 && <p>No files available.</p>}
    </div>
  );
};
