import { useQuery } from "react-query";
import { ItemService } from "../api/ItemService";

export const useFetchAttributeGroups = () => {
  const { data, error, isLoading, refetch } = useQuery(
    "attributeGroups",
    async () => {
      const response = await ItemService.getAttributes();
      return response.attributesList;
    },
    {}
  );

  return { data, error, isLoading, refetch };
};
