import { Folder } from "../../api/protosCompiled/folder/folder_pb";
import { FolderService } from "../../api/FolderService";
import { Entitytype, MenuOptions } from "../../types/FolderTypes";
import { GlobalAssemblyService } from "../../api/GlobalAssemblyService";

export const menuSelect = async (
  option: MenuOptions,
  clickedFolderOrItem: Folder.AsObject,
  setIsMoveMode: (move: boolean) => void,
  setFolderToRename: (folder: Folder.AsObject) => void,
  setMenuOption: (option: MenuOptions | null) => void,
  refetchFolders: () => void
) => {
  setMenuOption(option);

  switch (option) {
    case "Rename":
      setFolderToRename(clickedFolderOrItem);
      break;

    case "Delete Item":
    case "Delete Assembly":
    case "Delete Folder":
      await handleDelete(clickedFolderOrItem, refetchFolders);
      break;

    case "Move":
      setIsMoveMode(true);
      break;

    case "Create Folder":
    case "Create Item":
    case "Edit Item":
    case "Edit Assembly":
      setMenuOption(option);
      break;

    default:
      break;
  }
};

export const handleDelete = async (
  clickedFolderOrItem: Folder.AsObject | null,
  refetchFolders: () => void
) => {
  if (!clickedFolderOrItem) return;
  await deleteFolder(clickedFolderOrItem);
  refetchFolders();
};

export const manageFolder = async ({
  folder,
  folderName,
  parentFolderId = "",
  isFile,
  entitytype,
  isActive = true,
  onSuccess,
  refetchFolders,
}: {
  folder?: Folder.AsObject;
  folderName: string;
  parentFolderId?: string;
  isFile?: boolean; // Make isFile optional
  entitytype: Entitytype;
  isActive?: boolean;
  onSuccess: () => void;
  refetchFolders: () => void;
}) => {
  try {
    if (folder) {
      // Updating existing folder or file
      const updatedFolder: Folder.AsObject = {
        ...folder,
        name: folderName || folder.name,
        parentid: parentFolderId || folder.parentid,
        isfile: folder.isfile, // Ensure isfile remains unchanged
      };
      await FolderService.updateFolder(updatedFolder);
    } else {
      // Creating new folder or file
      if (typeof isFile !== "boolean") {
        console.error(
          "Error: isFile must be provided when creating a new folder or file."
        );
        return;
      }
      const newFolder: Folder.AsObject = {
        folderid: "",
        name: folderName,
        parentid: parentFolderId,
        isfile: isFile,
        level: parentFolderId ? 1 : 1,
        entitytype: entitytype,
        isactive: isActive,
        lastupdated: "",
      };
      await FolderService.createFolder(newFolder);
    }
    refetchFolders();
    onSuccess();
  } catch (error) {
    console.error("Error handling folder operation:", error);
  }
};

export const deleteFolder = async (clickedFolderOrItem: Folder.AsObject) => {
  if (clickedFolderOrItem) {
    try {
      if (clickedFolderOrItem.entitytype === "item") {
        await FolderService.deleteFolder(clickedFolderOrItem.folderid);
      } else if (clickedFolderOrItem.entitytype === "assembly") {
        await GlobalAssemblyService.deleteGlobalAssembly(
          clickedFolderOrItem.folderid
        );
      }
    } catch (error) {
      console.error("Error deleting folder", error);
    }
  }
};

export function capitalizeFirstLetter(str: string) {
  if (str.length === 0) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const defaultFolder: Folder.AsObject = {
  folderid: "",
  name: "",
  parentid: "",
  isfile: false,
  level: 0,
  entitytype: "",
  isactive: false,
  lastupdated: "",
};
