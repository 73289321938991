import { create } from "zustand";
import { GlobalItem } from "../api/protosCompiled/globalItem/globalItem_pb";
import { GlobalAssembly } from "../api/protosCompiled/globalAssembly/globalAssembly_pb";

export type CreateGlobalAssemblyStore = {
  filteredItems: GlobalItem.AsObject[];
  setFilteredItems: (items: GlobalItem.AsObject[]) => void;

  assemblyItems: GlobalItem.AsObject[];
  setAssemblyItems: (items: GlobalItem.AsObject[]) => void;

  globalAssembly: GlobalAssembly.AsObject;
  setGlobalAssembly: (assembly: GlobalAssembly.AsObject) => void;
};

export const useCreateAssemblyStore = create<CreateGlobalAssemblyStore>(
  (set) => ({
    filteredItems: [],
    setFilteredItems: (items) => set({ filteredItems: items }),

    assemblyItems: [],
    setAssemblyItems: (items) => set({ assemblyItems: items }),

    globalAssembly: {
      folderid: "",
      globalassemblyid: "",
      assemblyname: "",
      isactive: true,
      lastupdated: "",
      parentid: "",
      level: 0,
      entitytype: "",
      isfile: false,
      assemblymeasurementtype: "",
      itemqtyformulasList: [],
      imageclassificationid: 0,
    },
    setGlobalAssembly: (assembly) =>
      set((state) => ({
        globalAssembly: {
          ...state.globalAssembly,
          ...assembly,
          itemqtyformulasList:
            assembly.itemqtyformulasList ??
            state.globalAssembly.itemqtyformulasList,
        },
      })),
  })
);
