import { clients } from "../clients/grpcClients";
import {
  CreateChangeOrderAssemblyRequest,
  CreateChangeOrderAssemblyResponse,
  ChangeOrderAssembly,
  UpdateChangeOrderAssemblyRequest,
  UpdateChangeOrderAssemblyResponse,
  GetTakeoffStatsResponse,
  GetTakeoffStatsRequest,
  ChangeOrderPoint,
  ChangeOrderItemQtyFormula,
  ChangeOrderAssemblyItemAttribute,
  GetChangeOrderAssembliesByChangeOrderIdResponse,
  GetChangeOrderAssembliesByChangeOrderIdRequest,
} from "./protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import { Point } from "../types/Point";
import { useUnityBuildStore } from "../states/store";

export class ContractChangeOrderAssemblyService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  private static convertToChangeOrderPoints = (
    pointsList: Point[]
  ): ChangeOrderPoint[] => {
    return pointsList.map((point) => {
      const changeOrderPoint = new ChangeOrderPoint();
      changeOrderPoint.setPointid(point.pointid || "");
      changeOrderPoint.setX(point.x || 0);
      changeOrderPoint.setY(point.y || 0);
      changeOrderPoint.setAngle(point.angle || 0);
      changeOrderPoint.setVerticallength(point.verticallength || 0);
      changeOrderPoint.setAllowtrailingline(point.allowtrailingline || false);
      changeOrderPoint.setAllowtrailingmeasurement(
        point.allowtrailingmeasurement || false
      );
      changeOrderPoint.setIsincludedincount(point.isincludedincount || false);
      changeOrderPoint.setIsselected(point.isselected || false);
      changeOrderPoint.setIsactive(point.isactive || false);
      return changeOrderPoint;
    });
  };

  private static calculateSegmentCount = (
    assembly: ChangeOrderAssembly.AsObject
  ) => {
    if (
      assembly.assemblymeasurementtype === "length" ||
      assembly.assemblymeasurementtype === "area"
    ) {
      return assembly.pointsList.length - 1;
    } else {
      return 0;
    }
  };

  private static buildChangeOrderAssemblyMessage(
    assembly: ChangeOrderAssembly.AsObject
  ): ChangeOrderAssembly {
    const changeOrderAssemblyMessage = new ChangeOrderAssembly();
    changeOrderAssemblyMessage.setTakeoffid(assembly.takeoffid || "");
    changeOrderAssemblyMessage.setContractid(assembly.contractid || "");
    changeOrderAssemblyMessage.setChangeorderid(assembly.changeorderid || "");
    changeOrderAssemblyMessage.setObjectid(assembly.objectid || "");
    changeOrderAssemblyMessage.setSegmentlength(assembly.segmentlength || 0);
    changeOrderAssemblyMessage.setSegmentcount(
      this.calculateSegmentCount(assembly)
    );
    changeOrderAssemblyMessage.setSqft(assembly.sqft || 0);

    const pointsList = this.convertToChangeOrderPoints(
      assembly.pointsList || []
    );
    changeOrderAssemblyMessage.setPointsList(pointsList);
    changeOrderAssemblyMessage.setPointcount(pointsList.length);

    changeOrderAssemblyMessage.setPointtype(assembly.pointtype || "");
    changeOrderAssemblyMessage.setPointsize(assembly.pointsize || 0);
    changeOrderAssemblyMessage.setPointbordercolor(
      assembly.pointbordercolor || ""
    );
    changeOrderAssemblyMessage.setPointfillcolor(assembly.pointfillcolor || "");
    changeOrderAssemblyMessage.setLinesize(assembly.linesize || 0);
    changeOrderAssemblyMessage.setLinecolor(assembly.linecolor || "");
    changeOrderAssemblyMessage.setLinedasharray(assembly.linedasharray || "");
    changeOrderAssemblyMessage.setUserscale(assembly.userscale || 0);
    changeOrderAssemblyMessage.setPagenumber(assembly.pagenumber || 1);
    changeOrderAssemblyMessage.setAssemblyname(assembly.assemblyname || "");
    changeOrderAssemblyMessage.setAssemblymeasurementtype(
      assembly.assemblymeasurementtype || ""
    );
    changeOrderAssemblyMessage.setImageclassificationid(
      assembly.imageclassificationid || 0
    );

    if (assembly.itemqtyformulasList) {
      const itemQtyFormulas = assembly.itemqtyformulasList.map((formula) => {
        const changeOrderItemQtyFormula = new ChangeOrderItemQtyFormula();
        changeOrderItemQtyFormula.setItemid(formula.itemid || "");
        changeOrderItemQtyFormula.setItemname(formula.itemname || "");
        changeOrderItemQtyFormula.setItemqty(formula.itemqty || 0);
        changeOrderItemQtyFormula.setForevery(formula.forevery || 0);
        changeOrderItemQtyFormula.setTakeoffvariabletype(
          formula.takeoffvariabletype || ""
        );
        changeOrderItemQtyFormula.setAnd(formula.and || false);
        changeOrderItemQtyFormula.setOr(formula.or || false);
        changeOrderItemQtyFormula.setIsquoted(formula.isquoted || false);
        changeOrderItemQtyFormula.setAssemblyname(formula.assemblyname || "");
        changeOrderItemQtyFormula.setQuotegroup(formula.quotegroup || "");

        if (formula.attributesList) {
          const attributes = formula.attributesList.map((attr) => {
            const attribute = new ChangeOrderAssemblyItemAttribute();
            attribute.setAttributegroupid(attr.attributegroupid || 0);
            attribute.setAttributevalueid(attr.attributevalueid || 0);
            attribute.setAttributevalue(attr.attributevalue || "");
            attribute.setAttributegroupname(attr.attributegroupname || "");
            return attribute;
          });
          changeOrderItemQtyFormula.setAttributesList(attributes);
        }
        return changeOrderItemQtyFormula;
      });
      changeOrderAssemblyMessage.setItemqtyformulasList(itemQtyFormulas);
    }

    return changeOrderAssemblyMessage;
  }

  public static getContractChangeOrderAssemblies = (
    changeOrderId: string
  ): Promise<GetChangeOrderAssembliesByChangeOrderIdResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new GetChangeOrderAssembliesByChangeOrderIdRequest();
      req.setChangeorderid(changeOrderId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      clients.changeOrderAssemblyServiceClient.getChangeOrderAssembliesByChangeOrderId(
        req,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static getAssemblyStatsForChangeOrder = (
    changeOrderId: string
  ): Promise<GetTakeoffStatsResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new GetTakeoffStatsRequest();
      req.setChangeorderid(changeOrderId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      clients.changeOrderAssemblyServiceClient.getTakeoffStatsByChangeOrderID(
        req,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static createContractChangeOrderAssembly = (
    assembly: ChangeOrderAssembly.AsObject
  ): Promise<CreateChangeOrderAssemblyResponse.AsObject> => {
    const changeOrderAssemblyMessage =
      this.buildChangeOrderAssemblyMessage(assembly);

    const req = new CreateChangeOrderAssemblyRequest();
    req.setChangeorderassembly(changeOrderAssemblyMessage);
    req.setSessiontoken(this.getMetadata().sessionToken);

    return new Promise((resolve, reject) => {
      clients.changeOrderAssemblyServiceClient.createChangeOrderAssembly(
        req,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static updateContractChangeOrderAssembly = (
    assembly: ChangeOrderAssembly.AsObject
  ): Promise<UpdateChangeOrderAssemblyResponse.AsObject> => {
    const changeOrderAssemblyMessage =
      this.buildChangeOrderAssemblyMessage(assembly);

    const req = new UpdateChangeOrderAssemblyRequest();
    req.setChangeorderassembly(changeOrderAssemblyMessage);
    req.setSessiontoken(this.getMetadata().sessionToken);

    return new Promise((resolve, reject) => {
      clients.changeOrderAssemblyServiceClient.updateChangeOrderAssembly(
        req,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };
}
