import React, { useEffect, useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Calculations as c } from "../../utils/calculations";
import { DirectLaborRowProps } from "./closeoutTypes";
import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";

export const sxSwitch = (num: number) => {
  if (num > 0) {
    return {
      backgroundColor: "lightyellow",
      border: "1px solid black",
      borderRadius: "5px",
    };
  } else {
    return {
      backgroundColor: "lightGrey",
      border: "1px solid black",
      borderRadius: "5px",
    };
  }
};

export const DirectLaborRow: React.FC<
  DirectLaborRowProps & {
    handleDelete: (name: string) => void;
    handleFieldUpdate: (field: string, value: number) => void;
  }
> = ({
  labor,
  totalLaborHours,
  handleDelete,
  handleDistributionChange,
  handleFieldUpdate,
}) => {
  const { includedDirectLaborTypes, setIsUpdatingDirectLaborAllocation } =
    useStore(useCloseoutStore);
  const [distributionpercent, setDistributionPercent] = useState<string>(
    (labor.distributionpercent * 100).toFixed(2)
  );
  const [allocatedhours, setAllocatedHours] = useState(
    c.calculateAllocatedHours(totalLaborHours, labor.distributionpercent)
  );
  const [laborrate, setLaborRate] = useState<string>(
    labor.laborrate.toFixed(2)
  );
  const [burdenpercent, setBurdenPercent] = useState<string>(
    (labor.burdenpercent * 100).toFixed(2)
  );

  const [fringe, setFringe] = useState<string>(labor.fringe.toFixed(2));

  useEffect(() => {
    setDistributionPercent((labor.distributionpercent * 100).toFixed(2));
    setAllocatedHours(
      c.calculateAllocatedHours(totalLaborHours, labor.distributionpercent)
    );
    setLaborRate(labor.laborrate.toFixed(2));
    setBurdenPercent((labor.burdenpercent * 100).toFixed(2));
    setFringe(labor.fringe.toFixed(2));
  }, [labor, totalLaborHours, includedDirectLaborTypes]);

  const handleFieldChange = (field: string, value: string) => {
    // Allow only numbers and the decimal point
    const regex = /^[0-9]*\.?[0-9]{0,2}$/;
    if (!regex.test(value)) return;

    switch (field) {
      case "distributionpercent":
        if (parseFloat(value) > 100) return;
        setFieldValue(field, value);
        break;
      case "laborrate":
        if (parseFloat(value) > 999.99) return;
        setFieldValue(field, value);
        break;
      case "burdenpercent":
        if (parseFloat(value) > 100) return;
        setFieldValue(field, value);
        break;
      case "fringe":
        if (parseFloat(value) > 999.99) return;
        setFieldValue(field, value);
        break;
      default:
        break;
    }
  };

  const floatValueRef = useRef<number>(0);

  const handleBlur = (field: string, value: string) => {
    floatValueRef.current = parseFloat(value) || 0;
    const updatedValue = isNaN(floatValueRef.current) ? "0" : value;

    switch (field) {
      case "laborHours":
        setAllocatedHours(parseFloat(updatedValue));
        handleFieldUpdate(field, parseFloat(updatedValue));
        break;
      case "distributionpercent":
        const updatedLabor = {
          ...labor,
          distributionpercent: c.safeDivide(floatValueRef.current, 100),
        };
        setIsUpdatingDirectLaborAllocation(true);
        setDistributionPercent(updatedValue);
        setAllocatedHours(
          c.calculateAllocatedHours(
            totalLaborHours,
            c.safeDivide(floatValueRef.current, 100)
          )
        );
        handleDistributionChange(updatedLabor);
        handleFieldUpdate(field, c.safeDivide(floatValueRef.current, 100));
        break;
      case "laborrate":
        setLaborRate(updatedValue);
        handleFieldUpdate(field, parseFloat(updatedValue));
        break;
      case "burdenpercent":
        setBurdenPercent(updatedValue);
        handleFieldUpdate(field, c.safeDivide(parseFloat(updatedValue), 100));
        break;
      case "fringe":
        setFringe(updatedValue);
        handleFieldUpdate(field, parseFloat(updatedValue));
        break;
      default:
        break;
    }
  };

  const setFieldValue = (field: string, value: string) => {
    switch (field) {
      case "laborHours":
        setAllocatedHours(parseFloat(value));
        break;
      case "distributionpercent":
        setDistributionPercent(value);
        break;
      case "laborrate":
        setLaborRate(value);
        break;
      case "burdenpercent":
        setBurdenPercent(value);
        break;
      case "fringe":
        setFringe(value);
        break;
      default:
        break;
    }
  };

  const validLaborRate = isNaN(parseFloat(laborrate))
    ? 0
    : parseFloat(laborrate);
  const validBurdenPercent = c.safeDivide(parseFloat(burdenpercent), 100);
  const validFringe = isNaN(parseFloat(fringe)) ? 0 : parseFloat(fringe);

  const subtotal = c.calculateLaborSubtotal({
    ...labor,
    allocatedhours: allocatedhours,
    laborrate: validLaborRate,
  });
  const burdenTotal = c.calculateBurdenTotal({
    ...labor,
    allocatedhours: allocatedhours,
    laborrate: validLaborRate,
    burdenpercent: validBurdenPercent,
  });
  const fringeTotal = c.calculateFringeTotal({
    ...labor,
    allocatedhours: allocatedhours,
    fringe: validFringe,
  });
  const fullHoursCost = c.calculateFullHoursCost({
    ...labor,
    allocatedhours: allocatedhours,
    laborrate: validLaborRate,
    burdenpercent: validBurdenPercent,
    fringe: validFringe,
  });
  const fullCost = c.calculateFullLaborCost({
    ...labor,
    allocatedhours: allocatedhours,
    laborrate: validLaborRate,
    burdenpercent: validBurdenPercent,
    fringe: validFringe,
  });

  return (
    <>
      <tr>
        {/* Description */}
        <td>
          <button
            onClick={() => handleDelete(labor.name)}
            style={{ width: "40px" }}
          >
            <DeleteIcon />
          </button>
          {labor.name}
        </td>
        {/* Allocated Hours */}
        <td>{allocatedhours.toFixed(2)}</td>
        {/* Distribution % */}
        <td>
          <input
            type="text"
            value={distributionpercent}
            onChange={(e) =>
              handleFieldChange("distributionpercent", e.target.value)
            }
            onBlur={(e) => handleBlur("distributionpercent", e.target.value)}
            style={sxSwitch(labor.distributionpercent)}
          />
        </td>
        {/* Labor Rate */}
        <td>
          <input
            type="text"
            value={laborrate}
            onChange={(e) => handleFieldChange("laborrate", e.target.value)}
            onBlur={(e) => handleBlur("laborrate", e.target.value)}
            style={sxSwitch(labor.laborrate)}
          />
        </td>
        {/* Subtotal */}
        <td>${subtotal.toFixed(2)}</td>
        {/* Burden Percent */}
        <td>
          <input
            type="text"
            value={burdenpercent}
            onChange={(e) => handleFieldChange("burdenpercent", e.target.value)}
            onBlur={(e) => handleBlur("burdenpercent", e.target.value)}
            style={sxSwitch(labor.burdenpercent)}
          />
        </td>
        {/* Fringe */}
        <td>
          <input
            type="text"
            value={fringe}
            onChange={(e) => handleFieldChange("fringe", e.target.value)}
            onBlur={(e) => handleBlur("fringe", e.target.value)}
            style={sxSwitch(labor.fringe)}
          />
        </td>
        {/* Burden Total */}
        <td>${burdenTotal.toFixed(2)}</td>
        {/* Fringe Total */}
        <td>${fringeTotal.toFixed(2)}</td>
        {/* Full Hourly Cost */}
        <td>${fullHoursCost.toFixed(2)}</td>
        {/* Full Cost */}
        <td>${fullCost.toFixed(2)}</td>
      </tr>
    </>
  );
};
