import { useMutation, useQueryClient } from "react-query";
import { ContractService as cs } from "../api/ContractService";
import { Contract } from "../api/protosCompiled/contract/contract_pb";

export const useCreateContractMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: Contract.AsObject) => cs.createContract(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["contracts"]);
    },
    onError: (error) => {
      console.error("Error creating change order", error);
    },
  });
};

export const useUpdateContractMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: Contract.AsObject) => cs.updateContract(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["contracts"]);
    },
    onError: (error) => {
      console.error("Error updating contract", error);
    },
  });
};
