/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: classification.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as classification_pb from './classification_pb'; // proto import: "classification.proto"


export class ClassificationServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateClassification = new grpcWeb.MethodDescriptor(
    '/ClassificationService/CreateClassification',
    grpcWeb.MethodType.UNARY,
    classification_pb.CreateClassificationRequest,
    classification_pb.CreateClassificationResponse,
    (request: classification_pb.CreateClassificationRequest) => {
      return request.serializeBinary();
    },
    classification_pb.CreateClassificationResponse.deserializeBinary
  );

  createClassification(
    request: classification_pb.CreateClassificationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<classification_pb.CreateClassificationResponse>;

  createClassification(
    request: classification_pb.CreateClassificationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: classification_pb.CreateClassificationResponse) => void): grpcWeb.ClientReadableStream<classification_pb.CreateClassificationResponse>;

  createClassification(
    request: classification_pb.CreateClassificationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: classification_pb.CreateClassificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ClassificationService/CreateClassification',
        request,
        metadata || {},
        this.methodDescriptorCreateClassification,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ClassificationService/CreateClassification',
    request,
    metadata || {},
    this.methodDescriptorCreateClassification);
  }

  methodDescriptorGetAllClassifications = new grpcWeb.MethodDescriptor(
    '/ClassificationService/GetAllClassifications',
    grpcWeb.MethodType.UNARY,
    classification_pb.GetAllClassificationsRequest,
    classification_pb.GetAllClassificationsResponse,
    (request: classification_pb.GetAllClassificationsRequest) => {
      return request.serializeBinary();
    },
    classification_pb.GetAllClassificationsResponse.deserializeBinary
  );

  getAllClassifications(
    request: classification_pb.GetAllClassificationsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<classification_pb.GetAllClassificationsResponse>;

  getAllClassifications(
    request: classification_pb.GetAllClassificationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: classification_pb.GetAllClassificationsResponse) => void): grpcWeb.ClientReadableStream<classification_pb.GetAllClassificationsResponse>;

  getAllClassifications(
    request: classification_pb.GetAllClassificationsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: classification_pb.GetAllClassificationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ClassificationService/GetAllClassifications',
        request,
        metadata || {},
        this.methodDescriptorGetAllClassifications,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ClassificationService/GetAllClassifications',
    request,
    metadata || {},
    this.methodDescriptorGetAllClassifications);
  }

  methodDescriptorUpdateClassification = new grpcWeb.MethodDescriptor(
    '/ClassificationService/UpdateClassification',
    grpcWeb.MethodType.UNARY,
    classification_pb.UpdateClassificationRequest,
    classification_pb.UpdateClassificationResponse,
    (request: classification_pb.UpdateClassificationRequest) => {
      return request.serializeBinary();
    },
    classification_pb.UpdateClassificationResponse.deserializeBinary
  );

  updateClassification(
    request: classification_pb.UpdateClassificationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<classification_pb.UpdateClassificationResponse>;

  updateClassification(
    request: classification_pb.UpdateClassificationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: classification_pb.UpdateClassificationResponse) => void): grpcWeb.ClientReadableStream<classification_pb.UpdateClassificationResponse>;

  updateClassification(
    request: classification_pb.UpdateClassificationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: classification_pb.UpdateClassificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ClassificationService/UpdateClassification',
        request,
        metadata || {},
        this.methodDescriptorUpdateClassification,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ClassificationService/UpdateClassification',
    request,
    metadata || {},
    this.methodDescriptorUpdateClassification);
  }

  methodDescriptorDeleteClassification = new grpcWeb.MethodDescriptor(
    '/ClassificationService/DeleteClassification',
    grpcWeb.MethodType.UNARY,
    classification_pb.DeleteClassificationRequest,
    classification_pb.DeleteClassificationResponse,
    (request: classification_pb.DeleteClassificationRequest) => {
      return request.serializeBinary();
    },
    classification_pb.DeleteClassificationResponse.deserializeBinary
  );

  deleteClassification(
    request: classification_pb.DeleteClassificationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<classification_pb.DeleteClassificationResponse>;

  deleteClassification(
    request: classification_pb.DeleteClassificationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: classification_pb.DeleteClassificationResponse) => void): grpcWeb.ClientReadableStream<classification_pb.DeleteClassificationResponse>;

  deleteClassification(
    request: classification_pb.DeleteClassificationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: classification_pb.DeleteClassificationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ClassificationService/DeleteClassification',
        request,
        metadata || {},
        this.methodDescriptorDeleteClassification,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ClassificationService/DeleteClassification',
    request,
    metadata || {},
    this.methodDescriptorDeleteClassification);
  }

  methodDescriptorGetClassificationMap = new grpcWeb.MethodDescriptor(
    '/ClassificationService/GetClassificationMap',
    grpcWeb.MethodType.UNARY,
    classification_pb.GetClassificationMapRequest,
    classification_pb.GetClassificationMapResponse,
    (request: classification_pb.GetClassificationMapRequest) => {
      return request.serializeBinary();
    },
    classification_pb.GetClassificationMapResponse.deserializeBinary
  );

  getClassificationMap(
    request: classification_pb.GetClassificationMapRequest,
    metadata?: grpcWeb.Metadata | null): Promise<classification_pb.GetClassificationMapResponse>;

  getClassificationMap(
    request: classification_pb.GetClassificationMapRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: classification_pb.GetClassificationMapResponse) => void): grpcWeb.ClientReadableStream<classification_pb.GetClassificationMapResponse>;

  getClassificationMap(
    request: classification_pb.GetClassificationMapRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: classification_pb.GetClassificationMapResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ClassificationService/GetClassificationMap',
        request,
        metadata || {},
        this.methodDescriptorGetClassificationMap,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ClassificationService/GetClassificationMap',
    request,
    metadata || {},
    this.methodDescriptorGetClassificationMap);
  }

}

