import { useQuery } from "react-query";
import { CloseoutService as cs } from "../api/CloseoutService";

export function useFetchEstimateCloseout(estimateId: string) {
  return useQuery(
    ["estimateCloseout", estimateId],
    async () => {
      if (estimateId) {
        const response = await cs.getEstimateCloseout(estimateId);
        return response.estimatecloseout;
      }
      throw new Error("No valid ID available for fetching estimateCloseout");
    },

    {
      enabled: !!estimateId,
      staleTime: 0, // 0 seconds
    }
  );
}

export function useFetchChangeOrderCloseout(changeOrderId: string) {
  return useQuery(
    ["changeOrderCloseout", changeOrderId],
    async () => {
      if (changeOrderId) {
        const response = await cs.getChangeOrderCloseout(changeOrderId);
        return response.changeordercloseout;
      }
      throw new Error("No valid ID available for fetching changeOrderId");
    },

    {
      enabled: !!changeOrderId,
      staleTime: 0, // 0 seconds
    }
  );
}
