import { useState } from "react";
import { UoM } from "../../../api/protosCompiled/globalItem/globalItem_pb";
import { ItemService } from "../../../api/ItemService";
import { useFetchUoMs } from "../../../hooks/useFetchUoMs";

export const CreateUoM = () => {
  const [newUoMName, setNewUoMName] = useState<string>("");
  const [newUoMValue, setNewUoMValue] = useState<number | string>("");

  const { data: uoms, isLoading, refetch } = useFetchUoMs();

  const handleCreateOrUpdateUoM = async (uom?: UoM.AsObject) => {
    if (!newUoMName || !newUoMValue) return;
    const uomData: UoM.AsObject = uom
      ? { ...uom, name: uom.name, value: uom.value }
      : {
          id: 0,
          name: newUoMName,
          value: Number(newUoMValue),
          lastupdated: "",
        };

    try {
      await ItemService.createOrUpdateUoM(uomData);
      refetch(); // Refresh the UOM list
      setNewUoMName("");
      setNewUoMValue("");
    } catch (error) {
      console.error("Error creating or updating UoM:", error);
    }
  };

  const handleEditUoM = async (uom: UoM.AsObject) => {
    setNewUoMName(uom.name);
    setNewUoMValue(uom.value);
  };

  const handleDeleteUoM = async (uomId: number) => {
    try {
      await ItemService.deleteUoM(uomId);
      refetch(); // Refresh the UOM list
    } catch (error) {
      console.error("Error deleting UoM:", error);
    }
  };

  return (
    <div>
      <h4>Manage Units of Measure (UOM)</h4>

      <div
        style={{
          marginBottom: "1rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        UoM Name:
        <input
          type="text"
          placeholder="UoM Name"
          value={newUoMName}
          onChange={(e) => setNewUoMName(e.target.value)}
        />
        Uom Value:
        <input
          type="number"
          placeholder="UoM Value"
          value={newUoMValue}
          onChange={(e) => setNewUoMValue(e.target.value)}
        />
        <button onClick={() => handleCreateOrUpdateUoM()}>Add UoM</button>
      </div>

      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div
          className="scrollableCDiv"
          style={{
            height: "500px",
            border: "1px solid black",
            margin: "5px",
          }}
        >
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Value</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {uoms?.map((uom) => (
                <tr key={uom.id}>
                  <td>{uom.name}</td>
                  <td>{uom.value}</td>
                  <td>
                    <button onClick={() => handleEditUoM(uom)}>Edit</button>
                    <button onClick={() => handleDeleteUoM(uom.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
