// source: changeOrderAssembly.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.changeOrderAssembly.ChangeOrderAssembly', null, global);
goog.exportSymbol('proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute', null, global);
goog.exportSymbol('proto.changeOrderAssembly.ChangeOrderItem', null, global);
goog.exportSymbol('proto.changeOrderAssembly.ChangeOrderItemAttribute', null, global);
goog.exportSymbol('proto.changeOrderAssembly.ChangeOrderItemCost', null, global);
goog.exportSymbol('proto.changeOrderAssembly.ChangeOrderItemQtyFormula', null, global);
goog.exportSymbol('proto.changeOrderAssembly.ChangeOrderPoint', null, global);
goog.exportSymbol('proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest', null, global);
goog.exportSymbol('proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse', null, global);
goog.exportSymbol('proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest', null, global);
goog.exportSymbol('proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse', null, global);
goog.exportSymbol('proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest', null, global);
goog.exportSymbol('proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse', null, global);
goog.exportSymbol('proto.changeOrderAssembly.GetTakeoffStatsRequest', null, global);
goog.exportSymbol('proto.changeOrderAssembly.GetTakeoffStatsResponse', null, global);
goog.exportSymbol('proto.changeOrderAssembly.GlobalAssembly', null, global);
goog.exportSymbol('proto.changeOrderAssembly.TakeoffStats', null, global);
goog.exportSymbol('proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest', null, global);
goog.exportSymbol('proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.ChangeOrderPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.ChangeOrderPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.ChangeOrderPoint.displayName = 'proto.changeOrderAssembly.ChangeOrderPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.changeOrderAssembly.ChangeOrderItemQtyFormula.repeatedFields_, null);
};
goog.inherits(proto.changeOrderAssembly.ChangeOrderItemQtyFormula, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.ChangeOrderItemQtyFormula.displayName = 'proto.changeOrderAssembly.ChangeOrderItemQtyFormula';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.displayName = 'proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.ChangeOrderItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.changeOrderAssembly.ChangeOrderItem.repeatedFields_, null);
};
goog.inherits(proto.changeOrderAssembly.ChangeOrderItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.ChangeOrderItem.displayName = 'proto.changeOrderAssembly.ChangeOrderItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.ChangeOrderItemAttribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.ChangeOrderItemAttribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.ChangeOrderItemAttribute.displayName = 'proto.changeOrderAssembly.ChangeOrderItemAttribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.ChangeOrderItemCost = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.ChangeOrderItemCost, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.ChangeOrderItemCost.displayName = 'proto.changeOrderAssembly.ChangeOrderItemCost';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.ChangeOrderAssembly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.changeOrderAssembly.ChangeOrderAssembly.repeatedFields_, null);
};
goog.inherits(proto.changeOrderAssembly.ChangeOrderAssembly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.ChangeOrderAssembly.displayName = 'proto.changeOrderAssembly.ChangeOrderAssembly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.GlobalAssembly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.GlobalAssembly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.GlobalAssembly.displayName = 'proto.changeOrderAssembly.GlobalAssembly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.displayName = 'proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.displayName = 'proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.displayName = 'proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.displayName = 'proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.displayName = 'proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.displayName = 'proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.GetTakeoffStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.GetTakeoffStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.GetTakeoffStatsRequest.displayName = 'proto.changeOrderAssembly.GetTakeoffStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.TakeoffStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.changeOrderAssembly.TakeoffStats.repeatedFields_, null);
};
goog.inherits(proto.changeOrderAssembly.TakeoffStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.TakeoffStats.displayName = 'proto.changeOrderAssembly.TakeoffStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.GetTakeoffStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.changeOrderAssembly.GetTakeoffStatsResponse.repeatedFields_, null);
};
goog.inherits(proto.changeOrderAssembly.GetTakeoffStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.GetTakeoffStatsResponse.displayName = 'proto.changeOrderAssembly.GetTakeoffStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.displayName = 'proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.repeatedFields_, null);
};
goog.inherits(proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.displayName = 'proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.ChangeOrderPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.ChangeOrderPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    pointid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    angle: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    verticallength: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    allowtrailingline: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    allowtrailingmeasurement: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isincludedincount: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isselected: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint}
 */
proto.changeOrderAssembly.ChangeOrderPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.ChangeOrderPoint;
  return proto.changeOrderAssembly.ChangeOrderPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.ChangeOrderPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint}
 */
proto.changeOrderAssembly.ChangeOrderPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setX(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setY(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAngle(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVerticallength(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowtrailingline(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowtrailingmeasurement(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsincludedincount(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsselected(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.ChangeOrderPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.ChangeOrderPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getX();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getVerticallength();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAllowtrailingline();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getAllowtrailingmeasurement();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsincludedincount();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsselected();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional string pointId = 1;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.getPointid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint} returns this
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.setPointid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float x = 2;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint} returns this
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float y = 3;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint} returns this
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float angle = 4;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.getAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint} returns this
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.setAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float verticalLength = 5;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.getVerticallength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint} returns this
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.setVerticallength = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool allowTrailingLine = 6;
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.getAllowtrailingline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint} returns this
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.setAllowtrailingline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool allowTrailingMeasurement = 7;
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.getAllowtrailingmeasurement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint} returns this
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.setAllowtrailingmeasurement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool isIncludedInCount = 8;
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.getIsincludedincount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint} returns this
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.setIsincludedincount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool isSelected = 9;
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.getIsselected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint} returns this
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.setIsselected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool isActive = 10;
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint} returns this
 */
proto.changeOrderAssembly.ChangeOrderPoint.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.ChangeOrderItemQtyFormula.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    forevery: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    takeoffvariabletype: jspb.Message.getFieldWithDefault(msg, 5, ""),
    and: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    or: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    attributesList: jspb.Message.toObjectList(msg.getAttributesList(),
    proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.toObject, includeInstance),
    isquoted: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    assemblyname: jspb.Message.getFieldWithDefault(msg, 10, ""),
    quotegroup: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.ChangeOrderItemQtyFormula;
  return proto.changeOrderAssembly.ChangeOrderItemQtyFormula.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemname(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemqty(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setForevery(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTakeoffvariabletype(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnd(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOr(value);
      break;
    case 8:
      var value = new proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.deserializeBinaryFromReader);
      msg.addAttributes(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsquoted(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyname(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuotegroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.ChangeOrderItemQtyFormula.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemqty();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getForevery();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTakeoffvariabletype();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAnd();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getOr();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.serializeBinaryToWriter
    );
  }
  f = message.getIsquoted();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getAssemblyname();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getQuotegroup();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string itemId = 1;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getItemid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setItemid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string itemName = 2;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getItemname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setItemname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float itemQty = 3;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getItemqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setItemqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float forEvery = 4;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getForevery = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setForevery = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string takeoffVariableType = 5;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getTakeoffvariabletype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setTakeoffvariabletype = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool and = 6;
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getAnd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setAnd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool or = 7;
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getOr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setOr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated ChangeOrderAssemblyItemAttribute attributes = 8;
 * @return {!Array<!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute>}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getAttributesList = function() {
  return /** @type{!Array<!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute, 8));
};


/**
 * @param {!Array<!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute>} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
*/
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setAttributesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.addAttributes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.clearAttributesList = function() {
  return this.setAttributesList([]);
};


/**
 * optional bool isQuoted = 9;
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getIsquoted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setIsquoted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string assemblyName = 10;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getAssemblyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setAssemblyname = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string quoteGroup = 11;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.getQuotegroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemQtyFormula.prototype.setQuotegroup = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    attributegroupid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    attributevalueid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    attributevalue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    attributegroupname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute;
  return proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributegroupid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributevalueid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributevalue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributegroupname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAttributegroupid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAttributevalueid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAttributevalue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAttributegroupname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 attributeGroupId = 1;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.getAttributegroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.setAttributegroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 attributeValueId = 2;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.getAttributevalueid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.setAttributevalueid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string attributeValue = 3;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.getAttributevalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.setAttributevalue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string attributeGroupName = 4;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.getAttributegroupname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.setAttributegroupname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string lastUpdated = 5;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssemblyItemAttribute.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.changeOrderAssembly.ChangeOrderItem.repeatedFields_ = [4,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.ChangeOrderItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.ChangeOrderItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    costsList: jspb.Message.toObjectList(msg.getCostsList(),
    proto.changeOrderAssembly.ChangeOrderItemCost.toObject, includeInstance),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 5, ""),
    parentid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    level: jspb.Message.getFieldWithDefault(msg, 7, 0),
    entitytype: jspb.Message.getFieldWithDefault(msg, 8, ""),
    itemqtyformulasList: jspb.Message.toObjectList(msg.getItemqtyformulasList(),
    proto.changeOrderAssembly.ChangeOrderItemQtyFormula.toObject, includeInstance),
    imageclassificationid: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.ChangeOrderItem}
 */
proto.changeOrderAssembly.ChangeOrderItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.ChangeOrderItem;
  return proto.changeOrderAssembly.ChangeOrderItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.ChangeOrderItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.ChangeOrderItem}
 */
proto.changeOrderAssembly.ChangeOrderItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemname(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    case 4:
      var value = new proto.changeOrderAssembly.ChangeOrderItemCost;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderItemCost.deserializeBinaryFromReader);
      msg.addCosts(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLevel(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntitytype(value);
      break;
    case 9:
      var value = new proto.changeOrderAssembly.ChangeOrderItemQtyFormula;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderItemQtyFormula.deserializeBinaryFromReader);
      msg.addItemqtyformulas(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setImageclassificationid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.ChangeOrderItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.ChangeOrderItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCostsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.changeOrderAssembly.ChangeOrderItemCost.serializeBinaryToWriter
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getParentid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getEntitytype();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getItemqtyformulasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.changeOrderAssembly.ChangeOrderItemQtyFormula.serializeBinaryToWriter
    );
  }
  f = message.getImageclassificationid();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional string itemId = 1;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.getItemid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItem} returns this
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.setItemid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string itemName = 2;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.getItemname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItem} returns this
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.setItemname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isActive = 3;
 * @return {boolean}
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItem} returns this
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated ChangeOrderItemCost costs = 4;
 * @return {!Array<!proto.changeOrderAssembly.ChangeOrderItemCost>}
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.getCostsList = function() {
  return /** @type{!Array<!proto.changeOrderAssembly.ChangeOrderItemCost>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.changeOrderAssembly.ChangeOrderItemCost, 4));
};


/**
 * @param {!Array<!proto.changeOrderAssembly.ChangeOrderItemCost>} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItem} returns this
*/
proto.changeOrderAssembly.ChangeOrderItem.prototype.setCostsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.changeOrderAssembly.ChangeOrderItemCost=} opt_value
 * @param {number=} opt_index
 * @return {!proto.changeOrderAssembly.ChangeOrderItemCost}
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.addCosts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.changeOrderAssembly.ChangeOrderItemCost, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.changeOrderAssembly.ChangeOrderItem} returns this
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.clearCostsList = function() {
  return this.setCostsList([]);
};


/**
 * optional string lastUpdated = 5;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItem} returns this
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string parentId = 6;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.getParentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItem} returns this
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.setParentid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 level = 7;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.getLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItem} returns this
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.setLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string entityType = 8;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.getEntitytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItem} returns this
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.setEntitytype = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated ChangeOrderItemQtyFormula itemQtyFormulas = 9;
 * @return {!Array<!proto.changeOrderAssembly.ChangeOrderItemQtyFormula>}
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.getItemqtyformulasList = function() {
  return /** @type{!Array<!proto.changeOrderAssembly.ChangeOrderItemQtyFormula>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.changeOrderAssembly.ChangeOrderItemQtyFormula, 9));
};


/**
 * @param {!Array<!proto.changeOrderAssembly.ChangeOrderItemQtyFormula>} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItem} returns this
*/
proto.changeOrderAssembly.ChangeOrderItem.prototype.setItemqtyformulasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula=} opt_value
 * @param {number=} opt_index
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula}
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.addItemqtyformulas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.changeOrderAssembly.ChangeOrderItemQtyFormula, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.changeOrderAssembly.ChangeOrderItem} returns this
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.clearItemqtyformulasList = function() {
  return this.setItemqtyformulasList([]);
};


/**
 * optional int32 imageClassificationId = 10;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.getImageclassificationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItem} returns this
 */
proto.changeOrderAssembly.ChangeOrderItem.prototype.setImageclassificationid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.ChangeOrderItemAttribute.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.ChangeOrderItemAttribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.ChangeOrderItemAttribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderItemAttribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    attributegroupid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    attributevalueid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    attributevalue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    attributegroupname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.ChangeOrderItemAttribute}
 */
proto.changeOrderAssembly.ChangeOrderItemAttribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.ChangeOrderItemAttribute;
  return proto.changeOrderAssembly.ChangeOrderItemAttribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.ChangeOrderItemAttribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.ChangeOrderItemAttribute}
 */
proto.changeOrderAssembly.ChangeOrderItemAttribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributegroupid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributevalueid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributevalue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributegroupname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.ChangeOrderItemAttribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.ChangeOrderItemAttribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.ChangeOrderItemAttribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderItemAttribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAttributegroupid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAttributevalueid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAttributevalue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAttributegroupname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 attributeGroupId = 1;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderItemAttribute.prototype.getAttributegroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemAttribute} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemAttribute.prototype.setAttributegroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 attributeValueId = 2;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderItemAttribute.prototype.getAttributevalueid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemAttribute} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemAttribute.prototype.setAttributevalueid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string attributeValue = 3;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItemAttribute.prototype.getAttributevalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemAttribute} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemAttribute.prototype.setAttributevalue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string attributeGroupName = 4;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItemAttribute.prototype.getAttributegroupname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemAttribute} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemAttribute.prototype.setAttributegroupname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string lastUpdated = 5;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItemAttribute.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemAttribute} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemAttribute.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.ChangeOrderItemCost.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.ChangeOrderItemCost} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderItemCost.toObject = function(includeInstance, msg) {
  var f, obj = {
    uomid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemunitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    hourlyproductionrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 4, ""),
    uomname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    uomvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.ChangeOrderItemCost}
 */
proto.changeOrderAssembly.ChangeOrderItemCost.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.ChangeOrderItemCost;
  return proto.changeOrderAssembly.ChangeOrderItemCost.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.ChangeOrderItemCost} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.ChangeOrderItemCost}
 */
proto.changeOrderAssembly.ChangeOrderItemCost.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUomid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemunitcost(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHourlyproductionrate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUomname(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUomvalue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.ChangeOrderItemCost.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.ChangeOrderItemCost} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderItemCost.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUomid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getItemunitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getHourlyproductionrate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUomname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUomvalue();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional int32 uomId = 1;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.getUomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemCost} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.setUomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float itemUnitCost = 2;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.getItemunitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemCost} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.setItemunitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float hourlyProductionRate = 3;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.getHourlyproductionrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemCost} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.setHourlyproductionrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string lastUpdated = 4;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemCost} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string uomName = 5;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.getUomname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemCost} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.setUomname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float uomValue = 6;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.getUomvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderItemCost} returns this
 */
proto.changeOrderAssembly.ChangeOrderItemCost.prototype.setUomvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.changeOrderAssembly.ChangeOrderAssembly.repeatedFields_ = [7,20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.ChangeOrderAssembly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.ChangeOrderAssembly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderAssembly.toObject = function(includeInstance, msg) {
  var f, obj = {
    takeoffid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contractid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    changeorderid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    objectid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    segmentlength: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    segmentcount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.changeOrderAssembly.ChangeOrderPoint.toObject, includeInstance),
    pointcount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    pointtype: jspb.Message.getFieldWithDefault(msg, 9, ""),
    pointsize: jspb.Message.getFieldWithDefault(msg, 10, 0),
    pointbordercolor: jspb.Message.getFieldWithDefault(msg, 11, ""),
    pointfillcolor: jspb.Message.getFieldWithDefault(msg, 12, ""),
    linesize: jspb.Message.getFieldWithDefault(msg, 13, 0),
    linecolor: jspb.Message.getFieldWithDefault(msg, 14, ""),
    linedasharray: jspb.Message.getFieldWithDefault(msg, 15, ""),
    userscale: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 17, 0),
    assemblyname: jspb.Message.getFieldWithDefault(msg, 18, ""),
    assemblymeasurementtype: jspb.Message.getFieldWithDefault(msg, 19, ""),
    itemqtyformulasList: jspb.Message.toObjectList(msg.getItemqtyformulasList(),
    proto.changeOrderAssembly.ChangeOrderItemQtyFormula.toObject, includeInstance),
    imageclassificationid: jspb.Message.getFieldWithDefault(msg, 21, 0),
    datecreated: jspb.Message.getFieldWithDefault(msg, 22, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 23, ""),
    sqft: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.ChangeOrderAssembly;
  return proto.changeOrderAssembly.ChangeOrderAssembly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.ChangeOrderAssembly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTakeoffid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangeorderid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSegmentlength(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSegmentcount(value);
      break;
    case 7:
      var value = new proto.changeOrderAssembly.ChangeOrderPoint;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderPoint.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPointcount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointtype(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPointsize(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointbordercolor(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointfillcolor(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLinesize(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinecolor(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinedasharray(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUserscale(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenumber(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyname(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblymeasurementtype(value);
      break;
    case 20:
      var value = new proto.changeOrderAssembly.ChangeOrderItemQtyFormula;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderItemQtyFormula.deserializeBinaryFromReader);
      msg.addItemqtyformulas(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setImageclassificationid(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSqft(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.ChangeOrderAssembly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.ChangeOrderAssembly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.ChangeOrderAssembly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTakeoffid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContractid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChangeorderid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getObjectid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSegmentlength();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getSegmentcount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.changeOrderAssembly.ChangeOrderPoint.serializeBinaryToWriter
    );
  }
  f = message.getPointcount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getPointtype();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPointsize();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getPointbordercolor();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPointfillcolor();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLinesize();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getLinecolor();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getLinedasharray();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUserscale();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getAssemblyname();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getAssemblymeasurementtype();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getItemqtyformulasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.changeOrderAssembly.ChangeOrderItemQtyFormula.serializeBinaryToWriter
    );
  }
  f = message.getImageclassificationid();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getSqft();
  if (f !== 0.0) {
    writer.writeFloat(
      24,
      f
    );
  }
};


/**
 * optional string takeoffId = 1;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getTakeoffid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setTakeoffid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string contractId = 2;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getContractid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setContractid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string changeOrderId = 3;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getChangeorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setChangeorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string objectId = 4;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getObjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setObjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional float segmentLength = 5;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getSegmentlength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setSegmentlength = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 segmentCount = 6;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getSegmentcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setSegmentcount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated ChangeOrderPoint points = 7;
 * @return {!Array<!proto.changeOrderAssembly.ChangeOrderPoint>}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.changeOrderAssembly.ChangeOrderPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.changeOrderAssembly.ChangeOrderPoint, 7));
};


/**
 * @param {!Array<!proto.changeOrderAssembly.ChangeOrderPoint>} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
*/
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.changeOrderAssembly.ChangeOrderPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.changeOrderAssembly.ChangeOrderPoint}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.changeOrderAssembly.ChangeOrderPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};


/**
 * optional int32 pointCount = 8;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getPointcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setPointcount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string pointType = 9;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getPointtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setPointtype = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 pointSize = 10;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getPointsize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setPointsize = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string pointBorderColor = 11;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getPointbordercolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setPointbordercolor = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string pointFillColor = 12;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getPointfillcolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setPointfillcolor = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int32 lineSize = 13;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getLinesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setLinesize = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string lineColor = 14;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getLinecolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setLinecolor = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string lineDashArray = 15;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getLinedasharray = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setLinedasharray = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional double userScale = 16;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getUserscale = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setUserscale = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional int32 pageNumber = 17;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string assemblyName = 18;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getAssemblyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setAssemblyname = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string assemblyMeasurementType = 19;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getAssemblymeasurementtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setAssemblymeasurementtype = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * repeated ChangeOrderItemQtyFormula itemQtyFormulas = 20;
 * @return {!Array<!proto.changeOrderAssembly.ChangeOrderItemQtyFormula>}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getItemqtyformulasList = function() {
  return /** @type{!Array<!proto.changeOrderAssembly.ChangeOrderItemQtyFormula>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.changeOrderAssembly.ChangeOrderItemQtyFormula, 20));
};


/**
 * @param {!Array<!proto.changeOrderAssembly.ChangeOrderItemQtyFormula>} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
*/
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setItemqtyformulasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula=} opt_value
 * @param {number=} opt_index
 * @return {!proto.changeOrderAssembly.ChangeOrderItemQtyFormula}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.addItemqtyformulas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.changeOrderAssembly.ChangeOrderItemQtyFormula, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.clearItemqtyformulasList = function() {
  return this.setItemqtyformulasList([]);
};


/**
 * optional int32 imageClassificationId = 21;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getImageclassificationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setImageclassificationid = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional string dateCreated = 22;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string lastUpdated = 23;
 * @return {string}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional float sqft = 24;
 * @return {number}
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.getSqft = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly} returns this
 */
proto.changeOrderAssembly.ChangeOrderAssembly.prototype.setSqft = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.GlobalAssembly.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.GlobalAssembly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.GlobalAssembly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.GlobalAssembly.toObject = function(includeInstance, msg) {
  var f, obj = {
    assemblyid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assemblyname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assemblymeasurementtype: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.GlobalAssembly}
 */
proto.changeOrderAssembly.GlobalAssembly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.GlobalAssembly;
  return proto.changeOrderAssembly.GlobalAssembly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.GlobalAssembly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.GlobalAssembly}
 */
proto.changeOrderAssembly.GlobalAssembly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblymeasurementtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.GlobalAssembly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.GlobalAssembly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.GlobalAssembly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.GlobalAssembly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssemblyid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssemblyname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssemblymeasurementtype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string assemblyId = 1;
 * @return {string}
 */
proto.changeOrderAssembly.GlobalAssembly.prototype.getAssemblyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.GlobalAssembly} returns this
 */
proto.changeOrderAssembly.GlobalAssembly.prototype.setAssemblyid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assemblyName = 2;
 * @return {string}
 */
proto.changeOrderAssembly.GlobalAssembly.prototype.getAssemblyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.GlobalAssembly} returns this
 */
proto.changeOrderAssembly.GlobalAssembly.prototype.setAssemblyname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string assemblyMeasurementType = 3;
 * @return {string}
 */
proto.changeOrderAssembly.GlobalAssembly.prototype.getAssemblymeasurementtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.GlobalAssembly} returns this
 */
proto.changeOrderAssembly.GlobalAssembly.prototype.setAssemblymeasurementtype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    changeorderassembly: (f = msg.getChangeorderassembly()) && proto.changeOrderAssembly.ChangeOrderAssembly.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest;
  return proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.changeOrderAssembly.ChangeOrderAssembly;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderAssembly.deserializeBinaryFromReader);
      msg.setChangeorderassembly(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangeorderassembly();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.changeOrderAssembly.ChangeOrderAssembly.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ChangeOrderAssembly changeOrderAssembly = 1;
 * @return {?proto.changeOrderAssembly.ChangeOrderAssembly}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.prototype.getChangeorderassembly = function() {
  return /** @type{?proto.changeOrderAssembly.ChangeOrderAssembly} */ (
    jspb.Message.getWrapperField(this, proto.changeOrderAssembly.ChangeOrderAssembly, 1));
};


/**
 * @param {?proto.changeOrderAssembly.ChangeOrderAssembly|undefined} value
 * @return {!proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest} returns this
*/
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.prototype.setChangeorderassembly = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest} returns this
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.prototype.clearChangeorderassembly = function() {
  return this.setChangeorderassembly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.prototype.hasChangeorderassembly = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest} returns this
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse;
  return proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse} returns this
 */
proto.changeOrderAssembly.CreateChangeOrderAssemblyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    changeorderassembly: (f = msg.getChangeorderassembly()) && proto.changeOrderAssembly.ChangeOrderAssembly.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest;
  return proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.changeOrderAssembly.ChangeOrderAssembly;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderAssembly.deserializeBinaryFromReader);
      msg.setChangeorderassembly(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangeorderassembly();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.changeOrderAssembly.ChangeOrderAssembly.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ChangeOrderAssembly changeOrderAssembly = 1;
 * @return {?proto.changeOrderAssembly.ChangeOrderAssembly}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.prototype.getChangeorderassembly = function() {
  return /** @type{?proto.changeOrderAssembly.ChangeOrderAssembly} */ (
    jspb.Message.getWrapperField(this, proto.changeOrderAssembly.ChangeOrderAssembly, 1));
};


/**
 * @param {?proto.changeOrderAssembly.ChangeOrderAssembly|undefined} value
 * @return {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest} returns this
*/
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.prototype.setChangeorderassembly = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest} returns this
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.prototype.clearChangeorderassembly = function() {
  return this.setChangeorderassembly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.prototype.hasChangeorderassembly = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest} returns this
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse;
  return proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse} returns this
 */
proto.changeOrderAssembly.UpdateChangeOrderAssemblyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    changeorderid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest;
  return proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangeorderid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangeorderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string changeOrderId = 1;
 * @return {string}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.prototype.getChangeorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest} returns this
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.prototype.setChangeorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest} returns this
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse;
  return proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse} returns this
 */
proto.changeOrderAssembly.DeleteChangeOrderAssemblyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.GetTakeoffStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.GetTakeoffStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.GetTakeoffStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.GetTakeoffStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    changeorderid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.GetTakeoffStatsRequest}
 */
proto.changeOrderAssembly.GetTakeoffStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.GetTakeoffStatsRequest;
  return proto.changeOrderAssembly.GetTakeoffStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.GetTakeoffStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.GetTakeoffStatsRequest}
 */
proto.changeOrderAssembly.GetTakeoffStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangeorderid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.GetTakeoffStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.GetTakeoffStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.GetTakeoffStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.GetTakeoffStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangeorderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string changeOrderId = 1;
 * @return {string}
 */
proto.changeOrderAssembly.GetTakeoffStatsRequest.prototype.getChangeorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.GetTakeoffStatsRequest} returns this
 */
proto.changeOrderAssembly.GetTakeoffStatsRequest.prototype.setChangeorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.changeOrderAssembly.GetTakeoffStatsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.GetTakeoffStatsRequest} returns this
 */
proto.changeOrderAssembly.GetTakeoffStatsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.changeOrderAssembly.TakeoffStats.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.TakeoffStats.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.TakeoffStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.TakeoffStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.TakeoffStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    takeoffid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    takeoffname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalsegmentlength: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    totalpointcount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalsegmentcount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalsqft: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    globalitemsList: jspb.Message.toObjectList(msg.getGlobalitemsList(),
    proto.changeOrderAssembly.ChangeOrderItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.TakeoffStats}
 */
proto.changeOrderAssembly.TakeoffStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.TakeoffStats;
  return proto.changeOrderAssembly.TakeoffStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.TakeoffStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.TakeoffStats}
 */
proto.changeOrderAssembly.TakeoffStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTakeoffid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTakeoffname(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalsegmentlength(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalpointcount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalsegmentcount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalsqft(value);
      break;
    case 7:
      var value = new proto.changeOrderAssembly.ChangeOrderItem;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderItem.deserializeBinaryFromReader);
      msg.addGlobalitems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.TakeoffStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.TakeoffStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.TakeoffStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.TakeoffStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTakeoffid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTakeoffname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalsegmentlength();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTotalpointcount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTotalsegmentcount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTotalsqft();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getGlobalitemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.changeOrderAssembly.ChangeOrderItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string takeoffId = 1;
 * @return {string}
 */
proto.changeOrderAssembly.TakeoffStats.prototype.getTakeoffid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.TakeoffStats} returns this
 */
proto.changeOrderAssembly.TakeoffStats.prototype.setTakeoffid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string takeoffName = 2;
 * @return {string}
 */
proto.changeOrderAssembly.TakeoffStats.prototype.getTakeoffname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.TakeoffStats} returns this
 */
proto.changeOrderAssembly.TakeoffStats.prototype.setTakeoffname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double totalSegmentLength = 3;
 * @return {number}
 */
proto.changeOrderAssembly.TakeoffStats.prototype.getTotalsegmentlength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.TakeoffStats} returns this
 */
proto.changeOrderAssembly.TakeoffStats.prototype.setTotalsegmentlength = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 totalPointCount = 4;
 * @return {number}
 */
proto.changeOrderAssembly.TakeoffStats.prototype.getTotalpointcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.TakeoffStats} returns this
 */
proto.changeOrderAssembly.TakeoffStats.prototype.setTotalpointcount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 totalSegmentCount = 5;
 * @return {number}
 */
proto.changeOrderAssembly.TakeoffStats.prototype.getTotalsegmentcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.TakeoffStats} returns this
 */
proto.changeOrderAssembly.TakeoffStats.prototype.setTotalsegmentcount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional float totalSqft = 6;
 * @return {number}
 */
proto.changeOrderAssembly.TakeoffStats.prototype.getTotalsqft = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.changeOrderAssembly.TakeoffStats} returns this
 */
proto.changeOrderAssembly.TakeoffStats.prototype.setTotalsqft = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * repeated ChangeOrderItem globalItems = 7;
 * @return {!Array<!proto.changeOrderAssembly.ChangeOrderItem>}
 */
proto.changeOrderAssembly.TakeoffStats.prototype.getGlobalitemsList = function() {
  return /** @type{!Array<!proto.changeOrderAssembly.ChangeOrderItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.changeOrderAssembly.ChangeOrderItem, 7));
};


/**
 * @param {!Array<!proto.changeOrderAssembly.ChangeOrderItem>} value
 * @return {!proto.changeOrderAssembly.TakeoffStats} returns this
*/
proto.changeOrderAssembly.TakeoffStats.prototype.setGlobalitemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.changeOrderAssembly.ChangeOrderItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.changeOrderAssembly.ChangeOrderItem}
 */
proto.changeOrderAssembly.TakeoffStats.prototype.addGlobalitems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.changeOrderAssembly.ChangeOrderItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.changeOrderAssembly.TakeoffStats} returns this
 */
proto.changeOrderAssembly.TakeoffStats.prototype.clearGlobalitemsList = function() {
  return this.setGlobalitemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.changeOrderAssembly.GetTakeoffStatsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.GetTakeoffStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.GetTakeoffStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.GetTakeoffStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.GetTakeoffStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statsList: jspb.Message.toObjectList(msg.getStatsList(),
    proto.changeOrderAssembly.TakeoffStats.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.GetTakeoffStatsResponse}
 */
proto.changeOrderAssembly.GetTakeoffStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.GetTakeoffStatsResponse;
  return proto.changeOrderAssembly.GetTakeoffStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.GetTakeoffStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.GetTakeoffStatsResponse}
 */
proto.changeOrderAssembly.GetTakeoffStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.changeOrderAssembly.TakeoffStats;
      reader.readMessage(value,proto.changeOrderAssembly.TakeoffStats.deserializeBinaryFromReader);
      msg.addStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.GetTakeoffStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.GetTakeoffStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.GetTakeoffStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.GetTakeoffStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.changeOrderAssembly.TakeoffStats.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TakeoffStats stats = 1;
 * @return {!Array<!proto.changeOrderAssembly.TakeoffStats>}
 */
proto.changeOrderAssembly.GetTakeoffStatsResponse.prototype.getStatsList = function() {
  return /** @type{!Array<!proto.changeOrderAssembly.TakeoffStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.changeOrderAssembly.TakeoffStats, 1));
};


/**
 * @param {!Array<!proto.changeOrderAssembly.TakeoffStats>} value
 * @return {!proto.changeOrderAssembly.GetTakeoffStatsResponse} returns this
*/
proto.changeOrderAssembly.GetTakeoffStatsResponse.prototype.setStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.changeOrderAssembly.TakeoffStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.changeOrderAssembly.TakeoffStats}
 */
proto.changeOrderAssembly.GetTakeoffStatsResponse.prototype.addStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.changeOrderAssembly.TakeoffStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.changeOrderAssembly.GetTakeoffStatsResponse} returns this
 */
proto.changeOrderAssembly.GetTakeoffStatsResponse.prototype.clearStatsList = function() {
  return this.setStatsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    changeorderid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest;
  return proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangeorderid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangeorderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string changeOrderId = 1;
 * @return {string}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.prototype.getChangeorderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest} returns this
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.prototype.setChangeorderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest} returns this
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    assembliesList: jspb.Message.toObjectList(msg.getAssembliesList(),
    proto.changeOrderAssembly.ChangeOrderAssembly.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse;
  return proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.changeOrderAssembly.ChangeOrderAssembly;
      reader.readMessage(value,proto.changeOrderAssembly.ChangeOrderAssembly.deserializeBinaryFromReader);
      msg.addAssemblies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssembliesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.changeOrderAssembly.ChangeOrderAssembly.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ChangeOrderAssembly assemblies = 1;
 * @return {!Array<!proto.changeOrderAssembly.ChangeOrderAssembly>}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.prototype.getAssembliesList = function() {
  return /** @type{!Array<!proto.changeOrderAssembly.ChangeOrderAssembly>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.changeOrderAssembly.ChangeOrderAssembly, 1));
};


/**
 * @param {!Array<!proto.changeOrderAssembly.ChangeOrderAssembly>} value
 * @return {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse} returns this
*/
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.prototype.setAssembliesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.changeOrderAssembly.ChangeOrderAssembly=} opt_value
 * @param {number=} opt_index
 * @return {!proto.changeOrderAssembly.ChangeOrderAssembly}
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.prototype.addAssemblies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.changeOrderAssembly.ChangeOrderAssembly, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse} returns this
 */
proto.changeOrderAssembly.GetChangeOrderAssembliesByChangeOrderIdResponse.prototype.clearAssembliesList = function() {
  return this.setAssembliesList([]);
};


goog.object.extend(exports, proto.changeOrderAssembly);
