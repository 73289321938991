import { useMutation, useQueryClient } from "react-query";
import { ProjectEstimateAssemblyService as peas } from "../api/ProjectEstimateAssemblyService";
import { EstimateAssembly } from "../api/protosCompiled/estimateAssembly/estimateAssembly_pb";

export const useUpdateProjectEstimateAssemblyMutation = () => {
  const queryClient = useQueryClient();

  // Destructure mutate and mutateAsync from the useMutation return value
  const { mutate, mutateAsync } = useMutation(
    (data: EstimateAssembly.AsObject) =>
      peas.updateProjectEstimateAssembly(data),
    {
      onSuccess: () => {
        // Invalidate and refetch to ensure data is fresh
        queryClient.invalidateQueries(["countedAssemblies"]);
      },
      onError: (error) => {
        console.error("Error updating project estimate assembly", error);
      },
    }
  );

  // Return both mutate and mutateAsync for flexible usage outside
  return { mutate, mutateAsync };
};
