/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: projectEstimate.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as projectEstimate_pb from './projectEstimate_pb'; // proto import: "projectEstimate.proto"


export class ProjectEstimateServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateProjectEstimate = new grpcWeb.MethodDescriptor(
    '/ProjectEstimateService/CreateProjectEstimate',
    grpcWeb.MethodType.UNARY,
    projectEstimate_pb.CreateProjectEstimateRequest,
    projectEstimate_pb.CreateProjectEstimateResponse,
    (request: projectEstimate_pb.CreateProjectEstimateRequest) => {
      return request.serializeBinary();
    },
    projectEstimate_pb.CreateProjectEstimateResponse.deserializeBinary
  );

  createProjectEstimate(
    request: projectEstimate_pb.CreateProjectEstimateRequest,
    metadata?: grpcWeb.Metadata | null): Promise<projectEstimate_pb.CreateProjectEstimateResponse>;

  createProjectEstimate(
    request: projectEstimate_pb.CreateProjectEstimateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: projectEstimate_pb.CreateProjectEstimateResponse) => void): grpcWeb.ClientReadableStream<projectEstimate_pb.CreateProjectEstimateResponse>;

  createProjectEstimate(
    request: projectEstimate_pb.CreateProjectEstimateRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: projectEstimate_pb.CreateProjectEstimateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ProjectEstimateService/CreateProjectEstimate',
        request,
        metadata || {},
        this.methodDescriptorCreateProjectEstimate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ProjectEstimateService/CreateProjectEstimate',
    request,
    metadata || {},
    this.methodDescriptorCreateProjectEstimate);
  }

  methodDescriptorGetProjectEstimate = new grpcWeb.MethodDescriptor(
    '/ProjectEstimateService/GetProjectEstimate',
    grpcWeb.MethodType.UNARY,
    projectEstimate_pb.GetProjectEstimateRequest,
    projectEstimate_pb.GetProjectEstimateResponse,
    (request: projectEstimate_pb.GetProjectEstimateRequest) => {
      return request.serializeBinary();
    },
    projectEstimate_pb.GetProjectEstimateResponse.deserializeBinary
  );

  getProjectEstimate(
    request: projectEstimate_pb.GetProjectEstimateRequest,
    metadata?: grpcWeb.Metadata | null): Promise<projectEstimate_pb.GetProjectEstimateResponse>;

  getProjectEstimate(
    request: projectEstimate_pb.GetProjectEstimateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: projectEstimate_pb.GetProjectEstimateResponse) => void): grpcWeb.ClientReadableStream<projectEstimate_pb.GetProjectEstimateResponse>;

  getProjectEstimate(
    request: projectEstimate_pb.GetProjectEstimateRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: projectEstimate_pb.GetProjectEstimateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ProjectEstimateService/GetProjectEstimate',
        request,
        metadata || {},
        this.methodDescriptorGetProjectEstimate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ProjectEstimateService/GetProjectEstimate',
    request,
    metadata || {},
    this.methodDescriptorGetProjectEstimate);
  }

  methodDescriptorGetProjectEstimates = new grpcWeb.MethodDescriptor(
    '/ProjectEstimateService/GetProjectEstimates',
    grpcWeb.MethodType.UNARY,
    projectEstimate_pb.GetProjectEstimatesRequest,
    projectEstimate_pb.GetProjectEstimatesResponse,
    (request: projectEstimate_pb.GetProjectEstimatesRequest) => {
      return request.serializeBinary();
    },
    projectEstimate_pb.GetProjectEstimatesResponse.deserializeBinary
  );

  getProjectEstimates(
    request: projectEstimate_pb.GetProjectEstimatesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<projectEstimate_pb.GetProjectEstimatesResponse>;

  getProjectEstimates(
    request: projectEstimate_pb.GetProjectEstimatesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: projectEstimate_pb.GetProjectEstimatesResponse) => void): grpcWeb.ClientReadableStream<projectEstimate_pb.GetProjectEstimatesResponse>;

  getProjectEstimates(
    request: projectEstimate_pb.GetProjectEstimatesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: projectEstimate_pb.GetProjectEstimatesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ProjectEstimateService/GetProjectEstimates',
        request,
        metadata || {},
        this.methodDescriptorGetProjectEstimates,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ProjectEstimateService/GetProjectEstimates',
    request,
    metadata || {},
    this.methodDescriptorGetProjectEstimates);
  }

  methodDescriptorUpdateProjectEstimate = new grpcWeb.MethodDescriptor(
    '/ProjectEstimateService/UpdateProjectEstimate',
    grpcWeb.MethodType.UNARY,
    projectEstimate_pb.UpdateProjectEstimateRequest,
    projectEstimate_pb.UpdateProjectEstimateResponse,
    (request: projectEstimate_pb.UpdateProjectEstimateRequest) => {
      return request.serializeBinary();
    },
    projectEstimate_pb.UpdateProjectEstimateResponse.deserializeBinary
  );

  updateProjectEstimate(
    request: projectEstimate_pb.UpdateProjectEstimateRequest,
    metadata?: grpcWeb.Metadata | null): Promise<projectEstimate_pb.UpdateProjectEstimateResponse>;

  updateProjectEstimate(
    request: projectEstimate_pb.UpdateProjectEstimateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: projectEstimate_pb.UpdateProjectEstimateResponse) => void): grpcWeb.ClientReadableStream<projectEstimate_pb.UpdateProjectEstimateResponse>;

  updateProjectEstimate(
    request: projectEstimate_pb.UpdateProjectEstimateRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: projectEstimate_pb.UpdateProjectEstimateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ProjectEstimateService/UpdateProjectEstimate',
        request,
        metadata || {},
        this.methodDescriptorUpdateProjectEstimate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ProjectEstimateService/UpdateProjectEstimate',
    request,
    metadata || {},
    this.methodDescriptorUpdateProjectEstimate);
  }

  methodDescriptorDeleteProjectEstimate = new grpcWeb.MethodDescriptor(
    '/ProjectEstimateService/DeleteProjectEstimate',
    grpcWeb.MethodType.UNARY,
    projectEstimate_pb.DeleteProjectEstimateRequest,
    projectEstimate_pb.DeleteProjectEstimateResponse,
    (request: projectEstimate_pb.DeleteProjectEstimateRequest) => {
      return request.serializeBinary();
    },
    projectEstimate_pb.DeleteProjectEstimateResponse.deserializeBinary
  );

  deleteProjectEstimate(
    request: projectEstimate_pb.DeleteProjectEstimateRequest,
    metadata?: grpcWeb.Metadata | null): Promise<projectEstimate_pb.DeleteProjectEstimateResponse>;

  deleteProjectEstimate(
    request: projectEstimate_pb.DeleteProjectEstimateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: projectEstimate_pb.DeleteProjectEstimateResponse) => void): grpcWeb.ClientReadableStream<projectEstimate_pb.DeleteProjectEstimateResponse>;

  deleteProjectEstimate(
    request: projectEstimate_pb.DeleteProjectEstimateRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: projectEstimate_pb.DeleteProjectEstimateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ProjectEstimateService/DeleteProjectEstimate',
        request,
        metadata || {},
        this.methodDescriptorDeleteProjectEstimate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ProjectEstimateService/DeleteProjectEstimate',
    request,
    metadata || {},
    this.methodDescriptorDeleteProjectEstimate);
  }

}

