export const SVGCrosshair: React.FC<{
  cursorPosition: { x: number; y: number };
}> = ({ cursorPosition }) => {
  if (!cursorPosition) return null;

  return (
    <>
      <line
        x1={cursorPosition.x}
        y1="0"
        x2={cursorPosition.x}
        y2="100%"
        stroke="blue"
        strokeWidth=".5"
      />
      <line
        x1="0"
        y1={cursorPosition.y}
        x2="100%"
        y2={cursorPosition.y}
        stroke="blue"
        strokeWidth=".5"
      />
    </>
  );
};
