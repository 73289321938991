//InferenceService.ts
import { clients } from "../clients/grpcClients";
import {
  RunCountInferenceRequest,
  RunCountInferenceResponse,
} from "./protosCompiled/inference/inference_pb";

//gRPC API
export class InferenceService {
  // gRPC API
  public static runCountInference = (
    objectid: string,
    pagenumbersList: number[],
    gcsbucketname: string,
    gcsobjectnamesList: string[]
  ): Promise<RunCountInferenceResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const request = new RunCountInferenceRequest();
      request.setObjectid(objectid);
      request.setPagenumbersList(pagenumbersList);
      request.setGcsbucketname(gcsbucketname);
      request.setGcsobjectnamesList(gcsobjectnamesList);

      clients.inferenceServiceClient.runCountInference(
        request,
        {},
        (err: any, response: RunCountInferenceResponse) => {
          if (err) {
            console.error("gRPC Error:", err);
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };
}
