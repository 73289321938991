import { useStore } from "zustand";
import { Contract } from "../../api/protosCompiled/contract/contract_pb";
import { ResetStates } from "../../states/resetStates";
import { useUnityBuildStore } from "../../states/store";
import { Estimate } from "../../api/protosCompiled/projectEstimate/projectEstimate_pb";
import { ChangeOrder } from "../../api/protosCompiled/changeOrder/changeOrder_pb";
import { useCreateAssemblyStore } from "../../states/createAssemblyStore";

type HandleSetContractField = "create" | "update" | "setContract";

export const useHandleSetContract = () => {
  const store = useStore(useUnityBuildStore);
  const store1 = useStore(useCreateAssemblyStore);

  const {
    setIsViewingContractForm,
    setIsCreatingContract,
    setSelectedContract,
    setIsViewingChangeOrderForm,
  } = useStore(useUnityBuildStore);

  const handleSetContract = (
    field: HandleSetContractField,
    contract: Contract.AsObject | null
  ) => {
    switch (field) {
      case "create":
        setIsViewingContractForm(true);
        setIsCreatingContract(true);
        break;
      case "update":
        if (!contract) return;
        setIsCreatingContract(false);
        setIsViewingContractForm(true);
        setSelectedContract(contract);
        break;
      case "setContract":
        if (!contract) return;
        ResetStates.resetMainMenuStates(store, store1);
        setIsViewingChangeOrderForm(true);
        setSelectedContract(contract);
        break;
      default:
        break;
    }
  };

  return handleSetContract;
};

type HandleSetEstimateField = "create" | "update" | "setEstimate";

export const useHandleSetEstimate = () => {
  const store = useStore(useUnityBuildStore);
  const store1 = useStore(useCreateAssemblyStore);

  const {
    setIsViewingEstimateForm,
    setIsCreatingEstimate,
    setSelectedEstimate,
    setIsOpeningViewport,
  } = useStore(useUnityBuildStore);

  const handleSetEstimate = (
    field: HandleSetEstimateField,
    estimate: Estimate.AsObject | null
  ) => {
    switch (field) {
      case "create":
        setIsViewingEstimateForm(true);
        setIsCreatingEstimate(true);
        break;
      case "update":
        if (!estimate) return;
        setIsCreatingEstimate(false);
        setIsViewingEstimateForm(true);
        setSelectedEstimate(estimate);
        break;
      case "setEstimate":
        if (!estimate) return;
        ResetStates.resetMainMenuStates(store, store1);
        setIsOpeningViewport(true);
        setSelectedEstimate(estimate);
        break;
      default:
        break;
    }
  };
  return handleSetEstimate;
};

type HandleSetChangeOrderField = "create" | "update" | "setContract";

export const useHandleSetChangeOrder = () => {
  const store = useStore(useUnityBuildStore);
  const store1 = useStore(useCreateAssemblyStore);

  const {
    setIsViewingChangeOrderForm,
    setIsCreatingChangeOrder,
    setSelectedChangeOrder,
    setIsOpeningViewport,
  } = useStore(useUnityBuildStore);

  const handleSetChangeOrder = (
    field: HandleSetChangeOrderField,
    changeOrder: ChangeOrder.AsObject | null
  ) => {
    switch (field) {
      case "create":
        setIsViewingChangeOrderForm(true);
        setIsCreatingChangeOrder(true);
        break;
      case "update":
        if (!changeOrder) return;
        setIsCreatingChangeOrder(false);
        setIsViewingChangeOrderForm(true);
        setSelectedChangeOrder(changeOrder);
        break;
      case "setContract":
        if (!changeOrder) return;
        ResetStates.resetMainMenuStates(store, store1);
        setIsOpeningViewport(true);
        setSelectedChangeOrder(changeOrder);
        break;
      default:
        break;
    }
  };
  return handleSetChangeOrder;
};
