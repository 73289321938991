import { Avatar, Button } from "@mui/material";
import { useUnityBuildStore } from "../states/store";
import { useStore } from "zustand";

export const UserPicture = () => {
  const { user } = useStore(useUnityBuildStore);

  return (
    <>
      <Button>
        <Avatar
          key={user.id}
          src={user.userphotourl}
          alt="User Profile Picture"
          sx={{
            width: 48,
            height: 48,
            marginLeft: 1,
          }}
        />
      </Button>
    </>
  );
};
