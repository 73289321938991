/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: checkout.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as checkout_pb from './checkout_pb'; // proto import: "checkout.proto"


export class CheckoutServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateCheckoutSession = new grpcWeb.MethodDescriptor(
    '/CheckoutService/CreateCheckoutSession',
    grpcWeb.MethodType.UNARY,
    checkout_pb.CheckoutSessionRequest,
    checkout_pb.CheckoutSessionResponse,
    (request: checkout_pb.CheckoutSessionRequest) => {
      return request.serializeBinary();
    },
    checkout_pb.CheckoutSessionResponse.deserializeBinary
  );

  createCheckoutSession(
    request: checkout_pb.CheckoutSessionRequest,
    metadata?: grpcWeb.Metadata | null): Promise<checkout_pb.CheckoutSessionResponse>;

  createCheckoutSession(
    request: checkout_pb.CheckoutSessionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: checkout_pb.CheckoutSessionResponse) => void): grpcWeb.ClientReadableStream<checkout_pb.CheckoutSessionResponse>;

  createCheckoutSession(
    request: checkout_pb.CheckoutSessionRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: checkout_pb.CheckoutSessionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/CheckoutService/CreateCheckoutSession',
        request,
        metadata || {},
        this.methodDescriptorCreateCheckoutSession,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/CheckoutService/CreateCheckoutSession',
    request,
    metadata || {},
    this.methodDescriptorCreateCheckoutSession);
  }

  methodDescriptorRetrieveCheckoutSession = new grpcWeb.MethodDescriptor(
    '/CheckoutService/RetrieveCheckoutSession',
    grpcWeb.MethodType.UNARY,
    checkout_pb.SessionStatusRequest,
    checkout_pb.SessionStatusResponse,
    (request: checkout_pb.SessionStatusRequest) => {
      return request.serializeBinary();
    },
    checkout_pb.SessionStatusResponse.deserializeBinary
  );

  retrieveCheckoutSession(
    request: checkout_pb.SessionStatusRequest,
    metadata?: grpcWeb.Metadata | null): Promise<checkout_pb.SessionStatusResponse>;

  retrieveCheckoutSession(
    request: checkout_pb.SessionStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: checkout_pb.SessionStatusResponse) => void): grpcWeb.ClientReadableStream<checkout_pb.SessionStatusResponse>;

  retrieveCheckoutSession(
    request: checkout_pb.SessionStatusRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: checkout_pb.SessionStatusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/CheckoutService/RetrieveCheckoutSession',
        request,
        metadata || {},
        this.methodDescriptorRetrieveCheckoutSession,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/CheckoutService/RetrieveCheckoutSession',
    request,
    metadata || {},
    this.methodDescriptorRetrieveCheckoutSession);
  }

}

