import { create } from "zustand";
import { RunCountInferenceResponse } from "../api/protosCompiled/inference/inference_pb";

export interface InferenceCountObject
  extends RunCountInferenceResponse.AsObject {
  objectid: string;
  pagenumbersList: number[];
  gcsobjectnamesList: string[];
}

export type InferenceStore = {
  inferenceCountObjects: InferenceCountObject[];
  setInferenceCountObjects: (
    inferenceCountObjects: InferenceCountObject[]
  ) => void;

  gcsObjectNamesList: string[];
  setGcsObjectNamesList: (gcsObjectNamesList: string[]) => void;

  classMap: Record<string, string> | null;
  setClassMap: (classMap: Record<string, string> | null) => void;

  confidanceThreshold: number;
  setConfidanceThreshold: (confidanceThreshold: number) => void;

  inferenceItemFilter: string;
  setInferenceItemFilter: (inferenceItemFilter: string) => void;
};

export const useInferenceStore = create<InferenceStore>((set) => ({
  inferenceCountObjects: [],
  setInferenceCountObjects: (inferenceCountObjects) =>
    set({ inferenceCountObjects }),

  gcsObjectNamesList: [],
  setGcsObjectNamesList: (gcsObjectNamesList) => set({ gcsObjectNamesList }),

  classMap: null,
  setClassMap: (classMap) => set({ classMap }),

  confidanceThreshold: 0.0,
  setConfidanceThreshold: (confidanceThreshold) => set({ confidanceThreshold }),

  inferenceItemFilter: "",
  setInferenceItemFilter: (inferenceItemFilter) => set({ inferenceItemFilter }),
}));
