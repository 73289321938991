import { useQuery, useQueryClient } from "react-query";
import { FolderService } from "../api/FolderService";

export function useFetchFolders() {
  const queryClient = useQueryClient();

  const { data, error, isLoading } = useQuery(
    ["folders"],
    async () => {
      const response = await FolderService.getParentId();
      return response;
    },
    {
      enabled: true,
    }
  );

  const refetchFolders = () => {
    queryClient.invalidateQueries(["folders"]);
  };
  return { data, error, isLoading, refetchFolders };
}
