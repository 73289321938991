import { clients } from "../clients/grpcClients";
import { useUnityBuildStore } from "../states/store";
import {
  CreateGlobalAssemblyRequest,
  CreateGlobalAssemblyResponse,
  DeleteGlobalAssemblyRequest,
  DeleteGlobalAssemblyResponse,
  GetAllGlobalAssembliesRequest,
  GetAllGlobalAssembliesResponse,
  GetGlobalAssemblyRequest,
  GetGlobalAssemblyResponse,
  UpdateGlobalAssemblyRequest,
  UpdateGlobalAssemblyResponse,
  GlobalAssembly,
  ItemQtyFormula,
  AssemblyItemAttribute,
  GetAssembliesByFileIdResponse,
  GetAssembliesByFileIdRequest,
} from "./protosCompiled/globalAssembly/globalAssembly_pb";

const client = clients.globalAssemblyServiceClient;

export class GlobalAssemblyService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  public static async createGlobalAssembly(
    assembly: GlobalAssembly.AsObject
  ): Promise<CreateGlobalAssemblyResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateGlobalAssemblyRequest();

      const globalAssembly = new GlobalAssembly();
      globalAssembly.setFolderid(assembly.folderid);
      globalAssembly.setGlobalassemblyid(assembly.globalassemblyid);
      globalAssembly.setAssemblyname(assembly.assemblyname);
      globalAssembly.setIsactive(assembly.isactive);
      globalAssembly.setLastupdated(assembly.lastupdated);
      globalAssembly.setParentid(assembly.parentid);
      globalAssembly.setLevel(assembly.level);
      globalAssembly.setEntitytype(assembly.entitytype);
      globalAssembly.setIsfile(assembly.isfile);
      globalAssembly.setAssemblymeasurementtype(
        assembly.assemblymeasurementtype
      );
      globalAssembly.setImageclassificationid(assembly.imageclassificationid);

      globalAssembly.setItemqtyformulasList(
        assembly.itemqtyformulasList.map((formula) => {
          const itemQtyFormula = new ItemQtyFormula();
          itemQtyFormula.setItemid(formula.itemid);
          itemQtyFormula.setItemname(formula.itemname);
          itemQtyFormula.setItemqty(formula.itemqty);
          itemQtyFormula.setForevery(formula.forevery);
          itemQtyFormula.setTakeoffvariabletype(formula.takeoffvariabletype);
          itemQtyFormula.setAnd(formula.and);
          itemQtyFormula.setOr(formula.or);
          itemQtyFormula.setIsquoted(formula.isquoted);
          itemQtyFormula.setAssemblyname(formula.assemblyname);
          itemQtyFormula.setQuotegroup(formula.quotegroup);

          itemQtyFormula.setAttributesList(
            formula.attributesList.map((attr) => {
              const assemblyItemAttribute = new AssemblyItemAttribute();
              assemblyItemAttribute.setAttributegroupid(attr.attributegroupid);
              assemblyItemAttribute.setAttributevalueid(attr.attributevalueid);
              assemblyItemAttribute.setAttributegroupname(
                attr.attributegroupname
              );
              assemblyItemAttribute.setAttributevalue(attr.attributevalue);
              assemblyItemAttribute.setLastupdated(attr.lastupdated);
              return assemblyItemAttribute;
            })
          );

          return itemQtyFormula;
        })
      );

      req.setGlobalassembly(globalAssembly);
      req.setSessiontoken(this.getMetadata().sessionToken);
      client.createGlobalAssembly(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateGlobalAssembly(
    assembly: GlobalAssembly.AsObject
  ): Promise<UpdateGlobalAssemblyResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new UpdateGlobalAssemblyRequest();
      const globalAssembly = new GlobalAssembly();
      globalAssembly.setFolderid(assembly.folderid);
      globalAssembly.setGlobalassemblyid(assembly.globalassemblyid);
      globalAssembly.setAssemblyname(assembly.assemblyname);
      globalAssembly.setIsactive(assembly.isactive);
      globalAssembly.setLastupdated(assembly.lastupdated);
      globalAssembly.setParentid(assembly.parentid);
      globalAssembly.setLevel(assembly.level);
      globalAssembly.setEntitytype(assembly.entitytype);
      globalAssembly.setIsfile(assembly.isfile);
      globalAssembly.setAssemblymeasurementtype(
        assembly.assemblymeasurementtype
      );
      globalAssembly.setImageclassificationid(assembly.imageclassificationid);

      globalAssembly.setItemqtyformulasList(
        assembly.itemqtyformulasList.map((formula) => {
          const itemQtyFormula = new ItemQtyFormula();
          itemQtyFormula.setItemid(formula.itemid);
          itemQtyFormula.setItemname(formula.itemname);
          itemQtyFormula.setItemqty(formula.itemqty);
          itemQtyFormula.setForevery(formula.forevery);
          itemQtyFormula.setTakeoffvariabletype(formula.takeoffvariabletype);
          itemQtyFormula.setAnd(formula.and);
          itemQtyFormula.setOr(formula.or);
          itemQtyFormula.setIsquoted(formula.isquoted);
          itemQtyFormula.setAssemblyname(formula.assemblyname);
          itemQtyFormula.setQuotegroup(formula.quotegroup);

          itemQtyFormula.setAttributesList(
            formula.attributesList.map((attr) => {
              const assemblyItemAttribute = new AssemblyItemAttribute();
              assemblyItemAttribute.setAttributegroupid(attr.attributegroupid);
              assemblyItemAttribute.setAttributevalueid(attr.attributevalueid);
              assemblyItemAttribute.setAttributegroupname(
                attr.attributegroupname
              );
              assemblyItemAttribute.setAttributevalue(attr.attributevalue);
              assemblyItemAttribute.setLastupdated(attr.lastupdated);
              return assemblyItemAttribute;
            })
          );

          return itemQtyFormula;
        })
      );

      req.setGlobalassembly(globalAssembly);
      req.setSessiontoken(this.getMetadata().sessionToken);
      client.updateGlobalAssembly(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getGlobalAssembly(
    folderid: string
  ): Promise<GetGlobalAssemblyResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetGlobalAssemblyRequest();
      req.setFolderid(folderid);
      req.setSessiontoken(this.getMetadata().sessionToken);
      if (folderid) req.setFolderid(folderid);

      client.getGlobalAssembly(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getAllGlobalAssemblies(
    options: GetAllGlobalAssembliesRequest.AsObject
  ): Promise<GetAllGlobalAssembliesResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetAllGlobalAssembliesRequest();
      req.setPagenumber(options.pagenumber);
      req.setPagesize(options.pagesize);
      req.setSearchkeyword(options.searchkeyword);
      req.setSortby(options.sortby);
      req.setSortdescending(options.sortdescending);
      req.setSessiontoken(this.getMetadata().sessionToken);

      client.getAllGlobalAssemblies(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async deleteGlobalAssembly(
    folderId: string
  ): Promise<DeleteGlobalAssemblyResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteGlobalAssemblyRequest();
      req.setFolderid(folderId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      client.deleteGlobalAssembly(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getAssembliesByFileId(
    fileId: string
  ): Promise<GetAssembliesByFileIdResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetAssembliesByFileIdRequest();
      req.setFileid(fileId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      client.getAssembliesByFileId(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }
}
