import { useState } from "react";
import { CircularProgress, Alert, Dialog, DialogContent } from "@mui/material";
import { useUnityBuildStore } from "../../states/store";
import { useFetchContracts } from "../../hooks/useFetchContracts";
import { useStore } from "zustand";
import { Contract } from "../../api/protosCompiled/contract/contract_pb";
import { CreateContract } from "./CreateContract";
import { useHandleSetContract } from "./helpers";

export function ListContracts() {
  const {
    isViewingContractForm,
    setIsViewingContractForm,
    setIsViewingChangeOrderForm,
    setSelectedContract,
    setIsViewingChangeOrderList,
    setIsViewingEstimateList,
  } = useStore(useUnityBuildStore);

  // Fetch contract and error data
  const { data: contractList, error, isLoading } = useFetchContracts();
  const errorMessage = (error as Error)?.message;

  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSearchChange = (event: any) => setSearchTerm(event.target.value);
  const handleSetValue = useHandleSetContract();

  // Filter contracts based on the search term
  const filteredContracts: Contract.AsObject[] = (contractList ?? []).filter(
    (contracts: Contract.AsObject) =>
      contracts?.contractname
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      contracts?.contractdescription
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      contracts?.customid?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCloseForm = () => {
    setIsViewingContractForm(false);
    setIsViewingChangeOrderForm(false);
  };

  const handleSetContarct = (contract: Contract.AsObject) => {
    setIsViewingEstimateList(false);
    setSelectedContract(contract);
    setIsViewingChangeOrderList(true);
  };

  return (
    <>
      <button
        onClick={(event) => {
          event.stopPropagation();
          handleSetValue("create", null);
        }}
      >
        Create Contract
      </button>
      <input
        placeholder="Search Contracts"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <div className="scrollableTableContainer">
        <table>
          <thead>
            <tr>
              <th>Contract ID</th>
              <th style={{ width: "60%" }}>Contract Name</th>
              <th>Associated Estimates</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredContracts?.map((item, index) => (
              <>
                <tr
                  key={item.contractid}
                  onClick={() => handleSetContarct(item)}
                >
                  <td style={{ cursor: "pointer" }}>{item.customid}</td>
                  <td style={{ cursor: "pointer" }}>{item.contractname}</td>
                  <td style={{ pointerEvents: "none" }}>
                    <select
                      style={{
                        pointerEvents: "auto",
                        width: "95%",
                      }}
                      onClick={(event) => event.stopPropagation()}
                    >
                      {item && item.associatedestimatesList?.length <= 0 && (
                        <option value="">None</option>
                      )}
                      {item.associatedestimatesList?.map((estimate) => (
                        <option
                          key={estimate.estimateid}
                          value={estimate.estimateid}
                        >
                          {estimate.name || "Unnamed Estimate"}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td style={{ pointerEvents: "none" }}>
                    <button
                      style={{ pointerEvents: "auto" }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleSetValue("update", item ?? null);
                      }}
                    >
                      Edit Contract
                    </button>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>

      {isLoading && <CircularProgress />}
      {error ? <Alert severity="error">Error: {errorMessage}</Alert> : null}

      <Dialog open={isViewingContractForm} onClose={handleCloseForm}>
        <DialogContent>
          {isViewingContractForm && <CreateContract />}
        </DialogContent>
      </Dialog>
    </>
  );
}
