import { Collapse, Box, Paper, Dialog, DialogContent } from "@mui/material";
import { useState, MouseEvent, useEffect, useRef } from "react";
import { FolderIcon } from "../../icons/folderIcon";
import { ItemTable } from "./ItemTable";
import React from "react";
import { FolderCreationForm } from "./FolderCreationForm";
import { useFetchFolders } from "../../hooks/useFetchFolders";
import {
  CollapsibleRowProps,
  Entitytype,
  FolderNode,
  MenuOptions,
} from "../../types/FolderTypes";
import {
  capitalizeFirstLetter,
  defaultFolder,
  manageFolder,
  menuSelect,
} from "./helpers";
import CreateItemTab from "../assemblyManager/AssemblyAndItemCreate/CreateItemTab";
import { debounce } from "lodash";
import { AssemblyTable } from "../assemblyManager/AssemblyTable";
import { CreateAssembly } from "../assemblyManager/AssemblyAndItemCreate/CreateAssembly";
import { GlobalItem } from "../../api/protosCompiled/globalItem/globalItem_pb";
import { Folder } from "../../api/protosCompiled/folder/folder_pb";
import { useStore } from "zustand";
import { defaultGlobalAssembly, useUnityBuildStore } from "../../states/store";
import { CollapsableRowMenu } from "./CollapsableRowMenu";
import { ResetStates } from "../../states/resetStates";
import { useCreateAssemblyStore } from "../../states/createAssemblyStore";

export const CollapsibleRow = ({
  data,
  onAddItem,
  depth = 0,
  entitytype,
}: CollapsibleRowProps) => {
  const UBStore = useStore(useUnityBuildStore);
  const CAstore = useStore(useCreateAssemblyStore);
  const { refetchFolders } = useFetchFolders();
  const [openRows, setOpenRows] = useState<{ [key: string]: boolean }>({});

  const [menuOption, setMenuOption] = useState<MenuOptions | null>(null);
  const [folderToRename, setFolderToRename] =
    useState<Folder.AsObject>(defaultFolder);
  const [isMoveMode, setIsMoveMode] = useState(false);
  const [clickedFolderOrItem, setClickedFolderOrItem] =
    useState<any>(defaultFolder);

  const [contextMenuVisible, setContextMenuVisible] = useState(false);

  const handleClose = () => {
    UBStore.setSelectedTakeoffAssembly(null);
    ResetStates.resetCreateAssemblyStates(CAstore, UBStore);
    setMenuOption(null);
  };

  useEffect(() => {
    // If data is not defined or not an array, return early
    if (!Array.isArray(data)) return;

    // Check if a folder for the current entitytype exists
    const folderExists = data.some(
      (folder) => folder && folder.entitytype === entitytype
    );

    if (!folderExists) {
      const handleManageFolder = debounce(async () => {
        try {
          await manageFolder({
            folderName: `User ${capitalizeFirstLetter(entitytype)} Folder`,
            parentFolderId: "",
            isFile: false,
            entitytype,
            isActive: true,
            refetchFolders,
            onSuccess: () => {
              console.log(`Folder for ${entitytype} created successfully.`);
            },
          });
        } catch (error) {
          console.error(`Error managing folder for ${entitytype}:`, error);
        }
      }, 1000);

      handleManageFolder();
      return () => {
        handleManageFolder.cancel();
      };
    }
  }, [data, entitytype, refetchFolders]);

  useEffect(() => {
    if (menuOption) {
      setContextMenuVisible(false);
    }
  }, [menuOption]);

  const toggleRow = (key: string) => {
    if (!isMoveMode) {
      // Only toggle if not in move mode
      setOpenRows((prev) => ({
        ...prev,
        [key]: !prev[key],
      }));
    }
  };

  // Sets the folder or file and the context menu to be visible
  const handleRightClick = (event: MouseEvent, folderOrItem: FolderNode) => {
    event.preventDefault();
    setClickedFolderOrItem(folderOrItem);
    setContextMenuVisible(true);
  };

  const handleMenuSelect = async (option: MenuOptions) => {
    await menuSelect(
      option,
      clickedFolderOrItem,
      setIsMoveMode,
      setFolderToRename,
      setMenuOption,
      refetchFolders
    );
  };

  const handleMoveFolderOrItem = async (targetFolderId: string) => {
    if (
      clickedFolderOrItem.parentid === targetFolderId ||
      clickedFolderOrItem.folderid === targetFolderId
    ) {
      console.error(
        "Can move object to itself, or it is already in the folder"
      );
      setIsMoveMode(false);
      setContextMenuVisible(false);
      return;
    }

    try {
      await manageFolder({
        folder: clickedFolderOrItem,
        folderName:
          clickedFolderOrItem.name || clickedFolderOrItem.assemblyname,
        parentFolderId: targetFolderId,
        isFile: clickedFolderOrItem.isfile,
        entitytype: clickedFolderOrItem.entitytype as Entitytype,
        isActive: clickedFolderOrItem.isactive,
        refetchFolders,
        onSuccess: () => {
          setClickedFolderOrItem(defaultFolder);
        },
      });
      setIsMoveMode(false);
      setContextMenuVisible(false);
    } catch (error) {
      console.error("Error managing folder:", error);
    }
  };

  const renderFolderRow = (folder: FolderNode, nestedDepth: number) => {
    if (!folder) return null;
    if (folder.isfile) {
      if (folder.entitytype === "item") {
        return (
          <>
            <tr key={folder.folderid}>
              <td
                colSpan={2}
                style={{
                  padding: "10px",
                  paddingLeft: `${nestedDepth * 20}px`,
                }}
              >
                <ItemTable
                  folderOrFile={folder}
                  onAddItem={onAddItem}
                  isMoveMode={isMoveMode}
                  handleRightClick={handleRightClick}
                  handleMenuSelect={handleMenuSelect}
                  refetchFolders={refetchFolders}
                />
              </td>
            </tr>
          </>
        );
      }
      return null;
    }

    const isOpen = openRows[folder.folderid] || false;

    const handleMoveClick = () => {
      if (isMoveMode) {
        handleMoveFolderOrItem(folder.folderid);
      } else {
        toggleRow(folder.folderid);
      }
    };

    // Render the folder row
    const folderRow = (
      <tr
        key={folder.folderid}
        style={{ cursor: "pointer" }}
        onClick={
          isMoveMode ? handleMoveClick : () => toggleRow(folder.folderid)
        }
        onContextMenu={(event) => handleRightClick(event, folder)}
      >
        <td
          style={{
            textAlign: "left",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            paddingLeft: `${nestedDepth * 20}px`,
          }}
        >
          <span style={{ marginRight: "10px" }}>
            <FolderIcon isOpen={isOpen} />
          </span>
          {folder.name}
        </td>
      </tr>
    );

    // Render children if the folder is open
    const childRows = isOpen ? (
      <tr>
        <td colSpan={2} style={{ padding: 0 }}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <table style={{ width: "100%" }}>
              <tbody>{renderChildRows(folder.children, nestedDepth + 1)}</tbody>
            </table>
          </Collapse>
        </td>
      </tr>
    ) : null;

    return (
      <React.Fragment key={folder.folderid}>
        {folderRow}
        {childRows}
      </React.Fragment>
    );
  };

  const renderChildRows = (
    children: (FolderNode | undefined)[],
    nestedDepth: number
  ) => {
    // Filter out any undefined values in the children array
    const definedChildren = children.filter(
      (child): child is FolderNode => child !== undefined
    );

    if (definedChildren.length === 0) return null;

    let assemblyFiles: FolderNode[] = [];
    let otherChildren: FolderNode[] = [];

    try {
      assemblyFiles = definedChildren.filter(
        (child) => child.isfile && child.entitytype === "assembly"
      );
    } catch (error) {
      console.error("Error filtering assembly files:", error);
    }

    try {
      otherChildren = definedChildren.filter(
        (child) => !(child.isfile && child.entitytype === "assembly")
      );
    } catch (error) {
      console.error("Error filtering other children:", error);
    }

    const rows = [];

    // Render non-assembly children (this includes items and folders)
    otherChildren.forEach((child) => {
      const childRow = renderFolderRow(child, nestedDepth);
      if (childRow) {
        rows.push(childRow);
      }
    });

    // Render assembly files under one header
    if (assemblyFiles.length > 0) {
      rows.push(
        <tr key={`assembly-header-${nestedDepth}`}>
          <td colSpan={2} style={{ paddingLeft: `${nestedDepth * 20}px` }}>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Assembly Name (Click to takeoff)</th>
                  <th>Measurement Type</th>
                  <th>Class</th>
                </tr>
              </thead>
              <tbody>
                {assemblyFiles.map((assemblyFolder) => (
                  <AssemblyTable
                    key={assemblyFolder.folderid}
                    folderOrFile={assemblyFolder}
                    isMoveMode={isMoveMode}
                    handleRightClick={handleRightClick}
                    handleMenuSelect={handleMenuSelect}
                    refetchFolders={refetchFolders}
                  />
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      );
    }

    return rows;
  };

  //function to maintain true if at first it was set to edit

  const useEditingAssemblyTransitiontoTrue = (menuOption: string) => {
    const wasTrueRef = useRef(false);

    if (menuOption === "Edit Assembly") {
      wasTrueRef.current = true;
      return true;
    } else if (
      menuOption === "Create Assembly" &&
      wasTrueRef.current === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {/* Folder Tree */}
      <table style={{ width: "100%" }}>
        <tbody>{data.map((folder) => renderFolderRow(folder, depth))}</tbody>
      </table>

      {/* Context Menu - centered */}
      <Dialog
        open={contextMenuVisible && !isMoveMode}
        onClose={() => setContextMenuVisible(false)}
      >
        <CollapsableRowMenu
          clickedFolderOrItem={clickedFolderOrItem}
          entitytype={entitytype}
          handleMenuSelect={handleMenuSelect}
        />
      </Dialog>

      {/* Instruction when Move Mode is active */}
      {isMoveMode && contextMenuVisible && (
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2,
          }}
        >
          <Paper
            style={{ padding: "10px", width: "300px", textAlign: "center" }}
          >
            {" "}
            <p>Click on the destination folder.</p>
          </Paper>
        </Box>
      )}

      {/* Create Assembly or Edit Assembly */}
      <Dialog
        open={
          menuOption === "Create Assembly" || menuOption === "Edit Assembly"
        }
        onClose={handleClose}
        fullScreen={true}
        style={{
          height: "100vh",
          backgroundColor: UBStore.userBackgroundColor,
        }}
      >
        <DialogContent>
          <CreateAssembly
            assemblyOption="global"
            folderOrFile={clickedFolderOrItem}
            refetchFolders={refetchFolders}
            setMenuOption={setMenuOption}
            menuOption={menuOption}
            allowCreateFromExistingItems={useEditingAssemblyTransitiontoTrue(
              menuOption ?? ""
            )}
          />
        </DialogContent>
      </Dialog>

      {/* Folder Creation/Rename Form - centered */}
      <Dialog
        open={menuOption === "Create Folder" || menuOption === "Rename"}
        onClose={() => setMenuOption(null)}
      >
        <DialogContent>
          <FolderCreationForm
            parentFolderId={
              menuOption === "Create Folder"
                ? clickedFolderOrItem.folderid
                : null
            }
            folderToRename={menuOption === "Rename" ? folderToRename : null}
            onFolderCreated={() => {
              setMenuOption(null);
              setFolderToRename(defaultFolder);
            }}
            entitytype={entitytype}
          />
        </DialogContent>
      </Dialog>

      {/* Create & Edit Item Tabs */}
      <Dialog
        open={
          menuOption === "Create Item" ||
          menuOption === "Edit Item" ||
          menuOption === "Attribute Group" ||
          menuOption === "UOM"
        }
        onClose={() => setMenuOption(null)}
      >
        <DialogContent
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 4,
            width: "900px",
            height: "800px",
            backgroundColor: "lightgray",
            border: "1px solid black",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          <CreateItemTab
            parentFolderId={clickedFolderOrItem.folderid}
            onItemCreated={() => {
              setMenuOption(null);
              setContextMenuVisible(false);
            }}
            item={clickedFolderOrItem as GlobalItem.AsObject}
            menuOption={menuOption}
            setMenuOption={setMenuOption}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
