/**
 * @fileoverview gRPC-Web generated client stub for changeOrderAssembly
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: changeOrderAssembly.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as changeOrderAssembly_pb from './changeOrderAssembly_pb'; // proto import: "changeOrderAssembly.proto"


export class ChangeOrderAssemblyServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateChangeOrderAssembly = new grpcWeb.MethodDescriptor(
    '/changeOrderAssembly.ChangeOrderAssemblyService/CreateChangeOrderAssembly',
    grpcWeb.MethodType.UNARY,
    changeOrderAssembly_pb.CreateChangeOrderAssemblyRequest,
    changeOrderAssembly_pb.CreateChangeOrderAssemblyResponse,
    (request: changeOrderAssembly_pb.CreateChangeOrderAssemblyRequest) => {
      return request.serializeBinary();
    },
    changeOrderAssembly_pb.CreateChangeOrderAssemblyResponse.deserializeBinary
  );

  createChangeOrderAssembly(
    request: changeOrderAssembly_pb.CreateChangeOrderAssemblyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderAssembly_pb.CreateChangeOrderAssemblyResponse>;

  createChangeOrderAssembly(
    request: changeOrderAssembly_pb.CreateChangeOrderAssemblyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderAssembly_pb.CreateChangeOrderAssemblyResponse) => void): grpcWeb.ClientReadableStream<changeOrderAssembly_pb.CreateChangeOrderAssemblyResponse>;

  createChangeOrderAssembly(
    request: changeOrderAssembly_pb.CreateChangeOrderAssemblyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderAssembly_pb.CreateChangeOrderAssemblyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/changeOrderAssembly.ChangeOrderAssemblyService/CreateChangeOrderAssembly',
        request,
        metadata || {},
        this.methodDescriptorCreateChangeOrderAssembly,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/changeOrderAssembly.ChangeOrderAssemblyService/CreateChangeOrderAssembly',
    request,
    metadata || {},
    this.methodDescriptorCreateChangeOrderAssembly);
  }

  methodDescriptorUpdateChangeOrderAssembly = new grpcWeb.MethodDescriptor(
    '/changeOrderAssembly.ChangeOrderAssemblyService/UpdateChangeOrderAssembly',
    grpcWeb.MethodType.UNARY,
    changeOrderAssembly_pb.UpdateChangeOrderAssemblyRequest,
    changeOrderAssembly_pb.UpdateChangeOrderAssemblyResponse,
    (request: changeOrderAssembly_pb.UpdateChangeOrderAssemblyRequest) => {
      return request.serializeBinary();
    },
    changeOrderAssembly_pb.UpdateChangeOrderAssemblyResponse.deserializeBinary
  );

  updateChangeOrderAssembly(
    request: changeOrderAssembly_pb.UpdateChangeOrderAssemblyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderAssembly_pb.UpdateChangeOrderAssemblyResponse>;

  updateChangeOrderAssembly(
    request: changeOrderAssembly_pb.UpdateChangeOrderAssemblyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderAssembly_pb.UpdateChangeOrderAssemblyResponse) => void): grpcWeb.ClientReadableStream<changeOrderAssembly_pb.UpdateChangeOrderAssemblyResponse>;

  updateChangeOrderAssembly(
    request: changeOrderAssembly_pb.UpdateChangeOrderAssemblyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderAssembly_pb.UpdateChangeOrderAssemblyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/changeOrderAssembly.ChangeOrderAssemblyService/UpdateChangeOrderAssembly',
        request,
        metadata || {},
        this.methodDescriptorUpdateChangeOrderAssembly,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/changeOrderAssembly.ChangeOrderAssemblyService/UpdateChangeOrderAssembly',
    request,
    metadata || {},
    this.methodDescriptorUpdateChangeOrderAssembly);
  }

  methodDescriptorDeleteChangeOrderAssembly = new grpcWeb.MethodDescriptor(
    '/changeOrderAssembly.ChangeOrderAssemblyService/DeleteChangeOrderAssembly',
    grpcWeb.MethodType.UNARY,
    changeOrderAssembly_pb.DeleteChangeOrderAssemblyRequest,
    changeOrderAssembly_pb.DeleteChangeOrderAssemblyResponse,
    (request: changeOrderAssembly_pb.DeleteChangeOrderAssemblyRequest) => {
      return request.serializeBinary();
    },
    changeOrderAssembly_pb.DeleteChangeOrderAssemblyResponse.deserializeBinary
  );

  deleteChangeOrderAssembly(
    request: changeOrderAssembly_pb.DeleteChangeOrderAssemblyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderAssembly_pb.DeleteChangeOrderAssemblyResponse>;

  deleteChangeOrderAssembly(
    request: changeOrderAssembly_pb.DeleteChangeOrderAssemblyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderAssembly_pb.DeleteChangeOrderAssemblyResponse) => void): grpcWeb.ClientReadableStream<changeOrderAssembly_pb.DeleteChangeOrderAssemblyResponse>;

  deleteChangeOrderAssembly(
    request: changeOrderAssembly_pb.DeleteChangeOrderAssemblyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderAssembly_pb.DeleteChangeOrderAssemblyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/changeOrderAssembly.ChangeOrderAssemblyService/DeleteChangeOrderAssembly',
        request,
        metadata || {},
        this.methodDescriptorDeleteChangeOrderAssembly,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/changeOrderAssembly.ChangeOrderAssemblyService/DeleteChangeOrderAssembly',
    request,
    metadata || {},
    this.methodDescriptorDeleteChangeOrderAssembly);
  }

  methodDescriptorGetTakeoffStatsByChangeOrderID = new grpcWeb.MethodDescriptor(
    '/changeOrderAssembly.ChangeOrderAssemblyService/GetTakeoffStatsByChangeOrderID',
    grpcWeb.MethodType.UNARY,
    changeOrderAssembly_pb.GetTakeoffStatsRequest,
    changeOrderAssembly_pb.GetTakeoffStatsResponse,
    (request: changeOrderAssembly_pb.GetTakeoffStatsRequest) => {
      return request.serializeBinary();
    },
    changeOrderAssembly_pb.GetTakeoffStatsResponse.deserializeBinary
  );

  getTakeoffStatsByChangeOrderID(
    request: changeOrderAssembly_pb.GetTakeoffStatsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderAssembly_pb.GetTakeoffStatsResponse>;

  getTakeoffStatsByChangeOrderID(
    request: changeOrderAssembly_pb.GetTakeoffStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderAssembly_pb.GetTakeoffStatsResponse) => void): grpcWeb.ClientReadableStream<changeOrderAssembly_pb.GetTakeoffStatsResponse>;

  getTakeoffStatsByChangeOrderID(
    request: changeOrderAssembly_pb.GetTakeoffStatsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderAssembly_pb.GetTakeoffStatsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/changeOrderAssembly.ChangeOrderAssemblyService/GetTakeoffStatsByChangeOrderID',
        request,
        metadata || {},
        this.methodDescriptorGetTakeoffStatsByChangeOrderID,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/changeOrderAssembly.ChangeOrderAssemblyService/GetTakeoffStatsByChangeOrderID',
    request,
    metadata || {},
    this.methodDescriptorGetTakeoffStatsByChangeOrderID);
  }

  methodDescriptorGetChangeOrderAssembliesByChangeOrderId = new grpcWeb.MethodDescriptor(
    '/changeOrderAssembly.ChangeOrderAssemblyService/GetChangeOrderAssembliesByChangeOrderId',
    grpcWeb.MethodType.UNARY,
    changeOrderAssembly_pb.GetChangeOrderAssembliesByChangeOrderIdRequest,
    changeOrderAssembly_pb.GetChangeOrderAssembliesByChangeOrderIdResponse,
    (request: changeOrderAssembly_pb.GetChangeOrderAssembliesByChangeOrderIdRequest) => {
      return request.serializeBinary();
    },
    changeOrderAssembly_pb.GetChangeOrderAssembliesByChangeOrderIdResponse.deserializeBinary
  );

  getChangeOrderAssembliesByChangeOrderId(
    request: changeOrderAssembly_pb.GetChangeOrderAssembliesByChangeOrderIdRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderAssembly_pb.GetChangeOrderAssembliesByChangeOrderIdResponse>;

  getChangeOrderAssembliesByChangeOrderId(
    request: changeOrderAssembly_pb.GetChangeOrderAssembliesByChangeOrderIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderAssembly_pb.GetChangeOrderAssembliesByChangeOrderIdResponse) => void): grpcWeb.ClientReadableStream<changeOrderAssembly_pb.GetChangeOrderAssembliesByChangeOrderIdResponse>;

  getChangeOrderAssembliesByChangeOrderId(
    request: changeOrderAssembly_pb.GetChangeOrderAssembliesByChangeOrderIdRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderAssembly_pb.GetChangeOrderAssembliesByChangeOrderIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/changeOrderAssembly.ChangeOrderAssemblyService/GetChangeOrderAssembliesByChangeOrderId',
        request,
        metadata || {},
        this.methodDescriptorGetChangeOrderAssembliesByChangeOrderId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/changeOrderAssembly.ChangeOrderAssemblyService/GetChangeOrderAssembliesByChangeOrderId',
    request,
    metadata || {},
    this.methodDescriptorGetChangeOrderAssembliesByChangeOrderId);
  }

}

