import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import { ExtentionHeader } from "./ExtentionHeader";
import { DirectLaborHeader } from "./DirectLaborHeader";
import { IncidentalLaborHeader } from "./IncidentalLaborHeader";
import { IndirectLaborHeader } from "./IndirectLaborHeader";
import { LaborFactoringHeader } from "./LaborFactoringHeader";
import { EquipmentHeader } from "./EquipmentHeader";
import { GeneralExpenseHeader } from "./GeneralExpenseHeader";
import { SubcontractHeader } from "./SubcontractHeader";
import { QuoteHeader } from "./QuoteHeader";
import { TotalPricingHeader } from "./TotalPricingHeader";
import { useFetchAssemblyStats } from "../../hooks/useFetchCountedAssemblies";
import {
  TakeoffStats,
  EstimateItem,
} from "../../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import {
  useFetchChangeOrderCloseout,
  useFetchEstimateCloseout,
} from "../../hooks/useFetchCloseout";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";
import {
  directLaborDefaults,
  laborFactoringDefaults,
  incidentalLaborDefaults,
  indirectLaborDefaults,
  equipmentDefaults,
  generalExpenseDefaults,
  subcontractExpenseDefaults,
  quotesExpenseDefaults,
  totalPricingDefaults,
} from "./closeoutTypes";
import { useCloseoutStore } from "../../states/closeoutStore";
import {
  formatCloseoutData,
  orderExtension,
  recalculateTotalPricing,
} from "./closeoutHelpers";
import {
  useCreateChangeOrderCloseoutMutation,
  useCreateEstimateCloseoutMutation,
} from "../../hooks/useCreateCloseoutMutation";
import { useEffect, useState } from "react";
import { debounce } from "lodash";
import {
  EstimateCloseout,
  ExtensionType,
  QuoteType,
} from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { Calculations as c } from "../../utils/calculations";
import { a11yProps, CustomTabPanel } from "../../customTabs/CustomTabPanel";
import { ChangeOrderItem } from "../../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";

export const CloseoutTabs = () => {
  const { userBackgroundColor, selectedEstimate, selectedChangeOrder } =
    useStore(useUnityBuildStore);
  const {
    includedExtentionTypes,
    includedDirectLaborTypes,
    includedIncidentalLaborTypes,
    includedIndirectLaborTypes,
    includedLaborFactoringTypes,
    includedEquipmentTypes,
    includedGenExpenseTypes,
    includedSubcontractTypes,
    includedQuoteTypes,
    includedTotalPricingTypes,
    setIncludedExtentionTypes,
    setIncludedDirectLaborTypes,
    setIncludedIncidentalLaborTypes,
    setIncludedIndirectLaborTypes,
    setIncludedLaborFactoringTypes,
    setIncludedEquipmentTypes,
    setIncludedGenExpenseTypes,
    setIncludedSubcontractTypes,
    setIncludedQuoteTypes,
    setIncludedTotalPricingTypes,
  } = useStore(useCloseoutStore);

  const { data: assemblyStats, isLoading: isAssemblyLoading } =
    useFetchAssemblyStats();

  // Determine which ID to use and fetch the corresponding data
  const isEstimate = !!selectedEstimate.estimateid;
  const selectedId = isEstimate
    ? selectedEstimate.estimateid
    : selectedChangeOrder.changeorderid;

  const fetchEstimateCloseout = useFetchEstimateCloseout;
  const fetchChangeOrderCloseout = useFetchChangeOrderCloseout;

  const {
    data: closeoutData,
    isLoading: isCloseoutLoading,
    refetch: refetchCloseout,
  } = isEstimate
    ? fetchEstimateCloseout(selectedId)
    : fetchChangeOrderCloseout(selectedId);

  const { mutate: createOrUpdateEstimateCloseout } =
    useCreateEstimateCloseoutMutation();
  const { mutate: createOrUpdateChangeOrderCloseout } =
    useCreateChangeOrderCloseoutMutation();

  const [value, setValue] = useState(0);
  const [isTotalPricingLoading, setIsTotalPricingLoading] = useState(false);

  const isLoading = isCloseoutLoading || isAssemblyLoading;

  useEffect(() => {
    if (isLoading || !assemblyStats || assemblyStats.length === 0) return;

    // Aggregate items
    const aggregatedItems = c.aggregateItemQuantities(assemblyStats);

    const existingCloseoutMap = new Map<string, ExtensionType.AsObject>(
      closeoutData?.extensionList?.map((existingCloseoutItem) => [
        existingCloseoutItem.name,
        existingCloseoutItem,
      ]) ?? []
    );

    const filteredItems: ExtensionType.AsObject[] = aggregatedItems.map(
      (item) => {
        const itemName =
          item.itemqtyformulasList[0]?.attributesList[0]?.attributevalue +
          " - " +
          item.itemname;

        const existingCloseoutItem = existingCloseoutMap.get(itemName);

        c.materialCostPerUnit(item);

        return {
          name: item.itemqtyformulasList[0]?.isquoted
            ? item.itemqtyformulasList[0]?.assemblyname
            : itemName,
          isquoted: item.itemqtyformulasList[0]?.isquoted || false,
          attributegroupname:
            item.itemqtyformulasList[0]?.attributesList[0]
              ?.attributegroupname || "",
          itemqty: (item as any).calculatedQuantity,
          itemuom: item.costsList[0]?.uomname || "EA",
          itemuomvalue: item.costsList[0]?.uomvalue || 0,
          itemunitcost: existingCloseoutItem?.overrideunitcost
            ? existingCloseoutItem.itemunitcost
            : item.costsList[0]?.itemunitcost || 0,
          overrideunitcost: existingCloseoutItem?.overrideunitcost ?? false,
          itemtotalcost: existingCloseoutItem?.overrideunitcost
            ? c.calulateTotalMaterialCost(
                c.materialCostPerUnit(undefined, existingCloseoutItem),
                (item as any).calculatedQuantity
              )
            : c.calulateTotalMaterialCost(
                c.materialCostPerUnit(item, undefined),
                (item as any).calculatedQuantity
              ),
          hourlyproductionrate: existingCloseoutItem?.overrideproductionrate
            ? existingCloseoutItem.hourlyproductionrate
            : item.costsList[0]?.hourlyproductionrate || 0,
          overrideproductionrate:
            existingCloseoutItem?.overrideproductionrate ?? false,
          totallaborhours: existingCloseoutItem?.overrideproductionrate
            ? c.calculateTotalLaborHours(
                (item as any).calculatedQuantity,
                undefined,
                existingCloseoutItem
              )
            : c.calculateTotalLaborHours(
                (item as any).calculatedQuantity,
                item,
                undefined
              ),
          totalcost: existingCloseoutItem?.overrideunitcost
            ? c.calulateTotalMaterialCost(
                c.materialCostPerUnit(undefined, existingCloseoutItem),
                (item as any).calculatedQuantity
              )
            : c.calulateTotalMaterialCost(
                c.materialCostPerUnit(item, undefined),
                (item as any).calculatedQuantity
              ),
          totalQuantity: (item as any).calculatedQuantity,
          quotegroup: item.itemqtyformulasList?.[0].quotegroup,
        };
      }
    );

    const orderedItems = orderExtension(filteredItems);
    const quotedItems = orderedItems.filter((item) => item.isquoted);

    interface ExistingItemData {
      itemcost: number;
      // store other fields if needed
    }

    // --- NEW PART: Build a map of old quotes keyed by quotegroup ---
    const existingQuotesByGroup = new Map<string, QuoteType.AsObject>();
    (closeoutData?.quoteexpenseList ?? []).forEach((oldQuote) => {
      existingQuotesByGroup.set(oldQuote.quotegroup, oldQuote);
    });

    // Build a map for items keyed by itemname to restore itemcost
    const existingItemsByName = new Map<string, ExistingItemData>();
    (closeoutData?.quoteexpenseList ?? []).forEach((quote) => {
      quote.quoteitemsList.forEach((itm) => {
        existingItemsByName.set(itm.itemname, { itemcost: itm.itemcost });
      });
    });

    // Build `newQuotes` from `quotedItems`
    const newQuotes: QuoteType.AsObject[] = quotedItems.map((item) => {
      const existingItem = existingItemsByName.get(item.name);
      const groupName = item.quotegroup || "Default Group";
      const oldQuote = existingQuotesByGroup.get(groupName);

      // If oldQuote exists, preserve quotedcost and adjustedpercent
      const quotedcost = oldQuote
        ? oldQuote.quotedcost
        : item.itemunitcost * item.itemqty;
      const adjustedpercent = oldQuote ? oldQuote.adjustedpercent : 0;
      const totalcost = oldQuote ? oldQuote.totalcost : item.totalcost;

      return {
        quotegroup: groupName,
        quotedcost,
        adjustedpercent,
        totalcost,
        quoteitemsList: [
          {
            itemname: item.name,
            itemqty: item.itemqty,
            itemcost: existingItem ? existingItem.itemcost : item.itemunitcost,
          },
        ],
      };
    });

    const quoteGroupMap = new Map<string, QuoteType.AsObject>();

    newQuotes.forEach((newQuote) => {
      if (quoteGroupMap.has(newQuote.quotegroup)) {
        const existingQuote = quoteGroupMap.get(newQuote.quotegroup)!;
        // Merge items
        const itemMap = new Map(
          existingQuote.quoteitemsList.map((i) => [i.itemname, i])
        );
        newQuote.quoteitemsList.forEach((newItem) => {
          itemMap.set(newItem.itemname, newItem);
        });
        existingQuote.quoteitemsList = Array.from(itemMap.values());

        // Since we want to preserve old quotedcost and adjustedpercent,
        // do not overwrite them here with new defaults. They are already preserved.
        // If you need to recalc totalcost, do so carefully.
        // existingQuote.totalcost = recalc if needed
      } else {
        quoteGroupMap.set(newQuote.quotegroup, newQuote);
      }
    });

    const updatedQuoteExpense = Array.from(quoteGroupMap.values());

    setIncludedExtentionTypes(orderedItems);
    setIncludedDirectLaborTypes(closeoutData?.directlaborList ?? []);
    setIncludedIncidentalLaborTypes(closeoutData?.incidentallaborList ?? []);
    setIncludedLaborFactoringTypes(closeoutData?.laborfactoringList ?? []);
    setIncludedIndirectLaborTypes(closeoutData?.indirectlaborList ?? []);
    setIncludedEquipmentTypes(closeoutData?.equipmentexpenseList ?? []);
    setIncludedGenExpenseTypes(closeoutData?.generalexpenseList ?? []);
    setIncludedSubcontractTypes(closeoutData?.subcontractexpenseList ?? []);
    setIncludedQuoteTypes(updatedQuoteExpense ?? []);
    setIncludedTotalPricingTypes(
      closeoutData?.totalfinalprice ?? totalPricingDefaults
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assemblyStats,
    isLoading,
    closeoutData,
    setIncludedExtentionTypes,
    setIncludedDirectLaborTypes,
    setIncludedIncidentalLaborTypes,
    setIncludedLaborFactoringTypes,
    setIncludedIndirectLaborTypes,
    setIncludedEquipmentTypes,
    setIncludedGenExpenseTypes,
    setIncludedSubcontractTypes,
    setIncludedQuoteTypes,
    setIncludedTotalPricingTypes,
  ]);

  useEffect(() => {
    const updateAndRefetch = async (data: any) => {
      return new Promise<void>((resolve) => {
        if (isEstimate) {
          createOrUpdateEstimateCloseout(data, {
            onSuccess: async () => {
              resolve();
            },
            onError: (error) => {
              console.error("Error updating estimate closeout:", error);
              resolve();
            },
          });
        } else {
          createOrUpdateChangeOrderCloseout(data, {
            onSuccess: async () => {
              resolve();
            },
            onError: (error) => {
              console.error("Error updating change order closeout:", error);
              resolve();
            },
          });
        }
      });
    };

    const debouncedUpdate = debounce(async (data) => {
      await updateAndRefetch(data);
    }, 500);

    if (!isLoading && assemblyStats) {
      const formattedCloseoutData = formatCloseoutData(
        selectedId,
        isEstimate,
        includedExtentionTypes,
        includedDirectLaborTypes,
        includedIncidentalLaborTypes,
        includedLaborFactoringTypes,
        includedIndirectLaborTypes,
        includedEquipmentTypes,
        includedGenExpenseTypes,
        includedSubcontractTypes,
        includedQuoteTypes,
        includedTotalPricingTypes
      );
      debouncedUpdate(formattedCloseoutData);
    }

    return () => {
      debouncedUpdate.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoading,
    selectedId,
    assemblyStats,
    includedExtentionTypes,
    includedDirectLaborTypes,
    includedIncidentalLaborTypes,
    includedLaborFactoringTypes,
    includedIndirectLaborTypes,
    includedEquipmentTypes,
    includedGenExpenseTypes,
    includedSubcontractTypes,
    includedQuoteTypes,
    includedTotalPricingTypes,
    createOrUpdateEstimateCloseout,
    refetchCloseout,
  ]);

  useEffect(() => {
    if (!closeoutData) return;
    const updatedEstimateCloseout = {
      ...closeoutData,
      includedExtentionTypes,
      includedDirectLaborTypes,
      includedIncidentalLaborTypes,
      includedLaborFactoringTypes,
      includedIndirectLaborTypes,
      includedEquipmentTypes,
      includedGenExpenseTypes,
      includedSubcontractTypes,
      includedQuoteTypes,
      includedTotalPricingTypes,
    };
    recalculateTotalPricing(
      updatedEstimateCloseout,
      setIncludedTotalPricingTypes
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeoutData, setIncludedTotalPricingTypes]);

  const handleChange = async (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setValue(newValue);
    if (newValue === 9) {
      setIsTotalPricingLoading(true);
      await refetchCloseout();
      setIsTotalPricingLoading(false);
    }
  };

  return (
    <div
      style={{
        height: "100%",
        backgroundColor: userBackgroundColor,
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Extension" {...a11yProps(0)} />
          <Tab label="Direct Labor" {...a11yProps(1)} />
          <Tab label="Labor Factoring" {...a11yProps(2)} />
          <Tab label="Incidental Labor" {...a11yProps(3)} />
          <Tab label="Indirect Labor" {...a11yProps(4)} />
          <Tab label="Equipment" {...a11yProps(5)} />
          <Tab label="General Expenses" {...a11yProps(6)} />
          <Tab label="Subcontracts" {...a11yProps(7)} />
          <Tab label="Quotes" {...a11yProps(8)} />
          <Tab label="Total Pricing" {...a11yProps(9)} />
          {/* <Tab label="Bid Metrics" {...a11yProps(10)} />
          <Tab label="Schedule of Values" {...a11yProps(11)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {!closeoutData && isLoading ? (
          <CircularProgress />
        ) : (
          <ExtentionHeader
            estimateCloseout={closeoutData as EstimateCloseout.AsObject}
            extention={includedExtentionTypes}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {isLoading || isTotalPricingLoading ? (
          <CircularProgress />
        ) : (
          <DirectLaborHeader
            estimateCloseout={closeoutData as EstimateCloseout.AsObject}
            labor={includedDirectLaborTypes}
            laborDefaults={directLaborDefaults}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {isLoading || isTotalPricingLoading ? (
          <CircularProgress />
        ) : (
          <LaborFactoringHeader
            labor={includedLaborFactoringTypes}
            laborDefaults={laborFactoringDefaults}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        {isLoading || isTotalPricingLoading ? (
          <CircularProgress />
        ) : (
          <IncidentalLaborHeader
            labor={includedIncidentalLaborTypes}
            laborDefaults={incidentalLaborDefaults}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        {isLoading || isTotalPricingLoading ? (
          <CircularProgress />
        ) : (
          <IndirectLaborHeader
            labor={includedIndirectLaborTypes}
            laborDefaults={indirectLaborDefaults}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        {isLoading || isTotalPricingLoading ? (
          <CircularProgress />
        ) : (
          <EquipmentHeader
            equipmentExpenses={includedEquipmentTypes}
            equipmentExpensesDefaults={equipmentDefaults}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        {isLoading || isTotalPricingLoading ? (
          <CircularProgress />
        ) : (
          <GeneralExpenseHeader
            generalExpenses={includedGenExpenseTypes}
            generalExpensesDefaults={generalExpenseDefaults}
            assemblyStats={assemblyStats as TakeoffStats.AsObject[]}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={7}>
        {isLoading || isTotalPricingLoading ? (
          <CircularProgress />
        ) : (
          <SubcontractHeader
            subcontractExpenses={includedSubcontractTypes}
            subcontractExpensesDefaults={subcontractExpenseDefaults}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={8}>
        {isLoading || isTotalPricingLoading ? (
          <CircularProgress />
        ) : (
          <QuoteHeader quoteExpenses={includedQuoteTypes} />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={9}>
        {isLoading || isTotalPricingLoading ? (
          <CircularProgress />
        ) : (
          <TotalPricingHeader />
        )}
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={10}>
        {isLoading ? <CircularProgress /> : <BidMetrics />}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={11}>
        {isLoading ? <CircularProgress /> : <ScheduleOfValues />}
      </CustomTabPanel> */}
    </div>
  );
};
