import { useMutation, useQueryClient } from "react-query";
import { CloseoutService as cs } from "../api/CloseoutService";
import { CreateOrUpdateChangeOrderCloseoutRequest } from "../api/protosCompiled/changeOrderCloseout/changeOrderCloseout_pb";
import { CreateOrUpdateEstimateCloseoutRequest } from "../api/protosCompiled/estimateCloseout/estimateCloseout_pb";

export const useCreateEstimateCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: CreateOrUpdateEstimateCloseoutRequest.AsObject) =>
      cs.createOrUpdateEstimateCloseout(data),
    {
      onSuccess: (newData) => {
        queryClient.setQueryData(
          ["estimateCloseout", newData.estimateid],
          (oldData: any) => {
            if (JSON.stringify(oldData) !== JSON.stringify(newData)) {
              return {
                ...oldData,
                ...newData,
              };
            }
            return oldData;
          }
        );
      },

      onError: (error) => {
        console.error("Error creating estimateCloseout", error);
      },
    }
  );
};

export const useCreateChangeOrderCloseoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: CreateOrUpdateChangeOrderCloseoutRequest.AsObject) =>
      cs.createOrUpdateChangeOrderCloseout(data),
    {
      onSuccess: (newData) => {
        queryClient.setQueryData(
          ["changeOrderCloseout", newData.changeorderid],
          (oldData: any) => {
            if (JSON.stringify(oldData) !== JSON.stringify(newData)) {
              return {
                ...oldData,
                ...newData,
              };
            }
            return oldData;
          }
        );
      },

      onError: (error) => {
        console.error("Error creating changeOrderCloseout", error);
      },
    }
  );
};
