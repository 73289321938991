import React, { useState } from "react";

interface FolderIconProps {
  isOpen: boolean;
}

export const FolderIcon: React.FC<FolderIconProps> = ({ isOpen }) => {
  return (
    <>
      {isOpen ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="black"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-folder"
        >
          {/* Bottom part of the open folder */}
          <path
            d="M3 11v10a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V11H3z"
            fill="#F3E2A9" // Manilla-like color for the folder body
          ></path>
          {/* Top flap of the open folder */}
          <path
            d="M3 6h5l2 3h11v2H3z"
            fill="#F3E2A9" // Same manilla-like color for the folder flap
          ></path>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="black"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-folder"
        >
          {/* Closed folder base */}
          <path
            d="M3 4h5l2 3h11v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4z"
            fill="#F3E2A9" // Manilla-like color for the closed folder
          ></path>
        </svg>
      )}
    </>
  );
};
export const FolderRow = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFolder = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      onClick={toggleFolder}
      style={{ display: "flex", alignItems: "center" }}
    >
      <FolderIcon isOpen={isOpen} />
      <span style={{ marginLeft: "8px" }}>Folder Name</span>
    </div>
  );
};
