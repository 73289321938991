import { clients } from "../clients/grpcClients";
import {
  CreateEstimateAssemblyRequest,
  CreateEstimateAssemblyResponse,
  EstimateAssembly,
  UpdateEstimateAssemblyRequest,
  UpdateEstimateAssemblyResponse,
  GetTakeoffStatsResponse,
  GetTakeoffStatsRequest,
  EstimatePoint,
  EstimateItemQtyFormula,
  EstimateAssemblyItemAttribute,
  GetEstimateAssembliesByEstimateIdRequest,
  GetEstimateAssembliesByEstimateIdResponse,
} from "./protosCompiled/estimateAssembly/estimateAssembly_pb";
import { Point } from "../types/Point";
import { useUnityBuildStore } from "../states/store";

export class ProjectEstimateAssemblyService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  private static convertToEstimatePoints = (
    pointsList: Point[]
  ): EstimatePoint[] => {
    return pointsList.map((point) => {
      const estimatePoint = new EstimatePoint();
      estimatePoint.setPointid(point.pointid || "");
      estimatePoint.setX(point.x || 0);
      estimatePoint.setY(point.y || 0);
      estimatePoint.setAngle(point.angle || 0);
      estimatePoint.setVerticallength(point.verticallength || 0);
      estimatePoint.setAllowtrailingline(point.allowtrailingline || false);
      estimatePoint.setAllowtrailingmeasurement(
        point.allowtrailingmeasurement || false
      );
      estimatePoint.setIsincludedincount(point.isincludedincount || false);
      estimatePoint.setIsselected(point.isselected || false);
      estimatePoint.setIsactive(point.isactive || false);
      return estimatePoint;
    });
  };

  private static calculateSegmentCount = (
    assembly: EstimateAssembly.AsObject
  ) => {
    if (
      assembly.assemblymeasurementtype === "length" ||
      assembly.assemblymeasurementtype === "area"
    ) {
      return assembly.pointsList.length - 1;
    } else {
      return 0;
    }
  };

  private static buildEstimateAssemblyMessage(
    assembly: EstimateAssembly.AsObject
  ): EstimateAssembly {
    const estimateAssemblyMessage = new EstimateAssembly();
    estimateAssemblyMessage.setTakeoffid(assembly.takeoffid || "");
    estimateAssemblyMessage.setProjectid(assembly.projectid || "");
    estimateAssemblyMessage.setEstimateid(assembly.estimateid || "");
    estimateAssemblyMessage.setObjectid(assembly.objectid || "");
    estimateAssemblyMessage.setSegmentlength(assembly.segmentlength || 0);
    estimateAssemblyMessage.setSegmentcount(
      this.calculateSegmentCount(assembly)
    );
    estimateAssemblyMessage.setSqft(assembly.sqft || 0);

    const pointsList = this.convertToEstimatePoints(assembly.pointsList || []);
    estimateAssemblyMessage.setPointsList(pointsList);
    estimateAssemblyMessage.setPointcount(pointsList.length);

    estimateAssemblyMessage.setPointtype(assembly.pointtype || "");
    estimateAssemblyMessage.setPointsize(assembly.pointsize || 0);
    estimateAssemblyMessage.setPointbordercolor(
      assembly.pointbordercolor || ""
    );
    estimateAssemblyMessage.setPointfillcolor(assembly.pointfillcolor || "");
    estimateAssemblyMessage.setLinesize(assembly.linesize || 0);
    estimateAssemblyMessage.setLinecolor(assembly.linecolor || "");
    estimateAssemblyMessage.setLinedasharray(assembly.linedasharray || "");
    estimateAssemblyMessage.setUserscale(assembly.userscale || 0);
    estimateAssemblyMessage.setPagenumber(assembly.pagenumber || 1);
    estimateAssemblyMessage.setAssemblyname(assembly.assemblyname || "");
    estimateAssemblyMessage.setAssemblymeasurementtype(
      assembly.assemblymeasurementtype || ""
    );
    estimateAssemblyMessage.setImageclassificationid(
      assembly.imageclassificationid || 0
    );

    if (assembly.itemqtyformulasList) {
      const itemQtyFormulas = assembly.itemqtyformulasList.map((formula) => {
        const estimateItemQtyFormula = new EstimateItemQtyFormula();
        estimateItemQtyFormula.setItemid(formula.itemid || "");
        estimateItemQtyFormula.setItemname(formula.itemname || "");
        estimateItemQtyFormula.setItemqty(formula.itemqty || 0);
        estimateItemQtyFormula.setForevery(formula.forevery || 0);
        estimateItemQtyFormula.setTakeoffvariabletype(
          formula.takeoffvariabletype || ""
        );
        estimateItemQtyFormula.setAnd(formula.and || false);
        estimateItemQtyFormula.setOr(formula.or || false);
        estimateItemQtyFormula.setIsquoted(formula.isquoted || false);
        estimateItemQtyFormula.setAssemblyname(formula.assemblyname || "");
        estimateItemQtyFormula.setQuotegroup(formula.quotegroup || "");

        if (formula.attributesList) {
          const attributes = formula.attributesList.map((attr) => {
            const attribute = new EstimateAssemblyItemAttribute();
            attribute.setAttributegroupid(attr.attributegroupid || 0);
            attribute.setAttributevalueid(attr.attributevalueid || 0);
            attribute.setAttributevalue(attr.attributevalue || "");
            attribute.setAttributegroupname(attr.attributegroupname || "");
            return attribute;
          });
          estimateItemQtyFormula.setAttributesList(attributes);
        }
        return estimateItemQtyFormula;
      });
      estimateAssemblyMessage.setItemqtyformulasList(itemQtyFormulas);
    }

    return estimateAssemblyMessage;
  }

  public static getProjectEstimateAssemblies = (
    estimateId: string
  ): Promise<GetEstimateAssembliesByEstimateIdResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new GetEstimateAssembliesByEstimateIdRequest();
      req.setEstimateid(estimateId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      clients.estimateAssemblyServiceClient.getEstimateAssembliesByEstimateId(
        req,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static getAssemblyStatsForEstimate = (
    estimateId: string
  ): Promise<GetTakeoffStatsResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new GetTakeoffStatsRequest();
      req.setEstimateid(estimateId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      clients.estimateAssemblyServiceClient.getTakeoffStatsByEstimateID(
        req,
        {},
        (err: any, response: any) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static createProjectEstimateAssembly = (
    assembly: EstimateAssembly.AsObject
  ): Promise<CreateEstimateAssemblyResponse.AsObject> => {
    const estimateAssemblyMessage = this.buildEstimateAssemblyMessage(assembly);

    const req = new CreateEstimateAssemblyRequest();
    req.setEstimateassembly(estimateAssemblyMessage);
    req.setSessiontoken(this.getMetadata().sessionToken);

    return new Promise((resolve, reject) => {
      clients.estimateAssemblyServiceClient.createEstimateAssembly(
        req,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static updateProjectEstimateAssembly = (
    assembly: EstimateAssembly.AsObject
  ): Promise<UpdateEstimateAssemblyResponse.AsObject> => {
    const estimateAssemblyMessage = this.buildEstimateAssemblyMessage(assembly);

    const req = new UpdateEstimateAssemblyRequest();
    req.setEstimateassembly(estimateAssemblyMessage);
    req.setSessiontoken(this.getMetadata().sessionToken);

    return new Promise((resolve, reject) => {
      clients.estimateAssemblyServiceClient.updateEstimateAssembly(
        req,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };
}
