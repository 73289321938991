import {
  CreateFolderRequest,
  CreateFolderResponse,
  DeleteFolderRequest,
  DeleteFolderResponse,
  Folder as FolderProto,
  GetFoldersRequest,
  GetFoldersResponse,
  UpdateFolderRequest,
  UpdateFolderResponse,
} from "./protosCompiled/folder/folder_pb";

import { clients } from "../clients/grpcClients";
import { useUnityBuildStore } from "../states/store";

const client = clients.folderServiceClient;

export class FolderService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  // Get the parent ID of the folder
  public static async getParentId(): Promise<GetFoldersResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetFoldersRequest();
      req.setSessiontoken(this.getMetadata().sessionToken);

      client.getFolders(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  // Create a new folder
  public static async createFolder(
    folder: FolderProto.AsObject
  ): Promise<CreateFolderResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateFolderRequest();
      req.setSessiontoken(this.getMetadata().sessionToken);

      // Create an instance of FolderProto
      const folderProto = new FolderProto();
      folderProto.setName(folder.name);
      folderProto.setParentid(folder.parentid || ""); // Convert null to an empty string
      folderProto.setIsfile(folder.isfile);
      folderProto.setLevel(folder.level);
      folderProto.setEntitytype(folder.entitytype);
      folderProto.setIsactive(folder.isactive);

      req.setFolder(folderProto); // Set the folder object

      client.createFolder(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  // Update an existing folder
  public static async updateFolder(
    folder: FolderProto.AsObject
  ): Promise<UpdateFolderResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new UpdateFolderRequest();
      req.setSessiontoken(this.getMetadata().sessionToken);
      // Create an instance of FolderProto
      const folderProto = new FolderProto();
      folderProto.setFolderid(folder.folderid);
      folderProto.setName(folder.name);
      folderProto.setParentid(folder.parentid || "");
      folderProto.setIsfile(folder.isfile);
      folderProto.setLevel(folder.level);
      folderProto.setEntitytype(folder.entitytype);
      folderProto.setIsactive(folder.isactive);

      req.setFolder(folderProto); // Set the folder object

      client.updateFolder(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  // Get folders by parent ID (optionally, only folders or files)
  public static async getFolders(): Promise<GetFoldersResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetFoldersRequest();
      req.setSessiontoken(this.getMetadata().sessionToken);

      client.getFolders(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  // Delete a folder by its ID
  public static async deleteFolder(
    folderId: string
  ): Promise<DeleteFolderResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteFolderRequest();
      req.setFolderid(folderId);
      req.setSessiontoken(this.getMetadata().sessionToken);

      client.deleteFolder(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  // Move a folder or file to a new parent folder
  // public static async moveFolder(
  //   folderId: string,
  //   newParentId: string
  // ): Promise<MoveFolderResponse.AsObject> {
  //   return new Promise((resolve, reject) => {
  //     const req = new MoveFolderRequest();
  //     req.setFolderid(folderId);
  //     req.setNewparentid(newParentId);
  //     req.setSessiontoken(this.getMetadata().sessionToken);

  //     client.moveFolder(req, {}, (err, response) => {
  //       if (err) {
  //         reject(err);
  //         return;
  //       }
  //       resolve(response.toObject());
  //     });
  //   });
  // }
}
