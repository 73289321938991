import { useQuery } from "react-query";
import { useUnityBuildStore } from "../states/store";
import { ProjectEstimateService as pes } from "../api/ProjectEstimateService";
import { useStore } from "zustand";

export function useFetchEstimates() {
  const { selectedProject } = useStore(useUnityBuildStore);

  const { data, error, isLoading } = useQuery(
    "estimates",
    async () => {
      if (selectedProject.id) {
        const response = await pes.fetchEstimates(selectedProject.id);
        return response.estimateList;
      }
      throw new Error("No valid ID available for fetching estimates");
    },
    {
      onError: (error) => {
        console.error("Error fetching estimates:", error);
      },
    }
  );
  return { data, error, isLoading };
}
