// ToolBarHelpers.ts

import { EstimateAssembly } from "../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import { UserScaleList } from "../api/protosCompiled/object/object_pb";
import { ObjectService as osc } from "../api/ObjectService";
import { ResetStates } from "../states/resetStates";
import { ChangeOrderAssembly } from "../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";

// Namespace to group toolbar-related helper functions
export namespace ToolBarHelper {
  // Extract segment length from an assembly
  export const extractSegmentLength = (
    assembly: EstimateAssembly.AsObject | ChangeOrderAssembly.AsObject
  ) => {
    const segmentLength = assembly?.segmentlength ?? 0;
    const feet = Math.floor(segmentLength);
    const inches = Math.round((segmentLength - feet) * 12);
    return { feet, inches };
  };

  // Filter available objects based on a given id
  export const filterAvailableObjects = (
    availableObjects: any[],
    id: string
  ) => {
    const selectedObject = availableObjects.find((object) => object.id === id);
    return selectedObject;
  };

  // Handle object selection from the dropdown
  export const handleGetObject = async (
    e: any,
    availableObjects: any[],
    setInputPage: (page: number) => void,
    setSelectedObjectId: (id: string) => void,
    setSelectedObjectName: (name: string) => void,
    setUserScaleList: (scales: UserScaleList.AsObject[]) => void,
    setUserScale: (scale: number) => void,
    numPages: number,
    currentPageIndex: number,
    currentObjectRef: React.MutableRefObject<number>
  ) => {
    const [id, gcsObjectName] = e.target.value.split(",");
    setInputPage(1);
    setSelectedObjectId(id);
    setSelectedObjectName(gcsObjectName);

    if (availableObjects) {
      const selectedObject = filterAvailableObjects(availableObjects, id);

      if (selectedObject) {
        if (selectedObject.userScale.length === 0) {
          const initialScales: UserScaleList.AsObject[] = Array(numPages)
            .fill(null)
            .map((_, i) => ({ pagenumber: i + 1, scale: 0 }));
          setUserScaleList(initialScales);
          await osc.updateObjectScale(id, initialScales);
        } else {
          setUserScaleList(selectedObject.userScale || []);
        }
        currentObjectRef.current =
          availableObjects.indexOf(selectedObject) || 0;

        const scale = selectedObject.userScale[currentPageIndex]?.scale ?? 0;
        setUserScale(scale);
      } else {
        console.error("Selected object not found");
      }
    }
  };

  // Handle page change
  export const handleSetPage = (
    e: any,
    numPages: number,
    setInputPage: (page: number) => void,
    setCurrentPage: (page: number) => void,
    setUserScale: (scale: number) => void,
    availableObjects: any[],
    selectedObjectId: string
  ) => {
    const page = parseInt(e.target.value, 10);
    setInputPage(page);
    if (!isNaN(page) && page >= 1 && page <= numPages) {
      setCurrentPage(page);

      if (availableObjects) {
        const currentObject = availableObjects.find(
          (obj) => obj.id === selectedObjectId
        );
        const scale = currentObject?.userScale[page - 1]?.scale ?? 0;
        setUserScale(scale);
      }
    }
  };

  // Navigate to the next page
  export const handleNextPage = (
    currentPage: number,
    numPages: number,
    setCurrentPage: (page: number) => void,
    setInputPage: (page: number) => void,
    setUserScale: (scale: number) => void,
    availableObjects: any[],
    selectedObjectId: string,
    setInferenceItemFilter: (value: string) => void,
    store: any,
    store1: any
  ) => {
    if (currentPage < numPages) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      setInputPage(nextPage);

      if (availableObjects) {
        const currentObject = availableObjects.find(
          (obj) => obj.id === selectedObjectId
        );
        const scale = currentObject?.userScale[nextPage - 1]?.scale ?? 0;
        setUserScale(scale);
      }
      setInferenceItemFilter("");
      ResetStates.resetTakeoffStates(store, store1);
    }
  };

  // Navigate to the previous page
  export const handlePrevPage = (
    currentPage: number,
    setCurrentPage: (page: number) => void,
    setInputPage: (page: number) => void,
    setUserScale: (scale: number) => void,
    availableObjects: any[],
    selectedObjectId: string,
    setInferenceItemFilter: (value: string) => void,
    store: any,
    store1: any
  ) => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
      setInputPage(prevPage);

      if (availableObjects) {
        const currentObject = availableObjects.find(
          (obj) => obj.id === selectedObjectId
        );
        const scale = currentObject?.userScale[prevPage - 1]?.scale ?? 0;
        setUserScale(scale);
      }
      setInferenceItemFilter("");
      ResetStates.resetTakeoffStates(store, store1);
    }
  };

  // Handle setting scale for a page
  export const handleSetScale = async (
    e: React.ChangeEvent<HTMLSelectElement>,
    pagenumber: number,
    setUserScale: (scale: number) => void,
    setIsCreatingScale: (isCreating: boolean) => void,
    setUserScaleList: (scales: UserScaleList.AsObject[]) => void,
    selectedObjectId: string,
    userScaleList: UserScaleList.AsObject[],
    refetchObjects: () => void
  ) => {
    if (e.target.value === "Custom Scale") {
      setIsCreatingScale(true);
      return;
    }
    setIsCreatingScale(false);
    const userScale = parseFloat(e.target.value);
    setUserScale(userScale);

    // Update the scale list for the current page, ensuring the page number is consistent
    let scaleList: UserScaleList.AsObject[] = [
      ...userScaleList.slice(0, pagenumber - 1),
      { pagenumber, scale: userScale },
      ...userScaleList.slice(pagenumber),
    ];
    setUserScaleList(scaleList);

    // Persist the updated scale list
    await osc.updateObjectScale(selectedObjectId, scaleList);
    refetchObjects();
  };
}
