import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";

// Terms of Service Dialog Component
export const TermsOfServiceDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => (
  <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
    <DialogTitle>Terms of Service</DialogTitle>
    <DialogContent dividers>
      <Typography variant="subtitle2" gutterBottom>
        Last Updated: November 30, 2024
      </Typography>

      <Typography variant="body1" paragraph>
        Welcome to Unity-Build-Estimation, a service provided by Lumpy Studio
        LLC ("Company," "we," "us," or "our"). By accessing or using our
        construction estimation software ("Service"), you agree to be bound by
        these Terms of Service ("Terms"). If you do not agree to these Terms,
        please do not use our Service.
      </Typography>

      {/* Section 1 */}
      <Typography variant="h6" gutterBottom>
        1. Acceptance of Terms
      </Typography>
      <Typography variant="body1" paragraph>
        By creating an account or using the Service, you acknowledge that you
        have read, understood, and agree to be bound by these Terms and our
        Privacy Policy.
      </Typography>

      {/* Section 2 */}
      <Typography variant="h6" gutterBottom>
        2. Eligibility
      </Typography>
      <Typography variant="body1" paragraph>
        You must be at least 18 years old to use our Service. By using the
        Service, you represent and warrant that you meet this eligibility
        requirement.
      </Typography>

      {/* Continue similarly for all sections */}

      {/* Section 3 */}
      <Typography variant="h6" gutterBottom>
        3. License Grant
      </Typography>
      <Typography variant="body1" paragraph>
        We grant you a limited, non-exclusive, non-transferable, and revocable
        license to access and use the Service strictly in accordance with these
        Terms.
      </Typography>

      {/* Section 4 */}
      <Typography variant="h6" gutterBottom>
        4. User Responsibilities
      </Typography>
      <Typography variant="body1" component="div">
        <ul>
          <li>
            <strong>Accuracy of Information:</strong> You are solely responsible
            for the accuracy and completeness of the data you input into the
            Service.
          </li>
          <li>
            <strong>Compliance with Laws:</strong> You agree to use the Service
            in compliance with all applicable laws and regulations.
          </li>
          <li>
            <strong>Verification of Estimates:</strong> You acknowledge that the
            Service is a tool to assist in your estimation process, but
            verification of accuracy is{" "}
            <strong>100% your responsibility</strong>.
          </li>
        </ul>
      </Typography>

      {/* Section 5 */}
      <Typography variant="h6" gutterBottom>
        5. Disclaimer of Warranties
      </Typography>
      <Typography variant="body1" component="div">
        <ul>
          <li>
            <strong>No Guarantee of Accuracy:</strong> The Company does not
            guarantee the accuracy, reliability, or completeness of any
            estimations or calculations provided by the Service.
          </li>
          <li>
            <strong>Use at Your Own Risk:</strong> Your use of the Service is at
            your own risk. The Service is provided on an "AS IS" and "AS
            AVAILABLE" basis.
          </li>
        </ul>
      </Typography>

      {/* Section 6 */}
      <Typography variant="h6" gutterBottom>
        6. Limitation of Liability
      </Typography>
      <Typography variant="body1" component="div">
        <ul>
          <li>
            <strong>No Liability for Damages:</strong> To the fullest extent
            permitted by law, the Company shall not be liable for any direct,
            indirect, incidental, special, consequential, or exemplary damages
            arising from your use of the Service.
          </li>
          <li>
            <strong>Total Liability:</strong> In no event shall the Company's
            total liability to you exceed the amount paid by you, if any, for
            accessing the Service.
          </li>
        </ul>
      </Typography>

      {/* Continue similarly for Sections 7 to 16 */}

      {/* Section 7 */}
      <Typography variant="h6" gutterBottom>
        7. Indemnification
      </Typography>
      <Typography variant="body1" paragraph>
        You agree to indemnify, defend, and hold harmless the Company, its
        affiliates, officers, directors, employees, agents, and licensors from
        any claims, liabilities, damages, losses, or expenses arising from your
        use of the Service or violation of these Terms.
      </Typography>

      {/* Section 8 */}
      <Typography variant="h6" gutterBottom>
        8. Intellectual Property Rights
      </Typography>
      <Typography variant="body1" component="div">
        <ul>
          <li>
            <strong>Ownership:</strong> All content, features, and functionality
            of the Service are the exclusive property of the Company and are
            protected by intellectual property laws.
          </li>
          <li>
            <strong>Restrictions:</strong> You may not reproduce, distribute,
            modify, or create derivative works of any part of the Service
            without our prior written consent.
          </li>
        </ul>
      </Typography>

      {/* Section 9 */}
      <Typography variant="h6" gutterBottom>
        9. Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        Your use of the Service is also governed by our Privacy Policy, which is
        incorporated into these Terms by reference.
      </Typography>

      {/* Section 9.1 */}
      <Typography variant="subtitle1" gutterBottom>
        9.1 Data Usage
      </Typography>
      <Typography variant="body1" component="div">
        <ul>
          <li>
            <strong>No Sale of Personal Data:</strong> We will{" "}
            <strong>never sell</strong> your personal data or disclose it to
            third parties without your consent.
          </li>
          <li>
            <strong>Product Enhancement:</strong> We may use your data
            internally to enhance and improve our products and services.
          </li>
        </ul>
      </Typography>

      {/* Continue with Sections 10 to 16 in a similar fashion */}

      {/* Section 10 */}
      <Typography variant="h6" gutterBottom>
        10. Termination
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to suspend or terminate your access to the Service
        at our sole discretion, without notice, for conduct that we believe
        violates these Terms or is harmful to other users or the Company.
      </Typography>

      {/* Section 11 */}
      <Typography variant="h6" gutterBottom>
        11. Modifications to Service and Terms
      </Typography>
      <Typography variant="body1" component="div">
        <ul>
          <li>
            <strong>Service Changes:</strong> We reserve the right to modify or
            discontinue the Service at any time without notice.
          </li>
          <li>
            <strong>Terms Updates:</strong> We may update these Terms from time
            to time. Continued use of the Service after changes constitutes
            acceptance of the new Terms.
          </li>
        </ul>
      </Typography>

      {/* Section 12 */}
      <Typography variant="h6" gutterBottom>
        12. Governing Law
      </Typography>
      <Typography variant="body1" paragraph>
        These Terms shall be governed by and construed in accordance with the
        laws of the State of Florida, without regard to its conflict of law
        provisions.
      </Typography>

      {/* Section 13 */}
      <Typography variant="h6" gutterBottom>
        13. Dispute Resolution
      </Typography>
      <Typography variant="body1" paragraph>
        Any disputes arising out of or relating to these Terms or the Service
        shall be resolved through binding arbitration in accordance with the
        rules of the American Arbitration Association.
      </Typography>

      {/* Section 14 */}
      <Typography variant="h6" gutterBottom>
        14. Severability
      </Typography>
      <Typography variant="body1" paragraph>
        If any provision of these Terms is found to be unenforceable or invalid,
        the remaining provisions shall remain in full force and effect.
      </Typography>

      {/* Section 15 */}
      <Typography variant="h6" gutterBottom>
        15. Entire Agreement
      </Typography>
      <Typography variant="body1" paragraph>
        These Terms, along with the Privacy Policy, constitute the entire
        agreement between you and the Company regarding the use of the Service.
      </Typography>

      {/* Section 16 */}
      <Typography variant="h6" gutterBottom>
        16. Contact Information
      </Typography>
      <Typography variant="body1" component="div">
        If you have any questions about these Terms, please contact us at:
        <ul>
          <li>
            <strong>Email:</strong> support@unity-build-estimation.com
          </li>
          <li>
            <strong>Address:</strong> Lumpy Studio LLC, [Your Address]
          </li>
        </ul>
      </Typography>

      <Typography variant="body1" paragraph>
        **By using the Service, you acknowledge that you have read, understood,
        and agree to be bound by these Terms of Service.**
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);
