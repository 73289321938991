export class BaseAPIService {
  protected static async makeBackendCall(
    endpoint: string,
    data: any,
    method: string
  ): Promise<any> {
    const baseURL = process.env.REACT_APP_LOCAL_HOST;
    let url = `${baseURL}/${endpoint}`;

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const fetchOptions: RequestInit = {
      method: method,
      headers: headers,
    };

    if (method === "GET" && data && Object.keys(data).length > 0) {
      const queryParams = new URLSearchParams(data);
      url += `?${queryParams}`;
    } else if (method === "POST" || method === "PUT" || method === "DELETE") {
      fetchOptions.body = JSON.stringify(data);
    }

    try {
      const response = await fetch(url, fetchOptions);

      if (response.ok) {
        const responseData = await response.json();
        return responseData;
      } else {
        console.error(`Failed to call endpoint ${endpoint}`, response.status);
        return null;
      }
    } catch (error) {
      console.error(`Error calling endpoint ${endpoint}:`, error);
      return null;
    }
  }
}
