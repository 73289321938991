import { clients } from "../clients/grpcClients";
import { useUnityBuildStore } from "../states/store";
import {
  AssociatedEstimate,
  Contract,
  CreateContractRequest,
  CreateContractResponse,
  GetContractsRequest,
  GetContractsResponse,
  UpdateContractRequest,
  UpdateContractResponse,
} from "./protosCompiled/contract/contract_pb";

const client = clients.contractServiceClient;

export class ContractService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  public static createContract = (
    data: Contract.AsObject
  ): Promise<CreateContractResponse> => {
    const contract = new Contract();
    contract.setCustomid(data.customid ?? "");
    contract.setProjectid(data.projectid ?? "");
    contract.setDatecreated(data.datecreated ?? "");
    contract.setLastupdated(data.lastupdated ?? "");
    contract.setContractname(data.contractname ?? "");
    contract.setContractdescription(data.contractdescription ?? "");

    // Map the associatedEstimates from AsObject to the Protobuf object
    const associatedEstimates = data.associatedestimatesList.map((estimate) => {
      const associatedEstimate = new AssociatedEstimate();
      associatedEstimate.setEstimateid(estimate.estimateid ?? "");
      associatedEstimate.setContractid(estimate.contractid ?? "");
      return associatedEstimate;
    });

    contract.setAssociatedestimatesList(associatedEstimates);

    const req = new CreateContractRequest();
    req.setSessiontoken(this.getMetadata().sessionToken);
    req.setContract(contract);

    return new Promise((resolve, reject) => {
      client.createContract(req, {}, (err: any, response: any) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  };

  public static fetchContracts = (
    projectId: string
  ): Promise<GetContractsResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const request = new GetContractsRequest();
      request.setProjectid(projectId);
      request.setSessiontoken(this.getMetadata().sessionToken);
      client.getContracts(request, {}, (err: any, response: any) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  };

  public static updateContract = (
    data: Contract.AsObject
  ): Promise<UpdateContractResponse.AsObject> => {
    const contract = new Contract();
    contract.setContractid(data.contractid ?? "");
    contract.setCustomid(data.customid ?? "");
    contract.setProjectid(data.projectid ?? "");
    contract.setDatecreated(data.datecreated ?? "");
    contract.setLastupdated(data.lastupdated ?? "");
    contract.setContractname(data.contractname ?? "");
    contract.setContractdescription(data.contractdescription ?? "");

    // Map the associatedEstimates from AsObject to the Protobuf object
    const associatedEstimates = data.associatedestimatesList.map((estimate) => {
      const associatedEstimate = new AssociatedEstimate();
      associatedEstimate.setEstimateid(estimate.estimateid ?? "");
      associatedEstimate.setContractid(estimate.contractid ?? "");
      return associatedEstimate;
    });

    contract.setAssociatedestimatesList(associatedEstimates);

    const req = new UpdateContractRequest();
    req.setSessiontoken(this.getMetadata().sessionToken || "");
    req.setContract(contract);

    return new Promise((resolve, reject) => {
      client.updateContract(req, {}, (err: any, response: any) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  };
}
