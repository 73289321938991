import React, { useEffect } from "react";
import { useUnityBuildStore } from "../../states/store";
import { SVGCursorProps } from "../../types/SVGCursorProps";
import { Calculations } from "../../utils/calculations";
import { useStore } from "zustand";

export const SVGMeasurement: React.FC<SVGCursorProps> = ({
  cursorPositionRef,
}) => {
  const { measuredPoints, userScale, isMeasuring } =
    useStore(useUnityBuildStore);

  // Generate one solid 45 degree tick at each start and end point of the measurement line beginning with the very first to the very last and every one in between
  //The line should be offset 45 degrees from the measurement line E.G: /-----------------------------/-----------------------/
  const generateTickPoints = (startPoint: any, endPoint: any) => {
    const dx = endPoint.x - startPoint.x;
    const dy = endPoint.y - startPoint.y;
    const angle = Math.atan2(dy, dx);
    const tickLength = 20;
    const tickOffset = -10;
    const perpendicularAngle = angle - Math.PI / 4;

    const ticks = [
      {
        x1: startPoint.x + tickOffset * Math.cos(perpendicularAngle),
        y1: startPoint.y + tickOffset * Math.sin(perpendicularAngle),
        x2:
          startPoint.x +
          (tickOffset + tickLength) * Math.cos(perpendicularAngle),
        y2:
          startPoint.y +
          (tickOffset + tickLength) * Math.sin(perpendicularAngle),
      },
      {
        x1: endPoint.x + tickOffset * Math.cos(perpendicularAngle),
        y1: endPoint.y + tickOffset * Math.sin(perpendicularAngle),
        x2:
          endPoint.x + (tickOffset + tickLength) * Math.cos(perpendicularAngle),
        y2:
          endPoint.y + (tickOffset + tickLength) * Math.sin(perpendicularAngle),
      },
    ];

    return ticks;
  };

  return (
    <g>
      {measuredPoints.map((point, i) => {
        if (i < measuredPoints.length - 1) {
          const startPoint = point;
          const endPoint = measuredPoints[i + 1];
          const pixelDistance = Calculations.calculateDistance(
            startPoint,
            endPoint
          );
          const inchDistance = pixelDistance / 72; // TODO: pixelDistance is already in inches, I think ... test this out
          const realWorldDistanceInFeet = inchDistance * userScale;
          const feet = Math.floor(realWorldDistanceInFeet);
          const inches = Math.round((realWorldDistanceInFeet - feet) * 12);

          const ticks = generateTickPoints(startPoint, endPoint);

          return (
            <React.Fragment key={i}>
              <line
                x1={startPoint.x}
                y1={startPoint.y}
                x2={endPoint.x}
                y2={endPoint.y}
                stroke="black"
                strokeWidth={2}
              />
              <text
                x={(startPoint.x + endPoint.x) / 2}
                y={(startPoint.y + endPoint.y) / 2 - 5}
                fill="black"
                fontSize="12"
                textAnchor="middle"
              >
                {`${feet}' ${inches}"`}
              </text>
              {ticks.map((tick, i) => (
                <line
                  key={i}
                  x1={tick.x1}
                  y1={tick.y1}
                  x2={tick.x2}
                  y2={tick.y2}
                  stroke="black"
                  strokeWidth={2}
                />
              ))}
            </React.Fragment>
          );
        }
        return null;
      })}
      {isMeasuring && measuredPoints.length > 0 && (
        <>
          <line
            x1={measuredPoints[measuredPoints.length - 1].x}
            y1={measuredPoints[measuredPoints.length - 1].y}
            x2={cursorPositionRef.current.x}
            y2={cursorPositionRef.current.y}
            stroke="red"
            strokeWidth={2}
            className="temporary-measurement-line"
          />
          {/*add the ticks for the temporary measurement line*/}
          {generateTickPoints(
            measuredPoints[measuredPoints.length - 1],
            cursorPositionRef
          ).map((tick, i) => (
            <line
              key={i}
              x1={tick.x1}
              y1={tick.y1}
              x2={tick.x2}
              y2={tick.y2}
              stroke="red"
              strokeWidth={2}
            />
          ))}
        </>
      )}
    </g>
  );
};
