import { useState } from "react";
import MaximizeIcon from "@mui/icons-material/Maximize";
import { useUnityBuildStore } from "../../states/store";
import { RenderTakeoffList } from "./RenderTakeoffList";
import { ResetStates } from "../../states/resetStates";
import { useStore } from "zustand";
import { useCreateAssemblyStore } from "../../states/createAssemblyStore";

export function AuditTrail() {
  const store = useStore(useUnityBuildStore);
  const store1 = useStore(useCreateAssemblyStore);
  const {
    userBackgroundColor,
    showAssemblyWindow,
    selectedFilter,
    setSelectedFilter,
    setShowAssemblyWindow,
  } = useStore(useUnityBuildStore);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSetFilter = (filterType: any) => {
    setSelectedFilter(filterType);
    ResetStates.resetTakeoffStates(store, store1);
  };

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div
      className="TakeoffPalletteParentBox"
      style={{ backgroundColor: userBackgroundColor }}
    >
      {showAssemblyWindow ? (
        <button onClick={() => setShowAssemblyWindow(false)}>
          <MaximizeIcon />
        </button>
      ) : (
        <>
          <div style={{ marginBottom: "10px" }}>
            {["all", "count", "length", "area"].map((filterType) => (
              <button
                key={filterType}
                onClick={() => handleSetFilter(filterType)}
                style={{
                  color: selectedFilter === filterType ? "blue" : "black",
                  backgroundColor: "white",
                  border: "1px solid black",
                  margin: "2px",
                }}
              >
                {filterType}
              </button>
            ))}
          </div>
          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              style={{
                width: "100%",
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          </div>
          {/* Ensure scrolling within the content area */}
          <div style={{ flexGrow: 1, overflowY: "auto" }}>
            <RenderTakeoffList searchTerm={searchTerm} />
          </div>
        </>
      )}
    </div>
  );
}
