import { useEffect, useRef } from "react";
import { CompactPicker } from "react-color";
import { useUnityBuildStore } from "../../states/store";
import { useSubmitTakeoffAssembly } from "../../hooks/useSubmitTakeoffAssembly";
import { mutateSelectedAssembly } from "../../api/MutateSelectedAssembly";
import { useStore } from "zustand";

export const ColorPicker = () => {
  const {
    selectedTakeoffAssembly,
    isEditingPointFillColor,
    isEditingLineColor,
    isEditingPointBorderColor,
    backgroundColor,
    setBackgroundColor,
  } = useStore(useUnityBuildStore);

  const submitAssembly = useSubmitTakeoffAssembly();

  const currentAssemblyRef = useRef(selectedTakeoffAssembly);

  useEffect(() => {
    currentAssemblyRef.current = selectedTakeoffAssembly;
  }, [selectedTakeoffAssembly]);

  useEffect(() => {
    if (isEditingPointFillColor) {
      setBackgroundColor(
        currentAssemblyRef.current?.pointfillcolor || "#BD10E0"
      );
    } else if (isEditingPointBorderColor) {
      setBackgroundColor(
        currentAssemblyRef.current?.pointbordercolor || "#BD10E0"
      );
    } else if (isEditingLineColor) {
      setBackgroundColor(currentAssemblyRef.current?.linecolor || "#BD10E0");
    }
  }, [
    setBackgroundColor,
    isEditingPointBorderColor,
    isEditingPointFillColor,
    isEditingLineColor,
  ]);

  const handleOnChangeComplete = async (color: any) => {
    if (!currentAssemblyRef.current) {
      console.error("No assembly selected");
      return;
    }
    setBackgroundColor(color.hex);

    const updatedAssembly = mutateSelectedAssembly({
      assembly: currentAssemblyRef.current,
      color: color.hex,
      isEditingPointBorderColor,
      isEditingPointFillColor,
      isEditingLineColor,
    });

    await submitAssembly({ assembly: updatedAssembly, isUpdating: true });
  };

  return (
    <CompactPicker
      color={backgroundColor}
      onChangeComplete={handleOnChangeComplete}
      height={200}
    />
  );
};
