import { useQuery, useQueryClient } from "react-query";
import { ObjectService as objs } from "../api/ObjectService";
import { useUnityBuildStore } from "../states/store";
import { useStore } from "zustand";
import {
  GeneratePUTSignedUrlRequest,
  Object as ProtoObject,
} from "../api/protosCompiled/object/object_pb";
import { useState } from "react";
import { PDFDocument } from "pdf-lib";

export function useFetchObjects() {
  const { selectedProject } = useStore(useUnityBuildStore);
  const queryClient = useQueryClient();

  const { data, error, isLoading } = useQuery(
    ["objects", selectedProject.id],
    async () => {
      if (selectedProject.id) {
        const response = await objs.getObjectListByProjectId(
          selectedProject.id
        );
        const objects = response.objectsList.map((obj) => ({
          id: obj.id,
          project_id: obj.projectid,
          objectName: obj.objectname,
          gcsObjectName: obj.gcsobjectname,
          userScale: obj.userscalesList,
        }));
        return objects.sort((a, b) => a.objectName.localeCompare(b.objectName));
      }
      throw new Error("No valid ID available for fetching objects");
    },
    {
      enabled: !!selectedProject.id,
      onError: (error) => {
        console.error("Error fetching objects:", error);
      },
    }
  );

  const refetchObjects = () => {
    queryClient.invalidateQueries(["objects", selectedProject.id]);
  };

  return { data, error, isLoading, refetchObjects };
}

export function useHandleObjectUpload() {
  const [files, setFiles] = useState<File[]>([]);
  const { refetchObjects } = useFetchObjects();
  const { sessionToken } = useStore(useUnityBuildStore);

  const handleSingleFileUpload = async (
    file: File,
    projectId: string,
    fileNameOverride?: string
  ) => {
    const request = new GeneratePUTSignedUrlRequest();

    const protoObject = new ProtoObject();
    protoObject.setObjectname(fileNameOverride || file.name);
    protoObject.setProjectid(projectId);

    request.setMethod("PUT");
    request.setSessiontoken(sessionToken);
    request.setProjectid(projectId);
    request.setObject(protoObject);

    await objs.handlePUTGCSRequest(request, file, projectId);
  };

  const handlePDFFile = async (
    file: File,
    projectId: string,
    onProgress?: (current: number, total: number) => void
  ) => {
    const arrayBuffer = await file.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    const pageCount = pdfDoc.getPageCount();

    for (let i = 0; i < pageCount; i++) {
      const singlePagePdf = await PDFDocument.create();
      const [copiedPage] = await singlePagePdf.copyPages(pdfDoc, [i]);
      singlePagePdf.addPage(copiedPage);

      const pdfBytes = await singlePagePdf.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const pageNumber = i + 1;

      const fileName = file.name.replace(/\.pdf$/i, "");
      const pageFileName = `${fileName}_page(${pageNumber}).pdf`;
      const pageFile = new File([blob], pageFileName, {
        type: "application/pdf",
      });

      await handleSingleFileUpload(pageFile, projectId, pageFileName);

      // Update the progress for each page
      if (onProgress) {
        onProgress(pageNumber, pageCount);
      }
    }
  };

  const handleObjectUpload = async (
    projectId: string,
    onProgress?: (current: number, total: number) => void
  ) => {
    // Calculate total pages before starting
    let totalPages = 0;
    const pdfFiles = files.filter((f) => f.type === "application/pdf");
    for (const pdfFile of pdfFiles) {
      const arrayBuffer = await pdfFile.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      totalPages += pdfDoc.getPageCount();
    }

    // Track current page
    let currentPage = 0;

    // Helper to update progress
    const updateProgress = (page: number, pageCount: number) => {
      currentPage++;
      if (onProgress) {
        onProgress(currentPage, totalPages);
      }
    };

    for (const file of files) {
      if (file.type === "application/pdf") {
        await handlePDFFile(file, projectId, updateProgress);
      } else {
        // Non-PDF files are considered 1 "page" for simplicity
        currentPage++;
        if (onProgress) {
          onProgress(currentPage, totalPages || 1);
        }
        await handleSingleFileUpload(file, projectId);
      }
    }

    refetchObjects();
  };

  return {
    files,
    setFiles,
    handleObjectUpload,
  };
}
