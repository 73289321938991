import { defaultProject, useUnityBuildStore } from "../states/store";
import { ResetStates } from "../states/resetStates";
import { useStore } from "zustand";
import { useCreateAssemblyStore } from "../states/createAssemblyStore";
import { useCloseoutStore } from "../states/closeoutStore";
import { useInferenceStore } from "../states/inferenceStore";

export const useHandleGoHome = () => {
  const store = useStore(useUnityBuildStore);
  const store1 = useStore(useCreateAssemblyStore);
  const store2 = useStore(useCloseoutStore);
  const store3 = useStore(useInferenceStore);
  const { setSelectedProject, setIsViewingProjectDashboard } =
    useStore(useUnityBuildStore);

  return (bool: boolean) => {
    ResetStates.resetMainMenuStates(store, store1);
    setSelectedProject(defaultProject);
    ResetStates.resetTakeoffStates(store, store1);
    ResetStates.resetMasterProjectViewStates(store, store1, store3);
    ResetStates.resetCreateAssemblyStates(store1, store);
    setIsViewingProjectDashboard(bool);
    ResetStates.resetCloseoutStates(store2);
    store.setCountedAssemblies(null);
  };
};
