import { useGoogleLogin } from "@react-oauth/google";
import { useUnityBuildStore } from "../../states/store";
import { clients } from "../../clients/grpcClients";
import { useStore } from "zustand";
import { UB_User } from "../../api/protosCompiled/ub_users/ub_users_pb";

export function useGoogle_Login() {
  const {
    setIsFetchingToken,
    setSessionToken,
    setIsLoggedIn,
    setProjectListIsMounted,
    setUser,
  } = useStore(useUnityBuildStore);
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => handleSetToken(codeResponse),
    flow: "auth-code",
    onError: (error) => console.error(error),
  });

  const handleSetToken = async (codeResponse: any) => {
    try {
      setIsFetchingToken(true);
      try {
        clients.getUserTokenRequest.setCode(codeResponse.code);
      } catch (error) {
        console.error("error", error);
      }

      // Make the gRPC call
      let response = null;
      try {
        response = await clients.userTokenServiceClient.getToken(
          clients.getUserTokenRequest
        );
      } catch (error) {
        console.error("error", error);
      }

      if (response) {
        const newuser = new UB_User();
        newuser.setUserphotourl(response.getPicture());
        setUser(newuser.toObject());
      }

      if (response && response.getSessionToken()) {
        setIsFetchingToken(false);
        setSessionToken(response.getSessionToken());
        setIsLoggedIn(true);
        setProjectListIsMounted(true);
      } else {
        setIsFetchingToken(false);
        setSessionToken("");
        setIsLoggedIn(false);
        setProjectListIsMounted(false);
      }
    } catch (error) {
      console.error("error", error);
      setIsFetchingToken(false);
    }
  };

  return login;
}
