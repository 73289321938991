import { useEffect } from "react";
import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";
import { Calculations as c } from "../../utils/calculations";
import { QuoteType } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { QuoteRow } from "./QuoteRow";

export const QuoteHeader = ({
  quoteExpenses,
}: {
  quoteExpenses: QuoteType.AsObject[];
}) => {
  const { setIncludedQuoteTypes, updateQuoteType } = useStore(useCloseoutStore);

  const handleFieldUpdate = (field: string, value: any, index: number) => {
    const updatedQuote = { ...quoteExpenses[index] };

    if (field === "quotedCost") {
      updatedQuote.quotedcost = value;
    } else if (field === "adjustedPercent") {
      updatedQuote.adjustedpercent = value;
    }

    // Recalculate total cost after updating quotedCost or adjustedPercent
    // Assuming calculateQuoteTotal uses quote.quotedcost, quote.adjustedpercent,
    // and sums up sub-items total to produce total cost
    updatedQuote.totalcost = c.calculateQuoteTotal(updatedQuote);

    updateQuoteType(index, updatedQuote);
  };

  const handleItemUpdate = (
    quoteIndex: number,
    itemIndex: number,
    field: string,
    value: number
  ) => {
    const updatedQuotes = [...quoteExpenses];
    const updatedQuote = { ...updatedQuotes[quoteIndex] };
    const updatedItems = [...updatedQuote.quoteitemsList];

    // Update the specified field for the specific item
    updatedItems[itemIndex] = { ...updatedItems[itemIndex], [field]: value };
    updatedQuote.quoteitemsList = updatedItems;

    // After updating an item's cost or qty, recalculate total cost
    // c.calculateQuoteTotal should consider quotedcost, adjustedpercent, and item costs
    updatedQuote.totalcost = c.calculateQuoteTotal(updatedQuote);

    updatedQuotes[quoteIndex] = updatedQuote;
    setIncludedQuoteTypes(updatedQuotes);
  };

  useEffect(() => {
    console.log("quoteExpenses", quoteExpenses);
  }, [quoteExpenses]);

  return (
    <>
      <div className="scrollableCDiv">
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Quoted Cost</th>
              <th>% Adjustment</th>
              <th>Total Cost</th>
            </tr>
          </thead>
          <tbody>
            {quoteExpenses.map((quote, index) => (
              <QuoteRow
                key={index}
                quote={quote}
                handleFieldUpdate={(field, value) =>
                  handleFieldUpdate(field, value, index)
                }
                handleItemUpdate={(itemIndex, field, value) =>
                  handleItemUpdate(index, itemIndex, field, value)
                }
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
