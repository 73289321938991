/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.26.1
// source: client.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as client_pb from './client_pb'; // proto import: "client.proto"


export class ClientServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateClient = new grpcWeb.MethodDescriptor(
    '/ClientService/CreateClient',
    grpcWeb.MethodType.UNARY,
    client_pb.CreateClientRequest,
    client_pb.CreateClientResponse,
    (request: client_pb.CreateClientRequest) => {
      return request.serializeBinary();
    },
    client_pb.CreateClientResponse.deserializeBinary
  );

  createClient(
    request: client_pb.CreateClientRequest,
    metadata?: grpcWeb.Metadata | null): Promise<client_pb.CreateClientResponse>;

  createClient(
    request: client_pb.CreateClientRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_pb.CreateClientResponse) => void): grpcWeb.ClientReadableStream<client_pb.CreateClientResponse>;

  createClient(
    request: client_pb.CreateClientRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_pb.CreateClientResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ClientService/CreateClient',
        request,
        metadata || {},
        this.methodDescriptorCreateClient,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ClientService/CreateClient',
    request,
    metadata || {},
    this.methodDescriptorCreateClient);
  }

  methodDescriptorGetClient = new grpcWeb.MethodDescriptor(
    '/ClientService/GetClient',
    grpcWeb.MethodType.UNARY,
    client_pb.GetClientRequest,
    client_pb.GetClientResponse,
    (request: client_pb.GetClientRequest) => {
      return request.serializeBinary();
    },
    client_pb.GetClientResponse.deserializeBinary
  );

  getClient(
    request: client_pb.GetClientRequest,
    metadata?: grpcWeb.Metadata | null): Promise<client_pb.GetClientResponse>;

  getClient(
    request: client_pb.GetClientRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_pb.GetClientResponse) => void): grpcWeb.ClientReadableStream<client_pb.GetClientResponse>;

  getClient(
    request: client_pb.GetClientRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_pb.GetClientResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ClientService/GetClient',
        request,
        metadata || {},
        this.methodDescriptorGetClient,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ClientService/GetClient',
    request,
    metadata || {},
    this.methodDescriptorGetClient);
  }

  methodDescriptorGetAllClients = new grpcWeb.MethodDescriptor(
    '/ClientService/GetAllClients',
    grpcWeb.MethodType.UNARY,
    client_pb.GetAllClientsRequest,
    client_pb.GetAllClientsResponse,
    (request: client_pb.GetAllClientsRequest) => {
      return request.serializeBinary();
    },
    client_pb.GetAllClientsResponse.deserializeBinary
  );

  getAllClients(
    request: client_pb.GetAllClientsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<client_pb.GetAllClientsResponse>;

  getAllClients(
    request: client_pb.GetAllClientsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_pb.GetAllClientsResponse) => void): grpcWeb.ClientReadableStream<client_pb.GetAllClientsResponse>;

  getAllClients(
    request: client_pb.GetAllClientsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_pb.GetAllClientsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ClientService/GetAllClients',
        request,
        metadata || {},
        this.methodDescriptorGetAllClients,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ClientService/GetAllClients',
    request,
    metadata || {},
    this.methodDescriptorGetAllClients);
  }

  methodDescriptorUpdateClient = new grpcWeb.MethodDescriptor(
    '/ClientService/UpdateClient',
    grpcWeb.MethodType.UNARY,
    client_pb.UpdateClientRequest,
    client_pb.UpdateClientResponse,
    (request: client_pb.UpdateClientRequest) => {
      return request.serializeBinary();
    },
    client_pb.UpdateClientResponse.deserializeBinary
  );

  updateClient(
    request: client_pb.UpdateClientRequest,
    metadata?: grpcWeb.Metadata | null): Promise<client_pb.UpdateClientResponse>;

  updateClient(
    request: client_pb.UpdateClientRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_pb.UpdateClientResponse) => void): grpcWeb.ClientReadableStream<client_pb.UpdateClientResponse>;

  updateClient(
    request: client_pb.UpdateClientRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_pb.UpdateClientResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ClientService/UpdateClient',
        request,
        metadata || {},
        this.methodDescriptorUpdateClient,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ClientService/UpdateClient',
    request,
    metadata || {},
    this.methodDescriptorUpdateClient);
  }

  methodDescriptorDeleteClient = new grpcWeb.MethodDescriptor(
    '/ClientService/DeleteClient',
    grpcWeb.MethodType.UNARY,
    client_pb.DeleteClientRequest,
    client_pb.DeleteClientResponse,
    (request: client_pb.DeleteClientRequest) => {
      return request.serializeBinary();
    },
    client_pb.DeleteClientResponse.deserializeBinary
  );

  deleteClient(
    request: client_pb.DeleteClientRequest,
    metadata?: grpcWeb.Metadata | null): Promise<client_pb.DeleteClientResponse>;

  deleteClient(
    request: client_pb.DeleteClientRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_pb.DeleteClientResponse) => void): grpcWeb.ClientReadableStream<client_pb.DeleteClientResponse>;

  deleteClient(
    request: client_pb.DeleteClientRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_pb.DeleteClientResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ClientService/DeleteClient',
        request,
        metadata || {},
        this.methodDescriptorDeleteClient,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ClientService/DeleteClient',
    request,
    metadata || {},
    this.methodDescriptorDeleteClient);
  }

}

