import { useState } from "react";
import { SketchPicker } from "react-color";
import { useStore } from "zustand";
import {
  defaultProject,
  defaultUser,
  useUnityBuildStore,
} from "../states/store";
import { MainAdminPanel } from "./AdminPanel/MainAdminPanel";
import { UbAccountService } from "../api/UbAccountService";
import { useNavigate } from "react-router-dom";

type SettingsProps = {
  onCloseMenu: () => void;
  onLogout: () => void;
};
export const Settings = ({ onCloseMenu, onLogout }: SettingsProps) => {
  const {
    userBackgroundColor,
    setUserBackgroundColor,
    setIsLoggedIn,
    setProjectListIsMounted,
    setSessionToken,
    setSelectedProject,
    user,
    setUser,
  } = useStore(useUnityBuildStore);
  const navigate = useNavigate();

  const [isAdminPanelOpen, setIsAdminPanelOpen] = useState(false);

  const handleColorChange = (color: any) => {
    setUserBackgroundColor(color.hex);
  };

  const handleAdminPanel = () => {
    setIsAdminPanelOpen(!isAdminPanelOpen);
  };

  const handleLogOut = async () => {
    try {
      // Log the user out on the backend
      await UbAccountService.logAccountUserInorOut(user.id, false);
    } catch (error) {
      console.error("Error logging out:", error);
    }

    // Reset all related states
    setUser(defaultUser);
    setIsLoggedIn(false);
    setSessionToken("");
    setSelectedProject(defaultProject);
    setProjectListIsMounted(false);

    // Close the settings menu
    onCloseMenu();
    onLogout();

    // Navigate to the landing page
    navigate("/");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        alignContent: "center",
      }}
    >
      <h4>Settings</h4>
      <button onClick={handleAdminPanel}>Admin Panel</button>
      <button onClick={handleLogOut}>Log Out</button>
      {!isAdminPanelOpen && (
        <div>
          <label>Background Color:</label>
          <SketchPicker
            color={userBackgroundColor}
            onChange={handleColorChange}
          />
        </div>
      )}
      {isAdminPanelOpen && <MainAdminPanel />}
    </div>
  );
};
