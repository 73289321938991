import { useMutation, useQueryClient } from "react-query";
import { ProjectEstimateService as es } from "../api/ProjectEstimateService";

export const useCreateEstimateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: any) => es.createProjectEstimate(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["estimates"]);
    },
    onError: (error) => {
      console.error("Error creating estimate", error);
    },
  });
};

export const useUpdateEstimateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: any) => es.updateProjectEstimate(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["estimates"]);
    },
    onError: (error) => {
      console.error("Error updating estimate", error);
    },
  });
};
