import { useState } from "react";
import { Alert, CircularProgress, Dialog, DialogContent } from "@mui/material";
import { CreateEstimate } from "./CreateEstimate";
import { useUnityBuildStore } from "../../states/store";
import { useFetchEstimates } from "../../hooks/useFetchProjectEstimates";
import { useStore } from "zustand";
import { GetProjectEstimateResponse } from "../../api/protosCompiled/projectEstimate/projectEstimate_pb";
import { useHandleSetEstimate } from "./helpers";

export function ListEstimates() {
  const { isViewingEstimateForm, setIsViewingEstimateForm } =
    useStore(useUnityBuildStore);

  const { data: estimateList, error, isLoading } = useFetchEstimates();
  const errorMessage = (error as Error)?.message;
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event: any) => setSearchTerm(event.target.value);
  const handleSetValue = useHandleSetEstimate();

  const filteredEstimates = estimateList?.filter(
    (estimate: GetProjectEstimateResponse.AsObject) =>
      (estimate.estimate?.name
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ??
        false) ||
      (estimate.estimate?.description
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ??
        false) ||
      estimate.estimate?.description
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      (estimate.estimate?.customid
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ??
        false)
  );

  const handleCloseForm = () => {
    setIsViewingEstimateForm(false);
  };

  return (
    <>
      <button
        onClick={(event) => {
          event.stopPropagation();
          handleSetValue("create", null);
        }}
      >
        Create Estimate
      </button>
      <input
        placeholder="Search Estimates..."
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <div className="scrollableTableContainer">
        <table>
          <thead>
            <tr>
              <th>Estimate ID</th>
              <th style={{ width: "60%" }}>Estimate Name</th>
              <th>Date Created</th>
              <th>Due Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredEstimates &&
              filteredEstimates?.map((item) => (
                <tr
                  key={item.estimate!.estimateid}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleSetValue("setEstimate", item.estimate ?? null);
                  }}
                >
                  <td style={{ cursor: "pointer" }}>
                    {(item && item.estimate?.name) ?? ""}
                  </td>
                  <td style={{ cursor: "pointer" }}>
                    {(item && item.estimate?.customid) ?? ""}
                  </td>
                  <td>
                    {new Date(
                      item.estimate?.datecreated ?? ""
                    ).toLocaleDateString("en-US", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </td>
                  <td>
                    {new Date(item.estimate?.duedate ?? "").toLocaleDateString(
                      "en-US",
                      {
                        year: "2-digit",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )}
                  </td>
                  <td style={{ pointerEvents: "none" }}>
                    <button
                      style={{ pointerEvents: "auto" }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleSetValue("update", item.estimate ?? null);
                      }}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {isLoading && <CircularProgress />}
      {error ? <Alert severity="error">Error: {errorMessage}</Alert> : null}

      <Dialog open={isViewingEstimateForm} onClose={handleCloseForm}>
        <DialogContent>
          <CreateEstimate />
        </DialogContent>
      </Dialog>
    </>
  );
}
