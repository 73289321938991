import React, { useEffect, useState } from "react";
import {
  defaultGlobalAssembly,
  useUnityBuildStore,
} from "../../../states/store";
import { useStore } from "zustand";
import { useCreateAssemblyStore } from "../../../states/createAssemblyStore";
import { ResetStates } from "../../../states/resetStates";
import { GlobalAssemblyService } from "../../../api/GlobalAssemblyService";
import { Inference } from "../../../hooks/inferenceHooks";
import { FormulaHeader } from "./FormulaHeader";
import { AddedItemRow } from "./AddedItemRow";
import ItemTab from "./ItemTab";
import { CreateAssemblyProps } from "../../../types/FolderTypes";
import { useFetchAssembliesByFileId } from "../../../hooks/useFetchAssembliesByFileId";
import { useSubmitTakeoffAssembly } from "../../../hooks/useSubmitTakeoffAssembly";
import { EstimateAssembly } from "../../../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import { ChangeOrderAssembly } from "../../../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";

export function CreateAssembly({
  assemblyOption,
  assembly,
  folderOrFile,
  menuOption,
  setMenuOption,
  refetchFolders,
  allowCreateFromExistingItems = false,
}: CreateAssemblyProps) {
  const { setSelectedTakeoffAssembly } = useStore(useUnityBuildStore);
  const UBStore = useStore(useUnityBuildStore);
  const CAstore = useStore(useCreateAssemblyStore);

  const submitAssembly = useSubmitTakeoffAssembly();

  const { data: classifications } = Inference.useFetchClass();

  const { globalAssembly, setGlobalAssembly } = useStore(
    useCreateAssemblyStore
  );

  const { data: globalAssemblies } = useFetchAssembliesByFileId(
    folderOrFile?.folderid || ""
  );

  useEffect(() => {
    if (UBStore.isViewingExtension) {
      setMenuOption(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UBStore.isViewingExtension]);

  useEffect(() => {
    if (assemblyOption === "global" && menuOption === "Edit Assembly") {
      if (folderOrFile?.isfile === false) return;
      //find the globalAssembly with the same folderid
      const matchingGlobalAssembly = globalAssemblies?.find(
        (globalAssembly) => globalAssembly.folderid === folderOrFile?.folderid
      );

      if (matchingGlobalAssembly) {
        setGlobalAssembly({
          ...globalAssembly,
          folderid: matchingGlobalAssembly.folderid,
          globalassemblyid: matchingGlobalAssembly.globalassemblyid,
          assemblyname: matchingGlobalAssembly.assemblyname,
          entitytype: matchingGlobalAssembly.entitytype,
          parentid: matchingGlobalAssembly.parentid,
          level: matchingGlobalAssembly.level,
          assemblymeasurementtype:
            matchingGlobalAssembly.assemblymeasurementtype,
          imageclassificationid: matchingGlobalAssembly.imageclassificationid,
          isfile: true,
          itemqtyformulasList: matchingGlobalAssembly.itemqtyformulasList,
        });
      }
    } else if (assembly && assemblyOption === "takeoff") {
      setGlobalAssembly({
        ...globalAssembly,
        assemblyname: assembly.assemblyname,
        assemblymeasurementtype: assembly.assemblymeasurementtype,
        imageclassificationid: assembly.imageclassificationid,
        isfile: true,
        itemqtyformulasList: assembly.itemqtyformulasList,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [shouldSubmit, setShouldSubmit] = useState(false);

  const handleSetAssemblyName = (assemblyname: string) => {
    setGlobalAssembly({
      ...globalAssembly,
      assemblyname: assemblyname,
      itemqtyformulasList: globalAssembly.itemqtyformulasList.map((formula) => {
        if (formula.isquoted) {
          return {
            ...formula,
            assemblyname: assemblyname,
          };
        }
        return formula;
      }),
    });
  };

  const handleMeasurementTypeChange = (e: any) => {
    setGlobalAssembly({
      ...globalAssembly,
      assemblymeasurementtype: e.target.value,
    });
  };

  const handleClassificationChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setGlobalAssembly({
      ...globalAssembly,
      imageclassificationid: Number(e.target.value),
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!shouldSubmit) return;
    //creating a variable to allow manual override of edit to create
    try {
      if (menuOption === "Edit Assembly") {
        if (assemblyOption === "global") {
          await GlobalAssemblyService.updateGlobalAssembly(globalAssembly); //TODO: seems like we should be using react-query for handling updates to the global assembly
        } else if (assembly && assemblyOption === "takeoff") {
          const updatedAssembly:
            | EstimateAssembly.AsObject
            | ChangeOrderAssembly.AsObject = {
            ...assembly,
            assemblyname: globalAssembly.assemblyname,
            assemblymeasurementtype: globalAssembly.assemblymeasurementtype,
            imageclassificationid: globalAssembly.imageclassificationid,
            itemqtyformulasList: globalAssembly.itemqtyformulasList,
          };
          submitAssembly({
            assembly: updatedAssembly,
            isUpdating: true,
          });
        }
      } else if (folderOrFile) {
        const updatedAssembly = {
          ...globalAssembly,
          assemblymeasurementtype:
            globalAssembly.assemblymeasurementtype || "count",
          isFile: true,
          imageclassificationid: globalAssembly.imageclassificationid || 1,
          entityType: folderOrFile.entitytype,
          parentid: folderOrFile.isfile
            ? folderOrFile.parentid
            : folderOrFile.folderid,
          level: allowCreateFromExistingItems
            ? folderOrFile.level
            : folderOrFile.level + 1,
        };
        await GlobalAssemblyService.createGlobalAssembly(updatedAssembly);
      }
    } catch (error) {
      console.error("Error creating or updating assembly:", error);
    }
    refetchFolders();
    setSelectedTakeoffAssembly(null);
    setGlobalAssembly(defaultGlobalAssembly);
    setMenuOption(null);
  };

  const handleClickSubmit = () => {
    setShouldSubmit(true);
  };

  const handleClose = () => {
    UBStore.setSelectedTakeoffAssembly(null);
    ResetStates.resetCreateAssemblyStates(CAstore, UBStore);
    setMenuOption(null);
  };

  const handleCreateFromExistingItems = (e: any) => {
    if (e.target.checked) {
      setMenuOption("Create Assembly");
    }
  };

  return (
    <>
      <div
        style={{
          height: "100%",
          backgroundColor: UBStore.userBackgroundColor,
        }}
      >
        <form onSubmit={handleSubmit} className="assemblyForm">
          {/* Input and Select Fields */}
          <div>
            <input
              placeholder="Assembly Name"
              value={globalAssembly.assemblyname}
              onChange={(e) => handleSetAssemblyName(e.target.value)}
              style={{ width: "20%" }}
            />
            <> Takeoff Type: </>
            <select
              value={globalAssembly.assemblymeasurementtype}
              onChange={handleMeasurementTypeChange}
            >
              <option value={"count"}>Count</option>
              <option value={"length"}>Length</option>
              <option value={"area"}>Area</option>
            </select>
            Class Name:
            <select
              value={globalAssembly.imageclassificationid?.toString() || ""}
              onChange={handleClassificationChange}
            >
              {/* Add "N/A" option at the top */}
              <option value="">N/A</option>

              {/* Render the rest of the classifications */}
              {classifications?.map((classification, index) => (
                <option value={classification.id} key={index}>
                  {classification.classname}
                </option>
              ))}
            </select>
            <button onClick={handleClickSubmit}>Save Assembly</button>
            <button onClick={handleClose}>Close</button>
            {allowCreateFromExistingItems && (
              <>
                <label>
                  Create new Assembly from existing items:
                  <input
                    type="checkbox"
                    onChange={handleCreateFromExistingItems}
                  />
                </label>
              </>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "row", height: "95%" }}>
            <div
              className="scrollableTableContainer"
              style={{
                width: "50%",
                overflowY: "auto",
                height: "100%",
                border: "1px solid black",
              }}
            >
              <ItemTab />
            </div>

            {/* Second table with independent scrolling */}
            <div
              className="scrollableTableContainer"
              style={{
                width: "50%",
                overflowY: "auto",
                height: "100%",
                border: "1px solid black",
              }}
            >
              <table>
                <thead>
                  <FormulaHeader />
                </thead>
                <tbody>
                  {globalAssembly &&
                    globalAssembly.itemqtyformulasList.map((item, index) => (
                      <AddedItemRow
                        index={index}
                        key={index + item.itemid}
                        item={item}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
