import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useUnityBuildStore } from "./states/store";
import { CheckoutForm, Return } from "./components/storefront/Checkout";
import { AccountCreateFlow } from "./components/storefront/AccountCreateFlow";
import { LandingPage } from "./components/storefront/LandingPage";
import { ProjectList } from "./components/ListProjects";
import { MasterProjectView } from "./components/MasterProjectView";

export const AppRoutes = () => {
  const { isLoggedIn, selectedProject } = useUnityBuildStore();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      if (selectedProject?.id && location.pathname !== "/project") {
        navigate("/project");
      } else if (!selectedProject?.id && location.pathname !== "/projects") {
        navigate("/projects");
      }
    } else if (!location.pathname.startsWith("/signup")) {
      navigate("/");
    }
  }, [isLoggedIn, selectedProject, location.pathname, navigate]);

  return (
    <Routes>
      {/* Account creation flow */}
      <Route path="/signup/*" element={<AccountCreateFlow />} />

      {/* Checkout flow */}
      <Route path="/checkout" element={<CheckoutForm />} />
      <Route path="/return" element={<Return />} />

      {/* Main app routes */}
      <Route path="/projects" element={<ProjectList />} />
      <Route path="/project" element={<MasterProjectView />} />
      <Route path="/" element={<LandingPage />} />
    </Routes>
  );
};
