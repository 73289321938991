import { useQuery } from "react-query";
import { ProjectService as ps } from "../api/ProjectService";

export function useFetchProjects() {
  const { data, error, isLoading, isFetching } = useQuery(
    "projects",
    async () => {
      const response = await ps.fetchProjects();
      return response.projectsList;
    },
    {}
  );

  return { data, error, isLoading, isFetching };
}
