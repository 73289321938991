import { useUnityBuildStore } from "../states/store";
import { useCreateProjectEstimateAssemblyMutation } from "./useCreateProjectEstimateAssemblyMutation";
import { useUpdateProjectEstimateAssemblyMutation } from "./useUpdateProjectEstimateAssemblyMutation ";
import { useCreateContractChangeOrderAssemblyMutation } from "./useCreateContractChangeOrderAssemblyMutation";
import { useUpdateContractChangeOrderAssemblyMutation } from "./useUpdateContractChangeOrderAssemblyMutation ";
import { useQueryClient } from "react-query";
import { EstimateAssembly } from "../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import { ChangeOrderAssembly } from "../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import { Calculations } from "../utils/calculations";
import { useStore } from "zustand";
import {
  calculatePolygonArea,
  convertPointToRealWorld,
} from "../components/takeoffSVGelements/takeoffHelper";

export const useSubmitTakeoffAssembly = () => {
  const queryClient = useQueryClient();
  const {
    selectedEstimate,
    selectedContract,
    selectedChangeOrder,
    selectedProject,
    currentPage,
    userScale,
  } = useStore(useUnityBuildStore);

  const { mutateAsync: createEstimateAssembly } =
    useCreateProjectEstimateAssemblyMutation();
  const { mutateAsync: updateEstimateAssembly } =
    useUpdateProjectEstimateAssemblyMutation();
  const { mutateAsync: createChangeOrderAssembly } =
    useCreateContractChangeOrderAssemblyMutation();
  const { mutateAsync: updateChangeOrderAssembly } =
    useUpdateContractChangeOrderAssemblyMutation();

  const submitAssembly = async ({
    assembly = {} as EstimateAssembly.AsObject | ChangeOrderAssembly.AsObject,
    isCreating = false,
    isUpdating = false,
  }) => {
    if (isCreating === isUpdating) {
      console.error(
        `isCreating is ${isCreating} and isUpdating is ${isUpdating}, these two values cannot be the same`
      );
      return;
    } else if (!isCreating && !isUpdating) {
      console.error(
        `isCreating is ${isCreating} and isUpdating is ${isUpdating}, one of these values must be true`
      );
      return;
    }

    let calculatedTotalLength = 0;
    if (
      assembly.assemblymeasurementtype === "length" ||
      assembly.assemblymeasurementtype === "area"
    ) {
      calculatedTotalLength = Calculations.calculateTotalDistance(
        assembly.pointsList,
        userScale,
        assembly.assemblymeasurementtype
      );
    }

    let sqft = 0;

    if (assembly.assemblymeasurementtype === "area") {
      const realWorldPoints = assembly.pointsList.map((point) =>
        convertPointToRealWorld(point, userScale)
      );
      sqft = calculatePolygonArea(realWorldPoints);
    }

    // Prepare the optimistic update
    const optimisticAssembly = {
      ...assembly,
      segmentlength: calculatedTotalLength,
      sqft: sqft,
      userscale: userScale,
      pagenumber: currentPage,
    };

    // Begin optimistic update
    await queryClient.cancelQueries(["countedAssemblies"]);
    const previousAssemblies = queryClient.getQueryData(["countedAssemblies"]);

    queryClient.setQueryData(
      ["countedAssemblies"],
      (oldAssemblies: any[] | undefined) => {
        // Ensure oldAssemblies is not undefined
        const old = oldAssemblies || [];

        if (isCreating) {
          // For creation, add the optimisticAssembly to the array
          return [...old, optimisticAssembly];
        } else if (isUpdating) {
          // For updates, find the assembly by its ID and update it
          return old.map((assembly) =>
            assembly.id === optimisticAssembly.takeoffid
              ? optimisticAssembly
              : assembly
          );
        }
        return old; // Return the old state by default
      }
    );

    try {
      if (selectedEstimate.estimateid) {
        if (isCreating) {
          await createEstimateAssembly({
            ...optimisticAssembly,
            projectid: selectedProject.id,
            estimateid: selectedEstimate.estimateid,
          });
        } else if (isUpdating) {
          await updateEstimateAssembly({
            ...optimisticAssembly,
            projectid: selectedProject.id,
            estimateid: selectedEstimate.estimateid,
          });
        }
      } else if (selectedContract.contractid) {
        if (isCreating) {
          await createChangeOrderAssembly({
            ...optimisticAssembly,
            changeorderid: selectedChangeOrder.changeorderid,
            contractid: selectedContract.contractid,
          });
        } else if (isUpdating) {
          await updateChangeOrderAssembly({
            ...optimisticAssembly,
            changeorderid: selectedChangeOrder.changeorderid,
            contractid: selectedContract.contractid,
          });
        }
      }
    } catch (error) {
      queryClient.setQueryData(["countedAssemblies"], previousAssemblies);
      throw error;
    } finally {
      queryClient.invalidateQueries(["countedAssemblies"]);
    }
  };

  return submitAssembly;
};
