/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: changeOrderCloseout.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as changeOrderCloseout_pb from './changeOrderCloseout_pb'; // proto import: "changeOrderCloseout.proto"


export class ChangeOrderCloseoutServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/CreateChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.CreateChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.CreateChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.CreateChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.CreateChangeOrderCloseoutResponse.deserializeBinary
  );

  createChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.CreateChangeOrderCloseoutResponse>;

  createChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.CreateChangeOrderCloseoutResponse>;

  createChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/CreateChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/CreateChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateChangeOrderCloseout);
  }

  methodDescriptorCreateOrUpdateChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/CreateOrUpdateChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.CreateOrUpdateChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.CreateOrUpdateChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.CreateOrUpdateChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.CreateOrUpdateChangeOrderCloseoutResponse.deserializeBinary
  );

  createOrUpdateChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateOrUpdateChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.CreateOrUpdateChangeOrderCloseoutResponse>;

  createOrUpdateChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateOrUpdateChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateOrUpdateChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.CreateOrUpdateChangeOrderCloseoutResponse>;

  createOrUpdateChangeOrderCloseout(
    request: changeOrderCloseout_pb.CreateOrUpdateChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.CreateOrUpdateChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/CreateOrUpdateChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorCreateOrUpdateChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/CreateOrUpdateChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorCreateOrUpdateChangeOrderCloseout);
  }

  methodDescriptorGetChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/GetChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.GetChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.GetChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.GetChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.GetChangeOrderCloseoutResponse.deserializeBinary
  );

  getChangeOrderCloseout(
    request: changeOrderCloseout_pb.GetChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.GetChangeOrderCloseoutResponse>;

  getChangeOrderCloseout(
    request: changeOrderCloseout_pb.GetChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.GetChangeOrderCloseoutResponse>;

  getChangeOrderCloseout(
    request: changeOrderCloseout_pb.GetChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/GetChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorGetChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/GetChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorGetChangeOrderCloseout);
  }

  methodDescriptorGetChangeOrderCloseouts = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/GetChangeOrderCloseouts',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.GetChangeOrderCloseoutsRequest,
    changeOrderCloseout_pb.GetChangeOrderCloseoutsResponse,
    (request: changeOrderCloseout_pb.GetChangeOrderCloseoutsRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.GetChangeOrderCloseoutsResponse.deserializeBinary
  );

  getChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.GetChangeOrderCloseoutsResponse>;

  getChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetChangeOrderCloseoutsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetChangeOrderCloseoutsResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.GetChangeOrderCloseoutsResponse>;

  getChangeOrderCloseouts(
    request: changeOrderCloseout_pb.GetChangeOrderCloseoutsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.GetChangeOrderCloseoutsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/GetChangeOrderCloseouts',
        request,
        metadata || {},
        this.methodDescriptorGetChangeOrderCloseouts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/GetChangeOrderCloseouts',
    request,
    metadata || {},
    this.methodDescriptorGetChangeOrderCloseouts);
  }

  methodDescriptorUpdateChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/UpdateChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.UpdateChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.UpdateChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.UpdateChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.UpdateChangeOrderCloseoutResponse.deserializeBinary
  );

  updateChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.UpdateChangeOrderCloseoutResponse>;

  updateChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.UpdateChangeOrderCloseoutResponse>;

  updateChangeOrderCloseout(
    request: changeOrderCloseout_pb.UpdateChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.UpdateChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/UpdateChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorUpdateChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/UpdateChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorUpdateChangeOrderCloseout);
  }

  methodDescriptorDeleteChangeOrderCloseout = new grpcWeb.MethodDescriptor(
    '/ChangeOrderCloseoutService/DeleteChangeOrderCloseout',
    grpcWeb.MethodType.UNARY,
    changeOrderCloseout_pb.DeleteChangeOrderCloseoutRequest,
    changeOrderCloseout_pb.DeleteChangeOrderCloseoutResponse,
    (request: changeOrderCloseout_pb.DeleteChangeOrderCloseoutRequest) => {
      return request.serializeBinary();
    },
    changeOrderCloseout_pb.DeleteChangeOrderCloseoutResponse.deserializeBinary
  );

  deleteChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrderCloseout_pb.DeleteChangeOrderCloseoutResponse>;

  deleteChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteChangeOrderCloseoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.DeleteChangeOrderCloseoutResponse) => void): grpcWeb.ClientReadableStream<changeOrderCloseout_pb.DeleteChangeOrderCloseoutResponse>;

  deleteChangeOrderCloseout(
    request: changeOrderCloseout_pb.DeleteChangeOrderCloseoutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrderCloseout_pb.DeleteChangeOrderCloseoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderCloseoutService/DeleteChangeOrderCloseout',
        request,
        metadata || {},
        this.methodDescriptorDeleteChangeOrderCloseout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderCloseoutService/DeleteChangeOrderCloseout',
    request,
    metadata || {},
    this.methodDescriptorDeleteChangeOrderCloseout);
  }

}

