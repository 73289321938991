import { useMutation, useQueryClient } from "react-query";
import { ChangeOrderService as co } from "../api/ChangeOrderService";

export const useCreateChangeOrderMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: any) => co.createChangeOrder(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["changeOrders"]);
    },
    onError: (error) => {
      console.error("Error creating change order", error);
    },
  });
};

export const useUpdateChangeOrderMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: any) => co.updateChangeOrder(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["changeOrders"]);
    },
    onError: (error) => {
      console.error("Error updating change order", error);
    },
  });
};
