import { useQuery, useQueryClient } from "react-query";
import { ItemService as req } from "../api/ItemService";
import { GlobalItem } from "../api/protosCompiled/globalItem/globalItem_pb";

export function useFetchItemsByFileId(fileId: string) {
  const queryClient = useQueryClient();

  const fetchItems = async () => {
    const res = await req.getItemsByFileId(fileId);
    return res.itemsList || [];
  };

  const { data, error, isLoading } = useQuery<GlobalItem.AsObject[], Error>(
    ["itemsByFileId", fileId],
    fetchItems,
    {}
  );

  const refetchItems = () => {
    queryClient.invalidateQueries(["itemsByFileId", fileId]);
  };

  // Return the query data, refetch function, and the fetching function to call elsewhere
  return { data, error, isLoading, refetchItems, fetchItems };
}
