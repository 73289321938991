import { v4 as uuidv4 } from "uuid";

export class PointAttributeHelpers {
  static handleSetIsIncludedInCount(pointsList: any, e: any) {
    if (e.shiftKey) {
      return false;
    } else {
      return true;
    }
  }

  static handleSetTrailingLine(pointsList: any, e: any) {
    if (pointsList.index === 0) {
      return false;
    } else {
      return true;
    }
  }

  static handleIsSelected(pointsList: any, e: any) {
    if (pointsList.index === 0) {
      return true;
    } else {
      return false;
    }
  }

  static evaluatePoint(point: any) {
    if (point.allowTrailingLine === false) {
      return false;
    } else {
      return true;
    }
  }

  //TODO: Implement this function, this is just a placehoder. i want to be able
  //to evaluate an angle in order to control certim types of counts, like if the count
  //is active, and the angle is closest to 45 degrees, we might then use that to
  // determine what to order in something like ductwork to chose beween a 90 or a 45
  static evaluateAngle(pointsArray: any) {
    const p1 = pointsArray[pointsArray.length - 2];
    const p2 = pointsArray[pointsArray.length - 1];
    const angle = (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;
    return angle;
  }

  public static handleInsertPoint = (
    e: React.MouseEvent<SVGElement, MouseEvent>,
    pointsList: any,
    lineIndex: number,
    selectedTakeoffAssembly: any,
    setSelectedTakeoffAssembly: (assembly: any) => void,
    submitAssembly: (assembly: any) => void
  ) => {
    e.preventDefault();

    // Get the SVG element
    const svgElement = e.currentTarget.ownerSVGElement;
    if (!svgElement) {
      console.error("SVG element not found");
      return;
    }

    // Convert mouse coordinates to SVG coordinates
    const pt = svgElement.createSVGPoint();
    pt.x = e.clientX;
    pt.y = e.clientY;
    const svgPoint = pt.matrixTransform(svgElement.getScreenCTM()?.inverse());

    // Create the new point
    const newPoint = {
      pointid: uuidv4(),
      x: svgPoint.x,
      y: svgPoint.y,
      angle: 0,
      verticallength: 0,
      allowtrailingline: true,
      allowtrailingmeasurement: true,
      isincludedincount: true,
      isselected: true,
      isactive: true,
    };

    // Insert the new point into the pointsList
    const newPointsList = [
      ...pointsList.slice(0, lineIndex + 1),
      newPoint,
      ...pointsList.slice(lineIndex + 1),
    ];

    // Update the pointsList in your store or state
    setSelectedTakeoffAssembly((prevAssembly: any) => ({
      ...prevAssembly,
      pointsList: newPointsList,
    }));

    // Optionally, submit the updated assembly
    submitAssembly({
      assembly: {
        ...selectedTakeoffAssembly,
        pointsList: newPointsList,
      },
      isUpdating: true,
    });
  };
}
