import { useQuery } from "react-query";
import { useUnityBuildStore } from "../states/store";
import { ContractService as cs } from "../api/ContractService";
import { useStore } from "zustand";

export function useFetchContracts() {
  const { selectedProject } = useStore(useUnityBuildStore);

  const { data, error, isLoading, refetch } = useQuery(
    "contracts",
    async () => {
      if (selectedProject.id) {
        const response = await cs.fetchContracts(selectedProject.id);
        return response.contractsList;
      }
      throw new Error("No valid ID available for fetching contracts");
    },
    {
      enabled: !!selectedProject.id,
    }
  );
  return { data, error, isLoading, refetch };
}
