import React, { useCallback, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { CheckoutService } from "../../api/CheckoutService";
import { Navigate } from "react-router-dom";
import { Box, TextField, Typography, Paper, Grid } from "@mui/material";
import { CheckoutItem } from "../../api/protosCompiled/checkout/checkout_pb";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";

// Load Stripe outside of the component's render to avoid recreating the Stripe object on every render.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? ""
);

const priceIds = [
  {
    account: "price_1QJM1FBIdIe38bPqBe63ZD4S",
    seat: "price_1QL9gBBIdIe38bPqeFmtO0KU",
  },
];

export const CheckoutForm = () => {
  const [seatQuantity, setSeatQuantity] = useState(0);
  const { user } = useStore(useUnityBuildStore);

  const fetchClientSecret = useCallback(() => {
    // Create an array of items to purchase
    const items: CheckoutItem.AsObject[] = [
      {
        priceId: priceIds[0].account,
        quantity: 1,
        metadata: {
          ubAccountId: user.ubaccountid,
          numSeats: seatQuantity,
        },
      },
    ];

    if (seatQuantity > 0) {
      items.push({ priceId: priceIds[0].seat, quantity: seatQuantity });
    }

    return CheckoutService.createCheckoutSession(items)
      .then((response) => {
        if (!response.clientSecret) {
          throw new Error("Client secret missing in response");
        }
        return response.clientSecret;
      })
      .catch((error) => {
        console.error("Error creating checkout session:", error);
        throw error;
      });
  }, [seatQuantity, user.ubaccountid]);

  const options = { fetchClientSecret };

  return (
    <Box
      id="checkout"
      sx={{
        maxWidth: 1200, // Adjust maxWidth to fit the screen
        margin: "0 auto",
        padding: 3,
      }}
    >
      <Paper elevation={3} sx={{ padding: 3 }}>
        <Grid container spacing={4} alignItems="flex-start">
          {/* Left Side: Additional Seats Info */}
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              Additional Seats
            </Typography>
            <Typography variant="body1" gutterBottom>
              Your Unity-Build Estimation account comes with{" "}
              <strong>1 seat</strong>. A seat can be occupied by any user as
              long as there are enough seats available. To purchase additional
              seats, provide a number between <strong>1</strong> and{" "}
              <strong>5</strong>. You can always purchase additional seats
              later.
            </Typography>
            <TextField
              label="Additional Seats"
              type="number"
              variant="outlined"
              fullWidth
              InputProps={{
                inputProps: { min: 0, max: 5 },
              }}
              value={seatQuantity}
              onChange={(e) =>
                setSeatQuantity(parseInt(e.target.value, 10) || 0)
              }
              sx={{ marginTop: 2 }}
            />
          </Grid>

          {/* Right Side: Embedded Checkout */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                maxWidth: "100%",
                height: "70vh", // Set a maximum height for the Stripe component
                margin: "0 auto",
                overflowY: "auto", // Enable vertical scrolling if necessary
              }}
            >
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
                key={seatQuantity} // Force re-render when seatQuantity changes
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export const Return = () => {
  const [status, setStatus] = useState<string | null>(null);
  const [customerEmail, setCustomerEmail] = useState<string | null>("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get("session_id");

    if (sessionId) {
      CheckoutService.retrieveCheckoutSession(sessionId)
        .then((data) => {
          setStatus(data.status);
          setCustomerEmail(data.customerEmail);
        })
        .catch((error) => {
          console.error("Error retrieving checkout session:", error);
        });
    }
  }, []);

  if (status === "open") {
    return <Navigate to="/checkout" />;
  }

  if (status === "complete") {
    return (
      <Box id="success" sx={{ maxWidth: 500, margin: "0 auto", padding: 3 }}>
        <Typography variant="h5" gutterBottom>
          Thank You for Your Purchase!
        </Typography>
        <Typography variant="body1">
          A confirmation email will be sent to <strong>{customerEmail}</strong>.
          If you have any questions, please email{" "}
          <a href="mailto:orders@example.com">orders@example.com</a>.
        </Typography>
      </Box>
    );
  }

  return null;
};
