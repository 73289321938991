/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: changeOrder.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as changeOrder_pb from './changeOrder_pb'; // proto import: "changeOrder.proto"


export class ChangeOrderServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateChangeOrder = new grpcWeb.MethodDescriptor(
    '/ChangeOrderService/CreateChangeOrder',
    grpcWeb.MethodType.UNARY,
    changeOrder_pb.CreateChangeOrderRequest,
    changeOrder_pb.CreateChangeOrderResponse,
    (request: changeOrder_pb.CreateChangeOrderRequest) => {
      return request.serializeBinary();
    },
    changeOrder_pb.CreateChangeOrderResponse.deserializeBinary
  );

  createChangeOrder(
    request: changeOrder_pb.CreateChangeOrderRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrder_pb.CreateChangeOrderResponse>;

  createChangeOrder(
    request: changeOrder_pb.CreateChangeOrderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrder_pb.CreateChangeOrderResponse) => void): grpcWeb.ClientReadableStream<changeOrder_pb.CreateChangeOrderResponse>;

  createChangeOrder(
    request: changeOrder_pb.CreateChangeOrderRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrder_pb.CreateChangeOrderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderService/CreateChangeOrder',
        request,
        metadata || {},
        this.methodDescriptorCreateChangeOrder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderService/CreateChangeOrder',
    request,
    metadata || {},
    this.methodDescriptorCreateChangeOrder);
  }

  methodDescriptorGetChangeOrder = new grpcWeb.MethodDescriptor(
    '/ChangeOrderService/GetChangeOrder',
    grpcWeb.MethodType.UNARY,
    changeOrder_pb.GetChangeOrderRequest,
    changeOrder_pb.GetChangeOrderResponse,
    (request: changeOrder_pb.GetChangeOrderRequest) => {
      return request.serializeBinary();
    },
    changeOrder_pb.GetChangeOrderResponse.deserializeBinary
  );

  getChangeOrder(
    request: changeOrder_pb.GetChangeOrderRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrder_pb.GetChangeOrderResponse>;

  getChangeOrder(
    request: changeOrder_pb.GetChangeOrderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrder_pb.GetChangeOrderResponse) => void): grpcWeb.ClientReadableStream<changeOrder_pb.GetChangeOrderResponse>;

  getChangeOrder(
    request: changeOrder_pb.GetChangeOrderRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrder_pb.GetChangeOrderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderService/GetChangeOrder',
        request,
        metadata || {},
        this.methodDescriptorGetChangeOrder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderService/GetChangeOrder',
    request,
    metadata || {},
    this.methodDescriptorGetChangeOrder);
  }

  methodDescriptorGetChangeOrders = new grpcWeb.MethodDescriptor(
    '/ChangeOrderService/GetChangeOrders',
    grpcWeb.MethodType.UNARY,
    changeOrder_pb.GetChangeOrdersRequest,
    changeOrder_pb.GetChangeOrdersResponse,
    (request: changeOrder_pb.GetChangeOrdersRequest) => {
      return request.serializeBinary();
    },
    changeOrder_pb.GetChangeOrdersResponse.deserializeBinary
  );

  getChangeOrders(
    request: changeOrder_pb.GetChangeOrdersRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrder_pb.GetChangeOrdersResponse>;

  getChangeOrders(
    request: changeOrder_pb.GetChangeOrdersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrder_pb.GetChangeOrdersResponse) => void): grpcWeb.ClientReadableStream<changeOrder_pb.GetChangeOrdersResponse>;

  getChangeOrders(
    request: changeOrder_pb.GetChangeOrdersRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrder_pb.GetChangeOrdersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderService/GetChangeOrders',
        request,
        metadata || {},
        this.methodDescriptorGetChangeOrders,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderService/GetChangeOrders',
    request,
    metadata || {},
    this.methodDescriptorGetChangeOrders);
  }

  methodDescriptorUpdateChangeOrder = new grpcWeb.MethodDescriptor(
    '/ChangeOrderService/UpdateChangeOrder',
    grpcWeb.MethodType.UNARY,
    changeOrder_pb.UpdateChangeOrderRequest,
    changeOrder_pb.UpdateChangeOrderResponse,
    (request: changeOrder_pb.UpdateChangeOrderRequest) => {
      return request.serializeBinary();
    },
    changeOrder_pb.UpdateChangeOrderResponse.deserializeBinary
  );

  updateChangeOrder(
    request: changeOrder_pb.UpdateChangeOrderRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrder_pb.UpdateChangeOrderResponse>;

  updateChangeOrder(
    request: changeOrder_pb.UpdateChangeOrderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrder_pb.UpdateChangeOrderResponse) => void): grpcWeb.ClientReadableStream<changeOrder_pb.UpdateChangeOrderResponse>;

  updateChangeOrder(
    request: changeOrder_pb.UpdateChangeOrderRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrder_pb.UpdateChangeOrderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderService/UpdateChangeOrder',
        request,
        metadata || {},
        this.methodDescriptorUpdateChangeOrder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderService/UpdateChangeOrder',
    request,
    metadata || {},
    this.methodDescriptorUpdateChangeOrder);
  }

  methodDescriptorDeleteChangeOrder = new grpcWeb.MethodDescriptor(
    '/ChangeOrderService/DeleteChangeOrder',
    grpcWeb.MethodType.UNARY,
    changeOrder_pb.DeleteChangeOrderRequest,
    changeOrder_pb.DeleteChangeOrderResponse,
    (request: changeOrder_pb.DeleteChangeOrderRequest) => {
      return request.serializeBinary();
    },
    changeOrder_pb.DeleteChangeOrderResponse.deserializeBinary
  );

  deleteChangeOrder(
    request: changeOrder_pb.DeleteChangeOrderRequest,
    metadata?: grpcWeb.Metadata | null): Promise<changeOrder_pb.DeleteChangeOrderResponse>;

  deleteChangeOrder(
    request: changeOrder_pb.DeleteChangeOrderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: changeOrder_pb.DeleteChangeOrderResponse) => void): grpcWeb.ClientReadableStream<changeOrder_pb.DeleteChangeOrderResponse>;

  deleteChangeOrder(
    request: changeOrder_pb.DeleteChangeOrderRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: changeOrder_pb.DeleteChangeOrderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ChangeOrderService/DeleteChangeOrder',
        request,
        metadata || {},
        this.methodDescriptorDeleteChangeOrder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ChangeOrderService/DeleteChangeOrder',
    request,
    metadata || {},
    this.methodDescriptorDeleteChangeOrder);
  }

}

