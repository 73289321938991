import React, { useState, useRef } from "react";
import {
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";
import { useHandleObjectUpload } from "../../hooks/objectHooks";

type UploadObjectProps = {
  onClose: () => void;
};

export const UploadObject = ({ onClose }: UploadObjectProps) => {
  const { selectedProject } = useStore(useUnityBuildStore);
  const inputRef = useRef<HTMLInputElement>(null);

  const { files, setFiles, handleObjectUpload } = useHandleObjectUpload();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);

  // New states for page upload progress
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  // Initiates file selection
  const handleUploadClick = () => {
    if (inputRef.current) {
      setFiles([]);
      setIsUploading(false);
      setUploadComplete(false);
      setCurrentPage(0);
      setTotalPages(0);
      inputRef.current.click();
    }
  };

  // Handles file selection
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      setFiles(selectedFiles);
    }
  };

  // Handles file upload and starts animation
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsUploading(true);
    setUploadComplete(false);

    // Pass a callback to update page states
    await handleObjectUpload(selectedProject.id, (current, total) => {
      setCurrentPage(current);
      setTotalPages(total);
    });

    setIsUploading(false);
    setUploadComplete(true);
    setCurrentPage(0);
    setTotalPages(0);
  };

  return (
    <Dialog
      open
      onClose={onClose}
      PaperProps={{ style: { minWidth: "600px", minHeight: "400px" } }}
    >
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Button onClick={handleUploadClick} disabled={isUploading}>
            Upload Files
          </Button>
          <input
            ref={inputRef}
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
          />

          {files.length > 0 && (
            <Button
              type="submit"
              color="primary"
              disabled={!files.length || isUploading}
            >
              Start Upload
            </Button>
          )}
        </form>

        {files.map((file) => (
          <Card
            key={file.name}
            variant="outlined"
            style={{ marginTop: "15px" }}
          >
            <CardContent>
              <Typography variant="body2">{file.name}</Typography>
              <LinearProgress
                variant="determinate"
                value={100} // Fully loaded bar for each file listing
                className={isUploading ? "glow-progress" : ""}
                style={{ marginTop: "10px" }}
              />
            </CardContent>
          </Card>
        ))}

        {isUploading && totalPages > 0 && (
          <Typography
            variant="body2"
            align="center"
            style={{ marginTop: "10px" }}
          >
            Uploading page {currentPage} of {totalPages}...
          </Typography>
        )}

        {uploadComplete && (
          <Typography
            variant="h6"
            color="primary"
            align="center"
            style={{ marginTop: "20px" }}
          >
            Document Upload Complete
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};
