import React, { useEffect, useState } from "react";
import { TotalPricingRowProps } from "./closeoutTypes";

export const TotalPricingRow: React.FC<TotalPricingRowProps> = ({
  name,
  rowData,
  handleFieldUpdate,
}) => {
  const [adjustedCost, setAdjustedCost] = useState<number>(
    rowData?.adjustedcost || 0
  );
  const [totalOverhead, setTotalOverhead] = useState<number>(
    rowData?.totaloverhead || 0
  );
  const [totalMarkup, setTotalMarkup] = useState<number>(
    rowData?.totalmarkup || 0
  );
  const [finalTotalCost, setFinalTotalCost] = useState<number>(
    rowData?.totalcost || 0
  );

  const [adjustedPercent, setAdjustedPercent] = useState<string>(
    ((rowData?.adjustedpercent || 0) * 100).toFixed(2)
  );
  const [overheadPercent, setOverheadPercent] = useState<string>(
    ((rowData?.overheadpercent || 0) * 100).toFixed(2)
  );
  const [markupPercent, setMarkupPercent] = useState<string>(
    ((rowData?.markuppercent || 0) * 100).toFixed(2)
  );

  useEffect(() => {
    // Normalize percentage inputs, assuming that 1 should be 0.01
    const adjustedPercentValue = parseFloat(adjustedPercent) / 100;
    const overheadPercentValue = parseFloat(overheadPercent) / 100;
    const markupPercentValue = parseFloat(markupPercent) / 100;

    const adjustedValue =
      (rowData?.extendedcost || 0) * adjustedPercentValue +
      rowData.extendedcost;
    setAdjustedCost(adjustedValue);

    const overheadValue = adjustedValue * overheadPercentValue;
    setTotalOverhead(overheadValue);

    const markupValue = adjustedValue * markupPercentValue;
    setTotalMarkup(markupValue);

    setFinalTotalCost(adjustedValue + overheadValue + markupValue);
  }, [adjustedPercent, overheadPercent, markupPercent, rowData]);

  const handleFieldChange = (field: string, value: string) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/;
    if (!regex.test(value)) return;

    switch (field) {
      case "adjustedPercent":
        setAdjustedPercent(value);
        break;
      case "overheadPercent":
        setOverheadPercent(value);
        break;
      case "markupPercent":
        setMarkupPercent(value);
        break;
      default:
        break;
    }
  };

  const handleBlur = (field: string, value: string) => {
    const floatValue = parseFloat(value) || 0;

    switch (field) {
      case "adjustedPercent":
        setAdjustedPercent(floatValue.toFixed(2));
        handleFieldUpdate("adjustedpercent", name, floatValue / 100);
        break;
      case "overheadPercent":
        setOverheadPercent(floatValue.toFixed(2));
        handleFieldUpdate("overheadpercent", name, floatValue / 100);
        break;
      case "markupPercent":
        setMarkupPercent(floatValue.toFixed(2));
        handleFieldUpdate("markuppercent", name, floatValue / 100);
        break;
      default:
        break;
    }
  };

  return (
    <tr>
      <td>{name}</td>
      <td>${rowData.extendedcost.toFixed(2)}</td>
      <td>
        <input
          type="text"
          value={adjustedPercent}
          onChange={(e) => handleFieldChange("adjustedPercent", e.target.value)}
          onBlur={(e) => handleBlur("adjustedPercent", e.target.value)}
        />
      </td>
      <td>${adjustedCost.toFixed(2)}</td>
      <td>
        <input
          type="text"
          value={overheadPercent}
          onChange={(e) => handleFieldChange("overheadPercent", e.target.value)}
          onBlur={(e) => handleBlur("overheadPercent", e.target.value)}
        />
      </td>
      <td>${totalOverhead.toFixed(2)}</td>
      <td>
        <input
          type="text"
          value={markupPercent}
          onChange={(e) => handleFieldChange("markupPercent", e.target.value)}
          onBlur={(e) => handleBlur("markupPercent", e.target.value)}
        />
      </td>
      <td>${totalMarkup.toFixed(2)}</td>
      <td>
        <strong>${finalTotalCost.toFixed(2)}</strong>
      </td>
    </tr>
  );
};
