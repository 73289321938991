/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: ubAdmin.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as ubAdmin_pb from './ubAdmin_pb'; // proto import: "ubAdmin.proto"


export class UBAdminServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListOpenAiFiles = new grpcWeb.MethodDescriptor(
    '/UBAdminService/ListOpenAiFiles',
    grpcWeb.MethodType.UNARY,
    ubAdmin_pb.ListOpenAiFilesRequest,
    ubAdmin_pb.ListOpenAiFilesResponse,
    (request: ubAdmin_pb.ListOpenAiFilesRequest) => {
      return request.serializeBinary();
    },
    ubAdmin_pb.ListOpenAiFilesResponse.deserializeBinary
  );

  listOpenAiFiles(
    request: ubAdmin_pb.ListOpenAiFilesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ubAdmin_pb.ListOpenAiFilesResponse>;

  listOpenAiFiles(
    request: ubAdmin_pb.ListOpenAiFilesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ubAdmin_pb.ListOpenAiFilesResponse) => void): grpcWeb.ClientReadableStream<ubAdmin_pb.ListOpenAiFilesResponse>;

  listOpenAiFiles(
    request: ubAdmin_pb.ListOpenAiFilesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ubAdmin_pb.ListOpenAiFilesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UBAdminService/ListOpenAiFiles',
        request,
        metadata || {},
        this.methodDescriptorListOpenAiFiles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UBAdminService/ListOpenAiFiles',
    request,
    metadata || {},
    this.methodDescriptorListOpenAiFiles);
  }

  methodDescriptorDeleteOpenAiFile = new grpcWeb.MethodDescriptor(
    '/UBAdminService/DeleteOpenAiFile',
    grpcWeb.MethodType.UNARY,
    ubAdmin_pb.DeleteOpenAiFileRequest,
    ubAdmin_pb.DeleteOpenAiFileResponse,
    (request: ubAdmin_pb.DeleteOpenAiFileRequest) => {
      return request.serializeBinary();
    },
    ubAdmin_pb.DeleteOpenAiFileResponse.deserializeBinary
  );

  deleteOpenAiFile(
    request: ubAdmin_pb.DeleteOpenAiFileRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ubAdmin_pb.DeleteOpenAiFileResponse>;

  deleteOpenAiFile(
    request: ubAdmin_pb.DeleteOpenAiFileRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ubAdmin_pb.DeleteOpenAiFileResponse) => void): grpcWeb.ClientReadableStream<ubAdmin_pb.DeleteOpenAiFileResponse>;

  deleteOpenAiFile(
    request: ubAdmin_pb.DeleteOpenAiFileRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ubAdmin_pb.DeleteOpenAiFileResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UBAdminService/DeleteOpenAiFile',
        request,
        metadata || {},
        this.methodDescriptorDeleteOpenAiFile,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UBAdminService/DeleteOpenAiFile',
    request,
    metadata || {},
    this.methodDescriptorDeleteOpenAiFile);
  }

  methodDescriptorListOpenAiAssistants = new grpcWeb.MethodDescriptor(
    '/UBAdminService/ListOpenAiAssistants',
    grpcWeb.MethodType.UNARY,
    ubAdmin_pb.ListOpenAiAssistantsRequest,
    ubAdmin_pb.ListOpenAiAssistantsResponse,
    (request: ubAdmin_pb.ListOpenAiAssistantsRequest) => {
      return request.serializeBinary();
    },
    ubAdmin_pb.ListOpenAiAssistantsResponse.deserializeBinary
  );

  listOpenAiAssistants(
    request: ubAdmin_pb.ListOpenAiAssistantsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ubAdmin_pb.ListOpenAiAssistantsResponse>;

  listOpenAiAssistants(
    request: ubAdmin_pb.ListOpenAiAssistantsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ubAdmin_pb.ListOpenAiAssistantsResponse) => void): grpcWeb.ClientReadableStream<ubAdmin_pb.ListOpenAiAssistantsResponse>;

  listOpenAiAssistants(
    request: ubAdmin_pb.ListOpenAiAssistantsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ubAdmin_pb.ListOpenAiAssistantsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UBAdminService/ListOpenAiAssistants',
        request,
        metadata || {},
        this.methodDescriptorListOpenAiAssistants,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UBAdminService/ListOpenAiAssistants',
    request,
    metadata || {},
    this.methodDescriptorListOpenAiAssistants);
  }

  methodDescriptorDeleteOpenAiAssistant = new grpcWeb.MethodDescriptor(
    '/UBAdminService/DeleteOpenAiAssistant',
    grpcWeb.MethodType.UNARY,
    ubAdmin_pb.DeleteOpenAiAssistantRequest,
    ubAdmin_pb.DeleteOpenAiAssistantResponse,
    (request: ubAdmin_pb.DeleteOpenAiAssistantRequest) => {
      return request.serializeBinary();
    },
    ubAdmin_pb.DeleteOpenAiAssistantResponse.deserializeBinary
  );

  deleteOpenAiAssistant(
    request: ubAdmin_pb.DeleteOpenAiAssistantRequest,
    metadata?: grpcWeb.Metadata | null): Promise<ubAdmin_pb.DeleteOpenAiAssistantResponse>;

  deleteOpenAiAssistant(
    request: ubAdmin_pb.DeleteOpenAiAssistantRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: ubAdmin_pb.DeleteOpenAiAssistantResponse) => void): grpcWeb.ClientReadableStream<ubAdmin_pb.DeleteOpenAiAssistantResponse>;

  deleteOpenAiAssistant(
    request: ubAdmin_pb.DeleteOpenAiAssistantRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: ubAdmin_pb.DeleteOpenAiAssistantResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/UBAdminService/DeleteOpenAiAssistant',
        request,
        metadata || {},
        this.methodDescriptorDeleteOpenAiAssistant,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/UBAdminService/DeleteOpenAiAssistant',
    request,
    metadata || {},
    this.methodDescriptorDeleteOpenAiAssistant);
  }

}

