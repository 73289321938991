import { useEffect, useCallback } from "react";
import { useUnityBuildStore } from "../states/store";
import { ResetStates } from "../states/resetStates";
import { useSubmitTakeoffAssembly } from "./useSubmitTakeoffAssembly";
import { useStore } from "zustand";
import { useCreateAssemblyStore } from "../states/createAssemblyStore";

export const useHandleTakeoffKeyboardEvents = (svgRef: any) => {
  const store = useStore(useUnityBuildStore);
  const store1 = useStore(useCreateAssemblyStore);

  const {
    selectedTakeoffAssembly,
    isMeasuring,
    isCreatingTakeoffAssembly,
    isUpdatingTakeoffAssembly,
  } = store;

  const submitAssembly = useSubmitTakeoffAssembly();

  // Wrap handleKeyboardEvents in useCallback
  const handleKeyboardEvents = useCallback(
    async (event: KeyboardEvent | React.KeyboardEvent) => {
      if (event.key === "Escape") {
        ResetStates.resetTakeoffStates(store, store1);
        return;
      }
      if (!svgRef.current) return;
      if (isMeasuring && event.key === "Enter") {
        ResetStates.resetTakeoffStates(store, store1);
        return;
      }

      try {
        if (event.key === "Enter" && selectedTakeoffAssembly) {
          await submitAssembly({
            assembly: selectedTakeoffAssembly,
            isCreating: isCreatingTakeoffAssembly,
            isUpdating: isUpdatingTakeoffAssembly,
          });
          ResetStates.resetTakeoffStates(store, store1);
        }
      } catch (error) {
        console.error("Error submitting assembly:", error);
      }
    },
    [
      svgRef,
      isMeasuring,
      selectedTakeoffAssembly,
      isCreatingTakeoffAssembly,
      isUpdatingTakeoffAssembly,
      submitAssembly,
      store,
      store1,
    ]
  );

  // Attach the event listener globally on mount
  useEffect(() => {
    const handleGlobalKeydown = (event: KeyboardEvent) =>
      handleKeyboardEvents(event);
    window.addEventListener("keydown", handleGlobalKeydown);
    return () => window.removeEventListener("keydown", handleGlobalKeydown);
  }, [handleKeyboardEvents]);

  return handleKeyboardEvents;
};
