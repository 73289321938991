/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.26.1
// source: role.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as role_pb from './role_pb'; // proto import: "role.proto"


export class RoleServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateRole = new grpcWeb.MethodDescriptor(
    '/RoleService/CreateRole',
    grpcWeb.MethodType.UNARY,
    role_pb.CreateRoleRequest,
    role_pb.CreateRoleResponse,
    (request: role_pb.CreateRoleRequest) => {
      return request.serializeBinary();
    },
    role_pb.CreateRoleResponse.deserializeBinary
  );

  createRole(
    request: role_pb.CreateRoleRequest,
    metadata?: grpcWeb.Metadata | null): Promise<role_pb.CreateRoleResponse>;

  createRole(
    request: role_pb.CreateRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: role_pb.CreateRoleResponse) => void): grpcWeb.ClientReadableStream<role_pb.CreateRoleResponse>;

  createRole(
    request: role_pb.CreateRoleRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: role_pb.CreateRoleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/RoleService/CreateRole',
        request,
        metadata || {},
        this.methodDescriptorCreateRole,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/RoleService/CreateRole',
    request,
    metadata || {},
    this.methodDescriptorCreateRole);
  }

  methodDescriptorGetRole = new grpcWeb.MethodDescriptor(
    '/RoleService/GetRole',
    grpcWeb.MethodType.UNARY,
    role_pb.GetRoleRequest,
    role_pb.GetRoleResponse,
    (request: role_pb.GetRoleRequest) => {
      return request.serializeBinary();
    },
    role_pb.GetRoleResponse.deserializeBinary
  );

  getRole(
    request: role_pb.GetRoleRequest,
    metadata?: grpcWeb.Metadata | null): Promise<role_pb.GetRoleResponse>;

  getRole(
    request: role_pb.GetRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: role_pb.GetRoleResponse) => void): grpcWeb.ClientReadableStream<role_pb.GetRoleResponse>;

  getRole(
    request: role_pb.GetRoleRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: role_pb.GetRoleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/RoleService/GetRole',
        request,
        metadata || {},
        this.methodDescriptorGetRole,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/RoleService/GetRole',
    request,
    metadata || {},
    this.methodDescriptorGetRole);
  }

  methodDescriptorGetAllRoles = new grpcWeb.MethodDescriptor(
    '/RoleService/GetAllRoles',
    grpcWeb.MethodType.UNARY,
    role_pb.GetRolesRequest,
    role_pb.GetRolesResponse,
    (request: role_pb.GetRolesRequest) => {
      return request.serializeBinary();
    },
    role_pb.GetRolesResponse.deserializeBinary
  );

  getAllRoles(
    request: role_pb.GetRolesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<role_pb.GetRolesResponse>;

  getAllRoles(
    request: role_pb.GetRolesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: role_pb.GetRolesResponse) => void): grpcWeb.ClientReadableStream<role_pb.GetRolesResponse>;

  getAllRoles(
    request: role_pb.GetRolesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: role_pb.GetRolesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/RoleService/GetAllRoles',
        request,
        metadata || {},
        this.methodDescriptorGetAllRoles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/RoleService/GetAllRoles',
    request,
    metadata || {},
    this.methodDescriptorGetAllRoles);
  }

  methodDescriptorUpdateRole = new grpcWeb.MethodDescriptor(
    '/RoleService/UpdateRole',
    grpcWeb.MethodType.UNARY,
    role_pb.UpdateRoleRequest,
    role_pb.UpdateRoleResponse,
    (request: role_pb.UpdateRoleRequest) => {
      return request.serializeBinary();
    },
    role_pb.UpdateRoleResponse.deserializeBinary
  );

  updateRole(
    request: role_pb.UpdateRoleRequest,
    metadata?: grpcWeb.Metadata | null): Promise<role_pb.UpdateRoleResponse>;

  updateRole(
    request: role_pb.UpdateRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: role_pb.UpdateRoleResponse) => void): grpcWeb.ClientReadableStream<role_pb.UpdateRoleResponse>;

  updateRole(
    request: role_pb.UpdateRoleRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: role_pb.UpdateRoleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/RoleService/UpdateRole',
        request,
        metadata || {},
        this.methodDescriptorUpdateRole,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/RoleService/UpdateRole',
    request,
    metadata || {},
    this.methodDescriptorUpdateRole);
  }

  methodDescriptorDeleteRole = new grpcWeb.MethodDescriptor(
    '/RoleService/DeleteRole',
    grpcWeb.MethodType.UNARY,
    role_pb.DeleteRoleRequest,
    role_pb.DeleteRoleResponse,
    (request: role_pb.DeleteRoleRequest) => {
      return request.serializeBinary();
    },
    role_pb.DeleteRoleResponse.deserializeBinary
  );

  deleteRole(
    request: role_pb.DeleteRoleRequest,
    metadata?: grpcWeb.Metadata | null): Promise<role_pb.DeleteRoleResponse>;

  deleteRole(
    request: role_pb.DeleteRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: role_pb.DeleteRoleResponse) => void): grpcWeb.ClientReadableStream<role_pb.DeleteRoleResponse>;

  deleteRole(
    request: role_pb.DeleteRoleRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: role_pb.DeleteRoleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/RoleService/DeleteRole',
        request,
        metadata || {},
        this.methodDescriptorDeleteRole,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/RoleService/DeleteRole',
    request,
    metadata || {},
    this.methodDescriptorDeleteRole);
  }

}

