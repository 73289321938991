import { useStore } from "zustand";
import { useInferenceStore } from "../../states/inferenceStore"; // Update this path as needed
import { useUnityBuildStore } from "../../states/store";
import { ToolBarHelper } from "../../hooks/toolbarHooks";
import React from "react";
import { useFetchObjects } from "../../hooks/objectHooks";

export const SVGInferenceBoxes: React.FC = () => {
  const {
    inferenceCountObjects,
    classMap,
    confidanceThreshold,
    inferenceItemFilter,
  } = useStore(useInferenceStore);
  const { currentPage, selectedObjectId } = useStore(useUnityBuildStore);

  const { data: availableObjects } = useFetchObjects();

  const renderBoundingBoxes = () => {
    if (!availableObjects) return;
    const gscObject = ToolBarHelper.filterAvailableObjects(
      availableObjects,
      selectedObjectId
    );

    // Filter inference objects based on the currentPage and gcsObjectName
    return inferenceCountObjects
      .filter(
        (inferenceObject) =>
          inferenceObject.pagenumbersList.includes(currentPage - 1) &&
          inferenceObject.gcsobjectnamesList[0] === gscObject.gcsObjectName
      )
      .flatMap((inferenceObject, index) => {
        return inferenceObject.resultsList.map((result, resultIndex) => {
          return result.boxesList.map((box, boxIndex) => {
            const classId = result.labelsList[boxIndex];
            const className = classMap ? classMap[classId] : classId; // Retrieve the class name from the map or use the ID if not available
            const score = result.scoresList[boxIndex];

            // Apply filtering: check if className matches inferenceItemFilter
            if (inferenceItemFilter && className !== inferenceItemFilter) {
              return null; // Skip rendering this bounding box if it doesn't match the filter
            }

            // Calculate center coordinates of the box
            const cx = (box.xmin + box.xmax) / 2;
            const cy = (box.ymin + box.ymax) / 2;

            return (
              <React.Fragment key={`${index}-${resultIndex}-${boxIndex}`}>
                {/* Conditionally render circle and text based on score */}
                {score > confidanceThreshold && (
                  <>
                    <circle
                      cx={cx}
                      cy={cy}
                      r={5}
                      fill="red"
                      stroke="black"
                      fillOpacity={0.5} // Makes the circle semi-transparent
                    >
                      <animate
                        attributeName="r"
                        values="5;8;5" // Pulsing effect, increase/decrease radius
                        dur="3s" // Duration of the pulsing animation
                        repeatCount="indefinite" // Loop animation indefinitely
                      />
                    </circle>
                  </>
                )}
              </React.Fragment>
            );
          });
        });
      })
      .filter(Boolean); // Remove null entries from the result to avoid rendering empty fragments
  };

  return <>{renderBoundingBoxes()}</>;
};
