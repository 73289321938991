export type AccountFormErrors = {
  accountName: string;
  phoneNumber: string;
  address: string;
  email: string;
  terms: string;
};

export const initialErrors: AccountFormErrors = {
  accountName: "",
  phoneNumber: "",
  address: "",
  email: "",
  terms: "",
};

export const validateEmailFormat = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// Validate password for multiple conditions and return specific errors
export const getPasswordError = (password: string): string => {
  if (password.length < 8) {
    return "Password must be at least 8 characters long.";
  }
  if (!/[A-Z]/.test(password)) {
    return "Password must contain at least one uppercase letter.";
  }
  if (!/[a-z]/.test(password)) {
    return "Password must contain at least one lowercase letter.";
  }
  if (!/[0-9]/.test(password)) {
    return "Password must contain at least one number.";
  }
  if (!/[!@#$%^&*(),.?":{}|<>`-]/.test(password)) {
    return "Password must contain at least one special character (e.g., !@#$%^&*).";
  }
  if (/\s/.test(password)) {
    return "Password must not contain any spaces.";
  }
  return ""; // No errors, password is valid
};
