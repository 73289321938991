import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  qtyOfItemHelper,
  forEveryHelper,
  takeoffVariableHelper,
} from "../../../utils/constants";

/**
 * FormulaHeader component returns a header for the formula table.
 * Items are added to this table and the formula table assembles a "no code" interface
 * for users to determine how to quantify their items
 *
 * @returns
 *
 * @example
 * return(
 * <FormulaHeader />
 * )
 */
export const FormulaHeader = () => {
  return (
    <tr>
      <th>Quote</th>
      <th>Item / Description</th>
      <th>
        Qty of item
        <Tooltip
          title={
            <span
              dangerouslySetInnerHTML={{
                __html: qtyOfItemHelper,
              }}
            />
          }
        >
          <InfoOutlinedIcon sx={{ marginLeft: 1, cursor: "pointer" }} />
        </Tooltip>
      </th>
      {/* feet, boxes, each, etc */}
      <th>
        For every
        <Tooltip
          title={
            <span
              dangerouslySetInnerHTML={{
                __html: forEveryHelper,
              }}
            />
          }
        >
          <InfoOutlinedIcon sx={{ marginLeft: 1, cursor: "pointer" }} />
        </Tooltip>
      </th>
      <th>
        Takeoff variable
        <Tooltip
          title={
            <span
              dangerouslySetInnerHTML={{
                __html: takeoffVariableHelper,
              }}
            />
          }
        >
          <InfoOutlinedIcon sx={{ marginLeft: 1, cursor: "pointer" }} />
        </Tooltip>
      </th>
      <th>Action</th>
    </tr>
  );
};
