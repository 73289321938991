import { useCallback, useRef, useState } from "react";
import { useUnityBuildStore } from "../states/store";
import { ResetStates } from "../states/resetStates";
import { useStore } from "zustand";
import { useCreateAssemblyStore } from "../states/createAssemblyStore";
import { v4 as uuidv4 } from "uuid";
import { EstimateAssembly } from "../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import { ChangeOrderAssembly } from "../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";

// This function captures the area behind the point from the PDF canvas
export const capturePDFArea = async (
  pdfContainer: HTMLCanvasElement,
  cursorPositionRef: any,
  size: number
): Promise<string> => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = size;
  canvas.height = size;

  // Calculate the scaling factor based on the relationship between the canvas size and the PDF dimensions
  const scaleX = pdfContainer.width / pdfContainer.clientWidth;
  const scaleY = pdfContainer.height / pdfContainer.clientHeight;

  // Adjust the capture coordinates based on the scaling factors
  const captureX = cursorPositionRef.current.x * scaleX - size / 2;
  const captureY = cursorPositionRef.current.y * scaleY - size / 2;

  if (context) {
    context.drawImage(
      pdfContainer,
      captureX,
      captureY,
      size,
      size,
      0,
      0,
      size,
      size
    );
    return canvas.toDataURL("image/png", 1.0);
  }
  return "";
};

export const useHandleTakeoffMouseEvents = (svgRef: any) => {
  const store = useStore(useUnityBuildStore);
  const store1 = useStore(useCreateAssemblyStore);
  const {
    isAnnotating,
    selectedTakeoffAssembly,
    setSelectedTakeoffAssembly,
    isMeasuring,
    setMeasuredPoints,
    isDragging,
    setIsDragging,
    isEditingPoint,
    isEditingStyle,
    setIsEditingPoint,
    isEditingPointFillColor,
    isEditingPointBorderColor,
    isEditingLineColor,
    isInsertingPoint,
  } = useStore(useUnityBuildStore);
  const cursorPositionRef = useRef({ x: 0, y: 0 });
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const getAdjustedCursorPosition = (
    lastPoint: { x: number; y: number },
    currentCursor: { x: number; y: number },
    angleOptions: number[]
  ) => {
    const dx = currentCursor.x - lastPoint.x;
    const dy = currentCursor.y - lastPoint.y;

    // Calculate the angle from the last point to the cursor in degrees
    let angle = (Math.atan2(dy, dx) * 180) / Math.PI;

    // Ensure the angle is between 0 and 360 degrees
    if (angle < 0) {
      angle += 360;
    }

    // Find the closest allowed angle
    let closestAngle = angleOptions[0];
    let smallestDifference = Math.abs(angle - angleOptions[0]);

    for (let i = 1; i < angleOptions.length; i++) {
      const currentDifference = Math.abs(angle - angleOptions[i]);
      if (currentDifference < smallestDifference) {
        smallestDifference = currentDifference;
        closestAngle = angleOptions[i];
      }
    }

    // Convert the closest angle back to radians
    const snappedAngle = (closestAngle * Math.PI) / 180;

    // Calculate the distance between the last point and the current cursor
    const distance = Math.sqrt(dx * dx + dy * dy);

    // Calculate the new cursor position using the snapped angle
    const adjustedX = lastPoint.x + distance * Math.cos(snappedAngle);
    const adjustedY = lastPoint.y + distance * Math.sin(snappedAngle);

    return { x: adjustedX, y: adjustedY };
  };

  const calculateCursorPosition = useCallback(
    (e: any) => {
      if (!svgRef.current) return;
      const svg = svgRef.current;

      // Create an SVGPoint and set its coordinates to the mouse position
      const point = svg.createSVGPoint();
      point.x = e.clientX;
      point.y = e.clientY;

      // Get the inverse of the current transformation matrix
      const ctm = svg.getScreenCTM()?.inverse();
      if (ctm) {
        // Transform the point to SVG coordinates
        const cursorPoint = point.matrixTransform(ctm);
        cursorPositionRef.current = { x: cursorPoint.x, y: cursorPoint.y };
        setCursorPosition({ x: cursorPoint.x, y: cursorPoint.y });
      }
    },
    [svgRef]
  );

  const handleTakeoffButton0MouseEvents = async (e: any) => {
    if (!svgRef.current || e.button !== 0) return;

    if (e.altKey || e.ctrlKey) return;

    calculateCursorPosition(e);

    if (
      isDragging ||
      isEditingPoint ||
      isEditingStyle ||
      isEditingPointFillColor ||
      isEditingPointBorderColor ||
      isEditingLineColor
    ) {
      ResetStates.resetTakeoffStates(store, store1);
      return;
    }

    if (!isInsertingPoint && isAnnotating && selectedTakeoffAssembly) {
      const pointsArray:
        | EstimateAssembly.AsObject["pointsList"]
        | ChangeOrderAssembly.AsObject["pointsList"] = Array.isArray(
        selectedTakeoffAssembly.pointsList
      )
        ? selectedTakeoffAssembly.pointsList
        : [];

      // Adjust the cursor position using polar tracking
      let adjustedCursorPosition = cursorPositionRef.current;

      if (
        pointsArray.length > 0 &&
        (selectedTakeoffAssembly.assemblymeasurementtype === "length" ||
          selectedTakeoffAssembly.assemblymeasurementtype === "area")
      ) {
        // Adjust for angle snapping if necessary
        const lastPoint = pointsArray[pointsArray.length - 1];
        adjustedCursorPosition = getAdjustedCursorPosition(
          lastPoint,
          cursorPositionRef.current,
          store.angleOptions
        );
      }

      const updatedItem = {
        ...selectedTakeoffAssembly,
        pointsList: [
          ...pointsArray,
          {
            pointid: uuidv4(),
            x: adjustedCursorPosition.x,
            y: adjustedCursorPosition.y,
            angle: 0,
            verticallength: 0,
            allowtrailingline: true,
            allowtrailingmeasurement: true,
            isincludedincount: true,
            isselected: true,
            isactive: true,
          },
        ],
      };
      setSelectedTakeoffAssembly(updatedItem);
    }

    if (isMeasuring) {
      setMeasuredPoints((prev) => [...prev, cursorPositionRef.current]);
    }
  };

  const handleTakeoffButton2MouseEvents = (e: any) => {
    if (!svgRef.current || e.button !== 2) return;
    const x = e.clientX;
    const y = e.clientY;
    setIsDragging(false);
    setIsEditingPoint({ isEditingPoint: true, x: x, y: y });
  };

  const handleMouseMove = (e: any) => {
    if (!svgRef.current) return;
    calculateCursorPosition(e);
  };

  return {
    handleTakeoffButton0MouseEvents,
    handleTakeoffButton2MouseEvents,
    handleMouseMove,
    cursorPositionRef,
    cursorPosition,
  };
};
