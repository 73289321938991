import { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { useStore } from "zustand";
import { useSubmitTakeoffAssembly } from "../../hooks/useSubmitTakeoffAssembly";
import { ResetStates } from "../../states/resetStates";
import { useUnityBuildStore } from "../../states/store";
import { useSetSelectedAssemblyFromAssemblyPicker } from "../../hooks/useSetSelectedAssembly";
import { useCreateAssemblyStore } from "../../states/createAssemblyStore";
import { keepBoxInView } from "../../utils/keepBoxInView";

export const PointEditor = () => {
  const store = useStore(useUnityBuildStore);
  const store1 = useStore(useCreateAssemblyStore);
  const {
    assemblyIndex,
    pointIndex,
    editingPointX,
    editingPointY,
    includeAllPoints,
    selectedTakeoffAssembly,
    setIsEditingPoint,
    setIsAnnotating,
    setIncludeAllPoints,
    isAnnotating,
    setIsInsertingPoint,
  } = useStore(useUnityBuildStore);
  const [verticalLength, setVerticalLength] = useState<number>(0);
  const submitAssembly = useSubmitTakeoffAssembly();
  const handleSetSelectedAssembly = useSetSelectedAssemblyFromAssemblyPicker();

  useEffect(() => {
    if (
      selectedTakeoffAssembly &&
      selectedTakeoffAssembly.pointsList &&
      pointIndex !== null &&
      pointIndex !== undefined
    ) {
      setVerticalLength(
        selectedTakeoffAssembly.pointsList[pointIndex]?.verticallength || 0
      );
    }
  }, [selectedTakeoffAssembly, pointIndex]);

  const handleAddToRun = (assembly: any) => {
    setIsEditingPoint({ isEditingPoint: false, isEditingStyle: false });
    setIsAnnotating(true);
    handleSetSelectedAssembly(assembly);
  };

  const handleCompleteRun = async () => {
    if (selectedTakeoffAssembly) {
      try {
        if (selectedTakeoffAssembly.takeoffid) {
          await submitAssembly({
            assembly: selectedTakeoffAssembly,
            isUpdating: true,
          });
        } else {
          await submitAssembly({
            assembly: selectedTakeoffAssembly,
            isCreating: true,
          });
        }
        ResetStates.resetTakeoffStates(store, store1);
      } catch (error) {
        console.error("Error submitting assembly:", error);
      }
    }
    setIsAnnotating(false);
    setIsEditingPoint({ isEditingPoint: false, isEditingStyle: false });
  };

  const handleEditStyle = () => {
    setIsEditingPoint({ isEditingPoint: false, isEditingStyle: true });
  };

  const handleDeletePoint = async (assemblyIndex: any, pointIndex: any) => {
    if (selectedTakeoffAssembly === null) return;

    let updatedPoints = [...selectedTakeoffAssembly.pointsList];

    if (includeAllPoints) {
      updatedPoints = [];
    } else {
      updatedPoints = updatedPoints.filter(
        (point, index) => index !== pointIndex
      );
    }

    const updatedAssembly = {
      ...selectedTakeoffAssembly,
      pointsList: updatedPoints,
    };

    if (selectedTakeoffAssembly) {
      await submitAssembly({
        assembly: updatedAssembly,
        isUpdating: true,
      });
    }

    ResetStates.resetTakeoffStates(store, store1);
  };

  const handleAddVertical = async (pointIndex: any) => {
    if (selectedTakeoffAssembly === null) return;

    let updatedPoints = [...selectedTakeoffAssembly.pointsList];

    if (includeAllPoints) {
      updatedPoints = updatedPoints.map((point) => ({
        ...point,
        verticallength: verticalLength,
      }));
    } else {
      const updatedPoint = {
        ...updatedPoints[pointIndex],
        verticallength: verticalLength,
      };
      updatedPoints[pointIndex] = updatedPoint;
    }

    const updatedAssembly = {
      ...selectedTakeoffAssembly,
      pointsList: updatedPoints,
    };

    if (selectedTakeoffAssembly) {
      await submitAssembly({
        assembly: updatedAssembly,
        isUpdating: true,
      });
    }

    ResetStates.resetTakeoffStates(store, store1);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleAddVertical(pointIndex);
    }
  };

  const handleVerticalLengthChange = (e: any) => {
    const value = parseFloat(e.target.value);
    setVerticalLength(isNaN(value) ? 0 : value);
  };

  const handleIncludeAllPointsChange = (e: any) => {
    setIncludeAllPoints(e.target.checked);
  };

  const handleSetInsertPoint = (e: any) => {
    setIsInsertingPoint(true);
    setIsEditingPoint({ isEditingPoint: false });
  };

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const boxWidth = 200;
  const boxHeight = 300;

  const { x: adjustedX, y: adjustedY } = keepBoxInView(
    editingPointX,
    editingPointY,
    boxWidth,
    boxHeight,
    windowWidth,
    windowHeight
  );

  return (
    <Paper
      onContextMenu={(event) => event.preventDefault()}
      elevation={10}
      style={{
        position: "absolute",
        top: adjustedY ? adjustedY + 5 : 0,
        left: adjustedX ? adjustedX + 5 : 0,
        backgroundColor: store.userBackgroundColor,
        padding: "1rem",
        width: boxWidth,
        height: boxHeight,
        border: "1px solid black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <button
        style={{ cursor: "pointer", width: "100px" }}
        onClick={
          isAnnotating
            ? handleCompleteRun
            : () => handleAddToRun(selectedTakeoffAssembly)
        }
      >
        {isAnnotating ? "Complete Run" : "Add to Run"}
      </button>

      {selectedTakeoffAssembly?.assemblymeasurementtype === "length" ||
      selectedTakeoffAssembly?.assemblymeasurementtype === "area" ? (
        <button
          style={{ cursor: "pointer", width: "100px" }}
          onClick={handleSetInsertPoint}
        >
          Insert Point
        </button>
      ) : null}

      <button
        style={{ cursor: "pointer", width: "100px" }}
        onClick={handleEditStyle}
      >
        Edit Style
      </button>

      <div style={{ width: "100%", textAlign: "center" }}>
        _______________________
        <div style={{ marginBottom: "10px" }}>
          <label>
            Include all points:
            <input
              type="checkbox"
              checked={includeAllPoints}
              onChange={handleIncludeAllPointsChange}
              style={{ marginLeft: "10px" }}
            />
          </label>
        </div>
        <button
          style={{ cursor: "pointer", width: "100px", marginBottom: "10px" }}
          onClick={() => handleDeletePoint(assemblyIndex!, pointIndex!)}
        >
          Delete Point
        </button>
        {selectedTakeoffAssembly?.assemblymeasurementtype === "length" && (
          <div style={{ marginBottom: "10px" }}>
            Add length in feet (enter):
            <input
              type="number"
              value={verticalLength}
              onChange={handleVerticalLengthChange}
              onKeyDown={handleKeyDown}
              style={{ cursor: "pointer", width: "100px", marginLeft: "10px" }}
            />
          </div>
        )}
      </div>
    </Paper>
  );
};
