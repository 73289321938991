import React from "react";
import { MainMenu } from "./components/MainMenu";
import { QueryClient, QueryClientProvider } from "react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from "./AppRoutes"; // Import the new AppRoutes component

const queryClient = new QueryClient();
const oauthClientId = process.env.REACT_APP_OAUTH_CLIENT_ID!;

export const App = () => {
  return (
    <GoogleOAuthProvider clientId={oauthClientId}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
              overflow: "hidden",
              fontFamily: "'Roboto', sans-serif",
              color: "#333",
            }}
          >
            <MainMenu />
            <div style={{ flex: 1, overflow: "hidden" }}>
              <AppRoutes />
            </div>
          </div>
        </Router>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
};
