/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: folder.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as folder_pb from './folder_pb'; // proto import: "folder.proto"


export class FolderServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateFolder = new grpcWeb.MethodDescriptor(
    '/FolderService/CreateFolder',
    grpcWeb.MethodType.UNARY,
    folder_pb.CreateFolderRequest,
    folder_pb.CreateFolderResponse,
    (request: folder_pb.CreateFolderRequest) => {
      return request.serializeBinary();
    },
    folder_pb.CreateFolderResponse.deserializeBinary
  );

  createFolder(
    request: folder_pb.CreateFolderRequest,
    metadata?: grpcWeb.Metadata | null): Promise<folder_pb.CreateFolderResponse>;

  createFolder(
    request: folder_pb.CreateFolderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: folder_pb.CreateFolderResponse) => void): grpcWeb.ClientReadableStream<folder_pb.CreateFolderResponse>;

  createFolder(
    request: folder_pb.CreateFolderRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: folder_pb.CreateFolderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/FolderService/CreateFolder',
        request,
        metadata || {},
        this.methodDescriptorCreateFolder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/FolderService/CreateFolder',
    request,
    metadata || {},
    this.methodDescriptorCreateFolder);
  }

  methodDescriptorUpdateFolder = new grpcWeb.MethodDescriptor(
    '/FolderService/UpdateFolder',
    grpcWeb.MethodType.UNARY,
    folder_pb.UpdateFolderRequest,
    folder_pb.UpdateFolderResponse,
    (request: folder_pb.UpdateFolderRequest) => {
      return request.serializeBinary();
    },
    folder_pb.UpdateFolderResponse.deserializeBinary
  );

  updateFolder(
    request: folder_pb.UpdateFolderRequest,
    metadata?: grpcWeb.Metadata | null): Promise<folder_pb.UpdateFolderResponse>;

  updateFolder(
    request: folder_pb.UpdateFolderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: folder_pb.UpdateFolderResponse) => void): grpcWeb.ClientReadableStream<folder_pb.UpdateFolderResponse>;

  updateFolder(
    request: folder_pb.UpdateFolderRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: folder_pb.UpdateFolderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/FolderService/UpdateFolder',
        request,
        metadata || {},
        this.methodDescriptorUpdateFolder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/FolderService/UpdateFolder',
    request,
    metadata || {},
    this.methodDescriptorUpdateFolder);
  }

  methodDescriptorGetFolders = new grpcWeb.MethodDescriptor(
    '/FolderService/GetFolders',
    grpcWeb.MethodType.UNARY,
    folder_pb.GetFoldersRequest,
    folder_pb.GetFoldersResponse,
    (request: folder_pb.GetFoldersRequest) => {
      return request.serializeBinary();
    },
    folder_pb.GetFoldersResponse.deserializeBinary
  );

  getFolders(
    request: folder_pb.GetFoldersRequest,
    metadata?: grpcWeb.Metadata | null): Promise<folder_pb.GetFoldersResponse>;

  getFolders(
    request: folder_pb.GetFoldersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: folder_pb.GetFoldersResponse) => void): grpcWeb.ClientReadableStream<folder_pb.GetFoldersResponse>;

  getFolders(
    request: folder_pb.GetFoldersRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: folder_pb.GetFoldersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/FolderService/GetFolders',
        request,
        metadata || {},
        this.methodDescriptorGetFolders,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/FolderService/GetFolders',
    request,
    metadata || {},
    this.methodDescriptorGetFolders);
  }

  methodDescriptorDeleteFolder = new grpcWeb.MethodDescriptor(
    '/FolderService/DeleteFolder',
    grpcWeb.MethodType.UNARY,
    folder_pb.DeleteFolderRequest,
    folder_pb.DeleteFolderResponse,
    (request: folder_pb.DeleteFolderRequest) => {
      return request.serializeBinary();
    },
    folder_pb.DeleteFolderResponse.deserializeBinary
  );

  deleteFolder(
    request: folder_pb.DeleteFolderRequest,
    metadata?: grpcWeb.Metadata | null): Promise<folder_pb.DeleteFolderResponse>;

  deleteFolder(
    request: folder_pb.DeleteFolderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: folder_pb.DeleteFolderResponse) => void): grpcWeb.ClientReadableStream<folder_pb.DeleteFolderResponse>;

  deleteFolder(
    request: folder_pb.DeleteFolderRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: folder_pb.DeleteFolderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/FolderService/DeleteFolder',
        request,
        metadata || {},
        this.methodDescriptorDeleteFolder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/FolderService/DeleteFolder',
    request,
    metadata || {},
    this.methodDescriptorDeleteFolder);
  }

  methodDescriptorMoveFolder = new grpcWeb.MethodDescriptor(
    '/FolderService/MoveFolder',
    grpcWeb.MethodType.UNARY,
    folder_pb.MoveFolderRequest,
    folder_pb.MoveFolderResponse,
    (request: folder_pb.MoveFolderRequest) => {
      return request.serializeBinary();
    },
    folder_pb.MoveFolderResponse.deserializeBinary
  );

  moveFolder(
    request: folder_pb.MoveFolderRequest,
    metadata?: grpcWeb.Metadata | null): Promise<folder_pb.MoveFolderResponse>;

  moveFolder(
    request: folder_pb.MoveFolderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: folder_pb.MoveFolderResponse) => void): grpcWeb.ClientReadableStream<folder_pb.MoveFolderResponse>;

  moveFolder(
    request: folder_pb.MoveFolderRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: folder_pb.MoveFolderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/FolderService/MoveFolder',
        request,
        metadata || {},
        this.methodDescriptorMoveFolder,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/FolderService/MoveFolder',
    request,
    metadata || {},
    this.methodDescriptorMoveFolder);
  }

}

