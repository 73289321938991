import {
  CreateOrUpdateItemRequest,
  CreateOrUpdateItemResponse,
  DeleteGlobalItemRequest,
  DeleteGlobalItemResponse,
  GetAllGlobalItemsRequest,
  GetAllGlobalItemsResponse,
  GetAttributesRequest,
  GetAttributesResponse,
  GetItemAttributesRequest,
  GetItemAttributesResponse,
  GetItemsByFileIdRequest,
  GetItemsByFileIdResponse,
  GetItemsRequest,
  GetItemsResponse,
  CreateOrUpdateUoMRequest,
  CreateOrUpdateUoMResponse,
  DeleteUoMRequest,
  DeleteUoMResponse,
  GetUoMsRequest,
  GetUoMsResponse,
  CreateOrUpdateAttributeGroupRequest,
  CreateOrUpdateAttributeGroupResponse,
  DeleteAttributeGroupRequest,
  DeleteAttributeGroupResponse,
  GetAttributeGroupsRequest,
  GetAttributeGroupsResponse,
  CreateOrUpdateAttributeValueRequest,
  CreateOrUpdateAttributeValueResponse,
  DeleteAttributeValueRequest,
  DeleteAttributeValueResponse,
  GetAttributeValuesRequest,
  GetAttributeValuesResponse,
  GlobalItem,
  UoM,
  AttributeGroup,
  AttributeValue,
  ItemCost,
  UpdateItemCostResponse,
  UpdateItemCostRequest,
} from "./protosCompiled/globalItem/globalItem_pb";

import { clients } from "../clients/grpcClients";
import { useUnityBuildStore } from "../states/store";

const client = clients.globalItemServiceClient;

export class ItemService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  // createOrUpdateGlobalItem creates or updates a global item in the database
  public static async createOrUpdateGlobalItem(
    item: GlobalItem
  ): Promise<CreateOrUpdateItemResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateOrUpdateItemRequest();
      req.setItem(item);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.createOrUpdateItem(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  // getGlobalItems fetches a list of items by their IDs
  public static async getGlobalItems(
    ids: string[]
  ): Promise<GetItemsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetItemsRequest();
      req.setIdsList(ids);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.getGlobalItems(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  // getAllGlobalItems gets all items from the database based on active status
  public static async getAllGlobalItems(
    isActive: boolean
  ): Promise<GetAllGlobalItemsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetAllGlobalItemsRequest();
      req.setIsactive(isActive);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.getAllGlobalItems(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  // deleteGlobalItem deletes an item from the database
  public static async deleteItem(
    id: string
  ): Promise<DeleteGlobalItemResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteGlobalItemRequest();
      req.setId(id);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.deleteGlobalItem(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async createOrUpdateUoM(
    uom: UoM.AsObject
  ): Promise<CreateOrUpdateUoMResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateOrUpdateUoMRequest();
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      // Convert AsObject back to UoM protobuf message
      const uomMessage = new UoM();
      uomMessage.setId(uom.id);
      uomMessage.setName(uom.name);
      uomMessage.setValue(uom.value);

      req.setUom(uomMessage);

      client.createOrUpdateUoM(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getUoMs(): Promise<GetUoMsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetUoMsRequest();
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.getUoMs(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async deleteUoM(
    uomId: number
  ): Promise<DeleteUoMResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteUoMRequest();
      req.setUomid(uomId);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.deleteUoM(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async createOrUpdateAttributeGroup(
    attributeGroup: AttributeGroup.AsObject
  ): Promise<CreateOrUpdateAttributeGroupResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateOrUpdateAttributeGroupRequest();
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      // Convert AsObject back to AttributeGroup protobuf message
      const attributeGroupMessage = new AttributeGroup();
      attributeGroupMessage.setAttributegroupid(
        attributeGroup.attributegroupid
      );
      attributeGroupMessage.setAttributegroupname(
        attributeGroup.attributegroupname
      );

      req.setAttributegroup(attributeGroupMessage);

      client.createOrUpdateAttributeGroup(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getAttributeGroups(): Promise<GetAttributeGroupsResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetAttributeGroupsRequest();
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.getAttributeGroups(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async deleteAttributeGroup(
    attributeGroupId: number
  ): Promise<DeleteAttributeGroupResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteAttributeGroupRequest();
      req.setAttributegroupid(attributeGroupId);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.deleteAttributeGroup(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async createOrUpdateAttributeValue(
    attributeValue: AttributeValue.AsObject
  ): Promise<CreateOrUpdateAttributeValueResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new CreateOrUpdateAttributeValueRequest();
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      // Convert AsObject back to AttributeValue protobuf message
      const attributeValueMessage = new AttributeValue();
      attributeValueMessage.setAttributevalueid(
        attributeValue.attributevalueid
      );
      attributeValueMessage.setAttributevalue(attributeValue.attributevalue);
      attributeValueMessage.setAttributegroupid(
        attributeValue.attributegroupid
      );

      req.setAttributevalue(attributeValueMessage);

      client.createOrUpdateAttributeValue(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getAttributeValues(
    attributeGroupId: number
  ): Promise<GetAttributeValuesResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetAttributeValuesRequest();
      req.setAttributegroupid(attributeGroupId);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.getAttributeValues(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async deleteAttributeValue(
    attributeValueId: number
  ): Promise<DeleteAttributeValueResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new DeleteAttributeValueRequest();
      req.setAttributevalueid(attributeValueId);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.deleteAttributeValue(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getItemAttributes(
    itemId: string
  ): Promise<GetItemAttributesResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetItemAttributesRequest();
      req.setItemid(itemId);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.getItemAttributes(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getAttributes(): Promise<GetAttributesResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetAttributesRequest();
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);
      client.getAttributes(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async getItemsByFileId(
    fileId: string
  ): Promise<GetItemsByFileIdResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetItemsByFileIdRequest();
      req.setFileid(fileId);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.getItemsByFileId(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }

  public static async updateItemCost(
    itemCost: ItemCost.AsObject
  ): Promise<UpdateItemCostResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new UpdateItemCostRequest();
      req.setItemcostid(itemCost.itemcostid);
      req.setItemid(itemCost.itemid);
      req.setUomid(itemCost.uomid);
      req.setAttributevalueid(itemCost.itemattributevalueid);
      req.setItemunitcost(itemCost.itemunitcost);
      req.setHourlyproductionrate(itemCost.hourlyproductionrate);
      const metadata = ItemService.getMetadata();
      req.setSessiontoken(metadata.sessionToken);

      client.updateItemCost(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  }
}
