import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Calculations as c } from "../../utils/calculations";
import { GeneralExpenseRowProps } from "./closeoutTypes";

const quantityMultiplierOptions = [
  { name: "manual entry", value: 0 },
  { name: "total material cost", value: 1 },
  { name: "project duration (labor hours)", value: 2 },
  { name: "project duration (days)", value: 3 },
  { name: "project duration (months)", value: 4 },
];

export const GeneralExpenseRow: React.FC<
  GeneralExpenseRowProps & {
    handleDelete: (name: string) => void;
    handleFieldUpdate: (field: string, value: number) => void;
  }
> = ({ generalExpense, handleDelete, handleFieldUpdate }) => {
  const [quantity, setQuantity] = useState<string>(
    generalExpense.quantity.toFixed(2)
  );
  const [duration, setDuration] = useState<string>(
    generalExpense.duration.toFixed(2)
  );
  const [durationMultiplier, setDurationMultiplier] = useState<number>(0);
  const [unitcost, setUnitCost] = useState<string>(
    generalExpense.unitcost.toFixed(2)
  );

  useEffect(() => {
    setQuantity(generalExpense.quantity.toFixed(2));
    setDuration(generalExpense.duration.toFixed(2));
    setUnitCost(generalExpense.unitcost.toFixed(2));
  }, [generalExpense]);

  const handleDurationMultiplierChange = (e: any) => {
    const selectedValue = e.target.value as number;
    const selectedName = quantityMultiplierOptions.find(
      (option) => option.value === selectedValue
    )?.value;

    setDurationMultiplier(selectedValue);
    handleFieldUpdate("durationMultiplier", selectedValue);
    handleFieldUpdate("durationMultiplierName", selectedName || 0);
  };

  const handleFieldChange = (field: string, value: string) => {
    // Allow only numbers and the decimal point
    const regex = /^[0-9]*\.?[0-9]{0,2}$/;
    if (!regex.test(value)) return;

    switch (field) {
      case "quantity":
        if (parseFloat(value) > 9999.99) return;
        setQuantity(value);
        break;
      case "duration":
        if (parseFloat(value) > 9999.99) return;
        setDuration(value);
        break;
      case "unitcost":
        if (parseFloat(value) > 9999.99) return;
        setUnitCost(value);
        break;
      default:
        break;
    }
  };

  const handleBlur = (field: string, value: string) => {
    const floatValue = parseFloat(value) || 0;

    switch (field) {
      case "quantity":
        setQuantity(value);
        handleFieldUpdate(field, floatValue);
        break;
      case "duration":
        setDuration(value);
        handleFieldUpdate(field, floatValue);
        break;
      case "unitcost":
        setUnitCost(value);
        handleFieldUpdate(field, floatValue);
        break;
      default:
        break;
    }
  };

  const validQuantity = isNaN(parseFloat(quantity)) ? 0 : parseFloat(quantity);
  const validDuration = isNaN(parseFloat(duration)) ? 0 : parseFloat(duration);
  const validUnitCost = isNaN(parseFloat(unitcost)) ? 0 : parseFloat(unitcost);

  const fullCost = c.calculateGenExpenseTotal({
    ...generalExpense,
    quantity: validQuantity,
    unitcost: validUnitCost,
    duration: validDuration,
  });

  return (
      <tr>
        {/* Description */}
        <td>
          <button onClick={() => handleDelete(generalExpense.name)} style={{width:"40px"}}>
            <DeleteIcon />
          </button>
          {generalExpense.name}
        </td>
        {/* Quantity */}
        <td>
          <input
            type="text"
            value={quantity}
            onChange={(e) => handleFieldChange("quantity", e.target.value)}
            onBlur={(e) => handleBlur("quantity", e.target.value)}
            disabled={durationMultiplier !== 0}
          />
          <select
            value={durationMultiplier}
            onChange={handleDurationMultiplierChange}
          >
            {quantityMultiplierOptions.map((option, index) => (
              <option key={index} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
        </td>
        {/* Quantity Multiplier */}
        <td >
          <input
            type="text"
            value={duration}
            onChange={(e) => handleFieldChange("duration", e.target.value)}
            onBlur={(e) => handleBlur("duration", e.target.value)}
          />
        </td>
        {/* Unit Cost */}
        <td >
          <input
            type="text"
            value={unitcost}
            onChange={(e) => handleFieldChange("unitcost", e.target.value)}
            onBlur={(e) => handleBlur("unitcost", e.target.value)}
          />
        </td>
        {/* Total Cost */}
        <td >${fullCost.toFixed(2)}</td>
      </tr>
  );
};
