import React, { useEffect, useRef } from "react";
import { Box, Paper } from "@mui/material";
import { useFetchItemsByFileId } from "../../hooks/useFetchItemsByFileId";
import { ItemTableProps } from "../../types/FolderTypes";

export const ItemTable: React.FC<ItemTableProps> = ({
  folderOrFile,
  isMoveMode,
  onAddItem,
  handleRightClick,
  refetchFolders,
}) => {
  const {
    data: items,
    error,
    isLoading,
    refetchItems,
  } = useFetchItemsByFileId(folderOrFile.folderid);

  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    refetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchFolders]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading items: {error.message}</div>;
  }

  return (
    <div style={{ position: "relative" }}>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ width: "15%" }}>Attribute</th>
            <th>Description</th>
            <th style={{ width: "15%" }}>UOM</th>
          </tr>
        </thead>
        <tbody>
          {items && items.length > 0 ? (
            items.map((item) => {
              if (item.attributesList.length === 0) {
                return (
                  <tr
                    key={item.folderid}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onAddItem && onAddItem(item, null);
                    }}
                    onContextMenu={(e) => handleRightClick(e, item)}
                  >
                    <td>N/A</td>
                    <td>{item.name}</td>
                  </tr>
                );
              }

              return item.attributesList.map((attribute, index) => (
                <tr
                  key={`${item.folderid}-${index}`}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onAddItem && onAddItem(item, attribute);
                  }}
                  onContextMenu={(e) => handleRightClick(e, item)}
                >
                  <td>{attribute.attributevalue || "N/A"}</td>
                  <td>{item.name}</td>
                  <td>{item.costsList[0]?.uomname || "N/A"}</td>
                </tr>
              ));
            })
          ) : (
            <tr>
              <td colSpan={2}>No items found</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Instruction when Move Mode is active */}
      {isMoveMode && (
        <Box
          ref={menuRef}
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2,
          }}
        >
          <Paper
            style={{ padding: "10px", width: "300px", textAlign: "center" }}
          >
            <p>Click on the destination folder.</p>
          </Paper>
        </Box>
      )}
    </div>
  );
};
