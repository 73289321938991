// source: estimateAssembly.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.estimateAssembly.CreateEstimateAssemblyRequest', null, global);
goog.exportSymbol('proto.estimateAssembly.CreateEstimateAssemblyResponse', null, global);
goog.exportSymbol('proto.estimateAssembly.DeleteEstimateAssemblyRequest', null, global);
goog.exportSymbol('proto.estimateAssembly.DeleteEstimateAssemblyResponse', null, global);
goog.exportSymbol('proto.estimateAssembly.EstimateAssembly', null, global);
goog.exportSymbol('proto.estimateAssembly.EstimateAssemblyItemAttribute', null, global);
goog.exportSymbol('proto.estimateAssembly.EstimateItem', null, global);
goog.exportSymbol('proto.estimateAssembly.EstimateItemAttribute', null, global);
goog.exportSymbol('proto.estimateAssembly.EstimateItemCost', null, global);
goog.exportSymbol('proto.estimateAssembly.EstimateItemQtyFormula', null, global);
goog.exportSymbol('proto.estimateAssembly.EstimatePoint', null, global);
goog.exportSymbol('proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest', null, global);
goog.exportSymbol('proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse', null, global);
goog.exportSymbol('proto.estimateAssembly.GetTakeoffStatsRequest', null, global);
goog.exportSymbol('proto.estimateAssembly.GetTakeoffStatsResponse', null, global);
goog.exportSymbol('proto.estimateAssembly.GlobalAssembly', null, global);
goog.exportSymbol('proto.estimateAssembly.TakeoffStats', null, global);
goog.exportSymbol('proto.estimateAssembly.UpdateEstimateAssemblyRequest', null, global);
goog.exportSymbol('proto.estimateAssembly.UpdateEstimateAssemblyResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.EstimatePoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.EstimatePoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.EstimatePoint.displayName = 'proto.estimateAssembly.EstimatePoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.EstimateItemQtyFormula = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.estimateAssembly.EstimateItemQtyFormula.repeatedFields_, null);
};
goog.inherits(proto.estimateAssembly.EstimateItemQtyFormula, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.EstimateItemQtyFormula.displayName = 'proto.estimateAssembly.EstimateItemQtyFormula';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.EstimateAssemblyItemAttribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.EstimateAssemblyItemAttribute.displayName = 'proto.estimateAssembly.EstimateAssemblyItemAttribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.EstimateItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.estimateAssembly.EstimateItem.repeatedFields_, null);
};
goog.inherits(proto.estimateAssembly.EstimateItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.EstimateItem.displayName = 'proto.estimateAssembly.EstimateItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.EstimateItemAttribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.EstimateItemAttribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.EstimateItemAttribute.displayName = 'proto.estimateAssembly.EstimateItemAttribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.EstimateItemCost = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.EstimateItemCost, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.EstimateItemCost.displayName = 'proto.estimateAssembly.EstimateItemCost';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.EstimateAssembly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.estimateAssembly.EstimateAssembly.repeatedFields_, null);
};
goog.inherits(proto.estimateAssembly.EstimateAssembly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.EstimateAssembly.displayName = 'proto.estimateAssembly.EstimateAssembly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.GlobalAssembly = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.GlobalAssembly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.GlobalAssembly.displayName = 'proto.estimateAssembly.GlobalAssembly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.CreateEstimateAssemblyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.CreateEstimateAssemblyRequest.displayName = 'proto.estimateAssembly.CreateEstimateAssemblyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.CreateEstimateAssemblyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.CreateEstimateAssemblyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.CreateEstimateAssemblyResponse.displayName = 'proto.estimateAssembly.CreateEstimateAssemblyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.UpdateEstimateAssemblyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.UpdateEstimateAssemblyRequest.displayName = 'proto.estimateAssembly.UpdateEstimateAssemblyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.UpdateEstimateAssemblyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.UpdateEstimateAssemblyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.UpdateEstimateAssemblyResponse.displayName = 'proto.estimateAssembly.UpdateEstimateAssemblyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.DeleteEstimateAssemblyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.DeleteEstimateAssemblyRequest.displayName = 'proto.estimateAssembly.DeleteEstimateAssemblyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.DeleteEstimateAssemblyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.DeleteEstimateAssemblyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.DeleteEstimateAssemblyResponse.displayName = 'proto.estimateAssembly.DeleteEstimateAssemblyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.GetTakeoffStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.GetTakeoffStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.GetTakeoffStatsRequest.displayName = 'proto.estimateAssembly.GetTakeoffStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.TakeoffStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.estimateAssembly.TakeoffStats.repeatedFields_, null);
};
goog.inherits(proto.estimateAssembly.TakeoffStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.TakeoffStats.displayName = 'proto.estimateAssembly.TakeoffStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.GetTakeoffStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.estimateAssembly.GetTakeoffStatsResponse.repeatedFields_, null);
};
goog.inherits(proto.estimateAssembly.GetTakeoffStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.GetTakeoffStatsResponse.displayName = 'proto.estimateAssembly.GetTakeoffStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.displayName = 'proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.repeatedFields_, null);
};
goog.inherits(proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.displayName = 'proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.EstimatePoint.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.EstimatePoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.EstimatePoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimatePoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    pointid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    angle: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    verticallength: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    allowtrailingline: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    allowtrailingmeasurement: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isincludedincount: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isselected: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.EstimatePoint}
 */
proto.estimateAssembly.EstimatePoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.EstimatePoint;
  return proto.estimateAssembly.EstimatePoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.EstimatePoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.EstimatePoint}
 */
proto.estimateAssembly.EstimatePoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setX(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setY(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAngle(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVerticallength(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowtrailingline(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowtrailingmeasurement(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsincludedincount(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsselected(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.EstimatePoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.EstimatePoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.EstimatePoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimatePoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getX();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getVerticallength();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getAllowtrailingline();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getAllowtrailingmeasurement();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsincludedincount();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsselected();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional string pointId = 1;
 * @return {string}
 */
proto.estimateAssembly.EstimatePoint.prototype.getPointid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimatePoint} returns this
 */
proto.estimateAssembly.EstimatePoint.prototype.setPointid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float x = 2;
 * @return {number}
 */
proto.estimateAssembly.EstimatePoint.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimatePoint} returns this
 */
proto.estimateAssembly.EstimatePoint.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float y = 3;
 * @return {number}
 */
proto.estimateAssembly.EstimatePoint.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimatePoint} returns this
 */
proto.estimateAssembly.EstimatePoint.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float angle = 4;
 * @return {number}
 */
proto.estimateAssembly.EstimatePoint.prototype.getAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimatePoint} returns this
 */
proto.estimateAssembly.EstimatePoint.prototype.setAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float verticalLength = 5;
 * @return {number}
 */
proto.estimateAssembly.EstimatePoint.prototype.getVerticallength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimatePoint} returns this
 */
proto.estimateAssembly.EstimatePoint.prototype.setVerticallength = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool allowTrailingLine = 6;
 * @return {boolean}
 */
proto.estimateAssembly.EstimatePoint.prototype.getAllowtrailingline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.EstimatePoint} returns this
 */
proto.estimateAssembly.EstimatePoint.prototype.setAllowtrailingline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool allowTrailingMeasurement = 7;
 * @return {boolean}
 */
proto.estimateAssembly.EstimatePoint.prototype.getAllowtrailingmeasurement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.EstimatePoint} returns this
 */
proto.estimateAssembly.EstimatePoint.prototype.setAllowtrailingmeasurement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool isIncludedInCount = 8;
 * @return {boolean}
 */
proto.estimateAssembly.EstimatePoint.prototype.getIsincludedincount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.EstimatePoint} returns this
 */
proto.estimateAssembly.EstimatePoint.prototype.setIsincludedincount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool isSelected = 9;
 * @return {boolean}
 */
proto.estimateAssembly.EstimatePoint.prototype.getIsselected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.EstimatePoint} returns this
 */
proto.estimateAssembly.EstimatePoint.prototype.setIsselected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool isActive = 10;
 * @return {boolean}
 */
proto.estimateAssembly.EstimatePoint.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.EstimatePoint} returns this
 */
proto.estimateAssembly.EstimatePoint.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.estimateAssembly.EstimateItemQtyFormula.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.EstimateItemQtyFormula.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.EstimateItemQtyFormula} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateItemQtyFormula.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemqty: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    forevery: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    takeoffvariabletype: jspb.Message.getFieldWithDefault(msg, 5, ""),
    and: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    or: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    attributesList: jspb.Message.toObjectList(msg.getAttributesList(),
    proto.estimateAssembly.EstimateAssemblyItemAttribute.toObject, includeInstance),
    isquoted: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    assemblyname: jspb.Message.getFieldWithDefault(msg, 10, ""),
    quotegroup: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula}
 */
proto.estimateAssembly.EstimateItemQtyFormula.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.EstimateItemQtyFormula;
  return proto.estimateAssembly.EstimateItemQtyFormula.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.EstimateItemQtyFormula} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula}
 */
proto.estimateAssembly.EstimateItemQtyFormula.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemname(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemqty(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setForevery(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTakeoffvariabletype(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnd(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOr(value);
      break;
    case 8:
      var value = new proto.estimateAssembly.EstimateAssemblyItemAttribute;
      reader.readMessage(value,proto.estimateAssembly.EstimateAssemblyItemAttribute.deserializeBinaryFromReader);
      msg.addAttributes(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsquoted(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyname(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuotegroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.EstimateItemQtyFormula.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.EstimateItemQtyFormula} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateItemQtyFormula.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemqty();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getForevery();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTakeoffvariabletype();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAnd();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getOr();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.estimateAssembly.EstimateAssemblyItemAttribute.serializeBinaryToWriter
    );
  }
  f = message.getIsquoted();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getAssemblyname();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getQuotegroup();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string itemId = 1;
 * @return {string}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getItemid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setItemid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string itemName = 2;
 * @return {string}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getItemname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setItemname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float itemQty = 3;
 * @return {number}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getItemqty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setItemqty = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float forEvery = 4;
 * @return {number}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getForevery = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setForevery = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string takeoffVariableType = 5;
 * @return {string}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getTakeoffvariabletype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setTakeoffvariabletype = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool and = 6;
 * @return {boolean}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getAnd = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setAnd = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool or = 7;
 * @return {boolean}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getOr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setOr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated EstimateAssemblyItemAttribute attributes = 8;
 * @return {!Array<!proto.estimateAssembly.EstimateAssemblyItemAttribute>}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getAttributesList = function() {
  return /** @type{!Array<!proto.estimateAssembly.EstimateAssemblyItemAttribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.estimateAssembly.EstimateAssemblyItemAttribute, 8));
};


/**
 * @param {!Array<!proto.estimateAssembly.EstimateAssemblyItemAttribute>} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
*/
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setAttributesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.estimateAssembly.EstimateAssemblyItemAttribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.estimateAssembly.EstimateAssemblyItemAttribute}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.addAttributes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.estimateAssembly.EstimateAssemblyItemAttribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.clearAttributesList = function() {
  return this.setAttributesList([]);
};


/**
 * optional bool isQuoted = 9;
 * @return {boolean}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getIsquoted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setIsquoted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string assemblyName = 10;
 * @return {string}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getAssemblyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setAssemblyname = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string quoteGroup = 11;
 * @return {string}
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.getQuotegroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula} returns this
 */
proto.estimateAssembly.EstimateItemQtyFormula.prototype.setQuotegroup = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.EstimateAssemblyItemAttribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.EstimateAssemblyItemAttribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    attributegroupid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    attributevalueid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    attributevalue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    attributegroupname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.EstimateAssemblyItemAttribute}
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.EstimateAssemblyItemAttribute;
  return proto.estimateAssembly.EstimateAssemblyItemAttribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.EstimateAssemblyItemAttribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.EstimateAssemblyItemAttribute}
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributegroupid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributevalueid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributevalue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributegroupname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.EstimateAssemblyItemAttribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.EstimateAssemblyItemAttribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAttributegroupid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAttributevalueid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAttributevalue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAttributegroupname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 attributeGroupId = 1;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.getAttributegroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssemblyItemAttribute} returns this
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.setAttributegroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 attributeValueId = 2;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.getAttributevalueid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssemblyItemAttribute} returns this
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.setAttributevalueid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string attributeValue = 3;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.getAttributevalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssemblyItemAttribute} returns this
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.setAttributevalue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string attributeGroupName = 4;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.getAttributegroupname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssemblyItemAttribute} returns this
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.setAttributegroupname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string lastUpdated = 5;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssemblyItemAttribute} returns this
 */
proto.estimateAssembly.EstimateAssemblyItemAttribute.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.estimateAssembly.EstimateItem.repeatedFields_ = [4,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.EstimateItem.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.EstimateItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.EstimateItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    costsList: jspb.Message.toObjectList(msg.getCostsList(),
    proto.estimateAssembly.EstimateItemCost.toObject, includeInstance),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 5, ""),
    parentid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    level: jspb.Message.getFieldWithDefault(msg, 7, 0),
    entitytype: jspb.Message.getFieldWithDefault(msg, 8, ""),
    itemqtyformulasList: jspb.Message.toObjectList(msg.getItemqtyformulasList(),
    proto.estimateAssembly.EstimateItemQtyFormula.toObject, includeInstance),
    imageclassificationid: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.EstimateItem}
 */
proto.estimateAssembly.EstimateItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.EstimateItem;
  return proto.estimateAssembly.EstimateItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.EstimateItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.EstimateItem}
 */
proto.estimateAssembly.EstimateItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemname(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    case 4:
      var value = new proto.estimateAssembly.EstimateItemCost;
      reader.readMessage(value,proto.estimateAssembly.EstimateItemCost.deserializeBinaryFromReader);
      msg.addCosts(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLevel(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntitytype(value);
      break;
    case 9:
      var value = new proto.estimateAssembly.EstimateItemQtyFormula;
      reader.readMessage(value,proto.estimateAssembly.EstimateItemQtyFormula.deserializeBinaryFromReader);
      msg.addItemqtyformulas(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setImageclassificationid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.EstimateItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.EstimateItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.EstimateItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCostsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.estimateAssembly.EstimateItemCost.serializeBinaryToWriter
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getParentid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getEntitytype();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getItemqtyformulasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.estimateAssembly.EstimateItemQtyFormula.serializeBinaryToWriter
    );
  }
  f = message.getImageclassificationid();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional string itemId = 1;
 * @return {string}
 */
proto.estimateAssembly.EstimateItem.prototype.getItemid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItem} returns this
 */
proto.estimateAssembly.EstimateItem.prototype.setItemid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string itemName = 2;
 * @return {string}
 */
proto.estimateAssembly.EstimateItem.prototype.getItemname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItem} returns this
 */
proto.estimateAssembly.EstimateItem.prototype.setItemname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isActive = 3;
 * @return {boolean}
 */
proto.estimateAssembly.EstimateItem.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.EstimateItem} returns this
 */
proto.estimateAssembly.EstimateItem.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated EstimateItemCost costs = 4;
 * @return {!Array<!proto.estimateAssembly.EstimateItemCost>}
 */
proto.estimateAssembly.EstimateItem.prototype.getCostsList = function() {
  return /** @type{!Array<!proto.estimateAssembly.EstimateItemCost>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.estimateAssembly.EstimateItemCost, 4));
};


/**
 * @param {!Array<!proto.estimateAssembly.EstimateItemCost>} value
 * @return {!proto.estimateAssembly.EstimateItem} returns this
*/
proto.estimateAssembly.EstimateItem.prototype.setCostsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.estimateAssembly.EstimateItemCost=} opt_value
 * @param {number=} opt_index
 * @return {!proto.estimateAssembly.EstimateItemCost}
 */
proto.estimateAssembly.EstimateItem.prototype.addCosts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.estimateAssembly.EstimateItemCost, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.estimateAssembly.EstimateItem} returns this
 */
proto.estimateAssembly.EstimateItem.prototype.clearCostsList = function() {
  return this.setCostsList([]);
};


/**
 * optional string lastUpdated = 5;
 * @return {string}
 */
proto.estimateAssembly.EstimateItem.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItem} returns this
 */
proto.estimateAssembly.EstimateItem.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string parentId = 6;
 * @return {string}
 */
proto.estimateAssembly.EstimateItem.prototype.getParentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItem} returns this
 */
proto.estimateAssembly.EstimateItem.prototype.setParentid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 level = 7;
 * @return {number}
 */
proto.estimateAssembly.EstimateItem.prototype.getLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateItem} returns this
 */
proto.estimateAssembly.EstimateItem.prototype.setLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string entityType = 8;
 * @return {string}
 */
proto.estimateAssembly.EstimateItem.prototype.getEntitytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItem} returns this
 */
proto.estimateAssembly.EstimateItem.prototype.setEntitytype = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated EstimateItemQtyFormula itemQtyFormulas = 9;
 * @return {!Array<!proto.estimateAssembly.EstimateItemQtyFormula>}
 */
proto.estimateAssembly.EstimateItem.prototype.getItemqtyformulasList = function() {
  return /** @type{!Array<!proto.estimateAssembly.EstimateItemQtyFormula>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.estimateAssembly.EstimateItemQtyFormula, 9));
};


/**
 * @param {!Array<!proto.estimateAssembly.EstimateItemQtyFormula>} value
 * @return {!proto.estimateAssembly.EstimateItem} returns this
*/
proto.estimateAssembly.EstimateItem.prototype.setItemqtyformulasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.estimateAssembly.EstimateItemQtyFormula=} opt_value
 * @param {number=} opt_index
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula}
 */
proto.estimateAssembly.EstimateItem.prototype.addItemqtyformulas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.estimateAssembly.EstimateItemQtyFormula, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.estimateAssembly.EstimateItem} returns this
 */
proto.estimateAssembly.EstimateItem.prototype.clearItemqtyformulasList = function() {
  return this.setItemqtyformulasList([]);
};


/**
 * optional int32 imageClassificationId = 10;
 * @return {number}
 */
proto.estimateAssembly.EstimateItem.prototype.getImageclassificationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateItem} returns this
 */
proto.estimateAssembly.EstimateItem.prototype.setImageclassificationid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.EstimateItemAttribute.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.EstimateItemAttribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.EstimateItemAttribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateItemAttribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    attributegroupid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    attributevalueid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    attributevalue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    attributegroupname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.EstimateItemAttribute}
 */
proto.estimateAssembly.EstimateItemAttribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.EstimateItemAttribute;
  return proto.estimateAssembly.EstimateItemAttribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.EstimateItemAttribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.EstimateItemAttribute}
 */
proto.estimateAssembly.EstimateItemAttribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributegroupid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttributevalueid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributevalue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributegroupname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.EstimateItemAttribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.EstimateItemAttribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.EstimateItemAttribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateItemAttribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAttributegroupid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAttributevalueid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAttributevalue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAttributegroupname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 attributeGroupId = 1;
 * @return {number}
 */
proto.estimateAssembly.EstimateItemAttribute.prototype.getAttributegroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateItemAttribute} returns this
 */
proto.estimateAssembly.EstimateItemAttribute.prototype.setAttributegroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 attributeValueId = 2;
 * @return {number}
 */
proto.estimateAssembly.EstimateItemAttribute.prototype.getAttributevalueid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateItemAttribute} returns this
 */
proto.estimateAssembly.EstimateItemAttribute.prototype.setAttributevalueid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string attributeValue = 3;
 * @return {string}
 */
proto.estimateAssembly.EstimateItemAttribute.prototype.getAttributevalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItemAttribute} returns this
 */
proto.estimateAssembly.EstimateItemAttribute.prototype.setAttributevalue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string attributeGroupName = 4;
 * @return {string}
 */
proto.estimateAssembly.EstimateItemAttribute.prototype.getAttributegroupname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItemAttribute} returns this
 */
proto.estimateAssembly.EstimateItemAttribute.prototype.setAttributegroupname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string lastUpdated = 5;
 * @return {string}
 */
proto.estimateAssembly.EstimateItemAttribute.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItemAttribute} returns this
 */
proto.estimateAssembly.EstimateItemAttribute.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.EstimateItemCost.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.EstimateItemCost.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.EstimateItemCost} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateItemCost.toObject = function(includeInstance, msg) {
  var f, obj = {
    uomid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemunitcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    hourlyproductionrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 4, ""),
    uomname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    uomvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.EstimateItemCost}
 */
proto.estimateAssembly.EstimateItemCost.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.EstimateItemCost;
  return proto.estimateAssembly.EstimateItemCost.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.EstimateItemCost} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.EstimateItemCost}
 */
proto.estimateAssembly.EstimateItemCost.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUomid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setItemunitcost(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHourlyproductionrate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUomname(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUomvalue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.EstimateItemCost.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.EstimateItemCost.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.EstimateItemCost} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateItemCost.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUomid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getItemunitcost();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getHourlyproductionrate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUomname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUomvalue();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional int32 uomId = 1;
 * @return {number}
 */
proto.estimateAssembly.EstimateItemCost.prototype.getUomid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateItemCost} returns this
 */
proto.estimateAssembly.EstimateItemCost.prototype.setUomid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float itemUnitCost = 2;
 * @return {number}
 */
proto.estimateAssembly.EstimateItemCost.prototype.getItemunitcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateItemCost} returns this
 */
proto.estimateAssembly.EstimateItemCost.prototype.setItemunitcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float hourlyProductionRate = 3;
 * @return {number}
 */
proto.estimateAssembly.EstimateItemCost.prototype.getHourlyproductionrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateItemCost} returns this
 */
proto.estimateAssembly.EstimateItemCost.prototype.setHourlyproductionrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string lastUpdated = 4;
 * @return {string}
 */
proto.estimateAssembly.EstimateItemCost.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItemCost} returns this
 */
proto.estimateAssembly.EstimateItemCost.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string uomName = 5;
 * @return {string}
 */
proto.estimateAssembly.EstimateItemCost.prototype.getUomname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateItemCost} returns this
 */
proto.estimateAssembly.EstimateItemCost.prototype.setUomname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float uomValue = 6;
 * @return {number}
 */
proto.estimateAssembly.EstimateItemCost.prototype.getUomvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateItemCost} returns this
 */
proto.estimateAssembly.EstimateItemCost.prototype.setUomvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.estimateAssembly.EstimateAssembly.repeatedFields_ = [7,20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.EstimateAssembly.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.EstimateAssembly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.EstimateAssembly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateAssembly.toObject = function(includeInstance, msg) {
  var f, obj = {
    takeoffid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    estimateid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    objectid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    segmentlength: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    segmentcount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.estimateAssembly.EstimatePoint.toObject, includeInstance),
    pointcount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    pointtype: jspb.Message.getFieldWithDefault(msg, 9, ""),
    pointsize: jspb.Message.getFieldWithDefault(msg, 10, 0),
    pointbordercolor: jspb.Message.getFieldWithDefault(msg, 11, ""),
    pointfillcolor: jspb.Message.getFieldWithDefault(msg, 12, ""),
    linesize: jspb.Message.getFieldWithDefault(msg, 13, 0),
    linecolor: jspb.Message.getFieldWithDefault(msg, 14, ""),
    linedasharray: jspb.Message.getFieldWithDefault(msg, 15, ""),
    userscale: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 17, 0),
    assemblyname: jspb.Message.getFieldWithDefault(msg, 18, ""),
    assemblymeasurementtype: jspb.Message.getFieldWithDefault(msg, 19, ""),
    itemqtyformulasList: jspb.Message.toObjectList(msg.getItemqtyformulasList(),
    proto.estimateAssembly.EstimateItemQtyFormula.toObject, includeInstance),
    imageclassificationid: jspb.Message.getFieldWithDefault(msg, 21, 0),
    datecreated: jspb.Message.getFieldWithDefault(msg, 22, ""),
    lastupdated: jspb.Message.getFieldWithDefault(msg, 23, ""),
    sqft: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.EstimateAssembly}
 */
proto.estimateAssembly.EstimateAssembly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.EstimateAssembly;
  return proto.estimateAssembly.EstimateAssembly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.EstimateAssembly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.EstimateAssembly}
 */
proto.estimateAssembly.EstimateAssembly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTakeoffid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSegmentlength(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSegmentcount(value);
      break;
    case 7:
      var value = new proto.estimateAssembly.EstimatePoint;
      reader.readMessage(value,proto.estimateAssembly.EstimatePoint.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPointcount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointtype(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPointsize(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointbordercolor(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointfillcolor(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLinesize(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinecolor(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinedasharray(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUserscale(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenumber(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyname(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblymeasurementtype(value);
      break;
    case 20:
      var value = new proto.estimateAssembly.EstimateItemQtyFormula;
      reader.readMessage(value,proto.estimateAssembly.EstimateItemQtyFormula.deserializeBinaryFromReader);
      msg.addItemqtyformulas(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setImageclassificationid(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastupdated(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSqft(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.EstimateAssembly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.EstimateAssembly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.EstimateAssembly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.EstimateAssembly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTakeoffid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getObjectid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSegmentlength();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getSegmentcount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.estimateAssembly.EstimatePoint.serializeBinaryToWriter
    );
  }
  f = message.getPointcount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getPointtype();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPointsize();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getPointbordercolor();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPointfillcolor();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLinesize();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getLinecolor();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getLinedasharray();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUserscale();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getAssemblyname();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getAssemblymeasurementtype();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getItemqtyformulasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.estimateAssembly.EstimateItemQtyFormula.serializeBinaryToWriter
    );
  }
  f = message.getImageclassificationid();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getLastupdated();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getSqft();
  if (f !== 0.0) {
    writer.writeFloat(
      24,
      f
    );
  }
};


/**
 * optional string takeoffId = 1;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getTakeoffid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setTakeoffid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string projectId = 2;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getProjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setProjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string estimateId = 3;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string objectId = 4;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getObjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setObjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional float segmentLength = 5;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getSegmentlength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setSegmentlength = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 segmentCount = 6;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getSegmentcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setSegmentcount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated EstimatePoint points = 7;
 * @return {!Array<!proto.estimateAssembly.EstimatePoint>}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.estimateAssembly.EstimatePoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.estimateAssembly.EstimatePoint, 7));
};


/**
 * @param {!Array<!proto.estimateAssembly.EstimatePoint>} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
*/
proto.estimateAssembly.EstimateAssembly.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.estimateAssembly.EstimatePoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.estimateAssembly.EstimatePoint}
 */
proto.estimateAssembly.EstimateAssembly.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.estimateAssembly.EstimatePoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};


/**
 * optional int32 pointCount = 8;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getPointcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setPointcount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string pointType = 9;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getPointtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setPointtype = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 pointSize = 10;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getPointsize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setPointsize = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string pointBorderColor = 11;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getPointbordercolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setPointbordercolor = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string pointFillColor = 12;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getPointfillcolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setPointfillcolor = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int32 lineSize = 13;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getLinesize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setLinesize = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string lineColor = 14;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getLinecolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setLinecolor = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string lineDashArray = 15;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getLinedasharray = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setLinedasharray = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional double userScale = 16;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getUserscale = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setUserscale = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional int32 pageNumber = 17;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string assemblyName = 18;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getAssemblyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setAssemblyname = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string assemblyMeasurementType = 19;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getAssemblymeasurementtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setAssemblymeasurementtype = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * repeated EstimateItemQtyFormula itemQtyFormulas = 20;
 * @return {!Array<!proto.estimateAssembly.EstimateItemQtyFormula>}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getItemqtyformulasList = function() {
  return /** @type{!Array<!proto.estimateAssembly.EstimateItemQtyFormula>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.estimateAssembly.EstimateItemQtyFormula, 20));
};


/**
 * @param {!Array<!proto.estimateAssembly.EstimateItemQtyFormula>} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
*/
proto.estimateAssembly.EstimateAssembly.prototype.setItemqtyformulasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.estimateAssembly.EstimateItemQtyFormula=} opt_value
 * @param {number=} opt_index
 * @return {!proto.estimateAssembly.EstimateItemQtyFormula}
 */
proto.estimateAssembly.EstimateAssembly.prototype.addItemqtyformulas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.estimateAssembly.EstimateItemQtyFormula, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.clearItemqtyformulasList = function() {
  return this.setItemqtyformulasList([]);
};


/**
 * optional int32 imageClassificationId = 21;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getImageclassificationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setImageclassificationid = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional string dateCreated = 22;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string lastUpdated = 23;
 * @return {string}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getLastupdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setLastupdated = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional float sqft = 24;
 * @return {number}
 */
proto.estimateAssembly.EstimateAssembly.prototype.getSqft = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.EstimateAssembly} returns this
 */
proto.estimateAssembly.EstimateAssembly.prototype.setSqft = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.GlobalAssembly.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.GlobalAssembly.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.GlobalAssembly} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.GlobalAssembly.toObject = function(includeInstance, msg) {
  var f, obj = {
    assemblyid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assemblyname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assemblymeasurementtype: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.GlobalAssembly}
 */
proto.estimateAssembly.GlobalAssembly.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.GlobalAssembly;
  return proto.estimateAssembly.GlobalAssembly.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.GlobalAssembly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.GlobalAssembly}
 */
proto.estimateAssembly.GlobalAssembly.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblyname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssemblymeasurementtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.GlobalAssembly.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.GlobalAssembly.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.GlobalAssembly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.GlobalAssembly.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssemblyid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssemblyname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssemblymeasurementtype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string assemblyId = 1;
 * @return {string}
 */
proto.estimateAssembly.GlobalAssembly.prototype.getAssemblyid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.GlobalAssembly} returns this
 */
proto.estimateAssembly.GlobalAssembly.prototype.setAssemblyid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assemblyName = 2;
 * @return {string}
 */
proto.estimateAssembly.GlobalAssembly.prototype.getAssemblyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.GlobalAssembly} returns this
 */
proto.estimateAssembly.GlobalAssembly.prototype.setAssemblyname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string assemblyMeasurementType = 3;
 * @return {string}
 */
proto.estimateAssembly.GlobalAssembly.prototype.getAssemblymeasurementtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.GlobalAssembly} returns this
 */
proto.estimateAssembly.GlobalAssembly.prototype.setAssemblymeasurementtype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.CreateEstimateAssemblyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.CreateEstimateAssemblyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateassembly: (f = msg.getEstimateassembly()) && proto.estimateAssembly.EstimateAssembly.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.CreateEstimateAssemblyRequest}
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.CreateEstimateAssemblyRequest;
  return proto.estimateAssembly.CreateEstimateAssemblyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.CreateEstimateAssemblyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.CreateEstimateAssemblyRequest}
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.estimateAssembly.EstimateAssembly;
      reader.readMessage(value,proto.estimateAssembly.EstimateAssembly.deserializeBinaryFromReader);
      msg.setEstimateassembly(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.CreateEstimateAssemblyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.CreateEstimateAssemblyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateassembly();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.estimateAssembly.EstimateAssembly.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional EstimateAssembly estimateAssembly = 1;
 * @return {?proto.estimateAssembly.EstimateAssembly}
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.prototype.getEstimateassembly = function() {
  return /** @type{?proto.estimateAssembly.EstimateAssembly} */ (
    jspb.Message.getWrapperField(this, proto.estimateAssembly.EstimateAssembly, 1));
};


/**
 * @param {?proto.estimateAssembly.EstimateAssembly|undefined} value
 * @return {!proto.estimateAssembly.CreateEstimateAssemblyRequest} returns this
*/
proto.estimateAssembly.CreateEstimateAssemblyRequest.prototype.setEstimateassembly = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.estimateAssembly.CreateEstimateAssemblyRequest} returns this
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.prototype.clearEstimateassembly = function() {
  return this.setEstimateassembly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.prototype.hasEstimateassembly = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.CreateEstimateAssemblyRequest} returns this
 */
proto.estimateAssembly.CreateEstimateAssemblyRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.CreateEstimateAssemblyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.CreateEstimateAssemblyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.CreateEstimateAssemblyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.CreateEstimateAssemblyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.CreateEstimateAssemblyResponse}
 */
proto.estimateAssembly.CreateEstimateAssemblyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.CreateEstimateAssemblyResponse;
  return proto.estimateAssembly.CreateEstimateAssemblyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.CreateEstimateAssemblyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.CreateEstimateAssemblyResponse}
 */
proto.estimateAssembly.CreateEstimateAssemblyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.CreateEstimateAssemblyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.CreateEstimateAssemblyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.CreateEstimateAssemblyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.CreateEstimateAssemblyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.estimateAssembly.CreateEstimateAssemblyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.CreateEstimateAssemblyResponse} returns this
 */
proto.estimateAssembly.CreateEstimateAssemblyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.UpdateEstimateAssemblyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.UpdateEstimateAssemblyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateassembly: (f = msg.getEstimateassembly()) && proto.estimateAssembly.EstimateAssembly.toObject(includeInstance, f),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.UpdateEstimateAssemblyRequest}
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.UpdateEstimateAssemblyRequest;
  return proto.estimateAssembly.UpdateEstimateAssemblyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.UpdateEstimateAssemblyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.UpdateEstimateAssemblyRequest}
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.estimateAssembly.EstimateAssembly;
      reader.readMessage(value,proto.estimateAssembly.EstimateAssembly.deserializeBinaryFromReader);
      msg.setEstimateassembly(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.UpdateEstimateAssemblyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.UpdateEstimateAssemblyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateassembly();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.estimateAssembly.EstimateAssembly.serializeBinaryToWriter
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional EstimateAssembly estimateAssembly = 1;
 * @return {?proto.estimateAssembly.EstimateAssembly}
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.prototype.getEstimateassembly = function() {
  return /** @type{?proto.estimateAssembly.EstimateAssembly} */ (
    jspb.Message.getWrapperField(this, proto.estimateAssembly.EstimateAssembly, 1));
};


/**
 * @param {?proto.estimateAssembly.EstimateAssembly|undefined} value
 * @return {!proto.estimateAssembly.UpdateEstimateAssemblyRequest} returns this
*/
proto.estimateAssembly.UpdateEstimateAssemblyRequest.prototype.setEstimateassembly = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.estimateAssembly.UpdateEstimateAssemblyRequest} returns this
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.prototype.clearEstimateassembly = function() {
  return this.setEstimateassembly(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.prototype.hasEstimateassembly = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.UpdateEstimateAssemblyRequest} returns this
 */
proto.estimateAssembly.UpdateEstimateAssemblyRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.UpdateEstimateAssemblyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.UpdateEstimateAssemblyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.UpdateEstimateAssemblyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.UpdateEstimateAssemblyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.UpdateEstimateAssemblyResponse}
 */
proto.estimateAssembly.UpdateEstimateAssemblyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.UpdateEstimateAssemblyResponse;
  return proto.estimateAssembly.UpdateEstimateAssemblyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.UpdateEstimateAssemblyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.UpdateEstimateAssemblyResponse}
 */
proto.estimateAssembly.UpdateEstimateAssemblyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.UpdateEstimateAssemblyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.UpdateEstimateAssemblyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.UpdateEstimateAssemblyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.UpdateEstimateAssemblyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.estimateAssembly.UpdateEstimateAssemblyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.UpdateEstimateAssemblyResponse} returns this
 */
proto.estimateAssembly.UpdateEstimateAssemblyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.DeleteEstimateAssemblyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.DeleteEstimateAssemblyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.DeleteEstimateAssemblyRequest}
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.DeleteEstimateAssemblyRequest;
  return proto.estimateAssembly.DeleteEstimateAssemblyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.DeleteEstimateAssemblyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.DeleteEstimateAssemblyRequest}
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.DeleteEstimateAssemblyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.DeleteEstimateAssemblyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.DeleteEstimateAssemblyRequest} returns this
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.DeleteEstimateAssemblyRequest} returns this
 */
proto.estimateAssembly.DeleteEstimateAssemblyRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.DeleteEstimateAssemblyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.DeleteEstimateAssemblyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.DeleteEstimateAssemblyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.DeleteEstimateAssemblyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.DeleteEstimateAssemblyResponse}
 */
proto.estimateAssembly.DeleteEstimateAssemblyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.DeleteEstimateAssemblyResponse;
  return proto.estimateAssembly.DeleteEstimateAssemblyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.DeleteEstimateAssemblyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.DeleteEstimateAssemblyResponse}
 */
proto.estimateAssembly.DeleteEstimateAssemblyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.DeleteEstimateAssemblyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.DeleteEstimateAssemblyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.DeleteEstimateAssemblyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.DeleteEstimateAssemblyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.estimateAssembly.DeleteEstimateAssemblyResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.estimateAssembly.DeleteEstimateAssemblyResponse} returns this
 */
proto.estimateAssembly.DeleteEstimateAssemblyResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.GetTakeoffStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.GetTakeoffStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.GetTakeoffStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.GetTakeoffStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.GetTakeoffStatsRequest}
 */
proto.estimateAssembly.GetTakeoffStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.GetTakeoffStatsRequest;
  return proto.estimateAssembly.GetTakeoffStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.GetTakeoffStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.GetTakeoffStatsRequest}
 */
proto.estimateAssembly.GetTakeoffStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.GetTakeoffStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.GetTakeoffStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.GetTakeoffStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.GetTakeoffStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.estimateAssembly.GetTakeoffStatsRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.GetTakeoffStatsRequest} returns this
 */
proto.estimateAssembly.GetTakeoffStatsRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.estimateAssembly.GetTakeoffStatsRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.GetTakeoffStatsRequest} returns this
 */
proto.estimateAssembly.GetTakeoffStatsRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.estimateAssembly.TakeoffStats.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.TakeoffStats.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.TakeoffStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.TakeoffStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.TakeoffStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    takeoffid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    takeoffname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalsegmentlength: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    totalpointcount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalsegmentcount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalsqft: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    globalitemsList: jspb.Message.toObjectList(msg.getGlobalitemsList(),
    proto.estimateAssembly.EstimateItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.TakeoffStats}
 */
proto.estimateAssembly.TakeoffStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.TakeoffStats;
  return proto.estimateAssembly.TakeoffStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.TakeoffStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.TakeoffStats}
 */
proto.estimateAssembly.TakeoffStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTakeoffid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTakeoffname(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalsegmentlength(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalpointcount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalsegmentcount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalsqft(value);
      break;
    case 7:
      var value = new proto.estimateAssembly.EstimateItem;
      reader.readMessage(value,proto.estimateAssembly.EstimateItem.deserializeBinaryFromReader);
      msg.addGlobalitems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.TakeoffStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.TakeoffStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.TakeoffStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.TakeoffStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTakeoffid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTakeoffname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalsegmentlength();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTotalpointcount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTotalsegmentcount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTotalsqft();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getGlobalitemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.estimateAssembly.EstimateItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string takeoffId = 1;
 * @return {string}
 */
proto.estimateAssembly.TakeoffStats.prototype.getTakeoffid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.TakeoffStats} returns this
 */
proto.estimateAssembly.TakeoffStats.prototype.setTakeoffid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string takeoffName = 2;
 * @return {string}
 */
proto.estimateAssembly.TakeoffStats.prototype.getTakeoffname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.TakeoffStats} returns this
 */
proto.estimateAssembly.TakeoffStats.prototype.setTakeoffname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double totalSegmentLength = 3;
 * @return {number}
 */
proto.estimateAssembly.TakeoffStats.prototype.getTotalsegmentlength = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.TakeoffStats} returns this
 */
proto.estimateAssembly.TakeoffStats.prototype.setTotalsegmentlength = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 totalPointCount = 4;
 * @return {number}
 */
proto.estimateAssembly.TakeoffStats.prototype.getTotalpointcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.TakeoffStats} returns this
 */
proto.estimateAssembly.TakeoffStats.prototype.setTotalpointcount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 totalSegmentCount = 5;
 * @return {number}
 */
proto.estimateAssembly.TakeoffStats.prototype.getTotalsegmentcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.TakeoffStats} returns this
 */
proto.estimateAssembly.TakeoffStats.prototype.setTotalsegmentcount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional float totalSqft = 6;
 * @return {number}
 */
proto.estimateAssembly.TakeoffStats.prototype.getTotalsqft = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.estimateAssembly.TakeoffStats} returns this
 */
proto.estimateAssembly.TakeoffStats.prototype.setTotalsqft = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * repeated EstimateItem globalItems = 7;
 * @return {!Array<!proto.estimateAssembly.EstimateItem>}
 */
proto.estimateAssembly.TakeoffStats.prototype.getGlobalitemsList = function() {
  return /** @type{!Array<!proto.estimateAssembly.EstimateItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.estimateAssembly.EstimateItem, 7));
};


/**
 * @param {!Array<!proto.estimateAssembly.EstimateItem>} value
 * @return {!proto.estimateAssembly.TakeoffStats} returns this
*/
proto.estimateAssembly.TakeoffStats.prototype.setGlobalitemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.estimateAssembly.EstimateItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.estimateAssembly.EstimateItem}
 */
proto.estimateAssembly.TakeoffStats.prototype.addGlobalitems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.estimateAssembly.EstimateItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.estimateAssembly.TakeoffStats} returns this
 */
proto.estimateAssembly.TakeoffStats.prototype.clearGlobalitemsList = function() {
  return this.setGlobalitemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.estimateAssembly.GetTakeoffStatsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.GetTakeoffStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.GetTakeoffStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.GetTakeoffStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.GetTakeoffStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statsList: jspb.Message.toObjectList(msg.getStatsList(),
    proto.estimateAssembly.TakeoffStats.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.GetTakeoffStatsResponse}
 */
proto.estimateAssembly.GetTakeoffStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.GetTakeoffStatsResponse;
  return proto.estimateAssembly.GetTakeoffStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.GetTakeoffStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.GetTakeoffStatsResponse}
 */
proto.estimateAssembly.GetTakeoffStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.estimateAssembly.TakeoffStats;
      reader.readMessage(value,proto.estimateAssembly.TakeoffStats.deserializeBinaryFromReader);
      msg.addStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.GetTakeoffStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.GetTakeoffStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.GetTakeoffStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.GetTakeoffStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.estimateAssembly.TakeoffStats.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TakeoffStats stats = 1;
 * @return {!Array<!proto.estimateAssembly.TakeoffStats>}
 */
proto.estimateAssembly.GetTakeoffStatsResponse.prototype.getStatsList = function() {
  return /** @type{!Array<!proto.estimateAssembly.TakeoffStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.estimateAssembly.TakeoffStats, 1));
};


/**
 * @param {!Array<!proto.estimateAssembly.TakeoffStats>} value
 * @return {!proto.estimateAssembly.GetTakeoffStatsResponse} returns this
*/
proto.estimateAssembly.GetTakeoffStatsResponse.prototype.setStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.estimateAssembly.TakeoffStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.estimateAssembly.TakeoffStats}
 */
proto.estimateAssembly.GetTakeoffStatsResponse.prototype.addStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.estimateAssembly.TakeoffStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.estimateAssembly.GetTakeoffStatsResponse} returns this
 */
proto.estimateAssembly.GetTakeoffStatsResponse.prototype.clearStatsList = function() {
  return this.setStatsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimateid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessiontoken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest;
  return proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessiontoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimateid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessiontoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string estimateId = 1;
 * @return {string}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.prototype.getEstimateid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest} returns this
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.prototype.setEstimateid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sessionToken = 2;
 * @return {string}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.prototype.getSessiontoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest} returns this
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdRequest.prototype.setSessiontoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    assembliesList: jspb.Message.toObjectList(msg.getAssembliesList(),
    proto.estimateAssembly.EstimateAssembly.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse;
  return proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.estimateAssembly.EstimateAssembly;
      reader.readMessage(value,proto.estimateAssembly.EstimateAssembly.deserializeBinaryFromReader);
      msg.addAssemblies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssembliesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.estimateAssembly.EstimateAssembly.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EstimateAssembly assemblies = 1;
 * @return {!Array<!proto.estimateAssembly.EstimateAssembly>}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.prototype.getAssembliesList = function() {
  return /** @type{!Array<!proto.estimateAssembly.EstimateAssembly>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.estimateAssembly.EstimateAssembly, 1));
};


/**
 * @param {!Array<!proto.estimateAssembly.EstimateAssembly>} value
 * @return {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse} returns this
*/
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.prototype.setAssembliesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.estimateAssembly.EstimateAssembly=} opt_value
 * @param {number=} opt_index
 * @return {!proto.estimateAssembly.EstimateAssembly}
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.prototype.addAssemblies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.estimateAssembly.EstimateAssembly, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse} returns this
 */
proto.estimateAssembly.GetEstimateAssembliesByEstimateIdResponse.prototype.clearAssembliesList = function() {
  return this.setAssembliesList([]);
};


goog.object.extend(exports, proto.estimateAssembly);
