/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: globalAssembly.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as globalAssembly_pb from './globalAssembly_pb'; // proto import: "globalAssembly.proto"


export class GlobalAssemblyServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateGlobalAssembly = new grpcWeb.MethodDescriptor(
    '/GlobalAssemblyService/CreateGlobalAssembly',
    grpcWeb.MethodType.UNARY,
    globalAssembly_pb.CreateGlobalAssemblyRequest,
    globalAssembly_pb.CreateGlobalAssemblyResponse,
    (request: globalAssembly_pb.CreateGlobalAssemblyRequest) => {
      return request.serializeBinary();
    },
    globalAssembly_pb.CreateGlobalAssemblyResponse.deserializeBinary
  );

  createGlobalAssembly(
    request: globalAssembly_pb.CreateGlobalAssemblyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalAssembly_pb.CreateGlobalAssemblyResponse>;

  createGlobalAssembly(
    request: globalAssembly_pb.CreateGlobalAssemblyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.CreateGlobalAssemblyResponse) => void): grpcWeb.ClientReadableStream<globalAssembly_pb.CreateGlobalAssemblyResponse>;

  createGlobalAssembly(
    request: globalAssembly_pb.CreateGlobalAssemblyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.CreateGlobalAssemblyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalAssemblyService/CreateGlobalAssembly',
        request,
        metadata || {},
        this.methodDescriptorCreateGlobalAssembly,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalAssemblyService/CreateGlobalAssembly',
    request,
    metadata || {},
    this.methodDescriptorCreateGlobalAssembly);
  }

  methodDescriptorGetGlobalAssembly = new grpcWeb.MethodDescriptor(
    '/GlobalAssemblyService/GetGlobalAssembly',
    grpcWeb.MethodType.UNARY,
    globalAssembly_pb.GetGlobalAssemblyRequest,
    globalAssembly_pb.GetGlobalAssemblyResponse,
    (request: globalAssembly_pb.GetGlobalAssemblyRequest) => {
      return request.serializeBinary();
    },
    globalAssembly_pb.GetGlobalAssemblyResponse.deserializeBinary
  );

  getGlobalAssembly(
    request: globalAssembly_pb.GetGlobalAssemblyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalAssembly_pb.GetGlobalAssemblyResponse>;

  getGlobalAssembly(
    request: globalAssembly_pb.GetGlobalAssemblyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.GetGlobalAssemblyResponse) => void): grpcWeb.ClientReadableStream<globalAssembly_pb.GetGlobalAssemblyResponse>;

  getGlobalAssembly(
    request: globalAssembly_pb.GetGlobalAssemblyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.GetGlobalAssemblyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalAssemblyService/GetGlobalAssembly',
        request,
        metadata || {},
        this.methodDescriptorGetGlobalAssembly,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalAssemblyService/GetGlobalAssembly',
    request,
    metadata || {},
    this.methodDescriptorGetGlobalAssembly);
  }

  methodDescriptorGetAllGlobalAssemblies = new grpcWeb.MethodDescriptor(
    '/GlobalAssemblyService/GetAllGlobalAssemblies',
    grpcWeb.MethodType.UNARY,
    globalAssembly_pb.GetAllGlobalAssembliesRequest,
    globalAssembly_pb.GetAllGlobalAssembliesResponse,
    (request: globalAssembly_pb.GetAllGlobalAssembliesRequest) => {
      return request.serializeBinary();
    },
    globalAssembly_pb.GetAllGlobalAssembliesResponse.deserializeBinary
  );

  getAllGlobalAssemblies(
    request: globalAssembly_pb.GetAllGlobalAssembliesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalAssembly_pb.GetAllGlobalAssembliesResponse>;

  getAllGlobalAssemblies(
    request: globalAssembly_pb.GetAllGlobalAssembliesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.GetAllGlobalAssembliesResponse) => void): grpcWeb.ClientReadableStream<globalAssembly_pb.GetAllGlobalAssembliesResponse>;

  getAllGlobalAssemblies(
    request: globalAssembly_pb.GetAllGlobalAssembliesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.GetAllGlobalAssembliesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalAssemblyService/GetAllGlobalAssemblies',
        request,
        metadata || {},
        this.methodDescriptorGetAllGlobalAssemblies,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalAssemblyService/GetAllGlobalAssemblies',
    request,
    metadata || {},
    this.methodDescriptorGetAllGlobalAssemblies);
  }

  methodDescriptorUpdateGlobalAssembly = new grpcWeb.MethodDescriptor(
    '/GlobalAssemblyService/UpdateGlobalAssembly',
    grpcWeb.MethodType.UNARY,
    globalAssembly_pb.UpdateGlobalAssemblyRequest,
    globalAssembly_pb.UpdateGlobalAssemblyResponse,
    (request: globalAssembly_pb.UpdateGlobalAssemblyRequest) => {
      return request.serializeBinary();
    },
    globalAssembly_pb.UpdateGlobalAssemblyResponse.deserializeBinary
  );

  updateGlobalAssembly(
    request: globalAssembly_pb.UpdateGlobalAssemblyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalAssembly_pb.UpdateGlobalAssemblyResponse>;

  updateGlobalAssembly(
    request: globalAssembly_pb.UpdateGlobalAssemblyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.UpdateGlobalAssemblyResponse) => void): grpcWeb.ClientReadableStream<globalAssembly_pb.UpdateGlobalAssemblyResponse>;

  updateGlobalAssembly(
    request: globalAssembly_pb.UpdateGlobalAssemblyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.UpdateGlobalAssemblyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalAssemblyService/UpdateGlobalAssembly',
        request,
        metadata || {},
        this.methodDescriptorUpdateGlobalAssembly,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalAssemblyService/UpdateGlobalAssembly',
    request,
    metadata || {},
    this.methodDescriptorUpdateGlobalAssembly);
  }

  methodDescriptorDeleteGlobalAssembly = new grpcWeb.MethodDescriptor(
    '/GlobalAssemblyService/DeleteGlobalAssembly',
    grpcWeb.MethodType.UNARY,
    globalAssembly_pb.DeleteGlobalAssemblyRequest,
    globalAssembly_pb.DeleteGlobalAssemblyResponse,
    (request: globalAssembly_pb.DeleteGlobalAssemblyRequest) => {
      return request.serializeBinary();
    },
    globalAssembly_pb.DeleteGlobalAssemblyResponse.deserializeBinary
  );

  deleteGlobalAssembly(
    request: globalAssembly_pb.DeleteGlobalAssemblyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalAssembly_pb.DeleteGlobalAssemblyResponse>;

  deleteGlobalAssembly(
    request: globalAssembly_pb.DeleteGlobalAssemblyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.DeleteGlobalAssemblyResponse) => void): grpcWeb.ClientReadableStream<globalAssembly_pb.DeleteGlobalAssemblyResponse>;

  deleteGlobalAssembly(
    request: globalAssembly_pb.DeleteGlobalAssemblyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.DeleteGlobalAssemblyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalAssemblyService/DeleteGlobalAssembly',
        request,
        metadata || {},
        this.methodDescriptorDeleteGlobalAssembly,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalAssemblyService/DeleteGlobalAssembly',
    request,
    metadata || {},
    this.methodDescriptorDeleteGlobalAssembly);
  }

  methodDescriptorGetAssembliesByFileId = new grpcWeb.MethodDescriptor(
    '/GlobalAssemblyService/GetAssembliesByFileId',
    grpcWeb.MethodType.UNARY,
    globalAssembly_pb.GetAssembliesByFileIdRequest,
    globalAssembly_pb.GetAssembliesByFileIdResponse,
    (request: globalAssembly_pb.GetAssembliesByFileIdRequest) => {
      return request.serializeBinary();
    },
    globalAssembly_pb.GetAssembliesByFileIdResponse.deserializeBinary
  );

  getAssembliesByFileId(
    request: globalAssembly_pb.GetAssembliesByFileIdRequest,
    metadata?: grpcWeb.Metadata | null): Promise<globalAssembly_pb.GetAssembliesByFileIdResponse>;

  getAssembliesByFileId(
    request: globalAssembly_pb.GetAssembliesByFileIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.GetAssembliesByFileIdResponse) => void): grpcWeb.ClientReadableStream<globalAssembly_pb.GetAssembliesByFileIdResponse>;

  getAssembliesByFileId(
    request: globalAssembly_pb.GetAssembliesByFileIdRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: globalAssembly_pb.GetAssembliesByFileIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/GlobalAssemblyService/GetAssembliesByFileId',
        request,
        metadata || {},
        this.methodDescriptorGetAssembliesByFileId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/GlobalAssemblyService/GetAssembliesByFileId',
    request,
    metadata || {},
    this.methodDescriptorGetAssembliesByFileId);
  }

}

