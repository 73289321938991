/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: object.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as object_pb from './object_pb'; // proto import: "object.proto"


export class ObjectServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGeneratePUTSignedUrl = new grpcWeb.MethodDescriptor(
    '/ObjectService/GeneratePUTSignedUrl',
    grpcWeb.MethodType.UNARY,
    object_pb.GeneratePUTSignedUrlRequest,
    object_pb.GeneratePUTSignedUrlResponse,
    (request: object_pb.GeneratePUTSignedUrlRequest) => {
      return request.serializeBinary();
    },
    object_pb.GeneratePUTSignedUrlResponse.deserializeBinary
  );

  generatePUTSignedUrl(
    request: object_pb.GeneratePUTSignedUrlRequest,
    metadata?: grpcWeb.Metadata | null): Promise<object_pb.GeneratePUTSignedUrlResponse>;

  generatePUTSignedUrl(
    request: object_pb.GeneratePUTSignedUrlRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: object_pb.GeneratePUTSignedUrlResponse) => void): grpcWeb.ClientReadableStream<object_pb.GeneratePUTSignedUrlResponse>;

  generatePUTSignedUrl(
    request: object_pb.GeneratePUTSignedUrlRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: object_pb.GeneratePUTSignedUrlResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ObjectService/GeneratePUTSignedUrl',
        request,
        metadata || {},
        this.methodDescriptorGeneratePUTSignedUrl,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ObjectService/GeneratePUTSignedUrl',
    request,
    metadata || {},
    this.methodDescriptorGeneratePUTSignedUrl);
  }

  methodDescriptorGenerateGETSignedUrl = new grpcWeb.MethodDescriptor(
    '/ObjectService/GenerateGETSignedUrl',
    grpcWeb.MethodType.UNARY,
    object_pb.GenerateGETSignedUrlRequest,
    object_pb.GenerateGETSignedUrlResponse,
    (request: object_pb.GenerateGETSignedUrlRequest) => {
      return request.serializeBinary();
    },
    object_pb.GenerateGETSignedUrlResponse.deserializeBinary
  );

  generateGETSignedUrl(
    request: object_pb.GenerateGETSignedUrlRequest,
    metadata?: grpcWeb.Metadata | null): Promise<object_pb.GenerateGETSignedUrlResponse>;

  generateGETSignedUrl(
    request: object_pb.GenerateGETSignedUrlRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: object_pb.GenerateGETSignedUrlResponse) => void): grpcWeb.ClientReadableStream<object_pb.GenerateGETSignedUrlResponse>;

  generateGETSignedUrl(
    request: object_pb.GenerateGETSignedUrlRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: object_pb.GenerateGETSignedUrlResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ObjectService/GenerateGETSignedUrl',
        request,
        metadata || {},
        this.methodDescriptorGenerateGETSignedUrl,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ObjectService/GenerateGETSignedUrl',
    request,
    metadata || {},
    this.methodDescriptorGenerateGETSignedUrl);
  }

  methodDescriptorGetAllObjectsByProjectId = new grpcWeb.MethodDescriptor(
    '/ObjectService/GetAllObjectsByProjectId',
    grpcWeb.MethodType.UNARY,
    object_pb.GetAllObjectsByProjectIdRequest,
    object_pb.GetAllObjectsByProjectIdResponse,
    (request: object_pb.GetAllObjectsByProjectIdRequest) => {
      return request.serializeBinary();
    },
    object_pb.GetAllObjectsByProjectIdResponse.deserializeBinary
  );

  getAllObjectsByProjectId(
    request: object_pb.GetAllObjectsByProjectIdRequest,
    metadata?: grpcWeb.Metadata | null): Promise<object_pb.GetAllObjectsByProjectIdResponse>;

  getAllObjectsByProjectId(
    request: object_pb.GetAllObjectsByProjectIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: object_pb.GetAllObjectsByProjectIdResponse) => void): grpcWeb.ClientReadableStream<object_pb.GetAllObjectsByProjectIdResponse>;

  getAllObjectsByProjectId(
    request: object_pb.GetAllObjectsByProjectIdRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: object_pb.GetAllObjectsByProjectIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ObjectService/GetAllObjectsByProjectId',
        request,
        metadata || {},
        this.methodDescriptorGetAllObjectsByProjectId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ObjectService/GetAllObjectsByProjectId',
    request,
    metadata || {},
    this.methodDescriptorGetAllObjectsByProjectId);
  }

  methodDescriptorUpdateObjectScale = new grpcWeb.MethodDescriptor(
    '/ObjectService/UpdateObjectScale',
    grpcWeb.MethodType.UNARY,
    object_pb.UpdateObjectScaleRequest,
    object_pb.UpdateObjectScaleResponse,
    (request: object_pb.UpdateObjectScaleRequest) => {
      return request.serializeBinary();
    },
    object_pb.UpdateObjectScaleResponse.deserializeBinary
  );

  updateObjectScale(
    request: object_pb.UpdateObjectScaleRequest,
    metadata?: grpcWeb.Metadata | null): Promise<object_pb.UpdateObjectScaleResponse>;

  updateObjectScale(
    request: object_pb.UpdateObjectScaleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: object_pb.UpdateObjectScaleResponse) => void): grpcWeb.ClientReadableStream<object_pb.UpdateObjectScaleResponse>;

  updateObjectScale(
    request: object_pb.UpdateObjectScaleRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: object_pb.UpdateObjectScaleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ObjectService/UpdateObjectScale',
        request,
        metadata || {},
        this.methodDescriptorUpdateObjectScale,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ObjectService/UpdateObjectScale',
    request,
    metadata || {},
    this.methodDescriptorUpdateObjectScale);
  }

}

