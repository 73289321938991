import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CustomTabPanel, a11yProps } from "../../../customTabs/CustomTabPanel";
import { CreateItem } from "./CreateItem";
import { AssignCosts } from "./AssignCosts";
import { useFetchItemsByFileId } from "../../../hooks/useFetchItemsByFileId";
import { GlobalItem } from "../../../api/protosCompiled/globalItem/globalItem_pb";
import { MenuOptions } from "../../../types/FolderTypes";

export type CreateItemTabProps = {
  parentFolderId: string | null;
  onItemCreated: () => void;
  item: GlobalItem.AsObject;
  menuOption: MenuOptions | null;
  setMenuOption: (option: MenuOptions | null) => void;
};

export default function CreateItemTab({
  parentFolderId,
  onItemCreated,
  item,
  menuOption,
  setMenuOption,
}: CreateItemTabProps) {
  const { data: items, refetchItems } = useFetchItemsByFileId(
    parentFolderId || ""
  );

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <div style={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Item" {...a11yProps(0)} />
          <Tab label="Costs" {...a11yProps(1)} />
        </Tabs>
      </div>
      <CustomTabPanel value={value} index={0}>
        <CreateItem
          parentFolderId={parentFolderId}
          onItemCreated={onItemCreated}
          item={item}
          menuOption={menuOption}
          refetchItems={refetchItems}
          items={items || []}
          setMenuOption={setMenuOption}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <AssignCosts items={items || []} refetchItems={refetchItems} />
      </CustomTabPanel>
    </>
  );
}
