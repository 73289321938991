import React, { useState, useRef, useEffect } from "react";
import {
  InferenceCountObject,
  useInferenceStore,
} from "../../states/inferenceStore";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";
import { useSubmitTakeoffAssembly } from "../../hooks/useSubmitTakeoffAssembly";
import { CircularProgress } from "@mui/material";
import { Inference } from "../../hooks/inferenceHooks";
import {
  EstimateAssembly,
  EstimatePoint,
} from "../../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import { ChangeOrderAssembly } from "../../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import { v4 as uuidv4 } from "uuid";
import { ResetStates } from "../../states/resetStates";
import { useCreateAssemblyStore } from "../../states/createAssemblyStore";

export const AiCountManager: React.FC = () => {
  const UBstore = useStore(useUnityBuildStore);
  const store1 = useStore(useCreateAssemblyStore);
  const InfStore = useStore(useInferenceStore);
  const [selectedClass, setSelectedClass] = useState<string>("");
  const [selectedResults, setSelectedResults] = useState<boolean[]>([]);
  const [filteredInferenceCountObjects, setFilteredInferenceCountObjects] =
    useState<InferenceCountObject[]>([]);
  const [uniqueClassNames, setUniqueClassNames] = useState<string[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [filteredToOriginalIndexMap, setFilteredToOriginalIndexMap] = useState<
    { filteredIndex: number; originalIndex: number; image: string }[]
  >([]);
  const [boundingBoxImages, setBoundingBoxImages] = useState<string[][]>([]);
  const submitAssembly = useSubmitTakeoffAssembly();
  const { runInference, clearInference, runningInference, elapsedTime } =
    Inference.useHandleInference();

  // State for enlarged image tooltip
  const [hoveredImage, setHoveredImage] = useState<string | null>(null);
  const [hoveredImageStyle, setHoveredImageStyle] =
    useState<React.CSSProperties>({ top: 0, left: 0, display: "none" });
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);

  const inferenceCountObjects = InfStore.inferenceCountObjects;

  // Fetch and manage the class map using the Inference namespace
  const fetchClassMap = Inference.useFetchClassMap();

  useEffect(() => {
    fetchClassMap();
  }, [fetchClassMap]);

  const handleInferenceItemFilter = Inference.useHandleInferenceItemFilter(
    inferenceCountObjects,
    setFilteredInferenceCountObjects,
    setUniqueClassNames,
    setSelectedResults,
    setFilteredToOriginalIndexMap,
    setAllSelected,
    boundingBoxImages
  );

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleInferenceItemFilter(e.target.value);
    setSelectedClass(e.target.value);
  };

  // Capture bounding box images whenever necessary
  const captureBoundingBoxImages = Inference.useCaptureBoundingBoxImages(
    filteredInferenceCountObjects,
    setBoundingBoxImages
  );

  useEffect(() => {
    if (filteredInferenceCountObjects.length > 0) {
      captureBoundingBoxImages();
    }
  }, [filteredInferenceCountObjects, captureBoundingBoxImages]);

  // Ensure all dependencies are passed correctly
  Inference.useFilteredInferenceEffects(
    inferenceCountObjects,
    UBstore,
    InfStore,
    selectedClass,
    setFilteredInferenceCountObjects,
    setUniqueClassNames,
    setSelectedResults,
    captureBoundingBoxImages,
    setFilteredToOriginalIndexMap,
    boundingBoxImages
  );

  const handleResultCheckboxChange = (filteredIndex: number) => {
    const { originalIndex } = filteredToOriginalIndexMap[filteredIndex];

    setSelectedResults((prevState) => {
      const updatedResults = [...prevState];
      updatedResults[originalIndex] = !updatedResults[originalIndex];
      return updatedResults;
    });
  };

  const handleSelectAllToggle = () => {
    setAllSelected((prevAllSelected) => {
      const newAllSelected = !prevAllSelected;

      setSelectedResults((prevSelectedResults) => {
        const updatedResults = [...prevSelectedResults];
        filteredToOriginalIndexMap.forEach(({ originalIndex }) => {
          updatedResults[originalIndex] = newAllSelected;
        });
        return updatedResults;
      });

      return newAllSelected;
    });
  };

  const handleSubmitAssembly = async () => {
    const selectedTakeoffAssembly = UBstore.selectedTakeoffAssembly;

    if (!selectedTakeoffAssembly) {
      console.error("No assembly selected.");
      return;
    }

    const points: EstimatePoint.AsObject[] = [];
    let globalBoxIndex = 0; // To keep track of the overall index

    filteredInferenceCountObjects.forEach((inferenceObject) => {
      inferenceObject.resultsList.forEach((result) => {
        result.boxesList.forEach((box) => {
          const isSelected = selectedResults[globalBoxIndex];
          if (isSelected) {
            const point: EstimatePoint.AsObject = {
              pointid: uuidv4(),
              x: (box.xmin + box.xmax) / 2,
              y: (box.ymin + box.ymax) / 2,
              angle: 0,
              verticallength: 0,
              allowtrailingline: false,
              allowtrailingmeasurement: false,
              isincludedincount: true,
              isselected: false,
              isactive: true,
            };
            points.push(point);
          }
          globalBoxIndex++;
        });
      });
    });

    if (points.length === 0) {
      console.warn("No points selected for submission.");
      return;
    }

    const updatedAssembly:
      | EstimateAssembly.AsObject
      | ChangeOrderAssembly.AsObject = {
      ...selectedTakeoffAssembly,
      pointsList: points,
      pointcount: points.length,
      segmentlength: 0,
    };

    try {
      await submitAssembly({
        assembly: updatedAssembly,
        isCreating: UBstore.isCreatingTakeoffAssembly,
        isUpdating: UBstore.isUpdatingTakeoffAssembly,
      });
      setFilteredInferenceCountObjects([]);
      ResetStates.resetTakeoffStates(UBstore, store1);
    } catch (error) {
      console.error("Error submitting assembly:", error);
    }
  };
  // Event handlers for hovering
  const handleMouseEnter = (image: string, event: React.MouseEvent) => {
    if (hoverTimeout.current) clearTimeout(hoverTimeout.current);

    hoverTimeout.current = setTimeout(() => {
      const { clientX, clientY } = event;

      const tooltipX = Math.min(clientX + 10, window.innerWidth - 200);
      const tooltipY = Math.min(clientY + 10, window.innerHeight - 200);

      setHoveredImageStyle({
        top: tooltipY,
        left: tooltipX,
        display: "block",
        position: "fixed",
        zIndex: 2,
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        padding: "5px",
      });

      setHoveredImage(image);
    }, 500);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout.current) clearTimeout(hoverTimeout.current);
    setHoveredImage(null);
    setHoveredImageStyle({ ...hoveredImageStyle, display: "none" });
  };

  const handleImageClick = () => {};

  const filteredResults: {
    labelsList: number[];
  }[] = filteredInferenceCountObjects.flatMap((inferenceObject) =>
    inferenceObject.resultsList.filter((result) =>
      selectedClass
        ? result.labelsList.includes(
            parseInt(
              Object.keys(InfStore.classMap ?? {}).find(
                (key) => InfStore.classMap![key] === selectedClass
              ) || ""
            )
          )
        : true
    )
  );

  return (
    <div
      className="TakeoffPalletteParentBox"
      style={{ backgroundColor: UBstore.userBackgroundColor }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <button
          onClick={runInference}
          disabled={runningInference || !UBstore.selectedObjectId}
        >
          Run Inference
        </button>
        <button
          onClick={clearInference}
          disabled={runningInference || !UBstore.selectedObjectId}
        >
          Clear Inference
        </button>
        <>Elapsed Time: {elapsedTime.toFixed(2)} seconds</>
      </div>

      <div>
        Filter:
        <select
          value={selectedClass}
          onChange={handleFilterChange}
          disabled={!inferenceCountObjects.length || runningInference}
        >
          <option value="">Show All Classes</option>
          {uniqueClassNames.map((label) => (
            <option key={label} value={label}>
              {label}
            </option>
          ))}
        </select>
      </div>

      <div>
        <button
          onClick={handleSubmitAssembly}
          disabled={
            !inferenceCountObjects.length ||
            runningInference ||
            !UBstore.selectedTakeoffAssembly
          }
        >
          Assign Assembly to Selected
        </button>
        <strong> Selected Assembly:</strong>{" "}
        {UBstore.selectedTakeoffAssembly?.assemblyname || "Select an assembly"}
      </div>

      <div>
        <button
          onClick={handleSelectAllToggle}
          disabled={!inferenceCountObjects.length || runningInference}
        >
          {allSelected ? "Deselect All" : "Select All"}
        </button>
      </div>
      <div className="TakeoffPalletteChildBox">
        {runningInference ? (
          <CircularProgress />
        ) : (
          filteredResults.map((result, filteredIndex) => (
            <div key={filteredIndex} className="image-item">
              {result.labelsList
                .filter((label: number) =>
                  selectedClass
                    ? label ===
                      parseInt(
                        Object.keys(InfStore.classMap ?? {}).find(
                          (key) => InfStore.classMap![key] === selectedClass
                        ) || ""
                      )
                    : true
                )
                .map((label: number, labelIndex: number) => {
                  const { originalIndex, image } =
                    filteredToOriginalIndexMap[filteredIndex + labelIndex];

                  return (
                    <div
                      key={`${filteredIndex}-${labelIndex}`}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedResults[originalIndex]}
                          onChange={() =>
                            handleResultCheckboxChange(
                              filteredIndex + labelIndex
                            )
                          }
                        />
                      </label>
                      <p style={{ margin: 0 }}>
                        {InfStore.classMap
                          ? InfStore.classMap[label]
                          : `Label: ${label}`}
                      </p>
                      <button
                        onClick={handleImageClick}
                        onMouseEnter={(e) => handleMouseEnter(image, e)}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          border: "none",
                          padding: 0,
                          background: "none",
                        }}
                      >
                        {image && (
                          <img
                            src={image}
                            alt={`Captured for ${InfStore.classMap?.[label]}`}
                            style={{
                              width: "50px",
                              height: "50px",
                              marginLeft: "10px",
                            }}
                          />
                        )}
                      </button>
                    </div>
                  );
                })}
            </div>
          ))
        )}
      </div>

      {hoveredImage && (
        <div style={hoveredImageStyle}>
          <img
            src={hoveredImage}
            alt="Enlarged view"
            style={{
              width: "200px",
              height: "200px",
            }}
          />
        </div>
      )}
    </div>
  );
};
