import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CustomTabPanel, a11yProps } from "../../../customTabs/CustomTabPanel";
import { CollapsibleRow } from "../../folderManager/CollapsableRows";
import { useFetchFolders } from "../../../hooks/useFetchFolders";
import { useStore } from "zustand";
import { useCreateAssemblyStore } from "../../../states/createAssemblyStore";
import {
  GlobalItem,
  ItemAttribute,
} from "../../../api/protosCompiled/globalItem/globalItem_pb";
import { ItemQtyFormula } from "../../../api/protosCompiled/globalAssembly/globalAssembly_pb";
import { Entitytype, FolderNode } from "../../../types/FolderTypes";
import { defaultQuoteGroups } from "./AddedItemRow";

// Transform AsObject (from protobuf) into FolderNode by adding `children`
export const mapFoldersToFolderNodes = (foldersList: any[]): FolderNode[] => {
  return foldersList.map((folder) => ({
    ...folder,
    children: [], // Initialize the children property as an empty array
  }));
};

export default function ItemTab() {
  const [value, setValue] = React.useState(0);

  const { data: foldersData } = useFetchFolders();

  const { globalAssembly, setGlobalAssembly } = useStore(
    useCreateAssemblyStore
  );

  // Build the folder trees after mapping protobuf `AsObject[]` to `FolderNode[]`
  const itemFolderTree: FolderNode[] = foldersData
    ? buildFolderTree("item", mapFoldersToFolderNodes(foldersData.foldersList))
    : [];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleAddItem = (
    item: GlobalItem.AsObject,
    attribute: ItemAttribute.AsObject | null
  ) => {
    // Generate a unique item name based on item name and attribute values
    const uniqueItemName = generateUniqueItemName(
      globalAssembly.itemqtyformulasList,
      item.name,
      attribute
    );

    const newItemFormula: ItemQtyFormula.AsObject = {
      itemid: item.folderid,
      itemname: uniqueItemName, // Use the unique item name here
      itemqty: 1,
      forevery: 1,
      takeoffvariabletype: "point",
      and: false,
      or: false,
      attributesList: attribute
        ? [
            {
              attributegroupid: attribute.attributegroupid,
              attributevalueid: attribute.attributevalueid,
              attributegroupname: attribute.attributegroupname,
              attributevalue: attribute.attributevalue,
              lastupdated: attribute.lastupdated,
            },
          ]
        : [],
      isquoted: false,
      assemblyname: globalAssembly.assemblyname,
      quotegroup: defaultQuoteGroups[0],
    };

    setGlobalAssembly({
      ...globalAssembly,
      itemqtyformulasList: [
        ...globalAssembly.itemqtyformulasList,
        newItemFormula,
      ],
    });
  };

  // Function to generate a unique item name based on item name and attribute values
  const generateUniqueItemName = (
    formulasList: ItemQtyFormula.AsObject[],
    baseItemName: string,
    attribute: ItemAttribute.AsObject | null
  ): string => {
    // Create a unique identifier combining item name and attribute values
    const attributeValues = attribute ? attribute.attributevalue : "";
    const baseKey = `${baseItemName}-${attributeValues}`;

    // Count how many times this combination has occurred
    let count = 0;
    formulasList.forEach((formula) => {
      // Remove any existing suffixes like (1), (2) from the item name
      const existingBaseItemName = formula.itemname.replace(/\s\(\d+\)$/, "");
      const existingAttributeValues =
        formula.attributesList && formula.attributesList.length > 0
          ? formula.attributesList[0].attributevalue
          : "";
      const existingKey = `${existingBaseItemName}-${existingAttributeValues}`;

      if (existingKey === baseKey) {
        count++;
      }
    });

    // Append count only if duplicate exists
    if (count > 0) {
      return `${baseItemName} (${count})`;
    }
    return baseItemName;
  };

  return (
    <>
      <div style={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Items" {...a11yProps(0)} />
          {/* <Tab label="Assemblies" {...a11yProps(1)} /> */}
        </Tabs>
      </div>
      <CustomTabPanel value={value} index={0}>
        {/* Existing folder tree */}
        <CollapsibleRow
          data={itemFolderTree}
          onAddItem={handleAddItem}
          entitytype="item"
        />
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={1}>
        <CollapsibleRow
          data={assemblyFolderTree}
          onAddItem={handleAddItem}
          entitytype="assembly"
        />
      </CustomTabPanel> */}
    </>
  );
}

// Utility function to create a nested folder structure
export const buildFolderTree = (
  entityType: Entitytype,
  foldersList: FolderNode[]
): FolderNode[] => {
  const map: { [key: string]: FolderNode } = {};
  const roots: FolderNode[] = [];

  // Initialize the map with all folders
  foldersList.forEach((folder) => {
    if (folder.entitytype === entityType) {
      map[folder.folderid] = { ...folder, children: [] };
    }
  });

  // Build the tree
  foldersList.forEach((folder) => {
    if (folder.parentid) {
      // If the folder has a parent, add it to its parent's children
      if (map[folder.parentid]) {
        map[folder.parentid].children.push(map[folder.folderid]);
      }
    } else {
      // If the folder has no parent, it is a root folder
      roots.push(map[folder.folderid]);
    }
  });

  return roots;
};
