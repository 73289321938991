import React, { useState, useEffect } from "react";
import { Delete } from "@mui/icons-material";
import { useStore } from "zustand";
import { useCreateAssemblyStore } from "../../../states/createAssemblyStore";
import { AssemblyItemType } from "../../../types/AssemblyItemType";
import { ItemQtyFormula } from "../../../api/protosCompiled/globalAssembly/globalAssembly_pb";
import { useFetchItemsByFileId } from "../../../hooks/useFetchItemsByFileId";

export const defaultQuoteGroups = ["Lighting", "Electrical", "Plumbing"];

export interface AddedItemRowProps {
  item: ItemQtyFormula.AsObject;
  index: number;
}

export const AddedItemRow: React.FC<AddedItemRowProps> = ({ item, index }) => {
  const { globalAssembly, setGlobalAssembly } = useStore(
    useCreateAssemblyStore
  );

  const { data: items } = useFetchItemsByFileId(item.itemid);

  const [isQuoted, setIsQuoted] = useState<boolean>(item.isquoted || false);
  const [selectedAttributeValueId, setSelectedAttributeValueId] =
    useState<string>("");
  const [quantity, setQuantity] = useState<number>(item.itemqty || 1);
  const [forEvery, setForEvery] = useState<number>(item.forevery || 1);
  const [takeoffVariable, setTakeoffVariable] = useState<string>(
    item.takeoffvariabletype || "point"
  );
  const [quoteGroup, setQuoteGroup] = useState<string>(item.quotegroup || "");

  // Initialize the state with the item values on mount
  useEffect(() => {
    if (item.attributesList && item.attributesList.length > 0) {
      // Set selectedAttributeValueId to the attributevalueid of the current attribute
      setSelectedAttributeValueId(
        item.attributesList[0].attributevalueid.toString()
      );
    } else if (
      items &&
      items.length > 0 &&
      items[0].attributesList.length > 0
    ) {
      // If item doesn't have attributes, use the first attribute from items[0]
      setSelectedAttributeValueId(
        items[0].attributesList[0].attributevalueid.toString()
      );
    }
    setQuantity(item.itemqty);
    setForEvery(item.forevery);
    setTakeoffVariable(item.takeoffvariabletype);
    setIsQuoted(item.isquoted);
    setQuoteGroup(item.quotegroup);
  }, [item, items]);

  // Effect to update the itemname and attributesList when selectedAttributeValueId changes
  useEffect(() => {
    const updatedFormulas = globalAssembly.itemqtyformulasList.map(
      (formula, idx) => {
        if (idx === index) {
          // Find the selected attribute from items[0].attributesList
          const attributesList =
            items && items.length > 0 ? items[0].attributesList : [];
          const selectedAttribute = attributesList.find(
            (attr) =>
              attr.attributevalueid.toString() === selectedAttributeValueId
          );

          // Strip any suffixes from item.itemname
          const baseItemName = item.itemname.replace(/\s\(\d+\)$/, "");

          // Generate a unique item name based on item name and attribute values
          const uniqueItemName = generateUniqueItemName(
            globalAssembly.itemqtyformulasList,
            baseItemName,
            selectedAttribute,
            index
          );

          return {
            ...formula,
            itemname: uniqueItemName,
            attributesList: selectedAttribute
              ? [selectedAttribute]
              : formula.attributesList,
          };
        }
        return formula;
      }
    );

    setGlobalAssembly({
      ...globalAssembly,
      itemqtyformulasList: updatedFormulas,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAttributeValueId]);

  // Effect to update isquoted, itemqty, forevery, takeoffvariabletype, and quotegroup when they change
  useEffect(() => {
    const updatedFormulas = globalAssembly.itemqtyformulasList.map(
      (formula, idx) => {
        if (idx === index) {
          return {
            ...formula,
            isquoted: isQuoted,
            itemqty: quantity,
            forevery: forEvery,
            takeoffvariabletype: takeoffVariable,
            assemblyname: globalAssembly.assemblyname,
            quotegroup: quoteGroup,
          };
        }
        return formula;
      }
    );

    setGlobalAssembly({
      ...globalAssembly,
      itemqtyformulasList: updatedFormulas,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, forEvery, takeoffVariable, isQuoted, quoteGroup]);

  // Generate the label for each attribute
  const generateAttributeLabel = (attr: any) => {
    // Use base item name without suffix for the label
    const baseItemName = item.itemname.replace(/\s\(\d+\)$/, "");
    return `${attr.attributevalue} - ${baseItemName}`;
  };

  // Generate a unique item name based on item name and attribute values
  const generateUniqueItemName = (
    formulasList: ItemQtyFormula.AsObject[],
    baseItemName: string,
    selectedAttribute: any,
    currentIndex: number
  ): string => {
    const attributeValue = selectedAttribute
      ? selectedAttribute.attributevalue
      : "";
    const baseKey = `${baseItemName}-${attributeValue}`;

    // Build a set of used suffixes for this baseKey
    const usedSuffixes = new Set<number>();
    let baseNameUsed = false;

    formulasList.forEach((formula, idx) => {
      if (idx !== currentIndex) {
        const existingBaseItemName = formula.itemname.replace(/\s\(\d+\)$/, "");
        const existingAttributeValue =
          formula.attributesList && formula.attributesList.length > 0
            ? formula.attributesList[0].attributevalue
            : "";
        const existingKey = `${existingBaseItemName}-${existingAttributeValue}`;

        if (existingKey === baseKey) {
          // Extract suffix if present
          const suffixMatch = formula.itemname.match(/\s\((\d+)\)$/);
          if (suffixMatch) {
            usedSuffixes.add(parseInt(suffixMatch[1], 10));
          } else {
            baseNameUsed = true;
          }
        }
      }
    });

    // Assign the base name if it's not used
    if (!baseNameUsed) {
      return baseItemName;
    } else {
      // Find the lowest available suffix
      let suffix = 1;
      while (usedSuffixes.has(suffix)) {
        suffix++;
      }
      return `${baseItemName} (${suffix})`;
    }
  };

  // Handle isQuoted change
  const handleIsQuotedChange = () => {
    const updatedFormulas = globalAssembly.itemqtyformulasList.map(
      (formula, idx) => {
        if (idx === index) {
          return {
            ...formula,
            isquoted: !formula.isquoted,
          };
        }
        return formula;
      }
    );

    setGlobalAssembly({
      ...globalAssembly,
      itemqtyformulasList: updatedFormulas,
    });
    setIsQuoted(!isQuoted);
  };

  const handleDeleteAssemblyItem = () => {
    const updatedFormulas = globalAssembly.itemqtyformulasList.filter(
      (_, idx) => idx !== index
    );

    setGlobalAssembly({
      ...globalAssembly,
      itemqtyformulasList: updatedFormulas,
    });
  };

  return (
    <>
      <tr key={item.itemid}>
        {/* isQuoted Checkbox */}
        <td>
          <input
            type="checkbox"
            checked={globalAssembly.itemqtyformulasList[index].isquoted}
            onChange={handleIsQuotedChange}
          />
        </td>

        {/* Item/Description Select */}
        <td>
          <select
            value={selectedAttributeValueId}
            onChange={(e) => setSelectedAttributeValueId(e.target.value)}
            style={{ width: "95%" }}
          >
            {items &&
              items.length > 0 &&
              items[0].attributesList.map((attr) => (
                <option
                  key={attr.attributevalueid}
                  value={attr.attributevalueid.toString()}
                >
                  {generateAttributeLabel(attr)}
                </option>
              ))}
          </select>

          {/* Conditional quoteGroup selection */}
          {isQuoted && (
            <div style={{ marginTop: "5px" }}>
              <label htmlFor={`quoteGroup-${index}`}>Quote Group:</label>
              <select
                id={`quoteGroup-${index}`}
                value={quoteGroup || defaultQuoteGroups[0]}
                onChange={(e) => setQuoteGroup(e.target.value)}
                style={{ width: "95%" }}
              >
                {defaultQuoteGroups.map((group) => (
                  <option key={group} value={group}>
                    {group}
                  </option>
                ))}
              </select>
            </div>
          )}
        </td>

        {/* Quantity */}
        <td>
          <input
            aria-label="Qty"
            type="number"
            step="any"
            value={quantity}
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = parseFloat(value);
              setQuantity(isNaN(parsedValue) ? 0 : parsedValue);
            }}
          />
        </td>

        {/* For every */}
        <td>
          <input
            type="number"
            step="any"
            value={forEvery}
            onChange={(e) => {
              const value = e.target.value;
              const parsedValue = parseFloat(value);
              setForEvery(isNaN(parsedValue) ? 1 : parsedValue);
            }}
          />
        </td>

        {/* Takeoff variable */}
        <td>
          <select
            value={takeoffVariable}
            onChange={(e) => setTakeoffVariable(e.target.value)}
          >
            {globalAssembly.assemblymeasurementtype === "count" ? (
              <option value="point">point</option>
            ) : globalAssembly.assemblymeasurementtype === "length" ? (
              // Exclude "area" for length measurement type
              AssemblyItemType.takeoffVariableTypes
                .filter((type) => type !== "area")
                .map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))
            ) : (
              // Include all options for other types (e.g., "area")
              AssemblyItemType.takeoffVariableTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))
            )}
          </select>
        </td>

        {/* Delete Button */}
        <td>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteAssemblyItem();
            }}
          >
            <Delete />
          </button>
        </td>
      </tr>
    </>
  );
};
