import { useState } from "react";
import { ItemService } from "../../../api/ItemService";
import { GlobalItem } from "../../../api/protosCompiled/globalItem/globalItem_pb";

type AssignCostsProps = {
  items: GlobalItem.AsObject[];
  refetchItems: () => void;
};

export const AssignCosts = ({ items, refetchItems }: AssignCostsProps) => {
  const [editedCosts, setEditedCosts] = useState<{ [key: string]: any }>({});

  // Handle changes to the input fields
  const handleInputChange = (
    attributeId: string,
    field: string,
    value: string
  ) => {
    // Only allow valid numeric input or empty input
    if (/^(\d+(\.\d*)?|\.\d*)?$/.test(value)) {
      setEditedCosts((prev) => ({
        ...prev,
        [attributeId]: {
          ...prev[attributeId],
          [field]: value,
        },
      }));
    }
  };

  const handleBlur = async (
    item: GlobalItem.AsObject,
    attributeId: string,
    e: any
  ) => {
    try {
      const matchingCost = item.costsList.find(
        (cost) => cost.itemattributevalueid === parseInt(attributeId)
      );
      if (matchingCost) {
        const editedCost = editedCosts[attributeId] || {};

        const updatedCost = {
          ...matchingCost,
          itemunitcost:
            editedCost.itemunitcost !== undefined
              ? parseFloat(editedCost.itemunitcost)
              : matchingCost.itemunitcost,
          hourlyproductionrate:
            editedCost.hourlyproductionrate !== undefined
              ? parseFloat(editedCost.hourlyproductionrate)
              : matchingCost.hourlyproductionrate,
        };

        // Pass only the updated cost object to updateItemCost
        await ItemService.updateItemCost(updatedCost);
        refetchItems();
      } else {
        console.error("No matching cost found for attributeId:", attributeId);
      }
    } catch (error) {
      console.error("Error updating item costs:", error);
    }
  };

  return (
    <div
      style={{
        height: "700px",
        position: "relative",
        border: "1px solid black",
        overflowY: "auto",
      }}
    >
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>Attribute</th>
            <th>UOM</th>
            <th>Unit Cost</th>
            <th>Hourly Production Rate</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((item) =>
            item.attributesList.map((attribute, index) => {
              const matchingCost = item.costsList.find(
                (cost) =>
                  cost.itemattributevalueid === attribute.attributevalueid
              );

              return (
                <tr key={`${item.folderid}-${attribute.attributevalueid}`}>
                  <td>
                    {attribute.attributevalue} - {item.name}
                  </td>
                  <td>{matchingCost?.uomname ?? "N/A"}</td>
                  <td>
                    <input
                      type="text"
                      name="itemunitcost"
                      value={
                        editedCosts[attribute.attributevalueid]?.itemunitcost ??
                        matchingCost?.itemunitcost?.toString() ??
                        ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          attribute.attributevalueid.toString(),
                          "itemunitcost",
                          e.target.value
                        )
                      }
                      onBlur={(e) =>
                        handleBlur(
                          item,
                          attribute.attributevalueid.toString(),
                          e
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="hourlyproductionrate"
                      value={
                        editedCosts[attribute.attributevalueid]
                          ?.hourlyproductionrate ??
                        matchingCost?.hourlyproductionrate?.toString() ??
                        ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          attribute.attributevalueid.toString(),
                          "hourlyproductionrate",
                          e.target.value
                        )
                      }
                      onBlur={(e) =>
                        handleBlur(
                          item,
                          attribute.attributevalueid.toString(),
                          e
                        )
                      }
                    />
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};
