import { GetUserTokenRequest } from "../api/protosCompiled/userToken/userToken_pb";
import { UserTokenServiceClient } from "../api/protosCompiled/userToken/UserTokenServiceClientPb";
import { ProjectServiceClient } from "../api/protosCompiled/project/ProjectServiceClientPb";
import { ProjectEstimateServiceClient } from "../api/protosCompiled/projectEstimate/ProjectEstimateServiceClientPb";
import { ChangeOrderServiceClient } from "../api/protosCompiled/changeOrder/ChangeOrderServiceClientPb";
import { ContractServiceClient } from "../api/protosCompiled/contract/ContractServiceClientPb";
import { EstimateAssemblyServiceClient } from "../api/protosCompiled/estimateAssembly/EstimateAssemblyServiceClientPb";
import { RoleServiceClient } from "../api/protosCompiled/role/RoleServiceClientPb";
import { UB_UserServiceClient } from "../api/protosCompiled/ub_users/Ub_usersServiceClientPb";
import { ClientServiceClient } from "../api/protosCompiled/client/ClientServiceClientPb";
import { GlobalAssemblyServiceClient } from "../api/protosCompiled/globalAssembly/GlobalAssemblyServiceClientPb";
import { ChangeOrderAssemblyServiceClient } from "../api/protosCompiled/changeOrderAssembly/ChangeOrderAssemblyServiceClientPb";
import { ObjectServiceClient } from "../api/protosCompiled/object/ObjectServiceClientPb";
import { AssistantServiceClient } from "../api/protosCompiled/assistants/AssistantsServiceClientPb";
import { GlobalItemServiceClient } from "../api/protosCompiled/globalItem/GlobalItemServiceClientPb";
import { EstimateCloseoutServiceClient } from "../api/protosCompiled/estimateCloseout/EstimateCloseoutServiceClientPb";
import { ChangeOrderCloseoutServiceClient } from "../api/protosCompiled/changeOrderCloseout/ChangeOrderCloseoutServiceClientPb";
import { InferenceServiceClient } from "../api/protosCompiled/inference/InferenceServiceClientPb";
import { ClassificationServiceClient } from "../api/protosCompiled/classification/ClassificationServiceClientPb";
import { FolderServiceClient } from "../api/protosCompiled/folder/FolderServiceClientPb";
import { UBAdminServiceClient } from "../api/protosCompiled/ubAdmin/UbAdminServiceClientPb";
import { UB_AccountServiceClient } from "../api/protosCompiled/ub_account/Ub_accountServiceClientPb";
import { CheckoutServiceClient } from "../api/protosCompiled/checkout/CheckoutServiceClientPb";

//TODO: provide the right client for the GCS get and put methods
export namespace clients {
  export const host = process.env.REACT_APP_LOCAL_HOST || "";

  export const userTokenServiceClient = new UserTokenServiceClient(
    host,
    null,
    {}
  );

  export const assistantServiceClient = new AssistantServiceClient(
    host,
    null,
    {}
  );

  export const getUserTokenRequest = new GetUserTokenRequest();

  export const changeOrderServiceClient = new ChangeOrderServiceClient(
    host,
    null,
    {}
  );

  export const changeOrderAssemblyServiceClient =
    new ChangeOrderAssemblyServiceClient(host, null, {});

  export const clientServiceClient = new ClientServiceClient(host, null, {});

  export const contractServiceClient = new ContractServiceClient(
    host,
    null,
    {}
  );

  export const contractChangeOrderServiceClient = new ChangeOrderServiceClient(
    host,
    null,
    {}
  );

  export const estimateAssemblyServiceClient =
    new EstimateAssemblyServiceClient(host, null, {});

  export const globalAssemblyServiceClient = new GlobalAssemblyServiceClient(
    host,
    null,
    {}
  );

  export const globalItemServiceClient = new GlobalItemServiceClient(
    host,
    null,
    {}
  );

  export const projectServiceClient = new ProjectServiceClient(host, null, {});

  export const projectEstimateClient = new ProjectEstimateServiceClient(
    host,
    null,
    {}
  );

  export const roleServiceClient = new RoleServiceClient(host, null, {});

  export const ub_userServiceClient = new UB_UserServiceClient(host, null, {});

  export const objectServiceClient = new ObjectServiceClient(host, null, {});

  export const estimateCloseoutServiceClient =
    new EstimateCloseoutServiceClient(host, null, {});

  export const changeOrderCloseoutServiceClient =
    new ChangeOrderCloseoutServiceClient(host, null, {});

  export const inferenceServiceClient = new InferenceServiceClient(
    host,
    null,
    {}
  );

  export const classificationServiceClient = new ClassificationServiceClient(
    host,
    null,
    {}
  );

  export const folderServiceClient = new FolderServiceClient(host, null, {});

  export const ubAdminServiceClient = new UBAdminServiceClient(host, null, {});

  export const ubAccountServiceClient = new UB_AccountServiceClient(
    host,
    null,
    {}
  );

  export const checkoutServiceClient = new CheckoutServiceClient(
    host,
    null,
    {}
  );
}
