import { useMutation, useQueryClient } from "react-query";
import { ContractChangeOrderAssemblyService as ccoas } from "../api/ContractChangeOrderAssemblyService";
import { ChangeOrderAssembly } from "../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";

export const useCreateContractChangeOrderAssemblyMutation = () => {
  const queryClient = useQueryClient();

  const { mutate, mutateAsync } = useMutation(
    (data: ChangeOrderAssembly.AsObject) =>
      ccoas.createContractChangeOrderAssembly(data),
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(["countedAssemblies"]);
      },
      onError: (error) => {
        console.error("Error creating Change Order assembly", error);
      },
    }
  );

  // Return both mutate and mutateAsync for use in components or other hooks
  return { mutate, mutateAsync };
};
