/**
 * @fileoverview gRPC-Web generated client stub for assistants
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: assistants.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as assistants_pb from './assistants_pb'; // proto import: "assistants.proto"


export class AssistantServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorSendMessage = new grpcWeb.MethodDescriptor(
    '/assistants.AssistantService/SendMessage',
    grpcWeb.MethodType.UNARY,
    assistants_pb.SendMessageRequest,
    assistants_pb.SendMessageResponse,
    (request: assistants_pb.SendMessageRequest) => {
      return request.serializeBinary();
    },
    assistants_pb.SendMessageResponse.deserializeBinary
  );

  sendMessage(
    request: assistants_pb.SendMessageRequest,
    metadata?: grpcWeb.Metadata | null): Promise<assistants_pb.SendMessageResponse>;

  sendMessage(
    request: assistants_pb.SendMessageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: assistants_pb.SendMessageResponse) => void): grpcWeb.ClientReadableStream<assistants_pb.SendMessageResponse>;

  sendMessage(
    request: assistants_pb.SendMessageRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: assistants_pb.SendMessageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/assistants.AssistantService/SendMessage',
        request,
        metadata || {},
        this.methodDescriptorSendMessage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/assistants.AssistantService/SendMessage',
    request,
    metadata || {},
    this.methodDescriptorSendMessage);
  }

  methodDescriptorAddGCSObjectsToVectorStore = new grpcWeb.MethodDescriptor(
    '/assistants.AssistantService/AddGCSObjectsToVectorStore',
    grpcWeb.MethodType.UNARY,
    assistants_pb.AddGCSObjectsToVectorStoreRequest,
    assistants_pb.AddGCSObjectsToVectorStoreResponse,
    (request: assistants_pb.AddGCSObjectsToVectorStoreRequest) => {
      return request.serializeBinary();
    },
    assistants_pb.AddGCSObjectsToVectorStoreResponse.deserializeBinary
  );

  addGCSObjectsToVectorStore(
    request: assistants_pb.AddGCSObjectsToVectorStoreRequest,
    metadata?: grpcWeb.Metadata | null): Promise<assistants_pb.AddGCSObjectsToVectorStoreResponse>;

  addGCSObjectsToVectorStore(
    request: assistants_pb.AddGCSObjectsToVectorStoreRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: assistants_pb.AddGCSObjectsToVectorStoreResponse) => void): grpcWeb.ClientReadableStream<assistants_pb.AddGCSObjectsToVectorStoreResponse>;

  addGCSObjectsToVectorStore(
    request: assistants_pb.AddGCSObjectsToVectorStoreRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: assistants_pb.AddGCSObjectsToVectorStoreResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/assistants.AssistantService/AddGCSObjectsToVectorStore',
        request,
        metadata || {},
        this.methodDescriptorAddGCSObjectsToVectorStore,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/assistants.AssistantService/AddGCSObjectsToVectorStore',
    request,
    metadata || {},
    this.methodDescriptorAddGCSObjectsToVectorStore);
  }

  methodDescriptorListMessagesOnThread = new grpcWeb.MethodDescriptor(
    '/assistants.AssistantService/ListMessagesOnThread',
    grpcWeb.MethodType.UNARY,
    assistants_pb.ListMessagesOnThreadRequest,
    assistants_pb.ListMessagesOnThreadResponse,
    (request: assistants_pb.ListMessagesOnThreadRequest) => {
      return request.serializeBinary();
    },
    assistants_pb.ListMessagesOnThreadResponse.deserializeBinary
  );

  listMessagesOnThread(
    request: assistants_pb.ListMessagesOnThreadRequest,
    metadata?: grpcWeb.Metadata | null): Promise<assistants_pb.ListMessagesOnThreadResponse>;

  listMessagesOnThread(
    request: assistants_pb.ListMessagesOnThreadRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: assistants_pb.ListMessagesOnThreadResponse) => void): grpcWeb.ClientReadableStream<assistants_pb.ListMessagesOnThreadResponse>;

  listMessagesOnThread(
    request: assistants_pb.ListMessagesOnThreadRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: assistants_pb.ListMessagesOnThreadResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/assistants.AssistantService/ListMessagesOnThread',
        request,
        metadata || {},
        this.methodDescriptorListMessagesOnThread,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/assistants.AssistantService/ListMessagesOnThread',
    request,
    metadata || {},
    this.methodDescriptorListMessagesOnThread);
  }

  methodDescriptorStreamMessage = new grpcWeb.MethodDescriptor(
    '/assistants.AssistantService/StreamMessage',
    grpcWeb.MethodType.SERVER_STREAMING,
    assistants_pb.StreamMessageRequest,
    assistants_pb.StreamMessageResponse,
    (request: assistants_pb.StreamMessageRequest) => {
      return request.serializeBinary();
    },
    assistants_pb.StreamMessageResponse.deserializeBinary
  );

  streamMessage(
    request: assistants_pb.StreamMessageRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<assistants_pb.StreamMessageResponse> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/assistants.AssistantService/StreamMessage',
      request,
      metadata || {},
      this.methodDescriptorStreamMessage);
  }

}

