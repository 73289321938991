import { useState } from "react";
import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";
import { IncidentalLaborRow } from "./IncidentalLaborRow";
import { Calculations as c } from "../../utils/calculations";
import { IncidentalLaborType } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";

export const rowStyle = { width: "85px" };

export const IncidentalLaborHeader = ({
  labor,
  laborDefaults,
}: {
  labor: IncidentalLaborType.AsObject[];
  laborDefaults: IncidentalLaborType.AsObject[];
}) => {
  const { setIncludedIncidentalLaborTypes, updateIncidentalLaborType } =
    useStore(useCloseoutStore);

  const [selectedLaborType, setSelectedLaborType] = useState<string>("");

  const handleChange = (e: any) => {
    const newValue = e.target.value as string;
    const defaultLaborType = laborDefaults.find(
      (laborType) => laborType.name === newValue
    );

    if (defaultLaborType) {
      const newLaborType: IncidentalLaborType.AsObject = {
        name: defaultLaborType.name,
        allocatedhours: defaultLaborType.allocatedhours,
        laborrate: defaultLaborType.laborrate,
        burdenpercent: defaultLaborType.burdenpercent,
        fringe: defaultLaborType.fringe,
        totalcost: c.calculateFullLaborCost(defaultLaborType),
      };

      if (!labor.some((labor) => labor.name === newValue)) {
        const newIncludedLaborTypes = [...labor, newLaborType];
        setIncludedIncidentalLaborTypes(newIncludedLaborTypes);
        setSelectedLaborType("");
      }
    }
  };

  const handleDelete = (laborName: string) => {
    const newIncludedLaborTypes = labor.filter((l) => l.name !== laborName);
    setIncludedIncidentalLaborTypes(newIncludedLaborTypes);
  };

  const handleFieldUpdate = (field: string, value: number, index: number) => {
    const updatedLabor = {
      ...labor[index],
      [field]: value,
    };

    if (field === "allocatedhours") {
      updatedLabor.allocatedhours = value;
    }
    updateIncidentalLaborType(index, updatedLabor);
  };

  return (
    <>
      <select
        id="labor-select"
        value={selectedLaborType}
        onChange={handleChange}
      >
        <option value="" disabled selected hidden>
          Select labor type
        </option>
        {laborDefaults
          .sort((a, b) => a.name.localeCompare(b.name)) // Sorting alphabetically
          .map((laborType, index) => (
            <option value={laborType.name} key={index}>
              {laborType.name}
            </option>
          ))}
      </select>
      <div className="scrollableCDiv">
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Hours</th>
              <th>Labor Rate</th>
              <th>Subtotal</th>
              <th>Burden %</th>
              <th>Fringe</th>
              <th>Burden Total</th>
              <th>Fringe Total</th>
              <th>Full Hourly Cost</th>
              <th>Full Cost</th>
            </tr>
          </thead>
          <tbody>
            {labor.map((labor, index) => (
              <IncidentalLaborRow
                key={index}
                labor={labor}
                handleDelete={handleDelete}
                handleFieldUpdate={(field, value) =>
                  handleFieldUpdate(field, value, index)
                }
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
