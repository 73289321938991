import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AuditTrail } from "./AuditTrail";
import { ChatWindow } from "../ChatWindow";
import { AiCountManager } from "./AiCountManager";
import { CustomTabPanel, a11yProps } from "../../customTabs/CustomTabPanel";
import { CollapsibleRow } from "../folderManager/CollapsableRows";
import {
  buildFolderTree,
  mapFoldersToFolderNodes,
} from "./AssemblyAndItemCreate/ItemTab";
import { FolderNode } from "../../types/FolderTypes";
import { useFetchFolders } from "../../hooks/useFetchFolders";

export default function TakeoffPallette() {
  const [value, setValue] = React.useState(0);

  const { data: foldersData } = useFetchFolders();

  const folderTree: FolderNode[] = foldersData
    ? buildFolderTree(
        "assembly",
        mapFoldersToFolderNodes(foldersData.foldersList)
      )
    : [];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleAddItem = () => {};

  const scrollDown = () => {};

  return (
    <>
      <div style={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Audit Trail" {...a11yProps(0)} />
          <Tab label="Assemblies" {...a11yProps(1)} />
          <Tab label="AI Count (Beta)" {...a11yProps(2)} />
          <Tab label="AI Chat" {...a11yProps(3)} onClick={() => scrollDown()} />
        </Tabs>
      </div>
      <div style={{ flexGrow: 1, overflow: "auto" }}>
        <CustomTabPanel value={value} index={0}>
          <AuditTrail />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <CollapsibleRow
            data={folderTree}
            onAddItem={handleAddItem}
            entitytype="assembly"
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <AiCountManager />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <ChatWindow scrollDown={() => scrollDown()} />
        </CustomTabPanel>
      </div>
    </>
  );
}
