import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Calculations as c } from "../../utils/calculations";
import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";
import { SubcontractRowProps } from "./closeoutTypes";

export const SubcontractRow: React.FC<
  SubcontractRowProps & {
    handleDelete: (name: string) => void;
    handleFieldUpdate: (field: string, value: number) => void;
  }
> = ({ subcontract, handleDelete, handleFieldUpdate }) => {
  const { includedEquipmentTypes } = useStore(useCloseoutStore);

  const [quotedCost, setQuotedCost] = useState<string>(
    subcontract.quotedcost.toFixed(2)
  );

  const [adjustedPercent, setAdjustedPercent] = useState<string>(
    (subcontract.adjustedpercent * 100).toFixed(2)
  );

  useEffect(() => {
    setQuotedCost(subcontract.quotedcost.toFixed(2));
    setAdjustedPercent((subcontract.adjustedpercent * 100).toFixed(2));
  }, [subcontract, includedEquipmentTypes]);

  const handleFieldChange = (field: string, value: string) => {
    // Allow only numbers and the decimal point
    const regex = /^[0-9]*\.?[0-9]{0,2}$/;
    if (!regex.test(value)) return;

    switch (field) {
      case "quotedCost":
        if (parseFloat(value) > 9999999999.99) return;
        setQuotedCost(value);
        break;
      case "adjustedPercent":
        if (parseFloat(value) > 9999999999.99) return;
        setAdjustedPercent(value);
        break;
      default:
        break;
    }
  };

  const handleBlur = (field: string, value: string) => {
    const floatValue = parseFloat(value) || 0;

    switch (field) {
      case "quotedCost":
        setQuotedCost(value);
        handleFieldUpdate(field, floatValue);
        break;
      case "adjustedPercent":
        setAdjustedPercent(value);
        handleFieldUpdate(field, floatValue / 100);
        break;
      default:
        break;
    }
  };

  const fullCost = c.calculateSubcontractTotal({
    ...subcontract,
  });

  return (
      <tr>
        {/* Description */}
        <td>
          <button onClick={() => handleDelete(subcontract.name)} style={{width:"40px"}}>
            <DeleteIcon />
          </button>
          {subcontract.name}
        </td>
        {/* Quoted Cost */}
        <td>
          <input
            type="text"
            value={quotedCost}
            onChange={(e) => handleFieldChange("quotedCost", e.target.value)}
            onBlur={(e) => handleBlur("quotedCost", e.target.value)}
          />
        </td>
        {/* Adjusted Percent */}
        <td>
          <input
            type="text"
            value={adjustedPercent}
            onChange={(e) =>
              handleFieldChange("adjustedPercent", e.target.value)
            }
            onBlur={(e) => handleBlur("adjustedPercent", e.target.value)}
          />
        </td>
        {/* Total Cost */}
        <td>{fullCost.toFixed(2)}</td>
      </tr>
  );
};
