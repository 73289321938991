/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: project.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as project_pb from './project_pb'; // proto import: "project.proto"


export class ProjectServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateProject = new grpcWeb.MethodDescriptor(
    '/ProjectService/CreateProject',
    grpcWeb.MethodType.UNARY,
    project_pb.CreateProjectRequest,
    project_pb.CreateProjectResponse,
    (request: project_pb.CreateProjectRequest) => {
      return request.serializeBinary();
    },
    project_pb.CreateProjectResponse.deserializeBinary
  );

  createProject(
    request: project_pb.CreateProjectRequest,
    metadata?: grpcWeb.Metadata | null): Promise<project_pb.CreateProjectResponse>;

  createProject(
    request: project_pb.CreateProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: project_pb.CreateProjectResponse) => void): grpcWeb.ClientReadableStream<project_pb.CreateProjectResponse>;

  createProject(
    request: project_pb.CreateProjectRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: project_pb.CreateProjectResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ProjectService/CreateProject',
        request,
        metadata || {},
        this.methodDescriptorCreateProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ProjectService/CreateProject',
    request,
    metadata || {},
    this.methodDescriptorCreateProject);
  }

  methodDescriptorGetProject = new grpcWeb.MethodDescriptor(
    '/ProjectService/GetProject',
    grpcWeb.MethodType.UNARY,
    project_pb.GetProjectRequest,
    project_pb.GetProjectResponse,
    (request: project_pb.GetProjectRequest) => {
      return request.serializeBinary();
    },
    project_pb.GetProjectResponse.deserializeBinary
  );

  getProject(
    request: project_pb.GetProjectRequest,
    metadata?: grpcWeb.Metadata | null): Promise<project_pb.GetProjectResponse>;

  getProject(
    request: project_pb.GetProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: project_pb.GetProjectResponse) => void): grpcWeb.ClientReadableStream<project_pb.GetProjectResponse>;

  getProject(
    request: project_pb.GetProjectRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: project_pb.GetProjectResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ProjectService/GetProject',
        request,
        metadata || {},
        this.methodDescriptorGetProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ProjectService/GetProject',
    request,
    metadata || {},
    this.methodDescriptorGetProject);
  }

  methodDescriptorGetProjects = new grpcWeb.MethodDescriptor(
    '/ProjectService/GetProjects',
    grpcWeb.MethodType.UNARY,
    project_pb.GetProjectsRequest,
    project_pb.GetProjectsResponse,
    (request: project_pb.GetProjectsRequest) => {
      return request.serializeBinary();
    },
    project_pb.GetProjectsResponse.deserializeBinary
  );

  getProjects(
    request: project_pb.GetProjectsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<project_pb.GetProjectsResponse>;

  getProjects(
    request: project_pb.GetProjectsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: project_pb.GetProjectsResponse) => void): grpcWeb.ClientReadableStream<project_pb.GetProjectsResponse>;

  getProjects(
    request: project_pb.GetProjectsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: project_pb.GetProjectsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ProjectService/GetProjects',
        request,
        metadata || {},
        this.methodDescriptorGetProjects,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ProjectService/GetProjects',
    request,
    metadata || {},
    this.methodDescriptorGetProjects);
  }

  methodDescriptorUpdateProject = new grpcWeb.MethodDescriptor(
    '/ProjectService/UpdateProject',
    grpcWeb.MethodType.UNARY,
    project_pb.UpdateProjectRequest,
    project_pb.UpdateProjectResponse,
    (request: project_pb.UpdateProjectRequest) => {
      return request.serializeBinary();
    },
    project_pb.UpdateProjectResponse.deserializeBinary
  );

  updateProject(
    request: project_pb.UpdateProjectRequest,
    metadata?: grpcWeb.Metadata | null): Promise<project_pb.UpdateProjectResponse>;

  updateProject(
    request: project_pb.UpdateProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: project_pb.UpdateProjectResponse) => void): grpcWeb.ClientReadableStream<project_pb.UpdateProjectResponse>;

  updateProject(
    request: project_pb.UpdateProjectRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: project_pb.UpdateProjectResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ProjectService/UpdateProject',
        request,
        metadata || {},
        this.methodDescriptorUpdateProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ProjectService/UpdateProject',
    request,
    metadata || {},
    this.methodDescriptorUpdateProject);
  }

  methodDescriptorDeleteProject = new grpcWeb.MethodDescriptor(
    '/ProjectService/DeleteProject',
    grpcWeb.MethodType.UNARY,
    project_pb.DeleteProjectRequest,
    project_pb.DeleteProjectResponse,
    (request: project_pb.DeleteProjectRequest) => {
      return request.serializeBinary();
    },
    project_pb.DeleteProjectResponse.deserializeBinary
  );

  deleteProject(
    request: project_pb.DeleteProjectRequest,
    metadata?: grpcWeb.Metadata | null): Promise<project_pb.DeleteProjectResponse>;

  deleteProject(
    request: project_pb.DeleteProjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: project_pb.DeleteProjectResponse) => void): grpcWeb.ClientReadableStream<project_pb.DeleteProjectResponse>;

  deleteProject(
    request: project_pb.DeleteProjectRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: project_pb.DeleteProjectResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ProjectService/DeleteProject',
        request,
        metadata || {},
        this.methodDescriptorDeleteProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ProjectService/DeleteProject',
    request,
    metadata || {},
    this.methodDescriptorDeleteProject);
  }

}

