import { useState } from "react";
import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";
import { GeneralExpenseRow } from "./GeneralExpenseRow";
import { Calculations as c } from "../../utils/calculations";
import { GeneralExpenseType } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { TakeoffStats } from "../../api/protosCompiled/estimateAssembly/estimateAssembly_pb";

export const GeneralExpenseHeader = ({
  generalExpenses,
  generalExpensesDefaults,
  assemblyStats,
}: {
  generalExpenses: GeneralExpenseType.AsObject[];
  generalExpensesDefaults: GeneralExpenseType.AsObject[];
  assemblyStats: TakeoffStats.AsObject[];
}) => {
  const { setIncludedGenExpenseTypes, updateGenExpenseType } =
    useStore(useCloseoutStore);
  const [selectedGenExpenseType, setSelectedGenExpenseType] =
    useState<string>("");

  const handleChange = (e: any) => {
    const newValue = e.target.value as string;
    const defaultGenExpenseType = generalExpensesDefaults.find(
      (genExpenseType) => genExpenseType.name === newValue
    );

    if (defaultGenExpenseType) {
      const newGenExpenseTypeType: GeneralExpenseType.AsObject = {
        name: defaultGenExpenseType.name,
        quantity: defaultGenExpenseType.quantity || 0,
        duration: defaultGenExpenseType.duration || 0,
        durationmultiplier: defaultGenExpenseType.durationmultiplier || 0,
        durationmultipliername:
          defaultGenExpenseType.durationmultipliername || "",
        unitcost: defaultGenExpenseType.unitcost || 0,
        totalcost: c.calculateGenExpenseTotal(defaultGenExpenseType) || 0,
      };

      if (
        !generalExpenses.some(
          (generalExpenses) => generalExpenses.name === newValue
        )
      ) {
        const newIncludedGenExpenseTypes = [
          ...generalExpenses,
          newGenExpenseTypeType,
        ];
        setIncludedGenExpenseTypes(newIncludedGenExpenseTypes);
        setSelectedGenExpenseType("");
      }
    }
  };

  const handleDelete = (laborName: string) => {
    const newIncludedGenExpenseTypes = generalExpenses.filter(
      (l) => l.name !== laborName
    );
    setIncludedGenExpenseTypes(newIncludedGenExpenseTypes);
  };

  const handleFieldUpdate = (field: string, value: any, index: number) => {
    const updatedGenExpense = {
      ...generalExpenses[index],
      [field]: value,
    };

    if (field === "quantity") {
      updatedGenExpense.quantity = value;
    } else if (field === "duration") {
      updatedGenExpense.duration = value;
    } else if (field === "unitcost") {
      updatedGenExpense.unitcost = value;
    }

    updateGenExpenseType(index, updatedGenExpense);
  };

  return (
    <>
      <select value={selectedGenExpenseType} onChange={handleChange}>
        <option value="" disabled selected hidden>
          Select general expense type
        </option>
        {generalExpensesDefaults
          .sort((a, b) => a.name.localeCompare(b.name)) // Sorting alphabetically
          .map((generalExpensesType, index) => (
            <option value={generalExpensesType.name} key={index}>
              {generalExpensesType.name}
            </option>
          ))}
      </select>
      <div className="scrollableCDiv">
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Quantity</th>
              <th>Quantity Multiplier</th>
              <th>Unit Cost</th>
              <th>Total Cost</th>
            </tr>
          </thead>
          <tbody>
            {generalExpenses.map((generalExpense, index) => (
              <GeneralExpenseRow
                key={index}
                generalExpense={generalExpense}
                handleDelete={handleDelete}
                handleFieldUpdate={(field, value) =>
                  handleFieldUpdate(field, value, index)
                }
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
