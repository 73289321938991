import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useStore } from "zustand";
import { defaultChangeOrder, useUnityBuildStore } from "../../states/store";
import {
  useCreateChangeOrderMutation,
  useUpdateChangeOrderMutation,
} from "../../hooks/useCreateChangeOrderMutation";
import { ChangeOrder } from "../../api/protosCompiled/changeOrder/changeOrder_pb";
import { useFetchChangeOrders } from "../../hooks/useFetchChangeOrders";

// Helper function to format date to "yyyy-MM-dd"
const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0]; // Extract only the date part
};

export const CreateChangeOrder: React.FC = () => {
  const {
    isCreatingChangeOrder,
    selectedChangeOrder,
    selectedContract,
    setSelectedChangeOrder,
    setIsCreatingChangeOrder,
    setIsViewingChangeOrderForm,
  } = useStore(useUnityBuildStore);

  const { data: changeOrders } = useFetchChangeOrders();

  const { mutate: createChangeOrder } = useCreateChangeOrderMutation();
  const { mutate: updateChangeOrder } = useUpdateChangeOrderMutation();

  const [description, setDescription] = useState("");
  const [dueDate, setDueDate] = useState<string>(
    formatDate(new Date().toISOString())
  );
  const [name, setName] = useState("");
  const [customid, setCustomId] = useState("");

  useEffect(() => {
    if (!isCreatingChangeOrder) {
      const selectedChangeOrderObj = changeOrders?.find(
        (contract) =>
          contract.changeorder?.changeorderid.trim() ===
          selectedChangeOrder.changeorderid.trim()
      );

      if (!selectedChangeOrderObj) {
        setSelectedChangeOrder(defaultChangeOrder);
      } else {
        setCustomId(selectedChangeOrderObj.changeorder?.customid ?? "");
        setName(selectedChangeOrderObj.changeorder?.name ?? "");
        setDescription(selectedChangeOrderObj.changeorder?.description ?? "");
        setDueDate(
          formatDate(selectedChangeOrderObj.changeorder?.duedate ?? "")
        );
      }
    }
    // eslint-disable-next-line
  }, [selectedChangeOrder, changeOrders]);

  type Field = "name" | "description" | "duedate" | "custumid";

  const handleSetValue = (field: Field, value: string) => {
    switch (field) {
      case "description":
        setDescription(value);
        break;
      case "duedate":
        setDueDate(value);
        break;
      case "name":
        setName(value);
        break;
      case "custumid":
        setCustomId(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formData: ChangeOrder.AsObject = {
      changeorderid: selectedChangeOrder.changeorderid ?? "",
      description: description,
      duedate: dueDate,
      contractid: selectedContract.contractid,
      datecreated: selectedChangeOrder.datecreated,
      name: name,
      customid: customid,
      createdbyuserid: selectedChangeOrder.createdbyuserid,
      createdbyaccountid: selectedChangeOrder.createdbyaccountid,
      lastupdated: "",
    };
    if (isCreatingChangeOrder) {
      createChangeOrder(formData);
    } else {
      updateChangeOrder(formData);
    }
    setIsViewingChangeOrderForm(false);
    setIsCreatingChangeOrder(false);
    setIsCreatingChangeOrder(false);
    resetForm();
  };

  const handleEstimateFormCancel = () => {
    setIsCreatingChangeOrder(false);
    setIsViewingChangeOrderForm(false);
  };

  const resetForm = () => {
    setDescription("");
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="name"
        label="Change Order Name"
        rows={4}
        value={name}
        onChange={(e) => handleSetValue("name", e.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="description"
        label="Change Order Description"
        multiline
        rows={4}
        value={description}
        onChange={(e) => handleSetValue("description", e.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="customId"
        label="Change Order Id"
        rows={4}
        value={customid}
        onChange={(e) => handleSetValue("custumid", e.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="dueDate"
        label="Change Order Due Date"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        rows={4}
        value={dueDate}
        onChange={(e) => handleSetValue("duedate", e.target.value)}
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }}>
        {isCreatingChangeOrder ? "Create Change Order" : "Update Change Order"}
      </Button>
      <Button onClick={handleEstimateFormCancel}>Cancel</Button>
    </Box>
  );
};
