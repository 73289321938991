import { create } from "zustand";
import { totalPricingDefaults } from "../components/closeout/closeoutTypes";
import {
  ExtensionType,
  DirectLaborType,
  IncidentalLaborType,
  LaborFactoringType,
  IndirectLaborType,
  QuoteType,
  SubcontractType,
  GeneralExpenseType,
  EquipmentType,
  TotalFinalRowData,
  TotalPricingType,
} from "../api/protosCompiled/estimateCloseout/estimateCloseout_pb";

export type CloseoutStore = {
  isUpdatingDirectLaborAllocation: boolean;
  setIsUpdatingDirectLaborAllocation: (
    isUpdatingDirectLaborAllocation: boolean
  ) => void;

  includedDirectLaborTypes: DirectLaborType.AsObject[];
  setIncludedDirectLaborTypes: (laborTypes: DirectLaborType.AsObject[]) => void;
  updateDirectLaborType: (
    index: number,
    updatedDirectLaborType: DirectLaborType.AsObject
  ) => void;

  includedIncidentalLaborTypes: IncidentalLaborType.AsObject[];
  setIncludedIncidentalLaborTypes: (
    laborTypes: IncidentalLaborType.AsObject[]
  ) => void;
  updateIncidentalLaborType: (
    index: number,
    updatedIncidentalLaborType: IncidentalLaborType.AsObject
  ) => void;

  includedIndirectLaborTypes: IndirectLaborType.AsObject[];
  setIncludedIndirectLaborTypes: (
    laborTypes: IndirectLaborType.AsObject[]
  ) => void;
  updateIndirectLaborType: (
    index: number,
    updatedIndirectLaborType: IndirectLaborType.AsObject
  ) => void;

  includedLaborFactoringTypes: LaborFactoringType.AsObject[];
  setIncludedLaborFactoringTypes: (
    laborTypes: LaborFactoringType.AsObject[]
  ) => void;
  updateLaborFactoringType: (
    index: number,
    updatedLaborFactoringType: LaborFactoringType.AsObject
  ) => void;

  includedEquipmentTypes: EquipmentType.AsObject[];
  setIncludedEquipmentTypes: (equipmentTypes: EquipmentType.AsObject[]) => void;
  updateEquipmentType: (
    index: number,
    updatedEquipmentType: EquipmentType.AsObject
  ) => void;

  includedGenExpenseTypes: GeneralExpenseType.AsObject[];
  setIncludedGenExpenseTypes: (
    generalExpenseTypes: GeneralExpenseType.AsObject[]
  ) => void;
  updateGenExpenseType: (
    index: number,
    updatedGenExpenseType: GeneralExpenseType.AsObject
  ) => void;

  includedSubcontractTypes: SubcontractType.AsObject[];
  setIncludedSubcontractTypes: (
    subcontractTypes: SubcontractType.AsObject[]
  ) => void;
  updateSubcontractType: (
    index: number,
    updatedSubcontractType: SubcontractType.AsObject
  ) => void;

  includedQuoteTypes: QuoteType.AsObject[];
  setIncludedQuoteTypes: (quoteTypes: QuoteType.AsObject[]) => void;
  updateQuoteType: (
    index: number,
    updatedQuoteType: QuoteType.AsObject
  ) => void;

  includedTotalPricingTypes: TotalFinalRowData.AsObject;
  setIncludedTotalPricingTypes: (
    totalPricingTypes: TotalFinalRowData.AsObject
  ) => void;
  updateTotalPricingType: (
    index: number,
    updatedTotalPricingType: TotalPricingType.AsObject
  ) => void;

  includedExtentionTypes: ExtensionType.AsObject[];
  setIncludedExtentionTypes: (extentionTypes: ExtensionType.AsObject[]) => void;
  updateExtentionType: (
    index: number,
    updatedExtentionType: ExtensionType.AsObject
  ) => void;
};

export const useCloseoutStore = create<CloseoutStore>((set) => ({
  isUpdatingDirectLaborAllocation: false,
  setIsUpdatingDirectLaborAllocation: (isUpdatingDirectLaborAllocation) =>
    set({ isUpdatingDirectLaborAllocation }),

  includedDirectLaborTypes: [],
  setIncludedDirectLaborTypes: (laborTypes) =>
    set({ includedDirectLaborTypes: laborTypes }),

  updateDirectLaborType: (index, updatedDirectLaborType) =>
    set((state) => {
      const newIncludedDirectLaborTypes = [...state.includedDirectLaborTypes];
      newIncludedDirectLaborTypes[index] = updatedDirectLaborType;
      return { includedDirectLaborTypes: newIncludedDirectLaborTypes };
    }),

  includedIncidentalLaborTypes: [],
  setIncludedIncidentalLaborTypes: (laborTypes) =>
    set({ includedIncidentalLaborTypes: laborTypes }),

  updateIncidentalLaborType: (index, updatedIncidentalLaborType) =>
    set((state) => {
      const newIncludedIncidentalLaborTypes = [
        ...state.includedIncidentalLaborTypes,
      ];
      newIncludedIncidentalLaborTypes[index] = updatedIncidentalLaborType;
      return { includedIncidentalLaborTypes: newIncludedIncidentalLaborTypes };
    }),

  includedIndirectLaborTypes: [],
  setIncludedIndirectLaborTypes: (laborTypes) =>
    set({ includedIndirectLaborTypes: laborTypes }),

  updateIndirectLaborType: (index, updatedIndirectLaborType) =>
    set((state) => {
      const newIncludedIndirectLaborTypes = [
        ...state.includedIndirectLaborTypes,
      ];
      newIncludedIndirectLaborTypes[index] = updatedIndirectLaborType;
      return { includedIndirectLaborTypes: newIncludedIndirectLaborTypes };
    }),

  includedLaborFactoringTypes: [],
  setIncludedLaborFactoringTypes: (laborTypes) =>
    set({ includedLaborFactoringTypes: laborTypes }),

  updateLaborFactoringType: (index, updatedLaborFactoringType) =>
    set((state) => {
      const newIncludedLaborFactoringTypes = [
        ...state.includedLaborFactoringTypes,
      ];
      newIncludedLaborFactoringTypes[index] = updatedLaborFactoringType;
      return { includedLaborFactoringTypes: newIncludedLaborFactoringTypes };
    }),

  includedEquipmentTypes: [],
  setIncludedEquipmentTypes: (equipmentTypes) =>
    set({ includedEquipmentTypes: equipmentTypes }),

  updateEquipmentType: (index, updatedEquipmentType) =>
    set((state) => {
      const newIncludedEquipmentTypes = [...state.includedEquipmentTypes];
      newIncludedEquipmentTypes[index] = updatedEquipmentType;
      return { includedEquipmentTypes: newIncludedEquipmentTypes };
    }),

  includedGenExpenseTypes: [],
  setIncludedGenExpenseTypes: (generalExpenseTypes) =>
    set({ includedGenExpenseTypes: generalExpenseTypes }),

  updateGenExpenseType: (index, updatedGenExpenseType) =>
    set((state) => {
      const newIncludedGenExpenseTypes = [...state.includedGenExpenseTypes];
      newIncludedGenExpenseTypes[index] = updatedGenExpenseType;
      return { includedGenExpenseTypes: newIncludedGenExpenseTypes };
    }),

  includedSubcontractTypes: [],
  setIncludedSubcontractTypes: (subcontractTypes) =>
    set({ includedSubcontractTypes: subcontractTypes }),

  updateSubcontractType: (index, updatedSubcontractType) =>
    set((state) => {
      const newIncludedSubcontractTypes = [...state.includedSubcontractTypes];
      newIncludedSubcontractTypes[index] = updatedSubcontractType;
      return { includedSubcontractTypes: newIncludedSubcontractTypes };
    }),

  includedQuoteTypes: [],
  setIncludedQuoteTypes: (quoteTypes) =>
    set({ includedQuoteTypes: quoteTypes }),

  updateQuoteType: (index, updatedQuoteType) =>
    set((state) => {
      const newIncludedQuoteTypes = [...state.includedQuoteTypes];
      newIncludedQuoteTypes[index] = updatedQuoteType;
      return { includedQuoteTypes: newIncludedQuoteTypes };
    }),

  includedTotalPricingTypes: totalPricingDefaults,
  setIncludedTotalPricingTypes: (totalPricingTypes) =>
    set({ includedTotalPricingTypes: totalPricingTypes }),

  updateTotalPricingType: (index, updatedTotalPricingType) =>
    set((state) => {
      const newIncludedTotalPricingTypes = [
        ...state.includedTotalPricingTypes.rowtypesList,
      ];
      newIncludedTotalPricingTypes[index] = updatedTotalPricingType;
      return {
        includedTotalPricingTypes: {
          ...state.includedTotalPricingTypes,
          rowTypes: newIncludedTotalPricingTypes,
        },
      };
    }),

  includedExtentionTypes: [],
  setIncludedExtentionTypes: (extentionTypes) =>
    set({ includedExtentionTypes: extentionTypes }),

  updateExtentionType: (index, updatedExtentionType) =>
    set((state) => {
      const newIncludedExtentionTypes = [...state.includedExtentionTypes];
      newIncludedExtentionTypes[index] = updatedExtentionType;
      return { includedExtentionTypes: newIncludedExtentionTypes };
    }),
}));
