/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: contract.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as contract_pb from './contract_pb'; // proto import: "contract.proto"


export class ContractServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateContract = new grpcWeb.MethodDescriptor(
    '/ContractService/CreateContract',
    grpcWeb.MethodType.UNARY,
    contract_pb.CreateContractRequest,
    contract_pb.CreateContractResponse,
    (request: contract_pb.CreateContractRequest) => {
      return request.serializeBinary();
    },
    contract_pb.CreateContractResponse.deserializeBinary
  );

  createContract(
    request: contract_pb.CreateContractRequest,
    metadata?: grpcWeb.Metadata | null): Promise<contract_pb.CreateContractResponse>;

  createContract(
    request: contract_pb.CreateContractRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: contract_pb.CreateContractResponse) => void): grpcWeb.ClientReadableStream<contract_pb.CreateContractResponse>;

  createContract(
    request: contract_pb.CreateContractRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: contract_pb.CreateContractResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ContractService/CreateContract',
        request,
        metadata || {},
        this.methodDescriptorCreateContract,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ContractService/CreateContract',
    request,
    metadata || {},
    this.methodDescriptorCreateContract);
  }

  methodDescriptorGetContract = new grpcWeb.MethodDescriptor(
    '/ContractService/GetContract',
    grpcWeb.MethodType.UNARY,
    contract_pb.GetContractRequest,
    contract_pb.GetContractResponse,
    (request: contract_pb.GetContractRequest) => {
      return request.serializeBinary();
    },
    contract_pb.GetContractResponse.deserializeBinary
  );

  getContract(
    request: contract_pb.GetContractRequest,
    metadata?: grpcWeb.Metadata | null): Promise<contract_pb.GetContractResponse>;

  getContract(
    request: contract_pb.GetContractRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: contract_pb.GetContractResponse) => void): grpcWeb.ClientReadableStream<contract_pb.GetContractResponse>;

  getContract(
    request: contract_pb.GetContractRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: contract_pb.GetContractResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ContractService/GetContract',
        request,
        metadata || {},
        this.methodDescriptorGetContract,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ContractService/GetContract',
    request,
    metadata || {},
    this.methodDescriptorGetContract);
  }

  methodDescriptorGetContracts = new grpcWeb.MethodDescriptor(
    '/ContractService/GetContracts',
    grpcWeb.MethodType.UNARY,
    contract_pb.GetContractsRequest,
    contract_pb.GetContractsResponse,
    (request: contract_pb.GetContractsRequest) => {
      return request.serializeBinary();
    },
    contract_pb.GetContractsResponse.deserializeBinary
  );

  getContracts(
    request: contract_pb.GetContractsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<contract_pb.GetContractsResponse>;

  getContracts(
    request: contract_pb.GetContractsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: contract_pb.GetContractsResponse) => void): grpcWeb.ClientReadableStream<contract_pb.GetContractsResponse>;

  getContracts(
    request: contract_pb.GetContractsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: contract_pb.GetContractsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ContractService/GetContracts',
        request,
        metadata || {},
        this.methodDescriptorGetContracts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ContractService/GetContracts',
    request,
    metadata || {},
    this.methodDescriptorGetContracts);
  }

  methodDescriptorUpdateContract = new grpcWeb.MethodDescriptor(
    '/ContractService/UpdateContract',
    grpcWeb.MethodType.UNARY,
    contract_pb.UpdateContractRequest,
    contract_pb.UpdateContractResponse,
    (request: contract_pb.UpdateContractRequest) => {
      return request.serializeBinary();
    },
    contract_pb.UpdateContractResponse.deserializeBinary
  );

  updateContract(
    request: contract_pb.UpdateContractRequest,
    metadata?: grpcWeb.Metadata | null): Promise<contract_pb.UpdateContractResponse>;

  updateContract(
    request: contract_pb.UpdateContractRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: contract_pb.UpdateContractResponse) => void): grpcWeb.ClientReadableStream<contract_pb.UpdateContractResponse>;

  updateContract(
    request: contract_pb.UpdateContractRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: contract_pb.UpdateContractResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ContractService/UpdateContract',
        request,
        metadata || {},
        this.methodDescriptorUpdateContract,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ContractService/UpdateContract',
    request,
    metadata || {},
    this.methodDescriptorUpdateContract);
  }

  methodDescriptorDeleteContract = new grpcWeb.MethodDescriptor(
    '/ContractService/DeleteContract',
    grpcWeb.MethodType.UNARY,
    contract_pb.DeleteContractRequest,
    contract_pb.DeleteContractResponse,
    (request: contract_pb.DeleteContractRequest) => {
      return request.serializeBinary();
    },
    contract_pb.DeleteContractResponse.deserializeBinary
  );

  deleteContract(
    request: contract_pb.DeleteContractRequest,
    metadata?: grpcWeb.Metadata | null): Promise<contract_pb.DeleteContractResponse>;

  deleteContract(
    request: contract_pb.DeleteContractRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: contract_pb.DeleteContractResponse) => void): grpcWeb.ClientReadableStream<contract_pb.DeleteContractResponse>;

  deleteContract(
    request: contract_pb.DeleteContractRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: contract_pb.DeleteContractResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ContractService/DeleteContract',
        request,
        metadata || {},
        this.methodDescriptorDeleteContract,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ContractService/DeleteContract',
    request,
    metadata || {},
    this.methodDescriptorDeleteContract);
  }

  methodDescriptorAssociateEstimateWithContract = new grpcWeb.MethodDescriptor(
    '/ContractService/AssociateEstimateWithContract',
    grpcWeb.MethodType.UNARY,
    contract_pb.AssociateEstimateWithContractRequest,
    contract_pb.AssociateEstimateWithContractResponse,
    (request: contract_pb.AssociateEstimateWithContractRequest) => {
      return request.serializeBinary();
    },
    contract_pb.AssociateEstimateWithContractResponse.deserializeBinary
  );

  associateEstimateWithContract(
    request: contract_pb.AssociateEstimateWithContractRequest,
    metadata?: grpcWeb.Metadata | null): Promise<contract_pb.AssociateEstimateWithContractResponse>;

  associateEstimateWithContract(
    request: contract_pb.AssociateEstimateWithContractRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: contract_pb.AssociateEstimateWithContractResponse) => void): grpcWeb.ClientReadableStream<contract_pb.AssociateEstimateWithContractResponse>;

  associateEstimateWithContract(
    request: contract_pb.AssociateEstimateWithContractRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: contract_pb.AssociateEstimateWithContractResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ContractService/AssociateEstimateWithContract',
        request,
        metadata || {},
        this.methodDescriptorAssociateEstimateWithContract,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ContractService/AssociateEstimateWithContract',
    request,
    metadata || {},
    this.methodDescriptorAssociateEstimateWithContract);
  }

  methodDescriptorAssociateChangeOrderWithContract = new grpcWeb.MethodDescriptor(
    '/ContractService/AssociateChangeOrderWithContract',
    grpcWeb.MethodType.UNARY,
    contract_pb.AssociateChangeOrderWithContractRequest,
    contract_pb.AssociateChangeOrderWithContractResponse,
    (request: contract_pb.AssociateChangeOrderWithContractRequest) => {
      return request.serializeBinary();
    },
    contract_pb.AssociateChangeOrderWithContractResponse.deserializeBinary
  );

  associateChangeOrderWithContract(
    request: contract_pb.AssociateChangeOrderWithContractRequest,
    metadata?: grpcWeb.Metadata | null): Promise<contract_pb.AssociateChangeOrderWithContractResponse>;

  associateChangeOrderWithContract(
    request: contract_pb.AssociateChangeOrderWithContractRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: contract_pb.AssociateChangeOrderWithContractResponse) => void): grpcWeb.ClientReadableStream<contract_pb.AssociateChangeOrderWithContractResponse>;

  associateChangeOrderWithContract(
    request: contract_pb.AssociateChangeOrderWithContractRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: contract_pb.AssociateChangeOrderWithContractResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/ContractService/AssociateChangeOrderWithContract',
        request,
        metadata || {},
        this.methodDescriptorAssociateChangeOrderWithContract,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/ContractService/AssociateChangeOrderWithContract',
    request,
    metadata || {},
    this.methodDescriptorAssociateChangeOrderWithContract);
  }

}

