import { useMutation, useQueryClient } from "react-query";
import { ProjectService as ps } from "../api/ProjectService";
import { Project } from "../api/protosCompiled/project/project_pb";

export const useCreateProjectMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: Project.AsObject) => ps.createProject(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["projects"]);
    },
    onError: (error) => {
      console.error("Error creating project", error);
    },
  });
};

export const useUpdateProjectMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: Project.AsObject) => ps.updateProject(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["projects"]);
    },
    onError: (error) => {
      console.error("Error updating project", error);
    },
  });
};
