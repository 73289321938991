import { useMutation, useQueryClient } from "react-query";
import { ProjectEstimateAssemblyService as peas } from "../api/ProjectEstimateAssemblyService";
import { EstimateAssembly } from "../api/protosCompiled/estimateAssembly/estimateAssembly_pb";

export const useCreateProjectEstimateAssemblyMutation = () => {
  const queryClient = useQueryClient();

  const { mutate, mutateAsync } = useMutation(
    (data: EstimateAssembly.AsObject) =>
      peas.createProjectEstimateAssembly(data),
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries(["countedAssemblies"]);
      },
      onError: (error) => {
        console.error("Error creating project estimate assembly", error);
      },
    }
  );

  // Return both mutate and mutateAsync for use in components or other hooks
  return { mutate, mutateAsync };
};
