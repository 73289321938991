import { clients } from "../clients/grpcClients";
import {
  CreateProjectRequest,
  CreateProjectResponse,
  GetProjectsRequest,
  GetProjectsResponse,
  Project,
  UpdateProjectRequest,
  UpdateProjectResponse,
} from "./protosCompiled/project/project_pb";
import { useUnityBuildStore } from "../states/store";

export class ProjectService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }
  //gRPC API
  public static createProject = (
    data: Project.AsObject
  ): Promise<CreateProjectResponse.AsObject> => {
    const request = new Project();
    request.setName(data.name);
    request.setAddress(data.address);
    request.setDescription(data.description);
    request.setStartdate(data.startdate);
    request.setEnddate(data.enddate);

    const req = new CreateProjectRequest();
    req.setSessiontoken(this.getMetadata().sessionToken);
    req.setProject(request);

    return new Promise((resolve, reject) => {
      clients.projectServiceClient.createProject(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject()); // Convert the protobuf response to a plain object
      });
    });
  };

  public static fetchProjects = (): Promise<GetProjectsResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const request = new GetProjectsRequest();
      request.setSessiontoken(this.getMetadata().sessionToken);
      clients.projectServiceClient.getProjects(request, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  };

  public static updateProject = (
    data: Project.AsObject
  ): Promise<UpdateProjectResponse.AsObject> => {
    const project = new Project();
    project.setId(data.id);
    project.setName(data.name);
    project.setAddress(data.address);
    project.setDescription(data.description);
    project.setStartdate(data.startdate);
    project.setEnddate(data.enddate);

    const req = new UpdateProjectRequest();
    req.setSessiontoken(this.getMetadata().sessionToken || ""); // Ensure token is passed
    req.setProject(project); // Attach the Project object

    return new Promise((resolve, reject) => {
      clients.projectServiceClient.updateProject(req, {}, (err, response) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(response.toObject());
      });
    });
  };
}
