import { useEffect, useState, useMemo } from "react";
import { LaborFactoringRow } from "./LaborFactoringRow";
import { LaborFactoringType } from "../../api/protosCompiled/estimateCloseout/estimateCloseout_pb";
import { Calculations as c } from "../../utils/calculations";
import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";
import { recalculateLaborFactoring } from "./closeoutHelpers";

export const LaborFactoringHeader = ({
  labor,
  laborDefaults,
}: {
  labor: LaborFactoringType.AsObject[];
  laborDefaults: LaborFactoringType.AsObject[];
}) => {
  const {
    includedExtentionTypes,
    setIncludedLaborFactoringTypes,
    updateLaborFactoringType,
  } = useStore(useCloseoutStore);
  const [selectedLaborType, setSelectedLaborType] = useState<string>("");

  const totalLaborHours = useMemo(() => {
    return includedExtentionTypes.reduce(
      (total, item) => total + item.totallaborhours,
      0
    );
  }, [includedExtentionTypes]);

  useEffect(() => {
    recalculateLaborFactoring(
      labor,
      totalLaborHours,
      setIncludedLaborFactoringTypes
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labor, totalLaborHours, setIncludedLaborFactoringTypes]);

  const handleChange = (e: any) => {
    const newValue = e.target.value as string;
    const defaultLaborType = laborDefaults.find(
      (laborType) => laborType.name === newValue
    );

    if (defaultLaborType) {
      const newLaborType: LaborFactoringType.AsObject = {
        name: defaultLaborType.name,
        distributionpercent: defaultLaborType.distributionpercent || 0,
        allocatedhours: defaultLaborType.allocatedhours || 0,
        laborrate: defaultLaborType.laborrate || 0,
        burdenpercent: defaultLaborType.burdenpercent || 0,
        fringe: defaultLaborType.fringe || 0,
        laborpercent: defaultLaborType.laborpercent || 0,
        impactpercent: defaultLaborType.impactpercent || 0,
        totalcost: c.calculateFullLaborCost(defaultLaborType) || 0,
      };

      if (!labor.some((labor) => labor.name === newValue)) {
        const newIncludedLaborTypes = [...labor, newLaborType];
        setIncludedLaborFactoringTypes(newIncludedLaborTypes);
        setSelectedLaborType("");
      }
    }
  };

  const handleDelete = (laborName: string) => {
    const newIncludedLaborTypes = labor.filter((l) => l.name !== laborName);
    setIncludedLaborFactoringTypes(newIncludedLaborTypes);
  };

  const handleLaborDistribution = (
    updatedLabor: LaborFactoringType.AsObject,
    index: number
  ) => {
    const updatedLaborList = labor.map((laborType, idx) =>
      idx === index ? updatedLabor : laborType
    );

    updatedLaborList.forEach((laborType, idx) => {
      laborType.allocatedhours =
        totalLaborHours *
        (laborType.laborpercent || 0) *
        (laborType.impactpercent || 0);
    });

    setIncludedLaborFactoringTypes(updatedLaborList);
  };

  const handleFieldUpdate = (field: string, value: number, index: number) => {
    const updatedLabor = {
      ...labor[index],
      [field]: value,
    };

    if (field === "laborpercent" || field === "impactpercent") {
      updatedLabor.allocatedhours =
        totalLaborHours *
        (updatedLabor.laborpercent || 0) *
        (updatedLabor.impactpercent || 0);
    }

    const updatedLaborList = labor.map((laborType, idx) =>
      idx === index ? updatedLabor : laborType
    );

    setIncludedLaborFactoringTypes(updatedLaborList);
    updateLaborFactoringType(index, updatedLabor);
  };

  return (
    <>
      <select value={selectedLaborType} onChange={handleChange}>
        <option value="" disabled selected hidden>
          Select labor type
        </option>
        {laborDefaults
          .sort((a, b) => a.name.localeCompare(b.name)) // Sorting alphabetically
          .map((laborType, index) => (
            <option value={laborType.name} key={index}>
              {laborType.name}
            </option>
          ))}
      </select>
      <div className="scrollableCDiv">
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Labor %</th>
              <th>Impact %</th>
              <th>Hours: {totalLaborHours.toFixed(2)}</th>
              <th>Labor Rate</th>
              <th>Subtotal</th>
              <th>Burden %</th>
              <th>Fringe</th>
              <th>Burden Total</th>
              <th>Fringe Total</th>
              <th>Full Hourly Cost</th>
              <th>Full Cost</th>
            </tr>
          </thead>
          <tbody>
            {labor.map((labor, index) => (
              <LaborFactoringRow
                key={index}
                labor={labor}
                index={index}
                totalLaborHours={totalLaborHours}
                handleDelete={handleDelete}
                handleFieldUpdate={handleFieldUpdate}
                handleLaborDistribution={handleLaborDistribution}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
