import { useMutation, useQueryClient } from "react-query";
import { ContractChangeOrderAssemblyService as ccoas } from "../api/ContractChangeOrderAssemblyService";
import { ChangeOrderAssembly } from "../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";

export const useUpdateContractChangeOrderAssemblyMutation = () => {
  const queryClient = useQueryClient();

  // Destructure mutate and mutateAsync from the useMutation return value
  const { mutate, mutateAsync } = useMutation(
    (data: ChangeOrderAssembly.AsObject) =>
      ccoas.updateContractChangeOrderAssembly(data),
    {
      onSuccess: () => {
        // Invalidate and refetch to ensure data is fresh
        queryClient.invalidateQueries(["countedAssemblies"]);
      },
      onError: (error) => {
        console.error("Error updating project change order assembly", error);
      },
    }
  );

  // Return both mutate and mutateAsync for flexible usage outside
  return { mutate, mutateAsync };
};
