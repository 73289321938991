import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { defaultProject, useUnityBuildStore } from "../states/store";
import {
  useCreateProjectMutation,
  useUpdateProjectMutation,
} from "../hooks/useCreateProjectMutation";
import { useStore } from "zustand";
import { Project } from "../api/protosCompiled/project/project_pb";
import { useFetchProjects } from "../hooks/useFetchProjects";

type CreateProjectProps = {
  create: boolean;
  update: boolean;
  updateProjectId?: string;
  setIsCreatingProject: (creatingNewProject: boolean) => void;
  setIsUpdatingProject: (updatingNewProject: boolean) => void;
};

export const CreateProject: React.FC<CreateProjectProps> = (
  props: CreateProjectProps
) => {
  const { data: projectList } = useFetchProjects();
  const { setIsCreatingNewProject } = useStore(useUnityBuildStore);
  const { mutate: createProject } = useCreateProjectMutation();
  const { mutate: updateProject } = useUpdateProjectMutation();
  const [project, setProject] = useState<Project.AsObject>(defaultProject);

  // Helper function to format date to "yyyy-MM-dd"
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Extract only the date part
  };

  // Inside your useEffect:
  useEffect(() => {
    if (props.update) {
      const matchingProject = projectList?.find(
        (project) => project.project?.id === props.updateProjectId
      );
      if (matchingProject) {
        const projectData = matchingProject.project!;
        setProject({
          ...projectData,
          startdate: projectData.startdate
            ? formatDate(matchingProject.project?.startdate ?? "")
            : "",
          enddate: projectData.enddate
            ? formatDate(matchingProject.project?.enddate ?? "")
            : "",
        });
      }
    }
  }, [props.update, props.updateProjectId, projectList]);

  const defaultTodaysDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formData: Project.AsObject = {
      id: project.id || "",
      name: project.name || "",
      address: project.address || "",
      description: project.description || "",
      startdate: project.startdate || defaultTodaysDate(),
      enddate: project.enddate || defaultTodaysDate(),
      datecreated: project.datecreated || "",
      openaivectorstoreid: project.openaivectorstoreid || "",
      openaiprojectassistantid: project.openaiprojectassistantid || "",
      createdbyuserid: project.createdbyuserid || "",
      createdbyaccountid: project.createdbyaccountid || "",
    };
    if (props.update) {
      updateProject(formData);
    } else {
      createProject(formData);
    }
    props.setIsCreatingProject(false);
    props.setIsUpdatingProject(false);
    resetForm();
  };

  const resetForm = () => {
    setProject(defaultProject);
    setIsCreatingNewProject(false);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="name"
        label="Project Name"
        autoFocus
        value={project.name}
        onChange={(e) => setProject({ ...project, name: e.target.value })}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="address"
        label="Project Address"
        //type="text"
        value={project.address}
        onChange={(e) => setProject({ ...project, address: e.target.value })}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="description"
        label="Project Description"
        multiline
        rows={4}
        value={project.description}
        onChange={(e) =>
          setProject({ ...project, description: e.target.value })
        }
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="startDate"
        label="Start Date"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        value={project.startdate || defaultTodaysDate()}
        onChange={(e) => setProject({ ...project, startdate: e.target.value })}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="endDate"
        label="End Date"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        value={project.enddate || defaultTodaysDate()}
        onChange={(e) => setProject({ ...project, enddate: e.target.value })}
      />{" "}
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }}>
        {props.update ? "Update Project" : "Create Project"}
      </Button>
    </Box>
  );
};
