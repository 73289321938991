import React, { useEffect, useState } from "react";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";
import { useSetSelectedAssemblyFromAssemblyPicker } from "../../hooks/useSetSelectedAssembly";
import { AssemblyItemType } from "../../types/AssemblyItemType";
import { CreateAssembly } from "../assemblyManager/AssemblyAndItemCreate/CreateAssembly";
import {
  Paper,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Button,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useFetchFolders } from "../../hooks/useFetchFolders";
import { MenuOptions } from "../../types/FolderTypes";
import { EstimateAssembly } from "../../api/protosCompiled/estimateAssembly/estimateAssembly_pb";
import { ChangeOrderAssembly } from "../../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import { ItemQtyFormula } from "../../api/protosCompiled/globalAssembly/globalAssembly_pb";
import { ResetStates } from "../../states/resetStates";
import { useCreateAssemblyStore } from "../../states/createAssemblyStore";

export const getItemQtyByTakeoffVariable = (
  assembly: EstimateAssembly.AsObject | ChangeOrderAssembly.AsObject,
  formula: ItemQtyFormula.AsObject
): number => {
  let totalQty = 0;
  // Calculate quantity based on the takeoff variable type of the formula
  switch (formula.takeoffvariabletype) {
    case "point":
      totalQty = Math.round(assembly.pointcount * formula.itemqty);
      break;

    case "segment":
      totalQty = Math.round(assembly.segmentcount * formula.itemqty);
      break;

    case "length":
      totalQty = Math.round(
        (assembly.segmentlength * formula.itemqty) / formula.forevery
      );
      break;
    case "area":
      totalQty = Math.round(assembly.sqft * formula.itemqty);
      break;

    default:
      totalQty = 0;
      break;
  }

  if (totalQty < 0) {
    totalQty = 0;
  }

  return totalQty;
};

export function RenderTakeoffList({ searchTerm }: { searchTerm: string }) {
  const UBStore = useStore(useUnityBuildStore);
  const CAstore = useStore(useCreateAssemblyStore);

  const { refetchFolders } = useFetchFolders();
  const [menuOption, setMenuOption] = useState<MenuOptions | null>(null);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [contextMenuAssembly, setContextMenuAssembly] = useState<any>(null);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const setSelectedAssembly = useSetSelectedAssemblyFromAssemblyPicker();

  const [isRenaming, setIsRenaming] = useState(false);
  const [renameValue, setRenameValue] = useState("");

  useEffect(() => {
    if (UBStore.isViewingExtension) {
      setMenuOption(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UBStore.isViewingExtension]);

  const handleSetAssembly = (assembly: any) => {
    setSelectedAssembly(assembly);
  };

  // Handle right-click on a row
  const handleRowContextMenu = (event: React.MouseEvent, assembly: any) => {
    event.preventDefault();
    setShowContextMenu(true);
    setContextMenuPosition({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    setContextMenuAssembly(assembly);
  };

  const handleCloseContextMenu = () => {
    setShowContextMenu(false);
    setContextMenuPosition(null);
    setContextMenuAssembly(null);
  };

  const handleRenameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRenameValue(e.target.value);
  };

  const handleRenameSubmit = () => {
    setIsRenaming(false);
  };

  // Handle Edit Assembly
  const handleEditAssembly = () => {
    if (contextMenuAssembly) {
      setSelectedAssembly(contextMenuAssembly);
      setMenuOption("Edit Assembly");
    }
    handleCloseContextMenu();
  };

  // Toggle expanded rows
  const handleToggleExpandRow = (assemblyId: string) => {
    if (expandedRows.includes(assemblyId)) {
      setExpandedRows(expandedRows.filter((id) => id !== assemblyId));
    } else {
      setExpandedRows([...expandedRows, assemblyId]);
    }
  };

  const hasQuotedItems = (assembly: AssemblyItemType.AssemblyAsObject) =>
    assembly?.itemqtyformulasList.some((formula) => formula.isquoted === true);

  const handleClose = () => {
    UBStore.setSelectedTakeoffAssembly(null);
    ResetStates.resetCreateAssemblyStates(CAstore, UBStore);
    setMenuOption(null);
  };

  return (
    <div
      className="TakeoffPalletteChildBox"
      style={{ backgroundColor: UBStore.userBackgroundColor }}
    >
      {/* Render CreateAssembly when selectedTakeoffAssembly is set and menuOption is "Edit Assembly" */}
      <Dialog
        open={
          menuOption === "Create Assembly" || menuOption === "Edit Assembly"
        }
        onClose={handleClose}
        fullScreen={true}
        style={{
          height: "100vh",
          backgroundColor: UBStore.userBackgroundColor,
        }}
      >
        <DialogContent>
          <CreateAssembly
            assemblyOption="takeoff"
            assembly={UBStore.selectedTakeoffAssembly as any}
            refetchFolders={refetchFolders}
            menuOption={menuOption}
            setMenuOption={setMenuOption}
          />
        </DialogContent>
      </Dialog>

      {/* Context Menu */}
      {showContextMenu && contextMenuPosition && (
        <ClickAwayListener onClickAway={handleCloseContextMenu}>
          <Paper
            style={{
              position: "fixed",
              top: contextMenuPosition.mouseY,
              left: contextMenuPosition.mouseX,
              zIndex: 4,
            }}
          >
            <MenuList>
              <MenuItem onClick={handleEditAssembly}>Edit Assembly</MenuItem>
            </MenuList>
          </Paper>
        </ClickAwayListener>
      )}

      {/* Existing table rendering assemblies */}
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "60%" }}>Description</th>
            {UBStore.selectedFilter === "all" && (
              <>
                <th>Count</th>
                <th>Length</th>
                <th>Area</th>
              </>
            )}
            {UBStore.selectedFilter === "count" && <th>Count</th>}
            {UBStore.selectedFilter === "length" && (
              <>
                <th>Count</th>
                <th>Length</th>
              </>
            )}
            {UBStore.selectedFilter === "area" && (
              <>
                <th>Count</th>
                <th>Length</th>
                <th>Area</th>
              </>
            )}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="TakeoffTableBody">
          {UBStore.filteredCountedAssemblies
            ?.filter((assembly: AssemblyItemType.AssemblyAsObject) => {
              const matchesFilter =
                UBStore.selectedFilter === "all" ||
                assembly.assemblymeasurementtype === UBStore.selectedFilter;
              const matchesSearch = assembly.assemblyname?.includes(searchTerm);
              return matchesFilter && matchesSearch;
            })
            .map((assembly: any) => {
              const segmentlength = assembly.segmentlength ?? 0;
              const feet = Math.round(segmentlength);
              const isExpanded = expandedRows.includes(assembly.takeoffid);

              return (
                <React.Fragment key={assembly.takeoffid}>
                  <tr
                    onContextMenu={(event) =>
                      handleRowContextMenu(event, assembly)
                    }
                  >
                    <td
                      onClick={() => handleSetAssembly(assembly)}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          UBStore.selectedTakeoffAssembly?.takeoffid ===
                          assembly.takeoffid
                            ? "#add8e6"
                            : undefined,
                      }}
                    >
                      {isRenaming &&
                      UBStore.selectedTakeoffAssembly?.takeoffid ===
                        assembly.takeoffid ? (
                        <input
                          type="text"
                          value={renameValue}
                          onChange={handleRenameChange}
                          onBlur={handleRenameSubmit}
                          autoFocus
                        />
                      ) : (
                        `${hasQuotedItems(assembly) ? "* " : ""}${
                          assembly.assemblyname
                        }`
                      )}
                    </td>
                    {UBStore.selectedFilter === "all" && (
                      <>
                        <td>{assembly.pointcount}</td>
                        <td>{`${feet}'`}</td>
                        <td>{assembly.sqft.toFixed(2)}</td>
                      </>
                    )}
                    {UBStore.selectedFilter === "count" && (
                      <td>{assembly.pointcount}</td>
                    )}
                    {UBStore.selectedFilter === "length" && (
                      <>
                        <td>{assembly.pointcount}</td>
                        <td>{`${feet}'`}</td>
                      </>
                    )}
                    {UBStore.selectedFilter === "area" && (
                      <>
                        <td>{assembly.pointcount}</td>
                        <td>{`${feet}'`}</td>
                        <td>{assembly.sqft.toFixed(2)}</td>
                      </>
                    )}
                    <td>
                      <Button
                        size="small"
                        onClick={() =>
                          handleToggleExpandRow(assembly.takeoffid)
                        }
                      >
                        {isExpanded ? "Collapse" : "Expand"}
                      </Button>
                    </td>
                  </tr>
                  {/* Expanded Row */}
                  {isExpanded && (
                    <tr style={{ backgroundColor: "#f2f2f2" }}>
                      <td colSpan={6}>
                        <table
                          style={{
                            width: "95%",
                            marginLeft: "20px",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>Item Name</th>
                              <th>QTY</th>
                            </tr>
                          </thead>
                          <tbody>
                            {assembly.itemqtyformulasList.map(
                              (item: any, idx: number) => (
                                <tr key={`${assembly.takeoffid}-${idx}`}>
                                  <td>
                                    {item.attributesList &&
                                    item.attributesList.length > 0
                                      ? item.attributesList
                                          .map(
                                            (attr: any) =>
                                              `${attr.attributevalue} - ${item.itemname}`
                                          )
                                          .join(", ")
                                      : item.itemname}
                                  </td>
                                  {/* Using the updated function to calculate the item quantity for each item */}
                                  <td>
                                    {getItemQtyByTakeoffVariable(
                                      assembly,
                                      item
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
