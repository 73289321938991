import { clients } from "../clients/grpcClients";
import {
  UB_CreateUserRequest,
  UB_CreateUserResponse,
  UB_DeleteUserRequest,
  UB_DeleteUserResponse,
  UB_GetAllUsersRequest,
  UB_GetAllUsersResponse,
  UB_UpdateUserRequest,
  UB_UpdateUserResponse,
  UB_User,
} from "./protosCompiled/ub_users/ub_users_pb";
import { BaseAPIService } from "./BaseAPIService";

export class UbUserService extends BaseAPIService {
  //gRPC calls
  public static fetchUsers = (): Promise<UB_GetAllUsersResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const getUsersRequest = new UB_GetAllUsersRequest();
      clients.ub_userServiceClient.getAllUsers(
        getUsersRequest,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static createUser = (
    user: UB_User.AsObject
  ): Promise<UB_CreateUserResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const createUserRequest = new UB_CreateUserRequest();
      const userMsg = new UB_User();
      userMsg.setId(user.id);
      userMsg.setName(user.name);
      userMsg.setEmail(user.email);
      userMsg.setUbaccountid(user.ubaccountid);
      userMsg.setPassword(user.password);
      userMsg.setUserphotourl(user.userphotourl);
      userMsg.setRoleId(user.roleId);

      createUserRequest.setUser(userMsg);

      clients.ub_userServiceClient.createUser(
        createUserRequest,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };

  public static updateUser = (
    user: UB_User.AsObject,
    password: string
  ): Promise<UB_UpdateUserResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const updateUserRequest = new UB_UpdateUserRequest();
      const userMsg = new UB_User();
      userMsg.setId(user.id);
      userMsg.setName(user.name);
      userMsg.setEmail(user.email);
      userMsg.setUserphotourl(user.userphotourl);
      userMsg.setRoleId(user.roleId);

      updateUserRequest.setUser(userMsg);
      updateUserRequest.setPassword(password);

      clients.ub_userServiceClient.updateUser(
        updateUserRequest,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };
  public static deleteUser = (
    id: string
  ): Promise<UB_DeleteUserResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const deleteUserRequest = new UB_DeleteUserRequest();
      deleteUserRequest.setId(id);

      clients.ub_userServiceClient.deleteUser(
        deleteUserRequest,
        {},
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };
}
