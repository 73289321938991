import { useState } from "react";
import { useStore } from "zustand";
import { useUnityBuildStore } from "../../states/store";
import { useHandleSetChangeOrder } from "./helpers";
import { Dialog, DialogContent } from "@mui/material";
import { CreateChangeOrder } from "./CreateChangeOrder";
import { useFetchContracts } from "../../hooks/useFetchContracts";
import { useFetchChangeOrders } from "../../hooks/useFetchChangeOrders";
import { ChangeOrder } from "../../api/protosCompiled/changeOrder/changeOrder_pb";

export function ListChangeOrders() {
  const store = useStore(useUnityBuildStore);
  const { refetch } = useFetchContracts();
  const [searchTerm, setSearchTerm] = useState("");

  const { data: changeOrderList } = useFetchChangeOrders();

  const handleSetValue = useHandleSetChangeOrder();

  const { setSelectedChangeOrder, setIsOpeningViewport } =
    useStore(useUnityBuildStore);

  const handleSearchChange = (event: any) => setSearchTerm(event.target.value);

  // Data normalization: Flatten the nested structure
  const normalizedChangeOrders = changeOrderList?.map((changeOrderResponse) => {
    const changeOrder = changeOrderResponse.changeorder;
    return {
      ...changeOrder,
      contractname: changeOrderResponse.contractname,
      contractdescription: changeOrderResponse.contractdescription,
    };
  });

  const handleSelectChangeOrder = (changeOrder: ChangeOrder.AsObject) => {
    setIsOpeningViewport(true);
    setSelectedChangeOrder(changeOrder);
  };

  const handleCloseForm = () => {
    refetch();
    store.setIsViewingChangeOrderForm(false);
  };

  const filteredChangeOrders = normalizedChangeOrders?.filter(
    (change_order) =>
      change_order.contractid
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      change_order.description?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <button onClick={() => handleSetValue("create", null)}>
        Create Change Order
      </button>
      <input
        id="search"
        placeholder="Search Change Orders..."
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <div className="scrollableTableContainer">
        <table>
          <thead>
            <tr>
              <th>Contract ID</th>
              <th>Change Name</th>
              <th>Date Created</th>
              <th>Change Order Due Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredChangeOrders?.map((item) => (
              <tr
                className="clickable"
                key={item.changeorderid}
                onClick={() => handleSelectChangeOrder(item as any)}
              >
                <td>{item.customid}</td>
                <td>{item.name}</td>
                <td>
                  {new Date(item.datecreated!).toLocaleDateString("en-US")}
                </td>
                <td>{new Date(item.duedate!).toLocaleDateString("en-US")}</td>
                <td style={{ pointerEvents: "none" }}>
                  <button
                    style={{ pointerEvents: "auto" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleSetValue("update", (item as any) ?? null);
                    }}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {!changeOrderList?.length && <p>No change orders available</p>}

      <Dialog open={store.isViewingChangeOrderForm} onClose={handleCloseForm}>
        <DialogContent>
          {store.isViewingChangeOrderForm && <CreateChangeOrder />}
        </DialogContent>
      </Dialog>
    </>
  );
}
