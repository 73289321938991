import React, { useEffect } from "react";
import { useUnityBuildStore } from "../../states/store";
import { PointAttributeHelpers } from "../../api/PointAttributeHelpers";
import { AssemblyStyle } from "./SVGShape";
import { useStore } from "zustand";
import { EstimateAssembly } from "../../api/protosCompiled/estimateAssembly/estimateAssembly_pb";

import { useSubmitTakeoffAssembly } from "../../hooks/useSubmitTakeoffAssembly";
import { ChangeOrderAssembly } from "../../api/protosCompiled/changeOrderAssembly/changeOrderAssembly_pb";
import {
  calculateCentroid,
  calculatePolygonArea,
  calculateRealWorldDistance,
  convertPointToRealWorld,
  getAdjustedCursorPosition,
} from "./takeoffHelper";

interface SVGPointLineProps {
  cursorPositionRef?: React.RefObject<{ x: number; y: number }>;
}

export const SVGPointLine: React.FC<SVGPointLineProps> = ({
  cursorPositionRef,
}) => {
  const {
    temporaryPoints,
    angleOptions,
    setTemporaryPoints,
    filteredCountedAssemblies,
    userScale,
    isAnnotating,
    isGettingLength,
    selectedTakeoffAssembly,
    isDragging,
    isInsertingPoint,
    setIsInsertingPoint,
    setIsEditingPoint,
    setSelectedTakeoffAssembly,
  } = useStore(useUnityBuildStore);

  useEffect(() => {
    const isLengthOrArea = () => {
      if (
        selectedTakeoffAssembly &&
        (selectedTakeoffAssembly.assemblymeasurementtype === "length" ||
          selectedTakeoffAssembly.assemblymeasurementtype === "area")
      ) {
        return true;
      } else {
        return false;
      }
    };

    if (selectedTakeoffAssembly && isAnnotating && isLengthOrArea()) {
      setTemporaryPoints(selectedTakeoffAssembly.pointsList);
    } else {
      setTemporaryPoints([]);
    }
  }, [
    selectedTakeoffAssembly,
    isAnnotating,
    isGettingLength,
    setTemporaryPoints,
  ]);

  const handleLineContextMenu = (
    e: React.MouseEvent<SVGElement, MouseEvent>,
    pointsList: any,
    lineIndex: number
  ) => {
    e.preventDefault();

    // Set the position and data for PointEditor
    setIsEditingPoint({
      isEditingPoint: true,
      x: e.clientX,
      y: e.clientY,
      pointsList,
      lineIndex,
    });
  };

  const submitAssembly = useSubmitTakeoffAssembly();

  const handleLineMouseDown = (
    e: React.MouseEvent<SVGElement, MouseEvent>,
    pointsList: any,
    lineIndex: number,
    assemblyId: string
  ) => {
    if (e.button === 0 && isInsertingPoint) {
      if (assemblyId !== selectedTakeoffAssembly?.takeoffid) {
        console.error(
          "Cannot insert point on different assembly than selected"
        );
        return;
      }
      PointAttributeHelpers.handleInsertPoint(
        e,
        pointsList,
        lineIndex,
        selectedTakeoffAssembly,
        setSelectedTakeoffAssembly,
        submitAssembly
      );
      setIsInsertingPoint(false); // Reset after insertion
      setSelectedTakeoffAssembly(null);
    }
  };

  const renderFilledPolygon = (
    pointsList: { x: number; y: number }[],
    assemblyId: string,
    assemblyMeasurementType: string
  ) => {
    if (assemblyMeasurementType !== "area" || pointsList.length < 3) {
      return null;
    }

    // Create a string of points for the <polygon> element
    const pointsString = pointsList.map((p) => `${p.x},${p.y}`).join(" ");

    // Convert points to real-world coordinates (in feet)
    let areaInSqFeet = 0;
    if (userScale) {
      const realWorldPoints = pointsList.map((point) =>
        convertPointToRealWorld(point, userScale)
      );

      // Calculate the area in square feet using the shoelace formula
      areaInSqFeet = calculatePolygonArea(realWorldPoints);
    }

    // Calculate the centroid of the polygon (in pixel coordinates)
    const centroid = calculateCentroid(pointsList);

    return (
      <g key={`filled-polygon-${assemblyId}`}>
        <polygon
          points={pointsString}
          fill="rgba(173, 216, 230, 0.5)" // Light blue with 50% opacity
          stroke="none"
        />
        <text
          x={centroid.x}
          y={centroid.y}
          fill="black"
          fontSize="16"
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {areaInSqFeet > 0
            ? `${areaInSqFeet.toFixed(2)} sq ft`
            : "NO SCALE SET"}
        </text>
      </g>
    );
  };

  const renderLinesBetweenPoints = (
    pointsList:
      | EstimateAssembly.AsObject["pointsList"]
      | ChangeOrderAssembly.AsObject["pointsList"],
    assemblyStyle: AssemblyStyle,
    assemblyId: string,
    assemblyMeasurementType: string // Added parameter to identify the measurement type
  ) => {
    if (!Array.isArray(pointsList)) {
      return null;
    }

    const lines = [];

    for (let index = 0; index < pointsList.length - 1; index++) {
      const point = pointsList[index];
      const nextPoint = pointsList[index + 1];
      const key = `${index}-${point.x}-${point.y}`;

      if (PointAttributeHelpers.evaluatePoint(nextPoint) === false) {
        continue;
      }

      const pixelDistance = Math.sqrt(
        (nextPoint.x - point.x) ** 2 + (nextPoint.y - point.y) ** 2
      );

      const lineColor =
        assemblyStyle?.lineColor || selectedTakeoffAssembly?.linecolor || "red";
      const lineSize =
        assemblyStyle?.lineSize || selectedTakeoffAssembly?.linesize || 2;
      const strokeDasharray =
        assemblyStyle?.lineDashArray ||
        selectedTakeoffAssembly?.linedasharray ||
        "";

      if (userScale) {
        const realWorldDistance = calculateRealWorldDistance(
          pixelDistance,
          userScale
        );

        const feet = Math.floor(realWorldDistance);
        const inches = Math.round((realWorldDistance - feet) * 12);

        lines.push(
          <g key={key}>
            <line
              x1={point.x}
              y1={point.y}
              x2={nextPoint.x}
              y2={nextPoint.y}
              stroke={lineColor}
              strokeWidth={lineSize}
              strokeDasharray={strokeDasharray}
              onContextMenu={(e) => handleLineContextMenu(e, pointsList, index)}
              onMouseDown={(e) =>
                handleLineMouseDown(e, pointsList, index, assemblyId)
              }
              data-index={index}
              data-assemblyid={assemblyId}
            />
            <text
              x={(point.x + nextPoint.x) / 2}
              y={(point.y + nextPoint.y) / 2 - 10}
              fill="black"
              fontSize="12"
              textAnchor="middle"
            >{`${feet}' ${inches}"`}</text>
          </g>
        );
      } else {
        lines.push(
          <g key={key}>
            <line
              x1={point.x}
              y1={point.y}
              x2={nextPoint.x}
              y2={nextPoint.y}
              stroke={lineColor}
              strokeWidth={lineSize}
              strokeDasharray={strokeDasharray}
              onContextMenu={(e) => handleLineContextMenu(e, pointsList, index)}
              onMouseDown={(e) =>
                handleLineMouseDown(e, pointsList, index, assemblyId)
              }
              data-index={index}
              data-assemblyid={assemblyId}
            />
            <text
              x={(point.x + nextPoint.x) / 2}
              y={(point.y + nextPoint.y) / 2 - 10}
              fill="black"
              fontSize="12"
              textAnchor="middle"
            >
              NO SCALE SET!!
            </text>
          </g>
        );
      }
    }

    // Close the polygon for area measurement types
    if (assemblyMeasurementType === "area" && pointsList.length > 2) {
      const firstPoint = pointsList[0];
      const lastPoint = pointsList[pointsList.length - 1];
      const key = `closing-line-${assemblyId}`;

      const lineColor =
        assemblyStyle?.lineColor || selectedTakeoffAssembly?.linecolor || "red";
      const lineSize =
        assemblyStyle?.lineSize || selectedTakeoffAssembly?.linesize || 2;
      const strokeDasharray =
        assemblyStyle?.lineDashArray ||
        selectedTakeoffAssembly?.linedasharray ||
        "";

      const pixelDistance = Math.sqrt(
        (firstPoint.x - lastPoint.x) ** 2 + (firstPoint.y - lastPoint.y) ** 2
      );

      if (userScale) {
        const realWorldDistance = calculateRealWorldDistance(
          pixelDistance,
          userScale
        );

        const feet = Math.floor(realWorldDistance);
        const inches = Math.round((realWorldDistance - feet) * 12);

        lines.push(
          <g key={key}>
            <line
              x1={lastPoint.x}
              y1={lastPoint.y}
              x2={firstPoint.x}
              y2={firstPoint.y}
              stroke={lineColor}
              strokeWidth={lineSize}
              strokeDasharray={strokeDasharray}
              onContextMenu={(e) =>
                handleLineContextMenu(e, pointsList, pointsList.length - 1)
              }
              onMouseDown={(e) =>
                handleLineMouseDown(
                  e,
                  pointsList,
                  pointsList.length - 1,
                  assemblyId
                )
              }
              data-index={pointsList.length - 1}
              data-assemblyid={assemblyId}
            />
            <text
              x={(lastPoint.x + firstPoint.x) / 2}
              y={(lastPoint.y + firstPoint.y) / 2 - 10}
              fill="black"
              fontSize="12"
              textAnchor="middle"
            >{`${feet}' ${inches}"`}</text>
          </g>
        );
      } else {
        lines.push(
          <g key={key}>
            <line
              x1={lastPoint.x}
              y1={lastPoint.y}
              x2={firstPoint.x}
              y2={firstPoint.y}
              stroke={lineColor}
              strokeWidth={lineSize}
              strokeDasharray={strokeDasharray}
              onContextMenu={(e) =>
                handleLineContextMenu(e, pointsList, pointsList.length - 1)
              }
              onMouseDown={(e) =>
                handleLineMouseDown(
                  e,
                  pointsList,
                  pointsList.length - 1,
                  assemblyId
                )
              }
              data-index={pointsList.length - 1}
              data-assemblyid={assemblyId}
            />
            <text
              x={(lastPoint.x + firstPoint.x) / 2}
              y={(lastPoint.y + firstPoint.y) / 2 - 10}
              fill="black"
              fontSize="12"
              textAnchor="middle"
            >
              NO SCALE SET!!
            </text>
          </g>
        );
      }
    }

    // Add the filled polygon and area label for area measurement types
    if (assemblyMeasurementType === "area" && pointsList.length > 2) {
      lines.push(
        renderFilledPolygon(pointsList, assemblyId, assemblyMeasurementType)
      );
    }

    return lines;
  };

  const renderTemporaryLines = () => {
    // Render lines for temporaryPoints
    return renderLinesBetweenPoints(
      temporaryPoints,
      {
        lineColor: selectedTakeoffAssembly?.linecolor || "#BD10E0",
        lineSize: selectedTakeoffAssembly?.linesize || 2,
        lineDashArray: selectedTakeoffAssembly?.linedasharray || "",
        isSelected: false,
      },
      selectedTakeoffAssembly?.takeoffid || "",
      selectedTakeoffAssembly?.assemblymeasurementtype || "" // Pass the measurement type
    );
  };

  const renderTrailingLine = () => {
    if (!Array.isArray(temporaryPoints)) {
      console.error("temporaryPoints is not an array:", temporaryPoints);
      return null;
    }
    if (cursorPositionRef?.current && temporaryPoints.length > 0) {
      const lastPoint = temporaryPoints[temporaryPoints.length - 1];
      if (PointAttributeHelpers.evaluatePoint(lastPoint) === false) {
        return null;
      }

      // Get the current cursor position
      const currentCursor = cursorPositionRef.current;

      // Apply polar tracking to adjust the cursor position
      const adjustedCursor = getAdjustedCursorPosition(
        lastPoint,
        currentCursor,
        angleOptions,
        selectedTakeoffAssembly as
          | EstimateAssembly.AsObject
          | ChangeOrderAssembly.AsObject
      );

      return (
        <line
          x1={lastPoint.x}
          y1={lastPoint.y}
          x2={adjustedCursor.x}
          y2={adjustedCursor.y}
          stroke={selectedTakeoffAssembly?.linecolor || "red"}
          strokeWidth={selectedTakeoffAssembly?.linesize || 2}
          strokeDasharray={selectedTakeoffAssembly?.linedasharray || ""}
        />
      );
    }
    return null;
  };

  return (
    <>
      {filteredCountedAssemblies?.map((assembly) => {
        if (
          assembly.assemblymeasurementtype === "length" ||
          assembly.assemblymeasurementtype === "area"
        ) {
          const isSelectedAssembly =
            assembly.takeoffid === selectedTakeoffAssembly?.takeoffid;

          const assemblyStyle = {
            lineColor: assembly.linecolor,
            lineSize: assembly.linesize,
            lineDashArray: assembly.linedasharray,
            isSelected: isSelectedAssembly,
          };

          return renderLinesBetweenPoints(
            assembly.pointsList,
            assemblyStyle,
            assembly.takeoffid,
            assembly.assemblymeasurementtype
          );
        }
        return null;
      })}
      {!isDragging && (
        <>
          {renderTemporaryLines()}
          {renderTrailingLine()}
        </>
      )}
    </>
  );
};
