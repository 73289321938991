import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Alert, Container } from "@mui/material";
import { PrivacyPolicyDialog } from "./PrivacyPolicy";
import {
  AccountFormErrors,
  initialErrors,
  validateEmailFormat,
} from "./helperFile";
import { TermsOfServiceDialog } from "./TermsOfService";
import { UbAccountService } from "../../api/UbAccountService";
import { UB_Account } from "../../api/protosCompiled/ub_account/ub_account_pb";

type CreateAccountProps = {
  accountId: string;
  setAccountId: (accountId: string) => void;
  onNextStep: () => void;
};

export const CreateAccount: React.FC<CreateAccountProps> = ({
  accountId,
  setAccountId,
  onNextStep,
}) => {
  const [accountName, setAccountName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [termsOfService, setTermsOfService] = useState(false);
  const [errors, setErrors] = useState<AccountFormErrors>(initialErrors);
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const [privacyDialogOpen, setPrivacyDialogOpen] = useState(false);
  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountName(e.target.value);
    setErrors((prev) => ({ ...prev, accountName: "" }));
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setErrors((prev) => ({ ...prev, email: "" }));
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
    setErrors((prev) => ({ ...prev, phoneNumber: "" }));
  };

  const handleTermsOfServiceChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTermsOfService(e.target.checked);
    setErrors((prev) => ({ ...prev, terms: "" }));
  };

  const validateForm = () => {
    const newErrors = { ...initialErrors };
    let isValid = true;

    if (!accountName.trim()) {
      newErrors.accountName = "Account name is required";
      isValid = false;
    }

    if (!validateEmailFormat(email)) {
      newErrors.email = "Please enter a valid email address";
      isValid = false;
    }

    if (phoneNumber.length < 10) {
      newErrors.phoneNumber = "Please enter a valid phone number";
      isValid = false;
    }

    if (!termsOfService) {
      newErrors.terms = "You must agree to the terms and conditions";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      setSubmissionError(null);

      try {
        const accountData: UB_Account.AsObject = {
          id: "", //this gets handled in the backend
          name: accountName,
          email: email,
          phone: phoneNumber,
          gcsBucketName: "", //this gets handled in the backend
          numusers: 1,
          isactive: false, //this will get set to true if payments are successful
        };

        const response = await UbAccountService.createAccount(accountData);

        if (response.id) {
          setAccountId(response.id);
          onNextStep(); // Call onNextStep to proceed
        } else {
          setSubmissionError("Failed to create account. Please try again.");
        }
      } catch (error) {
        console.error("Account creation error:", error);
        setSubmissionError("An error occurred while creating the account.");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ mt: 4, mb: 2, textAlign: "center" }}>
        <Typography variant="h5" component="h1">
          Create Account
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="accountName"
              label="Account Name"
              type="text"
              value={accountName}
              onChange={handleAccountNameChange}
              error={!!errors.accountName}
              helperText={errors.accountName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="email"
              label="Email"
              type="email"
              value={email}
              onChange={handleEmailChange}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="phoneNumber"
              label="Phone Number"
              type="tel"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="terms"
                  checked={termsOfService}
                  onChange={handleTermsOfServiceChange}
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  I agree to the{" "}
                  <Link
                    component="button"
                    onClick={() => setTermsDialogOpen(true)}
                  >
                    Terms of Service
                  </Link>{" "}
                  and{" "}
                  <Link
                    component="button"
                    onClick={() => setPrivacyDialogOpen(true)}
                  >
                    Privacy Policy
                  </Link>
                </Typography>
              }
            />
            {errors.terms && <Alert severity="error">{errors.terms}</Alert>}
          </Grid>
          {submissionError && (
            <Grid item xs={12}>
              <Alert severity="error">{submissionError}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting || !email || !termsOfService}
            >
              {isSubmitting ? "Creating Account..." : "Create Account"}
            </Button>
          </Grid>
        </Grid>
      </form>
      <TermsOfServiceDialog
        open={termsDialogOpen}
        onClose={() => setTermsDialogOpen(false)}
      />
      <PrivacyPolicyDialog
        open={privacyDialogOpen}
        onClose={() => setPrivacyDialogOpen(false)}
      />
    </Container>
  );
};
