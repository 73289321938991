import { useStore } from "zustand";
import { useUnityBuildStore } from "../states/store";

export function useSetIsAssemblyMeasurementType() {
  const { setIsCounting, setIsGettingLength, setIsGettingArea } =
    useStore(useUnityBuildStore);

  return (assemblyMeasurementType: string) => {
    setIsCounting(assemblyMeasurementType === "count");
    setIsGettingLength(assemblyMeasurementType === "length");
    setIsGettingArea(assemblyMeasurementType === "area");
  };
}
