import { clients } from "../clients/grpcClients";
import { useUnityBuildStore } from "../states/store";
import {
  DeleteOpenAiFileRequest,
  DeleteOpenAiFileResponse,
  ListOpenAiFilesRequest,
  ListOpenAiFilesResponse,
} from "./protosCompiled/ubAdmin/ubAdmin_pb";

const client = clients.ubAdminServiceClient;

export class UbAdminService {
  private static getMetadata() {
    const sessionToken = useUnityBuildStore.getState().sessionToken;
    if (!sessionToken) {
      console.error("Session token is missing!");
    }
    return {
      sessionToken,
    };
  }

  public static getOpenAiFileList = (): Promise<
    ListOpenAiFilesResponse.AsObject["openaifilesList"]
  > => {
    return new Promise((resolve, reject) => {
      const req = new ListOpenAiFilesRequest();
      req.setSessiontoken(this.getMetadata().sessionToken);
      client.listOpenAiFiles(
        req,
        {},
        (err, response: ListOpenAiFilesResponse) => {
          if (err) {
            reject(err);
            return;
          }

          // Access the list of files from the response
          const filesList = response.toObject().openaifilesList;
          resolve(filesList);
        }
      );
    });
  };

  public static deleteOpenAiFile = (
    fileId: string
  ): Promise<DeleteOpenAiFileResponse.AsObject> => {
    return new Promise((resolve, reject) => {
      const req = new DeleteOpenAiFileRequest();
      req.setFileid(fileId);
      req.setSessiontoken(this.getMetadata().sessionToken);
      client.deleteOpenAiFile(
        req,
        {},
        (err, response: DeleteOpenAiFileResponse) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(response.toObject());
        }
      );
    });
  };
}
