import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Calculations as c } from "../../utils/calculations";
import { useStore } from "zustand";
import { useCloseoutStore } from "../../states/closeoutStore";
import { EquipmentRowProps } from "./closeoutTypes";

export const EquipmentRow: React.FC<
  EquipmentRowProps & {
    handleDelete: (name: string) => void;
    handleFieldUpdate: (field: string, value: number) => void;
  }
> = ({ equipment, handleDelete, handleFieldUpdate }) => {
  const { includedEquipmentTypes } = useStore(useCloseoutStore);
  const [quantity, setQuantity] = useState<string>(
    equipment.quantity.toFixed(2)
  );

  const [duration, setDuration] = useState<string>(
    equipment.duration.toFixed(2)
  );
  const [unitcost, setUnitCost] = useState<string>(
    equipment.unitcost.toFixed(2)
  );

  useEffect(() => {
    setQuantity(equipment.quantity.toFixed(2));
    setDuration(equipment.duration.toFixed(2));
    setUnitCost(equipment.unitcost.toFixed(2));
  }, [equipment, includedEquipmentTypes]);

  const handleFieldChange = (field: string, value: string) => {
    // Allow only numbers and the decimal point
    const regex = /^[0-9]*\.?[0-9]{0,2}$/;
    if (!regex.test(value)) return;

    switch (field) {
      case "quantity":
        if (parseFloat(value) > 9999.99) return;
        setQuantity(value);
        break;
      case "duration":
        if (parseFloat(value) > 9999.99) return;
        setDuration(value);
        break;
      case "unitcost":
        if (parseFloat(value) > 9999.99) return;
        setUnitCost(value);
        break;
      default:
        break;
    }
  };

  const handleBlur = (field: string, value: string) => {
    const floatValue = parseFloat(value) || 0;

    switch (field) {
      case "quantity":
        setQuantity(value);
        handleFieldUpdate(field, floatValue);
        break;
      case "duration":
        setDuration(value);
        handleFieldUpdate(field, floatValue);
        break;
      case "unitcost":
        setUnitCost(value);
        handleFieldUpdate(field, floatValue);
        break;
      default:
        break;
    }
  };

  const validQuanity = isNaN(parseFloat(quantity)) ? 0 : parseFloat(quantity);
  const validDuration = isNaN(parseFloat(duration)) ? 0 : parseFloat(duration);
  const validUnitCost = isNaN(parseFloat(unitcost)) ? 0 : parseFloat(unitcost);

  const fullCost = c.calculateEquipmentTotal({
    ...equipment,
    quantity: validQuanity,
    unitcost: validUnitCost,
    duration: validDuration,
  });

  return (
      <tr>
        {/* Description */}
        <td>
          <button onClick={() => handleDelete(equipment.name)} style={{width:"40px"}}>
            <DeleteIcon />
          </button>
          {equipment.name}
        </td>
        {/* Quantity */}
        <td>
          <input
            type="text"
            value={quantity}
            onChange={(e) => handleFieldChange("quantity", e.target.value)}
            onBlur={(e) => handleBlur("quantity", e.target.value)}
          />
        </td>
        {/* Duration */}
        <td>
          <input
            type="text"
            value={duration}
            onChange={(e) => handleFieldChange("duration", e.target.value)}
            onBlur={(e) => handleBlur("duration", e.target.value)}
          />
        </td>
        {/* Unit Cost */}
        <td>
          <input
            type="text"
            value={unitcost}
            onChange={(e) => handleFieldChange("unitcost", e.target.value)}
            onBlur={(e) => handleBlur("unitcost", e.target.value)}
          />
        </td>
        {/* Total Cost */}
        <td >${fullCost.toFixed(2)}</td>
      </tr>
  );
};
